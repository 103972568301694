import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import {useSelector} from 'react-redux';
import {TextField} from '@material-ui/core';
import styles from './Autocomplete.module.scss';
// import adaCodes from 'data/adaCodeList.json';

function WestonAutocomplete(props) {
  const [search, setSearch] = React.useState('');
  const adaCodes = useSelector(
    ({apiCallsReducer}) => apiCallsReducer?.adaCodeList,
  );
  const filterOptions = React.useMemo(
    () =>
      createFilterOptions({
        limit: 20,
        trim: true,
      }),
    [],
  );

  return (
    <div className="mt-1 fw">
      <Autocomplete
        filterOptions={filterOptions}
        data-html2canvas-ignore="true"
        fullWidth={true}
        freeSolo={true}
        options={props.options}
        inputValue={search}
        value={props.value}
        defaultValue={props.value}
        onChange={(_, value) => {
          props.onChange(props.name, value);
        }}
        onInputChange={(_, newInputValue) => {
          setSearch(newInputValue);
        }}
        disableClearable
        getOptionLabel={(option) =>
          option ? `${option} - ${adaCodes[option] || ''}` : ''
        }
        renderInput={(params) => (
          <TextField
            {...params}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                console.log(ev);
              }
            }}
            label="Search Code"
            variant="outlined"
          />
        )}
        className={`${styles.autoComplete} ${
          props.disabled ? styles.disabled : ''
        } ${props.value ? '' : styles.empty}`}
      />
      {props.error && <p className={styles.redAlerts}>ADA Code is required</p>}
    </div>
  );
}

WestonAutocomplete.propTypes = {
  value: PropTypes.any,
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

export default WestonAutocomplete;

import {} from './types';

export const parseData = (ADAcodes, JSON, insuranceName) => {
  return async (dispatch) => {
    let data = {};
    if (!ADAcodes) {
      //console.log('invalid codes');
      return data;
    }

    //console.log('incoming ADA COdes');
    //console.log(ADAcodes);
    //console.log(insuranceName);

    if (ADAcodes === 'all') {
      data = parseAllADACodes(JSON);
    } else if (insuranceName === 'CIGNA' || insuranceName === 'PRINCIPAL') {
      let arrayOfIncomingADACodes = Object.keys(ADAcodes);
      arrayOfIncomingADACodes.sort();
      data = parseArrayToSingleADCodes(JSON, arrayOfIncomingADACodes);
    } else {
      let arrayOfIncomingADACodes = Object.keys(ADAcodes);
      arrayOfIncomingADACodes.sort();
      data = parseSingleToSingleADCodes(JSON, arrayOfIncomingADACodes);
    }

    if (JSON.patient?.coverage) {
      data.coverageData = JSON.patient.coverage;
    }
    if (JSON.provider) {
      data.providerData = JSON.provider;
    }
    //console.log('parsed Data to be returned');
    //console.log(data);
    return data;
  };
};

const parseSingleToSingleADCodes = (JSON, arrayOfIncomingADACodes) => {
  let data = {};

  // This is so we can return data just in an array
  let masterArrayOfDeductible = [];
  let masterArrayOfMaximums = [];
  let masterArrayOfCoinsurance = [];
  let masterArrayOfLimitations = [];

  // these are for the inidivudla code objects
  let arrayOfDeductible = [];
  let arrayOfMaximums = [];
  let arrayOfCoinsurance = [];
  let arrayOfLimitations = [];

  var deductible = JSON.deductible;
  var maximums = JSON.maximums;
  var coinsurance = JSON.coinsurance;
  var limitations = JSON.limitations;

  //console.log('arrayOfIncomingADACodes');
  //console.log(arrayOfIncomingADACodes);

  let i = 0;
  if (typeof arrayOfIncomingADACodes !== 'undefined') {
    //Enter loop

    for (i = 0; i < arrayOfIncomingADACodes.length; i++) {
      //console.log('IN LOOOOP');
      let foundADCode = undefined;
      let j = 0;

      if (typeof deductible !== 'undefined') {
        //Enter loop

        for (j = 0; j < deductible.length; j++) {
          const aux = deductible[j].procedure_code
            ? deductible[j].procedure_code.replace('AD:', '')
            : deductible[j].procedure_code;

          //console.log('arrayOfIncomingADACodes[i]');
          //console.log(arrayOfIncomingADACodes[i]);
          //console.log('auxxxxxx');
          //console.log(aux);

          if (arrayOfIncomingADACodes[i] === aux) {
            foundADCode = aux;
            deductible[j].id = deductible[j].index ? deductible[j].index : j;
            //const d = {
            //network         : deductible[j].network,
            //insurance_type  : deductible[j].insurance_type,
            //coverage_level  : deductible[j].coverage_level,
            //amount          : deductible[j].amount,
            //service_type    : deductible[j].service_type,
            //disclaimers     : deductible[j].disclaimers
            //};
            arrayOfDeductible.push(deductible[j]);
            masterArrayOfDeductible.push(deductible[j]);
          }
        }
      }

      if (typeof maximums !== 'undefined') {
        //Enter loop

        for (j = 0; j < maximums.length; j++) {
          const aux = maximums[j].procedure_code
            ? maximums[j].procedure_code.replace('AD:', '')
            : maximums[j].procedure_code;

          if (arrayOfIncomingADACodes[i] === aux) {
            foundADCode = aux;
            //const m = {
            //service_type : maximums[j].service_type
            //};
            maximums[j].id = maximums[j].index ? maximums[j].index : j;
            arrayOfMaximums.push(maximums[j]);
            masterArrayOfMaximums.push(maximums[j]);
          }
        }
      }

      if (typeof coinsurance !== 'undefined') {
        //Enter loop

        for (j = 0; j < coinsurance.length; j++) {
          const aux = coinsurance[j].procedure_code
            ? coinsurance[j].procedure_code.replace('AD:', '')
            : coinsurance[j].procedure_code;

          if (arrayOfIncomingADACodes[i] === aux) {
            foundADCode = aux;
            coinsurance[j].id = coinsurance[j].index ? coinsurance[j].index : j;
            //const c = {
            //network         : coinsurance[j].network,
            //insurance_type  : coinsurance[j].insurance_type,
            //service_type    : coinsurance[j].service_type,
            //percent         : coinsurance[j].percent,
            //limitation      : coinsurance[j].limitation,
            //age_limit       : coinsurance[j].age_limit
            //};
            arrayOfCoinsurance.push(coinsurance[j]);
            masterArrayOfCoinsurance.push(coinsurance[j]);
          }
        }
      }

      if (typeof limitations !== 'undefined') {
        //Enter loop

        for (j = 0; j < limitations.length; j++) {
          const aux = limitations[j].procedure_code
            ? limitations[j].procedure_code.replace('AD:', '')
            : limitations[j].procedure_code;

          if (arrayOfIncomingADACodes[i] === aux) {
            foundADCode = aux;
            //const l = {
            //disclaimers : limitations[j].disclaimers,
            //limitation  : limitations[j].limitation,
            //age_limit   : limitations[j].age_limit
            //};
            limitations[j].id = limitations[j].index ? limitations[j].index : j;
            arrayOfLimitations.push(limitations[j]);
            masterArrayOfLimitations.push(limitations[j]);
          }
        }
      }

      const procedureObj = {
        deductible: arrayOfDeductible,
        maximums: arrayOfMaximums,
        coinsurance: arrayOfCoinsurance,
        limitations: arrayOfLimitations,
      };

      if (typeof foundADCode !== 'undefined') {
        if (data.procedureCodes) {
          data.procedureCodes[foundADCode] = procedureObj;
        } else {
          data.procedureCodes = {
            [foundADCode]: procedureObj,
          };
        }
      }

      arrayOfDeductible = [];
      arrayOfMaximums = [];
      arrayOfCoinsurance = [];
      arrayOfLimitations = [];
    }
  }
  const masterArrays = {
    deductible: masterArrayOfDeductible,
    coinsurance: masterArrayOfCoinsurance,
    maximums: masterArrayOfMaximums,
    limitations: masterArrayOfLimitations,
  };
  return {parsedByCode: data, parsedByServiceType: masterArrays};
};

// processes [D0000, D0001] --> [D0000, ... , D1111] --> D0000
const parseArrayToSingleADCodes = (JSON, arrayOfIncomingADACodes) => {
  let data = {};

  let masterArrayOfDeductible = [];
  let masterArrayOfMaximums = [];
  let masterArrayOfCoinsurance = [];
  let masterArrayOfLimitations = [];

  let arrayOfDeductible = [];
  let arrayOfMaximums = [];
  let arrayOfCoinsurance = [];
  let arrayOfLimitations = [];

  var deductible = JSON.deductible;
  var maximums = JSON.maximums;
  var coinsurance = JSON.coinsurance;
  var limitations = JSON.limitations;

  //AD Format is D0000, therefore doesn't contain AD:

  if (typeof arrayOfIncomingADACodes !== 'undefined') {
    //Enter loop
    for (let i = 0; i < arrayOfIncomingADACodes.length; i++) {
      let foundADCode = undefined;
      let j = 0;

      if (typeof deductible !== 'undefined') {
        //Enter loop
        for (j = 0; j < deductible.length; j++) {
          let arrayOfLimitiationAppliesTo = deductible[j].limitation_applies_to;
          let k = 0;

          if (typeof arrayOfLimitiationAppliesTo !== 'undefined') {
            //Enter loop
            for (k = 0; k < arrayOfLimitiationAppliesTo.length; k++) {
              const aux = arrayOfLimitiationAppliesTo[k]
                ? arrayOfLimitiationAppliesTo[k]
                : undefined;

              if (arrayOfIncomingADACodes[i] === aux) {
                foundADCode = aux;
                //const d = {
                //network         : deductible[j].network,
                //insurance_type  : deductible[j].insurance_type,
                //coverage_level  : deductible[j].coverage_level,
                //amount          : deductible[j].amount,
                //service_type    : deductible[j].service_type,
                //disclaimers     : deductible[j].disclaimers
                //};
                // deductible[j].limitation_applies_to = foundADCode;
                deductible[j].procedure_code = foundADCode;
                deductible[j].id = deductible[j].index ? deductible[j].index : j;

                arrayOfDeductible.push(deductible[j]);
                masterArrayOfDeductible.push(deductible[j]);
              }
            }
          }
        }
      }

      if (typeof maximums !== 'undefined') {
        //Enter loop
        for (j = 0; j < maximums.length; j++) {
          let arrayOfLimitiationAppliesTo = maximums[j].limitation_applies_to;
          let k = 0;

          if (typeof arrayOfLimitiationAppliesTo !== 'undefined') {
            //Enter loop
            for (k = 0; k < arrayOfLimitiationAppliesTo.length; k++) {
              const aux = arrayOfLimitiationAppliesTo[k]
                ? arrayOfLimitiationAppliesTo[k]
                : undefined;

              if (arrayOfIncomingADACodes[i] === aux) {
                foundADCode = aux;
                //const m = {
                //service_type : maximums[j].service_type
                //};
                // maximums[j].limitation_applies_to = foundADCode;
                maximums[j].procedure_code = foundADCode;
                maximums[j].id = maximums[j].index ? maximums[j].index : j;
                arrayOfMaximums.push(maximums[j]);
                masterArrayOfMaximums.push(maximums[j]);
              }
            }
          }
        }
      }

      if (typeof coinsurance !== 'undefined') {
        //Enter loop
        for (j = 0; j < coinsurance.length; j++) {
          let arrayOfLimitiationAppliesTo =
            coinsurance[j].limitation_applies_to;
          let k = 0;

          if (typeof arrayOfLimitiationAppliesTo !== 'undefined') {
            //Enter loop
            for (k = 0; k < arrayOfLimitiationAppliesTo.length; k++) {
              const aux = arrayOfLimitiationAppliesTo[k]
                ? arrayOfLimitiationAppliesTo[k]
                : undefined;

              if (arrayOfIncomingADACodes[i] === aux) {
                foundADCode = aux;
                //const c = {
                //network         : coinsurance[j].network,
                //insurance_type  : coinsurance[j].insurance_type,
                //service_type    : coinsurance[j].service_type,
                //percent         : coinsurance[j].percent,
                //limitation      : coinsurance[j].limitation,
                //age_limit       : coinsurance[j].age_limit
                //};
                // coinsurance[j].limitation_applies_to = foundADCode;
                coinsurance[j].procedure_code = foundADCode;
                coinsurance[j].id = coinsurance[j].index
                  ? coinsurance[j].id
                  : j;

                arrayOfCoinsurance.push(coinsurance[j]);
                masterArrayOfCoinsurance.push(coinsurance[j]);
              }
            }
          }
        }
      }

      if (typeof limitations !== 'undefined') {
        //Enter loop
        for (j = 0; j < limitations.length; j++) {
          let arrayOfLimitiationAppliesTo =
            limitations[j].limitation_applies_to;
          let k = 0;

          if (typeof arrayOfLimitiationAppliesTo !== 'undefined') {
            //Enter loop
            for (k = 0; k < arrayOfLimitiationAppliesTo.length; k++) {
              const aux = arrayOfLimitiationAppliesTo[k]
                ? arrayOfLimitiationAppliesTo[k]
                : undefined;

              if (arrayOfIncomingADACodes[i] === aux) {
                foundADCode = aux;
                //const l = {
                //disclaimers : limitations[j].disclaimers,
                //limitation  : limitations[j].limitation,
                //age_limit   : limitations[j].age_limit
                //};

                // This is to change this limitation applies to from array to single ADA Code
                // limitations[j].limitation_applies_to = foundADCode;
                limitations[j].procedure_code = foundADCode;
                limitations[j].id = limitations[j].index
                  ? limitations[j].id
                  : j;
                arrayOfLimitations.push(limitations[j]);
                masterArrayOfLimitations.push(limitations[j]);
              }
            }
          }
        }
      }

      const procedureObj = {
        deductible: arrayOfDeductible,
        maximums: arrayOfMaximums,
        coinsurance: arrayOfCoinsurance,
        limitations: arrayOfLimitations,
      };

      if (typeof foundADCode !== 'undefined') {
        if (data.procedureCodes) {
          data.procedureCodes[foundADCode] = procedureObj;
        } else {
          data.procedureCodes = {
            [foundADCode]: procedureObj,
          };
        }
      }

      arrayOfDeductible = [];
      arrayOfMaximums = [];
      arrayOfCoinsurance = [];
      arrayOfLimitations = [];
    }
  }
  const masterArrays = {
    deductible: masterArrayOfDeductible,
    coinsurance: masterArrayOfCoinsurance,
    maximums: masterArrayOfMaximums,
    limitations: masterArrayOfLimitations,
  };
  return {parsedByCode: data, parsedByServiceType: masterArrays};
};

export const parseGeneralFilterData = (filtering, JSON) => {
  return async (dispatch) => {
    let data = {};
    //console.log(filtering);
    //console.log(JSON);
    if (!filtering) {
      return data;
    }
    // Below is to filter out only actively searched filters
    const arrayOfFilterKeys = Object.keys(filtering);
    const arrayOfFilterValues = Object.values(filtering);
    let activeFilterArray = [];
    let anyFilter = false;
    arrayOfFilterValues.forEach((ele, index) => {
      if (ele && ele !== '') {
        anyFilter = true;
        activeFilterArray.push({[arrayOfFilterKeys[index]]: ele});
      }
    });
    if (!anyFilter) {
      // This is if no filters are selected just return empty
      return data;
    }

    data = parseGeneralFilter(JSON, activeFilterArray);

    return data;
  };
};

const parseGeneralFilter = (JSON, activeFilterObject) => {
  let procedureObj = {};

  let arrayOfDeductible = [];
  let arrayOfMaximums = [];
  let arrayOfCoinsurance = [];
  let arrayOfLimitations = [];

  const deductible = JSON.deductible;
  const maximums = JSON.maximums;
  const coinsurance = JSON.coinsurance;
  const limitations = JSON.limitations;

  if (typeof activeFilterObject !== 'undefined') {
    //Enter loop

    if (typeof deductible !== 'undefined') {
      //Enter loop
      for (let j = 0; j < deductible.length; j++) {
        // const aux = deductible[j].procedure_code;

        let countOfTrueSearch = 0;
        // This is looping through thee filters that are active which is like this [ {service_type: 'orthodontics'}]
        activeFilterObject.forEach((ele) => {
          const key1 = Object.keys(ele);
          const key = key1[0];
          const value = ele[key];
          // This is checking if that specific key exists and if the value of that key in the json equals orthodontics for example
          // The 'all' is to set the default value where it will add any data point that has a service type
          if (
            value === 'all' &&
            ((!deductible[j].procedure_code &&
              !deductible[j].limitation_applies_to) ||
              deductible[j].service_type)
          ) {
            countOfTrueSearch = countOfTrueSearch + 1;
          } else if (
            deductible[j][key] &&
            deductible[j][key] === value &&
            (!deductible[j].procedure_code ||
              Array.isArray(deductible[j].limitation_applies_to))
          ) {
            // If it is found, then set filter found to true and we will add the object to the filtered result
            countOfTrueSearch = countOfTrueSearch + 1;
          }
          return;
        });
        // Basically if the length of the amount of filters that are true equals the total length of filters add to array
        if (countOfTrueSearch === activeFilterObject.length) {
          // Below is becase some have category instead of service type
          if (deductible[j].category) {
            deductible[j].service_type = deductible[j].category;
          }
          // This is for capitalizing
          // const innerValues = Object.values(deductible[j]);
          // const innerKeys = Object.keys(deductible[j]);
          // // This will give array like [ individual, group, 80%, etc.]
          // innerValues.forEach((obj, index) => {
          //   if (obj && obj.length > 1 && !Array.isArray(obj)) {
          //     let capitalized = obj.replace(/\b(\w)/g, (s) => s.toUpperCase());
          //     capitalized = capitalized ? capitalized.replace(/_/g, ' ') : '';
          //     deductible[j][innerKeys[index]] = capitalized;
          //   }
          // });
          deductible[j].id = deductible[j].index ? deductible[j].index : j;
          arrayOfDeductible.push(deductible[j]);
        }
      }
    }

    if (typeof maximums !== 'undefined') {
      //Enter loop
      for (let j = 0; j < maximums.length; j++) {
        // const aux = deductible[j].procedure_code;

        let countOfTrueSearch = 0;
        // This is looping through thee filtres that are active which is like this [ {service_type: 'orthodontics'}]
        activeFilterObject.forEach((ele) => {
          const key1 = Object.keys(ele);
          const key = key1[0];
          const value = ele[key];
          // This is checking if that specific key exists and if the value of that key in the json equals orthodontics for example
          // The 'all' is to set the default value where it will add any data point that has a service type
          if (
            value === 'all' &&
            ((!maximums[j].procedure_code &&
              !maximums[j].limitation_applies_to) ||
              maximums[j].service_type)
          ) {
            countOfTrueSearch = countOfTrueSearch + 1;
          } else if (
            maximums[j][key] &&
            maximums[j][key] === value &&
            (!maximums[j].procedure_code ||
              Array.isArray(maximums[j].limitation_applies_to))
          ) {
            // If it is found, then set filter found to true and we will add the object to the filtered result
            countOfTrueSearch = countOfTrueSearch + 1;
          }
          return;
        });
        // Basically if the length of the amount of filters that are true equals the total length of filters add to array
        if (countOfTrueSearch === activeFilterObject.length) {
          // Below is becase some have category instead of service type
          if (maximums[j].category) {
            maximums[j].service_type = maximums[j].category;
          }
          // const innerValues = Object.values(maximums[j]);
          // const innerKeys = Object.keys(maximums[j]);
          // // This will give array like [ individual, group, 80%, etc.]
          // innerValues.forEach((obj, index) => {
          //   if (obj && obj.length > 1 && !Array.isArray(obj)) {
          //     let capitalized = obj.replace(/\b(\w)/g, (s) => s.toUpperCase());
          //     capitalized = capitalized ? capitalized.replace(/_/g, ' ') : '';
          //     maximums[j][innerKeys[index]] = capitalized;
          //   }
          // });
          maximums[j].id = maximums[j].index ? maximums[j].index : j;
          arrayOfMaximums.push(maximums[j]);
        }
      }
    }

    if (typeof coinsurance !== 'undefined') {
      //Enter loop

      for (let j = 0; j < coinsurance.length; j++) {
        // const aux = deductible[j].procedure_code;

        let countOfTrueSearch = 0;
        // This is looping through thee filtres that are active which is like this [ {service_type: 'orthodontics'}]
        activeFilterObject.forEach((ele) => {
          const key1 = Object.keys(ele);
          const key = key1[0];
          const value = ele[key];
          // This is checking if that specific key exists and if the value of that key in the json equals orthodontics for example
          // The 'all' is to set the default value where it will add any data point that has a service type
          if (
            value === 'all' &&
            ((!coinsurance[j].procedure_code &&
              !coinsurance[j].limitation_applies_to) ||
              coinsurance[j].service_type)
          ) {
            countOfTrueSearch = countOfTrueSearch + 1;
          } else if (
            coinsurance[j][key] &&
            coinsurance[j][key] === value &&
            (!coinsurance[j].procedure_code ||
              Array.isArray(coinsurance[j].limitation_applies_to))
          ) {
            // If it is found, then set filter found to true and we will add the object to the filtered result
            countOfTrueSearch = countOfTrueSearch + 1;
          }
          return;
        });
        // Basically if the length of the amount of filters that are true equals the total length of filters add to array
        if (countOfTrueSearch === activeFilterObject.length) {
          // Below is becase some have category instead of service type
          if (coinsurance[j].category) {
            coinsurance[j].service_type = coinsurance[j].category;
          }
          // const innerValues = Object.values(coinsurance[j]);
          // const innerKeys = Object.keys(coinsurance[j]);
          // // This will give array like [ individual, group, 80%, etc.]
          // innerValues.forEach((obj, index) => {
          //   if (obj && obj.length > 1 && !Array.isArray(obj)) {
          //     let capitalized = obj.replace(/\b(\w)/g, (s) => s.toUpperCase());
          //     capitalized = capitalized ? capitalized.replace(/_/g, ' ') : '';
          //     coinsurance[j][innerKeys[index]] = capitalized;
          //   }
          // });
          coinsurance[j].id = coinsurance[j].index ? coinsurance[j].index : j;
          arrayOfCoinsurance.push(coinsurance[j]);
        }
      }
    }

    if (typeof limitations !== 'undefined') {
      //Enter loop

      for (let j = 0; j < limitations.length; j++) {
        // const aux = deductible[j].procedure_code;

        let countOfTrueSearch = 0;
        // This is looping through thee filtres that are active which is like this [ {service_type: 'orthodontics'}]
        activeFilterObject.forEach((ele) => {
          const key1 = Object.keys(ele);
          const key = key1[0];
          const value = ele[key];
          // This is checking if that specific key exists and if the value of that key in the json equals orthodontics for example
          // The 'all' is to set the default value where it will add any data point that has a service type
          if (
            value === 'all' &&
            ((!limitations[j].procedure_code &&
              !limitations[j].limitation_applies_to) ||
              limitations[j].service_type)
          ) {
            countOfTrueSearch = countOfTrueSearch + 1;
          } else if (
            limitations[j][key] &&
            limitations[j][key] === value &&
            (!limitations[j].procedure_code ||
              Array.isArray(limitations[j].limitation_applies_to))
          ) {
            // If it is found, then set filter found to true and we will add the object to the filtered result
            countOfTrueSearch = countOfTrueSearch + 1;
          }
          return;
        });
        // Basically if the length of the amount of filters that are true equals the total length of filters add to array
        if (countOfTrueSearch === activeFilterObject.length) {
          // Below is becase some have category instead of service type
          if (limitations[j].category) {
            limitations[j].service_type = limitations[j].category;
          }
          // const innerValues = Object.values(limitations[j]);
          // const innerKeys = Object.keys(limitations[j]);
          // // This will give array like [ individual, group, 80%, etc.]
          // innerValues.forEach((obj, index) => {
          //   if (obj && obj.length > 1 && !Array.isArray(obj)) {
          //     ////console.log('obj');
          //     //console.log(obj);
          //     let capitalized = obj.replace(/\b(\w)/g, (s) => s.toUpperCase());
          //     capitalized = capitalized ? capitalized.replace(/_/g, ' ') : '';
          //     limitations[j][innerKeys[index]] = capitalized;
          //   }
          // });
          limitations[j].id = limitations[j].index ? limitations[j].index : j;
          arrayOfLimitations.push(limitations[j]);
        }
      }
    }

    procedureObj = {
      deductible: arrayOfDeductible,
      maximums: arrayOfMaximums,
      coinsurance: arrayOfCoinsurance,
      limitations: arrayOfLimitations,
    };

    // if (typeof foundADCode !== 'undefined') {
    //   if (data.procedureCodes) {
    //     data.procedureCodes[foundADCode] = procedureObj;
    //   } else {
    //     data.procedureCodes = {
    //       [foundADCode]: procedureObj,
    //     };
    //   }
    // }
  }
  //console.log('procedureObj in filter parse action');
  //console.log(procedureObj);
  return procedureObj;
};

const parseAllADACodes = (JSON) => {
  // This is so we can return data just in an array
  let masterArrayOfDeductible = [];
  let masterArrayOfMaximums = [];
  let masterArrayOfCoinsurance = [];
  let masterArrayOfLimitations = [];

  let deductible = JSON.deductible;
  let maximums = JSON.maximums;
  let coinsurance = JSON.coinsurance;
  let limitations = JSON.limitations;

  let j = 0;

  if (typeof deductible !== 'undefined') {
    //Enter loop

    for (j = 0; j < deductible.length; j++) {
      let procedure = '';
      if (typeof deductible[j].procedure_code === 'string') {
        procedure = deductible[j].procedure_code
          ? deductible[j].procedure_code.replace('AD:', '')
          : deductible[j].procedure_code;
      }

      if (procedure || deductible[j].limitation_applies_to) {
        // const innerValues = Object.values(deductible[j]);
        // const innerKeys = Object.keys(deductible[j]);

        // // This will give array like [ individual, group, 80%, etc.]
        // innerValues.forEach((obj, index) => {
        //   if (obj && obj.length > 1 && !Array.isArray(obj)) {
        //     let capitalized = obj.replace(/\b(\w)/g, (s) => s.toUpperCase());
        //     capitalized = capitalized ? capitalized.replace(/_/g, ' ') : '';
        //     deductible[j][innerKeys[index]] = capitalized;
        //   }
        // });
        deductible[j].id = deductible[j].index ? deductible[j].index : j;
        deductible[j].procedure_code = deductible[j]?.limitation_applies_to
          ? deductible[j].limitation_applies_to
          : procedure;
        masterArrayOfDeductible.push(deductible[j]);
      }
    }
  }

  if (typeof maximums !== 'undefined') {
    //Enter loop
    for (j = 0; j < maximums.length; j++) {
      let procedure = '';
      if (typeof maximums[j].procedure_code === 'string') {
        procedure = maximums[j].procedure_code
          ? maximums[j].procedure_code.replace('AD:', '')
          : maximums[j].procedure_code;
      }
      if (procedure || maximums[j].limitation_applies_to) {
        // const innerValues = Object.values(maximums[j]);
        // const innerKeys = Object.keys(maximums[j]);

        // // This will give array like [ individual, group, 80%, etc.]
        // innerValues.forEach((obj, index) => {
        //   if (obj && obj.length > 1 && !Array.isArray(obj)) {
        //     let capitalized = obj.replace(/\b(\w)/g, (s) => s.toUpperCase());
        //     capitalized = capitalized ? capitalized.replace(/_/g, ' ') : '';
        //     maximums[j][innerKeys[index]] = capitalized;
        //   }
        // });
        maximums[j].id = maximums[j].index ? maximums[j].index : j;
        maximums[j].procedure_code = maximums[j].limitation_applies_to
          ? maximums[j].limitation_applies_to
          : procedure;
        masterArrayOfMaximums.push(maximums[j]);
      }
    }
  }

  if (typeof coinsurance !== 'undefined') {
    //Enter loop

    for (j = 0; j < coinsurance.length; j++) {
      let procedure = '';
      if (typeof coinsurance[j].procedure_code === 'string') {
        procedure = coinsurance[j].procedure_code
          ? coinsurance[j].procedure_code.replace('AD:', '')
          : coinsurance[j].procedure_code;
      }
      if (procedure || coinsurance[j].limitation_applies_to) {
        // const innerValues = Object.values(coinsurance[j]);
        // const innerKeys = Object.keys(coinsurance[j]);

        // // This will give array like [ individual, group, 80%, etc.]
        // innerValues.forEach((obj, index) => {
        //   if (obj && obj.length > 1 && !Array.isArray(obj)) {
        //     let capitalized = obj.replace(/\b(\w)/g, (s) => s.toUpperCase());
        //     capitalized = capitalized ? capitalized.replace(/_/g, ' ') : '';
        //     coinsurance[j][innerKeys[index]] = capitalized;
        //   }
        // });
        coinsurance[j].id = coinsurance[j].index ? coinsurance[j].index : j;
        coinsurance[j].procedure_code = coinsurance[j].limitation_applies_to
          ? coinsurance[j].limitation_applies_to
          : procedure;
        masterArrayOfCoinsurance.push(coinsurance[j]);
      }
    }
  }

  if (typeof limitations !== 'undefined') {
    //Enter loop

    for (j = 0; j < limitations.length; j++) {
      let procedure = '';
      if (typeof limitations[j].procedure_code === 'string') {
        procedure = limitations[j].procedure_code
          ? limitations[j].procedure_code.replace('AD:', '')
          : limitations[j].procedure_code;
      }
      if (procedure || limitations[j].limitation_applies_to) {
        // const innerValues = Object.values(limitations[j]);
        // const innerKeys = Object.keys(limitations[j]);

        // // This will give array like [ individual, group, 80%, etc.]
        // innerValues.forEach((obj, index) => {
        //   if (obj && obj.length > 1 && !Array.isArray(obj)) {
        //     let capitalized = obj.replace(/\b(\w)/g, (s) => s.toUpperCase());
        //     capitalized = capitalized ? capitalized.replace(/_/g, ' ') : '';
        //     limitations[j][innerKeys[index]] = capitalized;
        //   }
        // });
        limitations[j].id = limitations[j].index ? limitations[j].index : j;
        limitations[j].procedure_code = limitations[j].limitation_applies_to
          ? limitations[j].limitation_applies_to
          : procedure;
        masterArrayOfLimitations.push(limitations[j]);
      }
    }
  }
  const masterArrays = {
    deductible: masterArrayOfDeductible,
    coinsurance: masterArrayOfCoinsurance,
    maximums: masterArrayOfMaximums,
    limitations: masterArrayOfLimitations,
  };
  return {parsedByServiceType: masterArrays};
};

import React from 'react';
import PropTypes from 'prop-types';
import MaterialTooltip from '@material-ui/core/Tooltip';
import styles from './Tooltip.module.scss';

function Tooltip(props) {
  return (
    <MaterialTooltip
      title={<span className={styles.tooltip}>{props.title}</span>}>
      {props.children}
    </MaterialTooltip>
  );
}

Tooltip.propTypes = {
  title: PropTypes.any.isRequired,
  children: PropTypes.element.isRequired,
};

export default Tooltip;

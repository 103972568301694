const options = {
  employerInformation: {
    phoneNumber: [],
    groupName: [],
    note: [],
    PPOOption: ['Yes', 'No'],
    DMOOption: ['Yes', 'No'],
    FSAHSA: ['Yes', 'No'],
    medical: ['Yes', 'No'],
    dentalCarrier: [],
    planType: [],
    carrierPhone: [],
    faxClaims: ['Yes', 'No'],
    claimFaxNumber: [],
    claimFaxNumberNotes: [],
    ECEC: [],
    EClaims: ['Yes', 'No'],
    requirements: [],
    eligibilityRequest: ['Yes', 'No'],
    EAttachment: ['Yes', 'No'],
    mailClaimsTo: [],
    previousCarrier: [],
    previousCarrierLocation: [],
  },
  general: {
    deductibleDollar: [
      '25',
      '50',
      '75',
      '100',
      '125',
      '150',
      '200',
      '250',
      '300',
      '350',
      '400',
      '450',
      '500',
    ],
    deductibleNote: [
      'Individual deductible applies',
      'Family deductible applies',
    ],
    familyDeductibleDollar: [
      '25',
      '50',
      '75',
      '100',
      '125',
      '150',
      '200',
      '250',
      '300',
      '400',
      '500',
    ],
    familyDeductibleNote: [
      'Individual deductible applies',
      'Family deductible applies',
    ],
    annualMaximumDollar: [
      '250',
      '500',
      '1000',
      '1500',
      '1750',
      '2000',
      '2500',
      '3000',
      '4000',
      '5000',
      '7500',
    ],
    maximumNote: [],
    benefitYear: ['Calendar Year', 'Fiscal Year'],
    dependentAge: [
      'Children under age',
      '18',
      '19',
      '20',
      '21',
      '25',
      '26',
      'Not applicable',
    ],
    dependentStudentAge: [
      'apply',
      'does not apply',
      'Yes',
      'No',
      '18',
      '21',
      '26',
    ],
    preventivePercent: ['100', '80', '0', '75', '50'],
    preventiveNote: [],
    basicPercent: ['80', '100', '50', '75', '0', '25'],
    basicNote: [],
    majorPercent: ['50', '80', '75', '0', '25'],
    majorNote: [],
    signatureOnFile: ['Yes', 'No'],
    standardADAForm: ['Yes', 'No'],
    coordinatorRule: ['Yes', 'No'],
    coordinationCOB: ['Birthday Rule', 'Standard', 'Non Duplicate'],
    comments: [
      'Waiting period for basic',
      'Waiting period for major',
      'No Waiting Period',
      'Waiting period for both basic and major',
      'Not applicable',
    ],
    AOB: ['Yes', 'No', 'Provider', 'Patient'],
  },
  preventive: {
    cleaningsAndFlouride: {
      prophy11101120: ['Preventative'],
      prophyLimitations: [
        'Twice in a Calendar Year',
        'Twice in a Fiscal Year',
        'Once in a Calendar Year',
        'Once in a Fiscal Year',
        'Must wait 6 months between two prophies',
      ],
      fluoride: ['Preventative'],
      fluorideLimitations: [
        'Twice in a Calendar Year',
        'Twice in a Fiscal Year',
        'Once in a Calendar Year',
        'Once in a Fiscal Year',
      ],
      fluorideVarnish: ['Preventative'],
      fluorideVarnishLimitations: [
        'Twice in a Calendar Year',
        'Twice in a Fiscal Year',
        'Once in a Calendar Year',
        'Once in a Fiscal Year',
      ],
      comments: [],
    },
    exams: {
      periodicExam: ['Preventative'],
      periodicExamLimitations: [
        'Twice in a Calendar Year',
        'Twice in a Fiscal Year',
        'Once in a Calendar Year',
        'Once in a Fiscal Year',
        'Total 2 exams allowed in a year',
        'Combined with other exams',
      ],
      exam: [],
      initialExam: ['Preventative'],
      initialExamLimitations: [
        'Once per provider',
        'Once in 3 years',
        'Once in 3 years per provider',
        'Combined with other exams',
      ],
      examLimitations: [],
      periodontalExam: ['Preventative'],
      periodontalExamLimitations: [
        'Twice in a Calendar Year',
        'Twice in a Fiscal Year',
        'Once in a Calendar Year',
        'Once in a Fiscal Year',
        'Total 2 exams allowed in a year',
        'Combined with other exams',
        'Once per provider',
        'Once every 3 years',
      ],
      limitedOralExamProblemFocusedExam: ['Preventative'],
      limitedOralExamProblemFocusedExamLimitations: [
        'Twice in a Calendar Year',
        'Twice in a Fiscal Year',
        'Once in a Calendar Year',
        'Once in a Fiscal Year',
        'Total 2 exams allowed in a year',
        'Combined with other exams',
      ],
    },
    xrays: {
      periapical: ['Preventative'],
      periapicalLimitations: [
        'Twice in a Calendar Year',
        'Twice in a Fiscal Year',
        'Once in a Calendar Year',
        'Once in a Fiscal Year',
        'Combined with FMX',
      ],
      bitewings: ['Preventative'],
      bitewingsLimitations: [
        'Twice in a Calendar Year',
        'Twice in a Fiscal Year',
        'Once in a Calendar Year',
        'Once in a Fiscal Year',
        'Combined with FMX',
        'Under 18 covered twice a year and over 18 once a year ',
      ],
      verticalBitewings: ['Preventative'],
      verticalBitewingsLimitations: ['Combined with Bite Wings D0274, D0272'],
      fullMouthXRay: ['Preventative'],
      fullMouthXRayLimitations: [
        'Once in 3 years',
        'Once in 5 years',
        'Once per provider in 3 years',
        ' Once per provider',
      ],
      panorex: ['Preventative'],
      panorexLimitations: [
        'Once in 3 years',
        'Once in 5 years',
        'Combined with FMX',
        'Once per provider in 3 years',
        ' Once per provider',
      ],
    },
    otherServices: {
      sealants: ['Preventative', 'Not Covered'],
      sealantsToothChoicesAndAgeLimitations: [
        'Covered Once a lifetime',
        'Covered once 24 months',
        'Covered under 14 years',
        'Covered under 15 years',
        'Covered under 19 years',
        'Covered under 18 years',
      ],
      cancerScreening: ['Preventative', 'Not Covered'],
      cancerScreeningLimitations: [
        'Covered',
        'Not Covered',
        'Covered every 6 months',
        'Covered every 2 years',
      ],
      palliative: ['Basic', 'Major', 'Preventative', 'Not Covered'],
      palliativeLimitations: [
        'One xray allowed same day',
        'covered Once a day',
        'Covered twice a year',
        'Covered once a year',
        'Same day treatment not allowed',
      ],
      spaceMaintainers: ['Preventative', 'Basic'],
      spaceMaintainersLimitations: [
        'Children under 17',
        'Children under 15',
        'Children under 18',
        'Children Under 14',
        'Children under 12',
        'Once in a Lifetime',
      ],
    },
  },
  basic: {
    fillings: {
      fillings: ['Basic', 'Major', 'Preventative'],
      fillingsNotes: ['Once Every 24 months'],
      posteriorComposites: ['Basic', 'Major', 'Preventative'],
      posteriorCompositesLimitations: [
        'Posterior composites allowed',
        'Downgraded',
        'Alternate benefits paid',
      ],
    },
    endodontics: {
      pulpotomy: ['Covered', 'Not Covered', 'Basic', 'Major', 'Preventative'],
      anteriorPremolarRootCanal: [
        'Major',
        'Basic',
        'Covered',
        'Not Covered',
        'Preventative',
      ],
      rootCanal: ['Major', 'Basic', 'Covered', 'Not Covered', 'Preventative'],
    },
    oralSurgeryExtractions: {
      boneGraft: [
        'Covered',
        'Not Covered',
        'Basic',
        'Major',
        'Covered at the time of extraction only',
      ],
      extraction: ['Covered', 'Not Covered', 'Basic', 'Major'],
      erupted: ['Covered', 'Not Covered', 'Basic', 'Major'],
      eruptedLimitations: ['Paid by Dental Insurance'],
      softTissue: ['Covered', 'Not Covered', 'Basic', 'Major'],
      softTissueLimitations: ['Paid by Dental Insurance'],
      partialBony: ['Covered', 'Not Covered', 'Basic', 'Major'],
      partialBonyLimitations: ['Paid by Dental Insurance'],
      completeBony: ['Covered', 'Not Covered', 'Basic', 'Major'],
      completeBonyLimitations: ['Paid by Dental Insurance'],
    },
    periodontal: {
      osseous: ['Major', 'Basic'],
      osseousLimitations: [
        'Covered',
        'Not Covered',
        'Covered every 2 years',
        'Pre-approval required',
      ],
      gingivectomy: ['Major', 'Basic'],
      gingivectomyLimitations: [
        'Covered',
        'Not Covered',
        'Covered every 5 years',
        'Pre-approval required',
      ],
      crownLengthening: ['Major', 'Basic'],
      crownLengtheningLimitations: [
        'Covered',
        'Not Covered',
        'Covered once per lifetime of the tooth',
        'Covered every 5 years per tooth',
        'Pre-approval required',
      ],
      tissueGraft: ['Major', 'Basic'],
      tissueGraftLimitations: [
        'Covered',
        'Not Covered',
        'Covered every 2 years',
        'Pre-approval required',
      ],
      surgicalPeriodontalFrequency: ['Once every 3 years'],
      rootPlanning: ['Major', 'Basic'],
      rootPlanningLimitations: [
        'Once every 2 years',
        'only 2 quadrants allowed in a day',
        '4 Quadrants allowed in a day',
        'Perio Charting Required',
        'FMX Required',
        'Once every year',
      ],
      scalingAndInflammation: ['Preventative', 'Basic', 'Major'],
      scalingAndInflammationLimitations: [
        'Allowed every 2 years',
        'Allowed 2 times a year',
        'Combined with prophy or periodontal Maintenance',
        'Xrays required',
      ],
      debridement: [' Preventative', 'Basic', 'Major'],
      debridementLimitations: [
        'Once in a lifetime',
        'Once every 2 years',
        'Not covered the same day as exam',
        'Combined with prophy or periodontal maintenance',
      ],
      chemoAgent: ['Basic', 'Major'],
      chemoAgentLimitations: [
        'Not Covered',
        'Covered',
        'Once in 2 years',
        'maximum 2 teeth in one quadrant',
        '5-10mm pockets',
      ],
      periodontalTrophy: ['Preventative', 'Basic', 'Major'],
      trophyLimitations: [
        'Twice a year',
        'Combined with SRP or Prophy',
        'Must be atleast 31 days after SRP',
      ],
      extraLimitations: [],
      periodontalComments: ['Perio Charting required', 'Xrays Required'],
    },
    anesthesia: {
      intravenous: ['Covered', 'Not Covered', 'Basic', 'Major'],
      generalAnesthesia: ['Covered', 'Not Covered', 'Basic'],
      generalAnesthesiaLimitations: [
        'Covered',
        'Not covered',
        'Paid by Medical Insurance',
        'Paid by Dental Insurance',
      ],
      nonIntravenous: ['Basic', 'Major'],
      nonIntravenousLimitations: [
        'Covered',
        'Not covered',
        'Paid by Medical Insurance',
        'Paid by Dental Insurance',
      ],
      nitrousOxide: ['Covered', 'Not Covered', 'Basic', 'Major'],
      nitrousOxideLimitations: ['Covered', 'Not Covered'],
      comments: [
        'IV sedation/General anesthesia is Not covered by dental insurance if performed by anesthesiologist',
      ],
    },
    otherServices: {
      occlusalAdjustment: ['Covered', 'Not Covered', 'Basic', 'Major'],
      occlusalAdjustmentLimitations: ['Pre approval required'],
    },
  },
  major: {
    restorative: {
      inlays: ['Major', 'Basic'],
      inlaysComments: ['Covered', 'Not Covered'],
      onlays: ['Major', 'Basic'],
      onlaysComments: ['Covered', 'Not Covered'],
      crownBuildUps: ['Major', 'Basic'],
      crownBuildUpsLimitations: [
        'Covered when Less than 3mm of tooth structure remaining',
        'Xrays required',
        'Extensive tooth loss',
        'Narrative required',
      ],
      stainlessSteelCrowns: ['Major', 'Basic', 'Preventive'],
    },
    dentureRelin: {
      relines: [
        'Major',
        'Basic',
        'Covered',
        'Not Covered',
        'Covered once in 5 years',
        'Combines with denture or immediate denture',
      ],
    },
    prosthodontics: {
      singleCrown: ['Major', 'Basic'],
      porcelainPosteriorLimitations: [
        'Once in 5 years',
        'Covered',
        'Downgraded on molars',
        'alternate benefits paid',
      ],
      implants: ['Major', 'Basic'],
      implantsLimitations: ['Once in lifetime', 'Once in 5 years'],
      implantAbutment: ['Major', 'Basic'],
      implantAbutmentLimitations: ['Once in 5 years'],
      implantCrown: ['Major', 'Basic'],
      implantCrownLimitations: ['Once in 5 years', 'Downgraded on posteriors'],
      bridges: ['Major', 'basic'],
      partials: ['Major', 'basic'],
      dentures: ['Major', 'basic'],
      missingToothClause: [
        'Yes',
        'No',
        'Will pay after 5 years if teeth/tooth were missing prior to coverage',
      ],
      replacementRule: [
        'Five years',
        '5 years',
        '10 years',
        'Ten years',
        '7 years',
        'Will pay after 5 years if teeth/tooth were missing prior to coverage',
        'Will pay if teeth extracted prior to coverage',
      ],
      other: [],
      prepSeatDate: [
        'crowns/bridges Paid at seat date',
        ' Crowns/bridges Paid at prep date',
      ],
    },
    otherServices: {
      nightguardsForBruxism: ['Basic', 'Major', 'Preventative', 'not covered'],
      nightguardsForBruxismLimitations: [
        'Covered',
        'Not covered',
        'Covered for bruxism',
        'Once in 5 years',
      ],
      TMJNonSurgical: ['Basic', 'Major', 'Preventative'],
      TMJNonSurgicalLimitations: [
        'Covered',
        'Not Covered',
        'paid by medical insurance',
        'Lifetime',
      ],
      comments: [],
    },
  },
  ortho: {
    benefits: {
      orthodontics: ['50', '70', '80', '100'],
      orthodonticsNotes: ['of fee table if provider in network'],
      deductible: ['none'],
      maximumDollar: ['1000', '1500', '2000', '2500', '3000'],
    },
    payment: {
      initial: ['25% at banding', '50% at banding', 'no initial at banding'],
      balancePaymentFrequency: [
        'automatic monthly',
        'automatic quarterly',
        'automatic semi-annualy',
        'automatic yearly',
        'manual monthly',
        'manual quarterly',
      ],
      ageLimit: ['18', '19', '20', '21', '26', '50', '99'],
      treatmentInProgress: ['Yes', 'No', 'Yes - will be prorated'],
      comments: [],
      AOB: [],
    },
  },
};

export default options;

import React from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import styles from './Input.module.scss';
import Tooltip from 'components/Tooltip/Tooltip';

const MAX_INPUT_TOOLTIP_LENGTH = 15;
function Input({error, onChange, value, ...props}) {
  const InputComponent = (
    <TextField
      multiline={props.multiline}
      disabled={props.disabled}
      variant={props.variant}
      required={props.isRequired}
      fullWidth={props.fullWidth}
      name={props.name}
      label={props.label}
      type={props.type}
      id={props.id || props.name}
      className={`input ${error && 'is-danger'}
  ${styles.input} ${props.disabled ? styles.disabled : ''}`}
      onChange={({target}) => onChange(props.name, target.value, target)}
      value={value}
      margin="dense"
      rows={props.rows}
      inputProps={props.inputProps}
    />
  );
  return (
    <>
      {value &&
      value.toString &&
      value.toString().length > MAX_INPUT_TOOLTIP_LENGTH &&
      props.disabled ? (
        <Tooltip title={value}>{InputComponent}</Tooltip>
      ) : (
        InputComponent
      )}
      {error && <p className={styles.redAlerts}>{props.label} is required</p>}
    </>
  );
}

Input.defaultProps = {
  value: '',
  variant: 'outlined',
  type: 'text',
  isRequired: false,
  fullWidth: true,
  InputLabelProps: {
    style: {
      height: '30px',
      marginTop: '-5px',
      fontSize: '15px',
      fontFamily: 'Roboto',
    },
  },
  inputProps: {
    style: {
      height: '40px',
      padding: '0 14px',
    },
  },
  style: {width: '100%', margin: '10px 0 0 0'},
};

Input.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default React.memo(Input);

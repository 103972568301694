import React from 'react';
import {DataGrid, GridOverlay} from '@material-ui/data-grid';
import {makeStyles} from '@material-ui/core/styles';
import Modal from 'components/Common/Modal/Modal';
import Input from 'components/Form/Input/Input';
import Button from 'components/Common/Button/Button';
import {Select, MenuItem} from '@material-ui/core';
import {useSelector, useDispatch} from 'react-redux';
import {update, pushDocument} from 'actions/firebaseActions';
import {useAlert} from 'react-alert';
import {getBasicInfo} from 'actions/apiCallsActions';
import AddIcon from '@material-ui/icons/Add';
import CardContainer from 'components/CardContainer';
import styles from './CarrierCredentials.module.scss';
import thirdVerificationList from '../../../data/3rdVerificationList.json';

const INITIAL_CREDENTIAL = {
  carrier: '',
  login: '',
  password: '',
  hasSecurityQuestions: false,
  firstSecurityQuestion: '',
  firstSecurityQuestionAnswer: '',
  secondSecurityQuestion: '',
  secondSecurityQuestionAnswer: '',
  thirdSecurityQuestion: '',
  thirdSecurityQuestionAnswer: '',
};

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '14',
    fontWeight: '700',
    paddingTop: 12,
  },
  select: {
    paddingBottom: 5,
  },
}));

const thirdVerificationArray = Object.values(thirdVerificationList);

function CarrierCredentials({
  isAdmin,
  addIconClass,
  clinicLocationsList,
  className,
}) {
  const [state, setState] = React.useState({
    open: false,
    carrierCredential: INITIAL_CREDENTIAL,
  });
  const classes = useStyles();
  const [selectedCarrierInfo, setSelectedCarrierInfo] = React.useState({
    id: '',
    hasSecurityQuestions: false,
    securityQuestions: [],
    APIcode: '',
  });

  const clinicBasicInfo = useSelector(
    ({apiCallsReducer}) => apiCallsReducer?.clinicBasicInfo,
  );

  const alert = useAlert();
  const dispatch = useDispatch();

  const clinicColumns = [
    {
      field: 'carrier',
      headerName: 'Carrier',
      flex: 1,
    },
    {
      field: 'login',
      headerName: 'Login',
      flex: 1,
    },
  ];

  const locationNoRowsOverlay = () => {
    return (
      <GridOverlay>
        <div>No Carrier Credentials Provided</div>
      </GridOverlay>
    );
  };

  const rows = React.useMemo(() => {
    const arrayOfCredentials = clinicBasicInfo?.carrierCredentials
      ? Object.values(clinicBasicInfo?.carrierCredentials)
      : [];

    const arrayOfCredentialsIds = clinicBasicInfo?.carrierCredentials
      ? Object.keys(clinicBasicInfo?.carrierCredentials)
      : [];
    return arrayOfCredentials.map((credential, index) => ({
      id: arrayOfCredentialsIds[index],
      carrier: credential.carrier,
      login: credential.login,
      password: credential.password,
      firstSecurityQuestion: credential.firstSecurityQuestion,
      firstSecurityQuestionAnswer: credential.firstSecurityQuestionAnswer,
      secondSecurityQuestion: credential.secondSecurityQuestion,
      secondSecurityQuestionAnswer: credential.secondSecurityQuestionAnswer,
      thirdSecurityQuestion: credential.thirdSecurityQuestion,
      thirdSecurityQuestionAnswer: credential.thirdSecurityQuestionAnswer,
    }));
  }, [clinicBasicInfo.carrierCredentials]);

  const submit = (event) => {
    const {carrierCredential} = state;
    event.preventDefault();
    if (
      !carrierCredential.carrier ||
      !carrierCredential.login ||
      !carrierCredential.password
    ) {
      alert.show('Please select the carrier and provide the username/login.');
      return;
    }
    if (carrierCredential.id) {
      update(
        `/clinics/${clinicBasicInfo.clinicId}/basicInfo/carrierCredentials/${carrierCredential.id}`,
        carrierCredential,
        () => {
          dispatch(getBasicInfo(clinicBasicInfo.clinicId));
          const msg = 'The credential information was updated';
          alert.success(msg);
          setState((currentState) => ({...currentState, open: false}));
        },
      );
    } else {
      pushDocument(
        `/clinics/${clinicBasicInfo.clinicId}/basicInfo/carrierCredentials/`,
        carrierCredential,
        (id) => {
          dispatch(getBasicInfo(clinicBasicInfo.clinicId));
          const msg = 'The credential information was added correctly!';
          alert.success(msg);
          setState((currentState) => ({...currentState, open: false}));
        },
      );
    }
  };

  const handleValue = React.useCallback((name, value) => {
    setState((currentState) => {
      const {carrierCredential} = currentState;
      return {
        ...currentState,
        carrierCredential: {...carrierCredential, [name]: value},
      };
    });
  }, []);

  const handleValueSelect = React.useCallback((nameObject, valueProps) => {
    const name = nameObject.target.name;
    const value = valueProps.props.value;
    setState((currentState) => {
      const {carrierCredential} = currentState;
      return {
        ...currentState,
        carrierCredential: {...carrierCredential, [name]: value},
      };
    });
    if (name === 'carrier') {
      setSelectedCarrierInfo(thirdVerificationList[value]);
    }
  }, []);

  return (
    <>
      <CardContainer
        title="Carrier Verification Credentials"
        iconTopRight={
          <AddIcon
            onClick={() => {
              if (!isAdmin) {
                alert.show(
                  'Only administrator accounts can add new credentials.',
                  {title: 'Not An Administrator.'},
                );
                return;
              }
              setState({
                open: true,
                carrierCredential: INITIAL_CREDENTIAL,
              });
            }}
            className={addIconClass}
          />
        }>
        <DataGrid
          columns={clinicColumns}
          rows={rows}
          className={className}
          disableSelectionOnClick
          hideFooter
          components={{
            noRowsOverlay: locationNoRowsOverlay,
          }}
          onRowClick={({row}) => {
            if (!isAdmin) {
              alert.show(
                'Only administrator accounts can view/edit credentials.',
                {title: 'Not An Administrator.'},
              );
              return;
            }
            setState((currentState) => ({
              ...currentState,
              carrierCredential: {...row, isEditing: true},
              open: true,
            }));
            setSelectedCarrierInfo(thirdVerificationList[row.carrier]);
          }}
        />
        <Modal
          title={
            state.carrierCredential.id ? 'Edit Credential' : 'Add Credential'
          }
          open={state.open}
          onClose={() => {
            setState((currentState) => ({...currentState, open: false}));
          }}>
          <div className={styles.wrapper}>
            <form onSubmit={submit}>
              <div className="col-12">
                <div className={classes.title}>Carrier:</div>
                <Select
                  labelId="demo-simple-select-label"
                  defaultValue={
                    state.carrierCredential.carrier
                      ? state.carrierCredential.carrier
                      : false
                  }
                  // input={<Input disableUnderline />}
                  name="carrier"
                  onChange={handleValueSelect}>
                  {thirdVerificationArray.map((object) => (
                    <MenuItem value={object.id}>{object.id}</MenuItem>
                  ))}
                </Select>
              </div>
              <div className="col-12">
                <Input
                  label="Login:"
                  name="login"
                  value={state.carrierCredential.login}
                  onChange={handleValue}
                />
              </div>
              <div className="col-12">
                <Input
                  label="Password:"
                  name="password"
                  value={state.carrierCredential.password}
                  onChange={handleValue}
                />
              </div>
              {selectedCarrierInfo.hasSecurityQuestions === true ? (
                <div className="col-12">
                  <div className={classes.title}>First Security Question:</div>
                  <Select
                    labelId="demo-simple-select-label"
                    defaultValue={
                      state.carrierCredential.firstSecurityQuestion
                        ? state.carrierCredential.firstSecurityQuestion
                        : false
                    }
                    // input={<Input disableUnderline />}
                    name="firstSecurityQuestion"
                    onChange={handleValueSelect}>
                    {selectedCarrierInfo.securityQuestions.map((value) => (
                      <MenuItem value={value}>{value}</MenuItem>
                    ))}
                  </Select>
                  <Input
                    label="First Security Question Answer:"
                    name="firstSecurityQuestionAnswer"
                    value={state.carrierCredential.firstSecurityQuestionAnswer}
                    onChange={handleValue}
                  />
                  <div className={classes.title}>Second Security Question:</div>
                  <Select
                    labelId="demo-simple-select-label"
                    className={classes.select}
                    defaultValue={
                      state.carrierCredential.secondSecurityQuestion
                        ? state.carrierCredential.secondSecurityQuestion
                        : false
                    }
                    // input={<Input disableUnderline />}
                    name="secondSecurityQuestion"
                    onChange={handleValueSelect}>
                    {selectedCarrierInfo.securityQuestions.map((value) => (
                      <MenuItem value={value}>{value}</MenuItem>
                    ))}
                  </Select>
                  <Input
                    label="Second Security Question Answer:"
                    name="secondSecurityQuestionAnswer"
                    value={state.carrierCredential.secondSecurityQuestionAnswer}
                    onChange={handleValue}
                  />
                  <div className={classes.title}>Third Security Question:</div>
                  <Select
                    labelId="demo-simple-select-label"
                    // classes={{
                    //   root: classes.clinicSelectRoot,
                    // }}
                    defaultValue={
                      state.carrierCredential.thirdSecurityQuestion
                        ? state.carrierCredential.thirdSecurityQuestion
                        : false
                    }
                    // input={<Input disableUnderline />}
                    name="thirdSecurityQuestion"
                    onChange={handleValueSelect}>
                    {selectedCarrierInfo.securityQuestions.map((value) => (
                      <MenuItem value={value}>{value}</MenuItem>
                    ))}
                  </Select>
                  <Input
                    label="Third Security Question Answer:"
                    name="thirdSecurityQuestionAnswer"
                    value={state.carrierCredential.thirdSecurityQuestionAnswer}
                    onChange={handleValue}
                  />
                </div>
              ) : null}
              <div className="col-12 mt-2">
                <Button type="submit" fullWidth>
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </Modal>
      </CardContainer>
    </>
  );
}

CarrierCredentials.propTypes = {};

export default CarrierCredentials;

import React from 'react';
import CardContainer from '../../CardContainer';
import AddIcon from '@material-ui/icons/Add';
import ProcedureModal from 'components/Profile/ProfileTabs/Phase2/Patient/TreatmentModal';
import {
  pushDocument,
  setDocument,
  getDocument,
  deleteDocument,
} from 'actions/firebaseActions';
import {useSelector} from 'react-redux';
import TrashIcon from '@material-ui/icons/Delete';
import {DataGrid} from '@material-ui/data-grid';
import useStyles from './ProcedureTemplatesClasses';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';

function ProcedureTemplates() {
  const [state, setState] = React.useState({
    open: false,
    currentProcedure: null,
  });
  const [confirmDialogState, setConfirmDialogState] = React.useState({
    open: false,
    currentProcedure: null,
  });

  const [procedures, setProcedures] = React.useState(null);

  const clinicId = useSelector(
    ({apiCallsReducer}) => apiCallsReducer?.clinicBasicInfo?.clinicId,
  );

  const classes = useStyles();

  React.useEffect(() => {
    (async function () {
      if (clinicId) {
        getDocument(`/procedureTemplates/${clinicId}`, setProcedures);
      }
    })();
  }, [clinicId]);

  const toggleModal = (status = false) => {
    setState((currentState) => ({
      ...currentState,
      open: status,
      currentProcedure: null,
    }));
  };

  const setCurrentProcedure = (procedureId) => {
    setState((currentState) => ({
      ...currentState,
      open: !!procedureId,
      currentProcedure: procedureId,
    }));
  };

  const submit = (model) => {
    if (state.currentProcedure) {
      setProcedures((currentProcedures) => ({
        ...currentProcedures,
        [state.currentProcedure]: model,
      }));
      setDocument(
        `/procedureTemplates/${clinicId}/${state.currentProcedure}`,
        model,
      );
    } else {
      pushDocument(`/procedureTemplates/${clinicId}`, model, (key) => {
        setProcedures((currentProcedures) => ({
          ...currentProcedures,
          [key]: model,
        }));
      });
    }
    setState((currentState) => ({...currentState, open: false}));
  };

  const deleteTemplate = (templateId) => {
    deleteDocument(`/procedureTemplates/${clinicId}/${templateId}`, () => {
      setProcedures((currentProcedures) => {
        delete currentProcedures[templateId];

        return {...currentProcedures};
      });
      setConfirmDialogState({open: false, currentProcedure: null});
    });
  };

  const handleDeleteTemplate = (templateId) => {
    if (templateId) {
      setConfirmDialogState({open: true, currentProcedure: templateId});
    }
  };

  const columns = [
    {
      field: 'trashIcon',
      headerName: ' ',
      flex: 0.3,
      renderCell: ({row}) => (
        <div>
          <TrashIcon
            onClick={() => {
              handleDeleteTemplate(row.id);
            }}
          />
        </div>
      ),
    },
    {
      field: 'treatmentPlanName',
      headerName: 'Name',
      flex: 1,
      renderCell: ({row}) => (
        <div
          onClick={() => setCurrentProcedure(row.id)}
          style={{width: '100%'}}>
          <span>{row.treatmentPlanName}</span>
        </div>
      ),
    },
  ];

  return (
    <>
      <CardContainer
        title="Procedure Templates"
        iconTopRight={
          <AddIcon
            onClick={() => toggleModal(true)}
            className={classes.addIcon}
          />
        }>
        <DataGrid
          columns={columns}
          rows={
            (procedures &&
              Object.keys(procedures).map((item) => ({
                ...procedures[item],
                id: item,
                key: item,
              }))) ||
            []
          }
          disableSelectionOnClick
          hideFooter
          components={{
            noRowsOverlay: () => <div>There's no templates</div>,
          }}
          className={classes.locationsDataGrid}
        />
      </CardContainer>
      <ProcedureModal
        open={state.open}
        submit={submit}
        onClose={toggleModal}
        treatment={(procedures && procedures[state.currentProcedure]) || null}
        editing={!!state.currentProcedure}
      />

      <ConfirmDialog
        title="Do you want to delete this template?"
        open={confirmDialogState.open}
        handleClose={() => {
          setConfirmDialogState({open: false, currentProcedure: null});
        }}
        handleConfirm={() => {
          deleteTemplate(confirmDialogState.currentProcedure);
        }}>
        <div>
          <span>The template will be deleted.</span>
        </div>
      </ConfirmDialog>
    </>
  );
}

ProcedureTemplates.propTypes = {};

export default ProcedureTemplates;

import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Common/Modal/Modal';
import Divider from 'components/Form/Divider/Divider';
import Input from 'components/Form/Input/Input';
import Select from 'components/Form/Select/Select';
import Procedures from './Procedures/Procedures';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/Add';
import Status from 'constants/Status';
import Button from 'components/Common/Button/Button';
import ValidateForm from 'components/Profile/ProfileTabs/Phase2/Patient/Validations';
import TemplatesSelect from './TemplatesSelect/TemplatesSelect';
import Loader from 'components/Loader/Loader';
import DeleteTreatment from './DeleteTreatment';

const procedureModel = {
  adaCode: '',
  scheduledDate: '',
  totalFee: '',
  discount: 0,
  insuranceAmount: 0,
  phase: '1',
  patientAmount: '',
  status: 1,
};

function TreatmentModal(props) {
  const proceduresRef = React.useRef({});
  const [errors, setErrors] = React.useState({procedures: {}});
  const [model, setModel] = React.useState(() => {
    if (props.treatment && props.editing) {
      return {...props.treatment};
    } else {
      const id = +new Date();
      return {
        treatmentPlanName: '',
        procedures: {
          [id]: {...procedureModel},
        },
      };
    }
  });

  React.useEffect(() => {
    setModel(
      props.editing
        ? {...props.treatment}
        : {
            treatmentPlanName: '',
            status: 1,
            procedures: {
              [+new Date()]: {...procedureModel},
            },
          },
    );
  }, [props.treatment, props.editing]);

  const updateValue = React.useCallback((name, value) => {
    setModel((currentModel) => ({...currentModel, [name]: value}));
  }, []);

  const updateProcedure = React.useCallback((procedureId, name, value) => {
    setModel((currentModel) => {
      const procedure = currentModel?.procedures[procedureId] || {};
      procedure[name] = value;

      return {
        ...currentModel,
        procedures: {...currentModel.procedures, [procedureId]: procedure},
      };
    });
  }, []);

  const removeItem = React.useCallback((selectedId) => {
    setModel((currentModel) => {
      const procedures = {...currentModel.procedures};
      delete procedures[selectedId];

      return {
        ...currentModel,
        procedures: {...procedures},
      };
    });
  }, []);

  const templateList = props.templates?.list;

  return (
    <>
      <Modal
        title={props.editing ? 'Edit Treatment' : 'Add Treatment'}
        open={props.open}
        onClose={() => {
          props.onClose();
          setErrors({procedures: {}});
        }}>
        <Loader loading={props.loading} />

        <div>
          <div className="row">
            {props.showTemplates && !props.editing && (
              <div className="col-12">
                {templateList.length ? (
                  <TemplatesSelect
                    value={model.id}
                    templates={props.templates}
                    onChange={(selectedTemplate) => {
                      setModel(selectedTemplate);
                    }}
                  />
                ) : (
                  <div className="my-3">The clinic hasn't any template</div>
                )}
              </div>
            )}
            <div className="col-12">
              <Divider label="Treatment Plan" />
            </div>
            <div className="col-6">
              <Input
                label="Treatment Plan Name"
                name="treatmentPlanName"
                value={model.treatmentPlanName}
                onChange={updateValue}
                error={errors.treatmentPlanName}
              />
            </div>
            <div className="col-6">
              <Select
                label="Status"
                value={model.status}
                name="status"
                options={Status}
                error={errors.status}
                onChange={updateValue}
                defaultValue={model.status}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Divider label="Procedures" />
            </div>
          </div>
          {model.procedures &&
            Object.keys(model.procedures).map(
              (procedureId, index) =>
                model.procedures[procedureId] && (
                  <Procedures
                    key={procedureId}
                    ref={proceduresRef}
                    removeItem={removeItem}
                    {...model.procedures[procedureId]}
                    index={index}
                    id={procedureId}
                    errors={errors.procedures[procedureId] || {}}
                    divider={index > 0}
                    onChange={updateProcedure}
                  />
                ),
            )}
        </div>
        <div className="row mt-3">
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() =>
              setModel((currentModel) => ({
                ...currentModel,
                procedures: {
                  ...currentModel.procedures,
                  [+new Date()]: {...procedureModel},
                },
              }))
            }>
            <PhotoCamera />
          </IconButton>
        </div>
        <div className="my-3">
          <Button
            onClick={() => {
              const {invalid, errors: validationErrors} = ValidateForm(model);
              setErrors(validationErrors);
              if (invalid) {
                return;
              }

              if (typeof props.submit === 'function') {
                props.submit(model);
              }
            }}
            fullWidth>
            {props.treatmentId && props.editing
              ? 'Update treatment'
              : 'Save treatment'}
          </Button>
        </div>
        {props.treatmentId && props.editing && (
          <DeleteTreatment
            treatmentId={props.treatmentId}
            deleteTreatment={props.deleteTreatment}
            closeModal={props.onClose}
            setCurrentTreatment={props.setCurrentTreatment}
            fetchTreatments={props.fetchTreatments}
            setLoading={props.setLoading}
            patientId={props.patientId}
          />
        )}
      </Modal>
    </>
  );
}

TreatmentModal.propTypes = {
  open: PropTypes.bool,
  treatmentId: PropTypes.string,
  url: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  editing: PropTypes.bool.isRequired,
  treatment: PropTypes.object,
};

export default TreatmentModal;

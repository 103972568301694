import React, {useEffect, useState} from 'react';
import {
  makeStyles,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import {useAlert} from 'react-alert';
import {DataGrid, GridOverlay} from '@material-ui/data-grid';
import {IconButton} from '@material-ui/core';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import UnarchiveOutlinedIcon from '@material-ui/icons/UnarchiveOutlined';
import {connect} from 'react-redux';
import {
  changeClinicLocationSelection,
  changeGlobalSearchObj,
  archivePatient,
  unArchivePatient,
} from '../../actions';
import {setCurrentPatient} from 'actions/patientActions';
import {useHistory} from 'react-router-dom';
import helpers from 'Utils/helpers';
import FollowUpModal from 'components/Profile/ProfileTabs/Phase2/Communications/FollowUpModal/FollowUpModal';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import sassStyles from './ProductionTable.module.scss';
import moment from 'moment';
import Tooltip from 'components/Tooltip/Tooltip';
//*************************************************************************************Component Styling Sheet***************************************************
const useStyles = makeStyles({
  tableContainer: {
    width: '100%',
    height: '100%',
  },
  root: {
    '& .MuiDataGrid-sortIcon': {
      color: 'black',
    },
    '& .MuiDataGrid-colCellWrapper': {
      backgroundColor: '#e8e8e8',
    },
    '& .MuiDataGrid-colCell': {
      width: '200px',
    },
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
      color: '#4D4B4B',
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
      outlineWidth: '1px',
      backgroundColor: '#C1C1C1',
      //color: '#FFFFFF',
    },
    cursor: 'pointer',
  },
});

const theme = createMuiTheme({
  typography: {
    fontFamily: '"Roboto"',
  },
});

//*************************************************************************************Generate Table Data**************************************************

//*******************************************************************************Table Data Component**************************************************************

const TableData = (props) => {
  const alert = useAlert();
  const history = useHistory();

  const rowsInput = props.searchedClinicPatientsList
    ? props.searchedClinicPatientsList
    : props.locationFilteredClinicPatientsList;

  const locationAccess = props.userInfo?.locationAccess
    ? Object.keys(props.userInfo.locationAccess)[0] || null
    : null;
  const {masterAdmin, admin} = props.userInfo;

  const rows = React.useMemo(() => {
    // Define function that converts UNIX to date
    const changeDOBFormatting = (DOB) => {
      const DOBArray = DOB.split('-');
      // DOBArray = [yyyy, mm, dd]
      const DOB1 = `${DOBArray[1]}/${DOBArray[2]}/${DOBArray[0]}`;
      // eslint-disable-next-line radix
      const DOB2 = parseInt(new Date(DOB1).getTime().toFixed(0));

      return DOB2;
    };
    const shortenName = (name) => {
      const nameArray = name.split(' ');
      // DOBArray = [yyyy, mm, dd]
      const first = nameArray[0].substring(0, 1);
      const shortenedName = `${first} ${nameArray[1]}`;
      return shortenedName;
    };

    const removeUnderscore = (insurance) => {
      const noUnderscore = insurance.replace(/_/g, ' ');
      return noUnderscore;
    };
    // Map data to object
    const patients = rowsInput.map((ele) => {
      if (
        (props.activeOrArchivedPatientList === 'Active' && !ele.archived) ||
        (props.activeOrArchivedPatientList === 'Archived' && ele.archived)
      ) {
        const location = ele.location || ele.personalInfo?.location || null;
        if (
          masterAdmin ||
          admin ||
          locationAccess === location ||
          locationAccess === 'All'
        ) {
          return {
            id: ele.patientId,
            totalUnbilled: ele.totalUnbilled,
            unplannedTreatments:
              ele.unplannedTreatments === undefined
                ? 0
                : ele.unplannedTreatments,
            lastSentCommunicationDate: ele.lastSentCommunicationDate,
            treatmentPlanName: ele.treatmentPlanName,
            lastFollowUpOpened: ele.lastFollowUpOpened,
            totalFollowUpsColor: ele.totalFollowUps
              ? ele.totalFollowUps < 5
                ? 'purple'
                : ele.totalFollowUps < 10
                ? 'orange'
                : 'red'
              : 'gray',
            nextProcedureDate: ele.nextProcedureDate,
            nextFollowUpDate: ele.nextFollowUpDate,
            totalFollowUps: ele.totalFollowUps,
            enabledFollowUps: ele.enabledFollowUps,
            patientOpenedLastCommunication: ele.patientOpenedLastCommunication,
            createdDate: ele.createdByDate,
            lastName: ele.personalInfo.lastName,
            firstName: ele.personalInfo.firstName,
            email: ele.personalInfo.email,
            phone: ele.personalInfo.phone,
            DOB: changeDOBFormatting(ele.personalInfo.DOB),
            clinic: ele.personalInfo.location,
            status: ele.status,
            lastCheckedInsuranceDate: ele.lastCheckedInsuranceDate,
            lastCheckedInsuranceBy: ele.lastCheckedInsuranceBy
              ? shortenName(ele.lastCheckedInsuranceBy)
              : '--',
            createdByFullName: shortenName(ele.createdByFullName),
            primaryInsurance: ele.primaryInsurance
              ? removeUnderscore(ele.primaryInsurance)
              : 'None',
            nextAppointmentDate: ele.personalInfo.nextAppointmentDate
              ? changeDOBFormatting(ele.personalInfo.nextAppointmentDate)
              : '',
            ele,
          };
        }
      }
      return null;
    });
    return patients.filter((item) => {
      if (item && props.filter) {
        return !!item.unplannedTreatments;
      }
      return !!item;
    });
  }, [
    rowsInput,
    props.activeOrArchivedPatientList,
    props.filter,
    admin,
    locationAccess,
    masterAdmin,
  ]);

  useEffect(() => {
    setRootHeight(document.getElementById('root').clientHeight);
    props.changeGlobalSearchObj('', props.locationFilteredClinicPatientsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [rootHeight, setRootHeight] = useState(0);

  const styles = useStyles();

  //*****************Generate Header and Body********************

  const usageNoRowsOverlay = () => {
    return (
      <GridOverlay>
        <div>
          Click the add patient icon in the navbar to add your first patient!
        </div>
      </GridOverlay>
    );
  };

  const columns = (openModal) => [
    {
      field: 'createdDate',
      headerName: 'Created',
      width: 110,
      renderCell: (data) => moment(data.value).format('MM/DD/YYYY'),
    },
    {
      field: 'firstName',
      headerName: 'Name',
      flex: 0.7,
      renderCell: ({value, ...data}) => {
        const {
          ele: {personalInfo},
        } = data.row;
        return (
          <span>
            {value} {personalInfo.lastName}
          </span>
        );
      },
    },
    {
      field: 'DOB',
      headerName: 'DOB',
      width: 110,
      renderCell: (data) => moment(data.value).format('MM/DD/YYYY'),
    },
    // {
    //   field: 'status',
    //   headerName: 'Insurance',
    //   width: 120,
    //   renderCell: ({value}) => (
    //     <div className={sassStyles.status}>
    //       <span className={sassStyles[value.toLowerCase()]}>{value}</span>
    //     </div>
    //   ),
    // },
    {
      field: 'unplannedTreatments',
      headerName: 'Unscheduled Procedures',
      flex: 0.7,
      renderCell: ({value, ...data}) => {
        const {totalUnbilled} = data.row;
        return (
          <span>
            {helpers.toCurrency(totalUnbilled)} ({value || 0})
          </span>
        );
      },
    },
    {
      field: 'nextProcedureDate',
      headerName: 'Next Procedure',
      flex: 0.7,
      renderCell: ({value}) =>
        value ? moment.utc(value).format('MM/DD/YYYY') : null,
    },
    {
      field: 'nextFollowUpDate',
      headerName: 'Next Follow-up',
      flex: 0.7,
      renderCell: ({value}) =>
        value ? moment.utc(value).format('MM/DD/YYYY') : null,
    },
    {
      field: 'lastSentCommunicationDate',
      headerName: 'Last Follow-up',
      flex: 1,
      renderCell: ({value, ...data}) => (
        <div className={sassStyles.lastFollowUpDate}>
          {value ? (
            <span>
              {moment(value).format('MM/DD/YYYY')}{' '}
              {data.row.treatmentPlanName &&
                (data.row.lastFollowUpOpened ? '(Opened)' : '(Unopened)')}
            </span>
          ) : null}
          {data.row.treatmentPlanName && (
            <span>{data.row.treatmentPlanName}</span>
          )}
        </div>
      ),
    },
    {
      field: 'totalFollowUps',
      width: 110,
      renderHeader: () => {
        return (
          <Tooltip title="Total Follow-ups">
            <span>Attempts</span>
          </Tooltip>
        );
      },
      renderCell: ({value, ...data}) => (
        <div>
          <span
            className={`${sassStyles.bubbleChat} ${
              sassStyles[data.row.totalFollowUpsColor]
            }`}>
            <b>{value || 0}</b>
          </span>
        </div>
      ),
    },
    {
      field: 'enabledFollowUps',
      headerName: 'Follow-ups',
      flex: 0.6,
      renderCell: ({value}) => (
        <div>
          <span
            className={`${sassStyles.enabledFollowUps} ${
              value === false ? sassStyles.disabled : sassStyles.enabled
            }`}>
            {value === false ? 'Disabled' : 'Enabled'}
          </span>
        </div>
      ),
    },
    // {
    //   field: 'totalUnbilled',
    //   headerName: 'Total Unbilled Treatments',
    //   renderCell: ({value, ...data}) => {
    //     return (
    //       <RenderCell data={data}>
    //         <span>{helpers.toCurrency(value)}</span>;
    //       </RenderCell>
    //     );
    //   },
    //   width: rootWidth < 1200 ? 100 : rootWidth / 9,
    // },
    // {
    //   field: 'patientOpenedLastCommunication',
    //   headerName: 'Opened Last follow-up date',
    //   width: rootWidth < 1200 ? 100 : rootWidth / 9,
    //   renderCell: ({value, ...data}) => (
    //     <RenderCell data={data}>{value ? 'Yes' : 'No'}</RenderCell>
    //   ),
    // },
    {
      headerName: 'Send',
      field: 'followUp',
      width: 100,
      disableClickEventBubbling: true,
      renderCell: ({row}) => {
        return (
          <PlaylistAddIcon
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              openModal({
                patientId: row.id,
                phone: row.phone,
                email: row.email,
                firstName: row.firstName,
                lastName: row.lastName,
              });
            }}
          />
        );
      },
    },
    {
      field: 'archive',
      headerName: ' ',
      width: 70,
      disableClickEventBubbling: true,
      renderCell: (params) => renderTrashOrUnArchive(params),
    },
  ];

  const archivePatientClick = async (data, activeOrUnarchive) => {
    const patientId = data.id;
    // setLoadingLocal(true);
    if (activeOrUnarchive === 'Active') {
      await props.archivePatient(patientId, props.clinicBasicInfo.clinicId);
    } else {
      await props.unArchivePatient(patientId, props.clinicBasicInfo.clinicId);
    }
    props.changeGlobalSearchObj('', props.locationFilteredClinicPatientsList);
    // setLoadingLocal(false);
  };

  const renderTrashOrUnArchive = (params) => {
    if (props.activeOrArchivedPatientList === 'Active') {
      return (
        <IconButton
          onClick={(e) => {
            e.ignore = true;
            e.preventDefault();
            e.stopPropagation();
            alert.show(
              'Please confirm that you wish to move this patient to the archived list.',
              {
                title: 'Archive Patient',
                actions: [
                  {
                    copy: 'Confirm',
                    onClick: () => archivePatientClick(params.row, 'Active'),
                  },
                ],
                closeCopy: 'Cancel',
              },
            );
          }}>
          <DeleteOutlineOutlinedIcon color="inherit" />
        </IconButton>
      );
    } else {
      return (
        <IconButton
          onClick={(e) => {
            e.ignore = true;
            e.preventDefault();
            e.stopPropagation();
            alert.show(
              'Please confirm that you wish to move this patient to the active patients list',
              {
                title: 'Re-activate Patient',
                actions: [
                  {
                    copy: 'Confirm',
                    onClick: () => archivePatientClick(params.row, 'unArchive'),
                  },
                ],
                closeCopy: 'Cancel',
              },
            );
          }}>
          <UnarchiveOutlinedIcon color="inherit" />
        </IconButton>
      );
    }
  };

  function onRowClick(h) {
    props.changeGlobalSearchObj('', props.locationFilteredClinicPatientsList);

    props.setCurrentPatient({...h.row.ele});
    if (props.redirect) {
      history.push(`/section/profile-benefits/${h.row.ele.patientId}`);
    }
    if (props.redirectTo) {
      history.push(`${props.redirectTo}/${h.row.ele.patientId}`);
    }
  }

  //*****************Return Component********************
  return (
    <MuiThemeProvider theme={theme}>
      <div
        className={`${styles.tableContainer} ${sassStyles.productionTable}`}
        style={{height: rootHeight - 60}}>
        <FollowUpModal>
          {({openModal}) => {
            return (
              <DataGrid
                autoHeight={true}
                columns={columns(openModal)}
                rows={rows}
                className={styles.root}
                onRowClick={onRowClick}
                components={{
                  noRowsOverlay: usageNoRowsOverlay,
                }}
              />
            );
          }}
        </FollowUpModal>
      </div>
    </MuiThemeProvider>
  );
};

//*********************************************************REDUX STORE***********************************************************

const mapStateToProp = (state) => {
  return {
    clinicPatientsList: state.apiCallsReducer.clinicPatientsList,
    clinicLocationsList: state.apiCallsReducer.clinicLocationsList,
    searchedClinicPatientsList:
      state.apiCallsReducer.searchedClinicPatientsList,
    locationFilteredClinicPatientsList:
      state.apiCallsReducer.locationFilteredClinicPatientsList,
    userInfo: state.apiCallsReducer.userInfo,
    clinicBasicInfo: state.apiCallsReducer.clinicBasicInfo,
    activeOrArchivedPatientList:
      state.apiCallsReducer.activeOrArchivedPatientList,
  };
};

//*********************************************************Export***********************************************************

export default connect(mapStateToProp, {
  changeClinicLocationSelection,
  changeGlobalSearchObj,
  archivePatient,
  unArchivePatient,
  setCurrentPatient,
})(TableData);

import React from 'react';
import styles from './Insurance.module.scss';
import {getClinicStats} from 'actions/firebaseActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useSelector} from 'react-redux';
import FilterListIcon from '@material-ui/icons/FilterList';
import Tooltip from 'components/Tooltip/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';
import {reloadData} from 'actions';
import {useDispatch} from 'react-redux';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

function Insurance({filter, setFilter}) {
  const [stastics, setStastics] = React.useState({
    fullFilled: false,
    data: null,
  });
  const clinicId = useSelector(
    ({apiCallsReducer}) => apiCallsReducer?.clinicBasicInfo?.clinicId,
  );

  const dispatch = useDispatch();

  const reloadTable = React.useCallback(() => {
    dispatch(reloadData());
  }, [dispatch]);

  const refresh = React.useCallback(async () => {
    if (clinicId) {
      const result = await getClinicStats(clinicId);
      const data = result.status === 200 ? result.data : {};
      setStastics({data, fullFilled: true});
    }
  }, [clinicId]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.box}>
        <div>
          <span>Total Unscheduled Procedures</span>
          <Tooltip title={'Refresh Clinic data'}>
            <RefreshIcon
              className={styles.refreshIcon}
              onClick={() => {
                reloadTable();
                refresh();
              }}
            />
          </Tooltip>
          <Tooltip
            title={
              filter
                ? 'Show all patients'
                : 'Show only patients with unscheduled procedures'
            }>
            <FilterListIcon
              className={`${styles.filterIcon} ${filter ? styles.active : ''}`}
              onClick={() => setFilter((currentFilter) => !currentFilter)}
            />
          </Tooltip>
        </div>
        <div>
          {stastics.fullFilled ? (
            <h2>{stastics.data.unescheduledProcedures || 0}</h2>
          ) : (
            <CircularProgress />
          )}
        </div>
      </div>
      <div className={styles.box}>
        <div>
          <span>Total Unscheduled Procedure Amount</span>
        </div>
        <div>
          {stastics.fullFilled ? (
            <h2>{formatter.format(stastics.data.unescheduledAmount || 0)}</h2>
          ) : (
            <CircularProgress />
          )}
        </div>
      </div>
      <div className={styles.box}>
        <div>
          <span>Total Scheduled Procedure Amount</span>
        </div>
        <div>
          {stastics.fullFilled ? (
            <h2>{formatter.format(stastics.data.scheduledAmount || 0)}</h2>
          ) : (
            <CircularProgress />
          )}
        </div>
      </div>
    </div>
  );
}

export default Insurance;

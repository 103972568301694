import axios from 'axios';
import firebase from 'firebase';
import {
  CLINIC_BASIC_INFO,
  CLINIC_USERS_LIST,
  USER_INFO,
  CLINIC_PATIENTS_LIST,
  CLINIC_TEMPLATES_LIST,
  CLINIC_USAGE_INFO,
  CLINIC_LOCATIONS_LIST,
  RESETALL,
  ACTIVE_OR_ARCHIVED_PATIENT_LIST,
  UPDATE_CLINIC_LOCATION_FILTER_RESULTS,
  SET_CURRENT_USER,
  ADA_CODE_LIST,
  ADA_CODE_KEYS,
} from './types';
import _ from 'lodash';
import {handleClinicLocationSelection} from 'actions/searchActions';
import {addPatient, updatePatient, removePatient} from 'actions/patientActions';
// var Blob = require('blob');
import fileDownload from 'js-file-download';

// const alert = useAlert();

export const submitFormToFirebase = (values) => {
  const {email, password} = values;
  return async (dispatch) => {
    // COMMENTING OUT NPI BELOW AS RUCHIR SAID WE DONT NEED
    // const postData = {
    //   npiNumber: values.provideid,
    // };
    // const axiosConfig = {
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    // };
    // let NPIresult = false;
    // if (values.provideid) {
    //   console.log(values.provideid);
    //   NPIresult = await axios
    //     .post(
    //       'https://us-central1-insurance-ca69d.cloudfunctions.net/checkNPI',
    //       // eslint-disable-next-line radix
    //       postData,
    //     )
    //     .then((token) => {
    //       console.log(token);
    //       if (token.data && token.data.result_count) {
    //         if (token.data.result_count > 0) {
    //           return true;
    //         }
    //       }
    //       return false;
    //     })
    //     .catch(() => {
    //       return false;
    //     });
    // }
    // console.log('NPIresult', NPIresult);
    // // IF NPI is incorrect, return error:true and respond with error message
    // if (!NPIresult) {
    //   const returnObject = {
    //     error: true,
    //     errorMessage:
    //       'NPI number is invalid. Please verify at https://npiregistry.cms.hhs.gov/',
    //   };
    //   return returnObject;
    // }
    // Make user on Firebase
    // console.log(firebase.auth());
    await firebase
      .auth()
      .signOut()
      .then((auth) => {
        console.log(auth);
        return;
      })
      .catch((err) => {
        console.log(err);
        return;
      });
    const result = firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async (user) => {
        const zohoInfo = await createUserOnFirebase(
          values,
          user.user.uid,
          dispatch,
        );
        await createUserOnZoho(zohoInfo, dispatch);
        const returnObject = {error: false};
        return returnObject;
      })
      .catch((error) => {
        if (error.code === 'auth/email-already-in-use') {
          const returnObject = {error: true, errorMessage: 'Email in system'};
          return returnObject;
        } else if (error.message) {
          const returnObject = {error: true, errorMessage: error.message};
          return returnObject;
        } else {
          const returnObject = {
            error: true,
            errorMessage:
              'Account creation failed. Please try again or contact support.',
          };
          return returnObject;
        }
      });
    return result;
  };
};

const setInitialClinicFilter = (reloadOutput, dispatch) => {
  if (reloadOutput.clinicData?.patients) {
    const patientsList = Object.values(reloadOutput.clinicData.patients);
    const locationsList = Object.values(reloadOutput.clinicData.locations);
    if (reloadOutput.userInfo) {
      if (reloadOutput.userInfo.admin || reloadOutput.userInfo.masterAdmin) {
        const result = handleClinicLocationSelection(
          'All',
          patientsList,
          locationsList,
        );
        dispatch({
          type: UPDATE_CLINIC_LOCATION_FILTER_RESULTS,
          payload: result,
        });
      } else if (reloadOutput.userInfo.locationAccess) {
        const result = handleClinicLocationSelection(
          Object.keys(reloadOutput.userInfo.locationAccess)[0],
          patientsList,
          locationsList,
        );
        dispatch({
          type: UPDATE_CLINIC_LOCATION_FILTER_RESULTS,
          payload: result,
        });
      }
    }
  }
};

export const reloadData = (callback) => {
  let output = {
    userInfo: {},
    clinicData: {},
  };
  const {currentUser} = firebase.auth();
  const uid = currentUser.uid;
  return async (dispatch) => {
    let userInfo = '';
    await firebase
      .database()
      .ref(`/users/${uid}/`)
      .once(
        'value',
        (snapshot) => {
          userInfo = snapshot.val();
          output.userInfo = userInfo;
          dispatch({
            type: USER_INFO,
            payload: {...userInfo, uid},
          });
        },
        () => {
          if (typeof callback === 'function') {
            callback();
          }
        },
      );
    output.clinicData = await getAllClinicInfo(
      {clinicId: userInfo.clinicId},
      dispatch,
    );
    setInitialClinicFilter(output, dispatch);
    if (typeof callback === 'function') {
      callback(output);
    }
    return output;
  };
};

const createUserOnZoho = async (zohoInfo, dispatch) => {
  let basicInfo = zohoInfo.basicInfo;
  const usage = zohoInfo.usage;
  const postData = {...basicInfo, ...usage};

  await axios
    .post(
      'https://us-central1-insurance-ca69d.cloudfunctions.net/createZohoContact',
      // eslint-disable-next-line radix
      postData,
    )
    .then((token) => {
      // get zohoId
      basicInfo.zohoId = token.data;
      dispatch({
        type: CLINIC_BASIC_INFO,
        payload: basicInfo,
      });
      return;
    })
    .catch(() => {
      console.log('error in zoho call');
      return;
    });
  return;
};

const createUserOnFirebase = async (values, uid, dispatch) => {
  const UNIX = new Date().getTime();
  const d = new Date();
  const month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
  const year = d.getFullYear();
  const monthYear = `${month}${year}`;
  const {
    email,
    city,
    clinicaddress,
    clinicaddress2,
    // confirmation,
    clinicname,
    phonenumber,
    firstName,
    lastName,
    // password,
    provideid,
    state,
    zip,
    referralCode,
  } = values;
  // to determine referaall code comparison to see which zoho account should go into
  const referralCodeComparison = referralCode
    ? referralCode.toLowerCase().trim()
    : '';

  let relatedZohoAccount = '';
  if (
    referralCodeComparison === 'braces' ||
    referralCodeComparison === 'dentistry' ||
    referralCodeComparison === 'dental'
  ) {
    relatedZohoAccount = 'ortho';
  }

  const defaultLocation = city.replace(/\b(\w)/g, (s) => s.toUpperCase());
  const userObject = {
    clinicId: uid,
    admin: true,
    firstName,
    lastName,
    email,
    navBarColor: '#3ab298',
    lastLogin: UNIX,
    locationAccess: {
      [defaultLocation]: true,
    },
    allClinics: true,
  };
  const basicInfo = {
    zohoId: false,
    active: false,
    email,
    nationalProviderIds: {
      [provideid]: {
        code: provideid,
        physician: 'Default',
      },
    },
    firstName,
    lastName,
    clinicName: clinicname,
    clinicId: uid,
    clinicAddress: clinicaddress ? clinicaddress : '',
    clinicAddress2: clinicaddress2 ? clinicaddress2 : '',
    city: city ? city : '',
    state: state ? state : '',
    zip: zip ? zip : '',
    referralCode: referralCode || '',
    relatedZohoAccount,
    phone: phonenumber,
    createdTime: UNIX,
    theme: '#3ab298',
  };
  const users = {
    [uid]: {
      active: true,
      masterAdmin: true,
      theme: '#3ab298',
      firstName,
      lastName,
      email,
      locationAccess: {
        [defaultLocation]: true,
      },
      allClinics: true,
    },
  };

  const locations = {
    [defaultLocation]: {
      active: true,
      name: defaultLocation,
    },
  };
  const usage = {
    subscriptionPrice: 200,
    apiCallPrice: 1.75,
    history: {
      [monthYear]: {
        invoiceNumber: 'null',
        month,
        year,
        apiCalls: 0,
        subscriptionPriceCharged: false,
        apiCallPriceCharged: false,
        totalInvoiced: false,
        invoicePaid: false,
      },
    },
  };
  await firebase.database().ref(`/users/${uid}/`).set(userObject);
  await firebase.database().ref(`/clinics/${uid}/basicInfo`).set(basicInfo);
  await firebase.database().ref(`/clinics/${uid}/users`).set(users);
  await firebase.database().ref(`/clinics/${uid}/usage`).set(usage);
  await firebase.database().ref(`/clinics/${uid}/locations`).set(locations);
  await firebase
    .database()
    .ref('/adaCodes/')
    .once('value', async (snapshot3) => {
      let adaCodeObject = snapshot3.val();
      // let adaCodeArrayOfObjectsForProcedures = [];
      const adaCodeKeys = Object.keys(adaCodeObject);
      const adaCodeValues = Object.values(adaCodeObject);

      adaCodeValues.forEach((ele, index) => {
        const description = ele.description;
        adaCodeObject[adaCodeKeys[index]] = description;
        // adaCodeArrayOfObjectsForProcedures[index] = { key:adaCodeKeys[index], label: adaCodeKeys[index]}
      });
      dispatch({
        type: ADA_CODE_LIST,
        payload: adaCodeObject,
      });
      dispatch({
        type: ADA_CODE_KEYS,
        payload: adaCodeKeys,
      });
    });

  dispatch({
    type: CLINIC_BASIC_INFO,
    payload: basicInfo,
  });
  dispatch({
    type: CLINIC_USERS_LIST,
    payload: users,
  });
  dispatch({
    type: CLINIC_PATIENTS_LIST,
    payload: [],
  });
  dispatch({
    type: CLINIC_USAGE_INFO,
    payload: usage,
  });
  dispatch({
    type: USER_INFO,
    payload: userObject,
  });
  const locationListArray = Object.values(locations);
  dispatch({
    type: CLINIC_LOCATIONS_LIST,
    payload: locationListArray,
  });

  const zohoInfo = {basicInfo, usage};
  return zohoInfo;
};

export const loginUser = (email, password) => {
  return async (dispatch) => {
    const result = firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(async (user) => {
        const uid = user.user.uid;
        let userInfo = {};
        dispatch({type: RESETALL});
        await firebase
          .database()
          .ref(`/users/${uid}/`)
          .once('value', (snapshot) => {
            userInfo = snapshot.val();
            dispatch({
              type: USER_INFO,
              payload: userInfo,
            });
          });
        const clinicData = await getAllClinicInfo(
          {clinicId: userInfo.clinicId},
          dispatch,
        );

        const result1 = {error: false, clinicBasicInfo: clinicData.basicInfo};
        return result1;
      })
      .catch((response) => {
        if (response.code === 'auth/user-not-found') {
          const result1 = {
            error: true,
            errorMessage:
              'Email not found. Please go back and create a new account, or use the correct email address.',
          };
          return result1;
        } else if (response.code === 'auth/wrong-password') {
          const result1 = {
            error: true,
            errorMessage: 'Please enter correct password.',
          };
          return result1;
        } else if (response.code === 'auth/too-many-requests') {
          const result1 = {
            error: true,
            errorMessage:
              'Exceeded request limit. Please wait some time or reset your password below.',
          };
          return result1;
        } else {
          const result1 = {
            error: true,
            errorMessage:
              'Authentication Error. Please check your credentials and try again.',
          };
          return result1;
        }
      });
    return result;
  };
};

const getAllClinicInfo = async ({clinicId}, dispatch) => {
  let clinicObject = {};
  await firebase
    .database()
    .ref(`/clinics/${clinicId}/`)
    .once('value', async (snapshot) => {
      clinicObject = snapshot.val();
      dispatch({
        type: CLINIC_BASIC_INFO,
        payload: clinicObject.basicInfo,
      });
      dispatch({
        type: CLINIC_USERS_LIST,
        payload: clinicObject.users,
      });
      dispatch({
        type: CLINIC_USAGE_INFO,
        payload: clinicObject.usage,
      });

      if (clinicObject.patients) {
        const path = `/clinics/${clinicId}/patients/`;
        await firebase
          .database()
          .ref(path)
          .orderByChild('archived')
          .endAt(null)
          .once('value', (snapshot) => {
            const patientObject = snapshot.val() || {};
            let patientListArray = Object.values(patientObject);
            if (patientListArray.length > 0) {
              patientListArray = _.orderBy(
                patientListArray,
                ['createdByDate'],
                ['desc'],
              );
              dispatch({
                type: CLINIC_PATIENTS_LIST,
                payload: patientListArray,
              });
            }
          });
      } else {
        dispatch({
          type: CLINIC_PATIENTS_LIST,
          payload: [],
        });
      }
      console.log('dispatched patient list');
      if (clinicObject.templates) {
        const templateListArray = Object.values(clinicObject.templates);
        dispatch({
          type: CLINIC_TEMPLATES_LIST,
          payload: templateListArray,
        });
      }
      if (clinicObject.locations) {
        const locationListArray = Object.values(clinicObject.locations);
        if (locationListArray.length > 0) {
          dispatch({
            type: CLINIC_LOCATIONS_LIST,
            payload: locationListArray,
          });
        }
      }

      await firebase
        .database()
        .ref('/adaCodes/')
        .once('value', async (snapshot3) => {
          let adaCodeObject = snapshot3.val();
          // let adaCodeArrayOfObjectsForProcedures = [];
          const adaCodeKeys = Object.keys(adaCodeObject);
          const adaCodeValues = Object.values(adaCodeObject);

          adaCodeValues.forEach((ele, index) => {
            const description = ele.description;
            adaCodeObject[adaCodeKeys[index]] = description;
            // adaCodeArrayOfObjectsForProcedures[index] = { key:adaCodeKeys[index], label: adaCodeKeys[index]}
          });
          console.log('adaCodeObject', adaCodeObject);
          console.log('adaCodeKeys', adaCodeKeys);
          dispatch({
            type: ADA_CODE_LIST,
            payload: adaCodeObject,
          });
          dispatch({
            type: ADA_CODE_KEYS,
            payload: adaCodeKeys,
          });
        });
    });
  dispatch({
    type: ACTIVE_OR_ARCHIVED_PATIENT_LIST,
    payload: 'Active',
  });
  return clinicObject;
};

export const addOrEditPatientData = (
  values,
  insuranceArray,
  clinicBasicInfo,
  userInfo,
  existingPatientInfo,
  editPatient,
) => {
  // console.log('edittt', editPatient);
  // existingPatientInfo will only be passed if updating an existing patient
  const UNIX = new Date().getTime();
  const {currentUser} = firebase.auth();
  const uid = currentUser.uid;
  return async (dispatch) => {
    // need to do data validation

    // Check if its an existing patient, if so, just update dont reset;
    let patientId = '';
    if (editPatient === true) {
      patientId = existingPatientInfo.patientId;
      await firebase
        .database()
        .ref(`/clinics/${clinicBasicInfo.clinicId}/patients/${patientId}`)
        .update({personalInfo: values});
    } else {
      const createdByFullName = `${userInfo.firstName} ${userInfo.lastName}`;
      const newPatientData = {
        personalInfo: {...values},
        createdBy: uid,
        createdByFullName,
        createdByDate: UNIX,
        // location: values.location,
        lastCheckedInsuranceDate: false,
        lastCheckedInsuranceBy: false,
        status: 'Unchecked',
      };
      const newPostRef = await firebase
        .database()
        .ref(`/clinics/${clinicBasicInfo.clinicId}/patients`)
        .push(newPatientData);
      patientId = newPostRef.key;
      await firebase
        .database()
        .ref(`/clinics/${clinicBasicInfo.clinicId}/patients/${patientId}/`)
        .update({patientId: patientId});
    }
    insuranceArray.forEach(async function (ele, index) {
      // first if is to check if it is primary insurance and set on outer object so we can display on patient grid
      if (ele.primaryInsurance === true) {
        await firebase
          .database()
          .ref(
            `/clinics/${clinicBasicInfo.clinicId}/patients/${patientId}/primaryInsurance`,
          )
          .set(ele.insurance);
      }
      // If the insurance already has an id, then we just want to update
      if (ele.id) {
        await firebase
          .database()
          .ref(
            `/clinics/${clinicBasicInfo.clinicId}/patients/${patientId}/insurance/`,
          )
          .update({[ele.id]: ele});
      } else {
        // If it is a new insurance, then we need to push to create unique id for insurance
        const newPostRef = await firebase
          .database()
          .ref(
            `/clinics/${clinicBasicInfo.clinicId}/patients/${patientId}/insurance/`,
          )
          .push(ele);
        const id = newPostRef.key;
        await firebase
          .database()
          .ref(
            `/clinics/${clinicBasicInfo.clinicId}/patients/${patientId}/insurance/${id}/id`,
          )
          .set(id);
      }
    });
    // This is for edit patient data only to get new data stored on firebase and return back to local state

    const patientData = await firebase
      .database()
      .ref(`/clinics/${clinicBasicInfo.clinicId}/patients/${patientId}`)
      .once('value')
      .then((snapshot) => {
        const returnObject = snapshot.val();
        return returnObject;
      });

    if (editPatient === true) {
      dispatch(updatePatient(patientData));
      return patientData;
    } else {
      dispatch(addPatient(patientData));
    }
  };
};

export const makeOnederfulCall = (
  patientData,
  npiNumber,
  userInfo,
  clinicBasicInfo,
  insurance,
  memberId,
  groupNumber,
  insuranceId,
  dependent,
  UNIX,
  noUpdateOfUsage,
  selectedInsurance,
) => {
  // existingPatientInfo will only be passed if updating an existing patient
  const {
    subscriberDOB,
    subscriberLastName,
    subscriberFirstName,
  } = selectedInsurance;

  return async (dispatch) => {
    // need to do data validation DOB is currently 2020-01-30
    // need it to be mm/dd/yyyy
    const DOBArray = patientData.personalInfo.DOB.split('-');
    // DOBArray = [yyyy, mm, dd]
    const DOB = `${DOBArray[1]}/${DOBArray[2]}/${DOBArray[0]}`;

    let adjustedSubscriberDOB = '';
    if (dependent) {
      const adjustedDOBArray = subscriberDOB.split('-');
      // DOBArray = [yyyy, mm, dd]
      adjustedSubscriberDOB = `${adjustedDOBArray[1]}/${adjustedDOBArray[2]}/${adjustedDOBArray[0]}`;
    }
    const postData = {
      firstName: patientData.personalInfo.firstName,
      lastName: patientData.personalInfo.lastName,
      DOB,
      npiNumber,
      clinicId: clinicBasicInfo.clinicId,
      patientId: patientData.patientId,
      insurance,
      memberId,
      groupNumber,
      verifiedByFullName: `${userInfo.firstName} ${userInfo.lastName}`,
      insuranceId,
      dependent,
      subscriberLastName,
      subscriberFirstName,
      subscriberDOB: dependent === true ? adjustedSubscriberDOB : '',
      changeInsuranceID: '',
      UNIX,
    };
    // console.log('postData', postData);
    const result = await axios
      .post(
        'https://us-central1-insurance-ca69d.cloudfunctions.net/makeOnederfulCall',
        // eslint-disable-next-line radix
        postData,
      )
      .then((data) => {
        // console.log(data);
        if (data.data.error) {
          const returnObject = {
            error: true,
            errorMessage: data.data.errorMessage,
          };
          return returnObject;
        }
        return data.data;
      })
      .catch(() => {
        const returnObject = {
          error: true,
          errorMessage:
            'Error connecting with the Insurance Company. Please contact support.',
        };
        return returnObject;
      });

    // Calling this to update all clinic info on app
    // await getAllClinicInfo(userInfo, dispatch);

    await firebase
      .database()
      .ref(`/clinics/${clinicBasicInfo.clinicId}/usage`)
      .once('value', async (snapshot) => {
        let clinicObject = snapshot.val();
        dispatch({
          type: CLINIC_USAGE_INFO,
          payload: clinicObject,
        });
      });
    // This is for edit patient data only to get new data stored on firebase and return back to local state
    await firebase
      .database()
      .ref(
        `/clinics/${clinicBasicInfo.clinicId}/patients/${patientData.patientId}`,
      )
      .once('value', (snapshot) => {
        const returnObject = snapshot.val();
        result.patientData = returnObject;
        dispatch(updatePatient(returnObject));
      });

    return result;
  };
};

export const makeChangeCall = (
  patientData,
  npiNumber,
  userInfo,
  clinicBasicInfo,
  insurance,
  memberId,
  groupNumber,
  insuranceId,
  dependent,
  UNIX,
  noUpdateOfUsage,
  changeId,
  OnederfulSuccess,
  selectedInsurance,
  taxId,
  taxLicense,
  doctorFirstName,
  doctorLastName,
) => {
  // existingPatientInfo will only be passed if updating an existing patient

  const {
    subscriberDOB,
    subscriberLastName,
    subscriberFirstName,
  } = selectedInsurance;
  return async (dispatch) => {
    // need to do data validation DOB is currently 2020-01-30
    // need it to be mm/dd/yyyy
    const DOBArray = patientData.personalInfo.DOB.split('-');
    // DOBArray = [yyyy, mm, dd]
    const DOB = `${DOBArray[1]}/${DOBArray[2]}/${DOBArray[0]}`;

    // *** NEED TO ADD IN SUUBSCRIBER DOB TRANSFORMATIONN
    let adjustedSubscriberDOB = '';
    if (dependent) {
      const adjustedDOBArray = subscriberDOB.split('-');
      // DOBArray = [yyyy, mm, dd]
      adjustedSubscriberDOB = `${adjustedDOBArray[1]}/${adjustedDOBArray[2]}/${adjustedDOBArray[0]}`;
    }

    const postData = {
      firstName: patientData.personalInfo.firstName,
      lastName: patientData.personalInfo.lastName,
      DOB,
      npiNumber,
      clinicId: clinicBasicInfo.clinicId,
      patientId: patientData.patientId,
      insurance,
      memberId,
      groupNumber,
      verifiedByFullName: `${userInfo.firstName} ${userInfo.lastName}`,
      insuranceId,
      dependent,
      subscriberLastName,
      subscriberFirstName,
      subscriberDOB: dependent === true ? adjustedSubscriberDOB : '',
      changeInsuranceId: changeId,
      UNIX,
      noUpdateOfUsage,
      OnederfulSuccess,
      taxId,
      taxLicense,
      doctorFirstName,
      doctorLastName,
    };
    // console.log('postData', postData);
    const result = await axios
      .post(
        'https://us-central1-insurance-ca69d.cloudfunctions.net/makeChangeCall',
        // eslint-disable-next-line radix
        postData,
      )
      .then((data) => {
        // console.log(data);
        return data.data;
      })
      .catch(() => {
        const returnObject = {
          changeError: true,
          errorMessage:
            'The Insurance Company server was unable to respond at this time. Please try again and contact 123 support if the issues continues.',
        };
        return returnObject;
      });

    // Calling this to update all clinic info on app
    // await getAllClinicInfo(userInfo, dispatch);
    // This is for edit patient data only to get new data stored on firebase and return back to local state
    await firebase
      .database()
      .ref(`/clinics/${clinicBasicInfo.clinicId}/usage`)
      .once('value', async (snapshot) => {
        let clinicObject = snapshot.val();
        dispatch({
          type: CLINIC_USAGE_INFO,
          payload: clinicObject,
        });
      });

    const path = `/clinics/${clinicBasicInfo.clinicId}/patients/${patientData.patientId}`;
    // console.log('PATH', path);
    await firebase
      .database()
      .ref(path)
      .once('value', (snapshot) => {
        const returnObject = snapshot.val();
        result.patientData = returnObject;
        dispatch(updatePatient(returnObject));
      });

    return result;
  };
};

export const makeThirdVerificationCall = (
  patientData,
  npiNumber,
  userInfo,
  clinicBasicInfo,
  insurance,
  memberId,
  groupNumber,
  insuranceId,
  dependent,
  UNIX,
  noUpdateOfUsage,
  changeId,
  OnederfulSuccess,
  selectedInsurance,
  taxId,
  taxLicense,
  doctorFirstName,
  doctorLastName,
  thirdVerificationPayer,
  payer,
) => {
  // existingPatientInfo will only be passed if updating an existing patient

  const {
    subscriberDOB,
    subscriberLastName,
    subscriberFirstName,
  } = selectedInsurance;
  return async (dispatch) => {
    // need to do data validation DOB is currently 2020-01-30
    // need it to be mm/dd/yyyy
    const DOBArray = patientData.personalInfo.DOB.split('-');
    // DOBArray = [yyyy, mm, dd]
    const DOB = `${DOBArray[1]}/${DOBArray[2]}/${DOBArray[0]}`;

    // *** NEED TO ADD IN SUUBSCRIBER DOB TRANSFORMATIONN
    let adjustedSubscriberDOB = '';
    if (dependent) {
      const adjustedDOBArray = subscriberDOB.split('-');
      // DOBArray = [yyyy, mm, dd]
      adjustedSubscriberDOB = `${adjustedDOBArray[1]}/${adjustedDOBArray[2]}/${adjustedDOBArray[0]}`;
    }

    const postData = {
      firstName: patientData.personalInfo.firstName,
      lastName: patientData.personalInfo.lastName,
      DOB,
      npiNumber,
      clinicId: clinicBasicInfo.clinicId,
      patientId: patientData.patientId,
      insurance,
      memberId,
      groupNumber,
      verifiedByFullName: `${userInfo.firstName} ${userInfo.lastName}`,
      insuranceId,
      dependent,
      subscriberLastName,
      subscriberFirstName,
      subscriberDOB: dependent === true ? adjustedSubscriberDOB : '',
      changeInsuranceId: changeId,
      UNIX,
      noUpdateOfUsage,
      OnederfulSuccess,
      taxId,
      taxLicense,
      doctorFirstName,
      doctorLastName,
      thirdVerificationPayer,
      userEmail: userInfo.email,
      clinicBasicInfo,
      payer,
    };
    // console.log('postData', postData);
    const result = await axios
      .post(
        'https://us-central1-insurance-ca69d.cloudfunctions.net/makeThirdVerificationCall',
        // eslint-disable-next-line radix
        postData,
      )
      .then((data) => {
        console.log(data);

        // withAlert.show(`Third verification attempt was made`, {
        //   title: 'Successful call',
        // });
        return data.data;
      })
      .catch(() => {
        let returnObject1 = {
          thirdVerificationError: true,
          errorMessage:
            'The Insurance Company server was unable to respond at this time. Please try again and contact 123 support if the issues continues.',
        };
        return returnObject1;
      });

    // This is for edit patient data only to get new data stored on firebase and return back to local state
    await firebase
      .database()
      .ref(`/clinics/${clinicBasicInfo.clinicId}/usage`)
      .once('value', async (snapshot) => {
        let clinicObject = snapshot.val();
        dispatch({
          type: CLINIC_USAGE_INFO,
          payload: clinicObject,
        });
      });

    const path = `/clinics/${clinicBasicInfo.clinicId}/patients/${patientData.patientId}`;
    // console.log('PATH', path);
    await firebase
      .database()
      .ref(path)
      .once('value', (snapshot) => {
        const returnObject = snapshot.val();
        result.patientData = returnObject;
        dispatch(updatePatient(returnObject));
      });

    if (!result.error) {
      turnOnThirdVerificationListener(
        clinicBasicInfo.clinicId,
        patientData.patientId,
        insuranceId,
        UNIX,
        patientData.personalInfo.firstName,
        patientData.personalInfo.lastName,
      );
    }

    return result;
  };
};

const turnOnThirdVerificationListener = (
  clinicId,
  patientId,
  insuranceId,
  UNIX,
  patientFirstName,
  patientLastName,
) => {
  firebase
    .database()
    .ref(
      `/clinics/${clinicId}/patients/${patientId}/usageHistory/${insuranceId}/${UNIX}`,
    )
    .on('value', (snapshot) => {
      let result = {};
      if (snapshot.val()) {
        result = snapshot.val();
      }
      console.log('got new value');
      console.log(snapshot.val());

      if (
        result.calledThirdVerification === true &&
        result.thirdVerificationError === false
      ) {
        firebase
          .database()
          .ref(
            `/clinics/${clinicId}/patients/${patientId}/usageHistory/${insuranceId}/${UNIX}`,
          )
          .off();

        console.log('show success');

        window.alert(
          `Third verification attempt for ${patientFirstName} ${patientLastName} was successful. Please refresh the page to view the results.`,
        );
      } else if (
        result.thirdVerificationError &&
        result.thirdVerificationError !== 'In Progress'
      ) {
        // withAlert.show(
        //   `Third verification attempt for ${patientFirstName} ${patientLastName} failed due to ${result.thirdVerificationError}. Please try again or contact support`,
        //   {
        //     title: 'Successful call',
        //   },
        // );
        console.log('show failure');

        window.alert(
          `Third verification attempt for ${patientFirstName} ${patientLastName} failed.`,
        );
        firebase
          .database()
          .ref(
            `/clinics/${clinicId}/patients/${patientId}/usageHistory/${insuranceId}/${UNIX}`,
          )
          .off();
      }
      return;
    });
};

export const createTemplate = (templateName, basicClinicInfo, userInfo) => {
  return async (dispatch) => {
    await firebase
      .database()
      .ref(
        `/clinics/${basicClinicInfo.clinicId}/templates/${templateName}/name/`,
      )
      .set(templateName);
    await getAllClinicInfo(userInfo, dispatch);
    return;
  };
};

export const saveTemplate = (
  templateName,
  templateObject,
  userInfo,
  basicClinicInfo,
) => {
  return async (dispatch) => {
    await firebase
      .database()
      .ref(`/clinics/${basicClinicInfo.clinicId}/templates/${templateName}/`)
      .set(templateObject);
    await getAllClinicInfo(userInfo, dispatch);
    return;
  };
};

export const sendEmailAndOrTextToPatient = (
  phoneNumberSendForm,
  emailSendForm,
  locationSendForm,
  clinicBasicInfo,
  userInfo,
) => {
  return async (dispatch) => {
    let returnObject = {
      textMessageSendError: false,
      emailSendError: false,
    };
    if (phoneNumberSendForm.length > 1) {
      const postData = {
        clinicId: clinicBasicInfo.clinicId,
        location: locationSendForm,
        clinicName: clinicBasicInfo.clinicName,
        phone: phoneNumberSendForm,
        clinicEmail: clinicBasicInfo.email,
        clinicPhone: clinicBasicInfo.phone,
        userFirstName: `${userInfo.firstName}`,
      };
      await axios
        .post(
          'https://us-central1-insurance-ca69d.cloudfunctions.net/smsFormToPatient',
          // eslint-disable-next-line radix
          postData,
        )
        .then((token) => {
          return;
        })
        .catch(() => {
          returnObject.textMessageSendError = true;
          return;
        });
    }
    if (emailSendForm.length > 1) {
      const postData = {
        clinicId: clinicBasicInfo.clinicId,
        location: locationSendForm,
        clinicName: clinicBasicInfo.clinicName,
        email: emailSendForm,
        clinicEmail: clinicBasicInfo.email,
        clinicPhone: clinicBasicInfo.phone,
        userFirstName: `${userInfo.firstName}`,
      };
      await axios
        .post(
          'https://us-central1-insurance-ca69d.cloudfunctions.net/emailFormToPatient',
          // eslint-disable-next-line radix
          postData,
        )
        .then((token) => {
          return;
        })
        .catch(() => {
          returnObject.emailSendError = true;
          return;
        });
    }
    return returnObject;
  };
};

export const addNpiToFb = (
  {npiNumber, fullName, doctorFirstName, doctorLastName, taxLicense, taxId},
  clinicId,
) => {
  return async (dispatch) => {
    await firebase
      .database()
      .ref(`/clinics/${clinicId}/basicInfo/nationalProviderIds/${npiNumber}/`)
      .set({
        code: npiNumber,
        physician: fullName,
        doctorFirstName: doctorFirstName ? doctorFirstName : '',
        doctorLastName: doctorLastName ? doctorLastName : '',
        taxLicense: taxLicense ? taxLicense : '',
        taxId: taxId ? taxId : '',
      });
    await getAllClinicInfo({clinicId}, dispatch);
  };
};

export const addLocationToFb = ({location}, clinicId) => {
  return async (dispatch) => {
    await firebase
      .database()
      .ref(`/clinics/${clinicId}/locations/${location}/`)
      .set({
        active: true,
        name: location,
      });
    await getAllClinicInfo({clinicId}, dispatch);
  };
};

export const addUsersToFb = (textValues, clinicId) => {
  return async (dispatch) => {
    const postData = {
      textValues,
      clinicId,
    };

    const result = await axios
      .post(
        'https://us-central1-insurance-ca69d.cloudfunctions.net/createNewUser',
        // eslint-disable-next-line radix
        postData,
      )
      .then((response) => {
        console.log('response', response);
        return response.data;
      })
      .catch((error) => {
        console.log('error', error);
        const returnObject = {
          error: true,
          errorMessage: 'Error creating an account. Please contact support',
        };
        return returnObject;
      });
    console.log(result);
    // const result = await firebase
    //   .auth()
    //   .createUserWithEmailAndPassword(email, password)
    //   .then(async (user) => {
    //     const uid = user.user.uid;
    //     const userObj = {
    //       active: true,
    //       masterAdmin: false,
    //       admin: false,
    //       clinicId,
    //       theme,
    //       firstName,
    //       lastName,
    //       email,
    //       locationAccess: {
    //         [location]: true,
    //       },
    //       allClinics: location === 'All' ? true : false,
    //     };
    //     await firebase.database().ref(`/users/${uid}`).set(userObj);
    //     await firebase
    //       .database()
    //       .ref(`clinics/${clinicId}/users/${uid}`)
    //       .set(userObj);
    //     const returnObject = {error: false};
    //     return returnObject;
    //   })
    //   .catch((error) => {
    //     if (error.code === 'auth/email-already-in-use') {
    //       const returnObject = {error: true, errorMessage: 'Email in system'};
    //       return returnObject;
    //     } else if (error.message) {
    //       const returnObject = {error: true, errorMessage: error.message};
    //       return returnObject;
    //     } else {
    //       const returnObject = {
    //         error: true,
    //         errorMessage:
    //           'Account creation failed. Please try again or contact support.',
    //       };
    //       return returnObject;
    //     }
    //   });
    await getAllClinicInfo({clinicId}, dispatch);
    return result;
  };
};

export const editUserProperties = (
  {selectedUid, firstName, lastName, location, theme, admin},
  clinicId,
) => {
  return async (dispatch) => {
    await firebase
      .database()
      .ref(`/users/${selectedUid}/firstName`)
      .set(firstName);
    await firebase
      .database()
      .ref(`/users/${selectedUid}/lastName`)
      .set(lastName);
    await firebase.database().ref(`/users/${selectedUid}/admin`).set(admin);
    await firebase
      .database()
      .ref(`/users/${selectedUid}/locationAccess`)
      .set({[location]: true});
    await firebase.database().ref(`/users/${selectedUid}/theme`).set(theme);

    await firebase
      .database()
      .ref(`clinics/${clinicId}/users/${selectedUid}/firstName`)
      .set(firstName);
    await firebase
      .database()
      .ref(`clinics/${clinicId}/users/${selectedUid}/lastName`)
      .set(lastName);
    await firebase
      .database()
      .ref(`clinics/${clinicId}/users/${selectedUid}/admin`)
      .set(admin);
    await firebase
      .database()
      .ref(`clinics/${clinicId}/users/${selectedUid}/locationAccess`)
      .set({[location]: true});
    await firebase
      .database()
      .ref(`clinics/${clinicId}/users/${selectedUid}/theme`)
      .set(theme);

    await getAllClinicInfo({clinicId}, dispatch);
    return 'done';
  };
};

export const deleteNpiOnFb = ({npiNumber}, clinicId) => {
  return async (dispatch) => {
    await firebase
      .database()
      .ref(`/clinics/${clinicId}/basicInfo/nationalProviderIds/${npiNumber}/`)
      .remove();
    await getAllClinicInfo({clinicId}, dispatch);
  };
};

export const inactivateUser = ({selectedUid, isMasterAdmin}, clinicId) => {
  return async (dispatch) => {
    await firebase
      .database()
      .ref(`/clinics/${clinicId}/users/${selectedUid}/active/`)
      .set(false);
    await getAllClinicInfo({clinicId}, dispatch);
  };
};

export const resetPassword = (email) => {
  return async (dispatch) => {
    console.log(email);
    let result = await firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        console.log('success');
        return 'You have been sent an email to reset your password.';
      })
      .catch((error) => {
        console.log(error);
        if (error.message) {
          return error.message;
        } else {
          return 'Password reset failed. Please contact support.';
        }
      });
    return result;
  };
};

export const logoutFunc = () => {
  return async (dispatch) => {
    const result = await firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({type: RESETALL});
        return true;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
    return result;
  };
};

export const uploadToFirebase = (pdfBlob, clinicInfo, patientInfo) => {
  return async (dispatch) => {
    const formData = new FormData();
    formData.append('data', pdfBlob);
    console.log('formData', formData);
    let imageRef = null;
    if (patientInfo) {
      imageRef = firebase
        .storage()
        .ref(
          `/pdfStorage/${clinicInfo.clinicId}/${patientInfo.personalInfo.lastName}${patientInfo.personalInfo.firstName}.pdf`,
        );
    } else {
      imageRef = firebase
        .storage()
        .ref(`/pdfStorage/${clinicInfo.clinicId}/${clinicInfo.clinicName}.pdf`);
    }
    let url = false;
    await imageRef
      .put(pdfBlob)
      .then(async (pdf) => {
        console.log(pdf);
        await imageRef.getDownloadURL().then((url1) => {
          console.log('url in action', url1);
          url = url1;
          return;
        });
        return;
      })
      .catch((err) => {
        console.log(err);
        return;
      });
    return url;
  };
};

export const archivePatient = (patientId, clinicId) => {
  return async (dispatch) => {
    await firebase
      .database()
      .ref(`/clinics/${clinicId}/patients/${patientId}/archived`)
      .set(true);
    dispatch(removePatient(patientId));
    return;
  };
};

export const unArchivePatient = (patientId, clinicId) => {
  return async (dispatch) => {
    await firebase
      .database()
      .ref(`/clinics/${clinicId}/patients/${patientId}/archived`)
      .remove();
    dispatch(removePatient(patientId));
    return;
  };
};

export const loadArchivedPatients = (clinicId) => {
  return async (dispatch) => {
    dispatch({
      type: ACTIVE_OR_ARCHIVED_PATIENT_LIST,
      payload: 'Archived',
    });

    const path = `/clinics/${clinicId}/patients/`;
    console.log('PATH', path);
    await firebase
      .database()
      .ref(path)
      .orderByChild('archived')
      .equalTo(true)
      .once('value', (snapshot) => {
        const patientObject = snapshot.val();

        if (patientObject) {
          let patientListArray = Object.values(patientObject);
          if (patientListArray.length > 0) {
            patientListArray = _.orderBy(
              patientListArray,
              ['createdByDate'],
              ['desc'],
            );
            dispatch({
              type: CLINIC_PATIENTS_LIST,
              payload: patientListArray,
            });
          } else {
            dispatch({
              type: CLINIC_PATIENTS_LIST,
              payload: [],
            });
          }
        } else {
          dispatch({
            type: CLINIC_PATIENTS_LIST,
            payload: [],
          });
        }
      });

    return;
  };
};

export const loadActivePatientsList = (clinicId) => {
  return async (dispatch) => {
    dispatch({
      type: ACTIVE_OR_ARCHIVED_PATIENT_LIST,
      payload: 'Active',
    });

    await firebase
      .database()
      .ref(`/clinics/${clinicId}/patients/`)
      .orderByChild('archived')
      .endAt(null)
      .once('value', (snapshot) => {
        const patientObject = snapshot.val();

        if (patientObject) {
          let patientListArray = Object.values(patientObject);
          if (patientListArray.length > 0) {
            patientListArray = _.orderBy(
              patientListArray,
              ['createdByDate'],
              ['desc'],
            );
            dispatch({
              type: CLINIC_PATIENTS_LIST,
              payload: patientListArray,
            });
          } else {
            dispatch({
              type: CLINIC_PATIENTS_LIST,
              payload: [],
            });
          }
        } else {
          dispatch({
            type: CLINIC_PATIENTS_LIST,
            payload: [],
          });
        }
      });

    return;
  };
};

export const sendPDFBenefitsEmail = (email, emailBody, subject) => {
  return async (dispatch) => {
    let returnObject = {
      error: false,
    };

    let postData = {
      email,
      message: emailBody,
      subject,
    };
    await axios
      .post(
        'https://us-central1-insurance-ca69d.cloudfunctions.net/sendPDFBenefitsEmail',
        // eslint-disable-next-line radix
        postData,
      )
      .then(() => {
        return;
      })
      .catch(() => {
        returnObject.error = true;
        return;
      });
    return returnObject;
  };
};

export async function getInsurance(insurance, groupNumber, setState) {
  return await firebase
    .database()
    .ref(`employerBenefitsLibraries/${insurance}/${groupNumber}`)
    .once('value', (snapshot) => {
      const data = snapshot.val();
      if (typeof setState === 'function' && data) {
        const options = Object.values(data).map((option) => option);
        setState({employerBenefits: options[0], fullFilled: true, options});
      } else {
        setState({fullFilled: true});
      }
    });
}

export function getInsurancePlan(insurance, groupNumber, planId, callback) {
  firebase
    .database()
    .ref(`employerBenefitsLibraries/${insurance}/${groupNumber}/${planId}`)
    .once('value', (snapshot) => {
      const data = snapshot.val();
      if (typeof callback === 'function' && data) {
        callback(data);
      } else {
        callback();
      }
    });
}

export async function getInsuranceGroupNumberOptions(insurance, groupNumber) {}

export function updateLocationList(newLocation) {
  return (dispatch, getState) => {
    const {clinicLocationsList} = getState().apiCallsReducer;
    const newLocations = clinicLocationsList.map((location) => {
      if (
        (location.id && location.id === newLocation.id) ||
        location.name === newLocation.id
      ) {
        return newLocation;
      }

      return location;
    });

    dispatch({
      type: CLINIC_LOCATIONS_LIST,
      payload: newLocations,
    });
  };
}

export function pushLocation(newLocation) {
  return (dispatch, getState) => {
    const {clinicLocationsList} = getState().apiCallsReducer;
    const newLocations = [...clinicLocationsList, newLocation];

    dispatch({
      type: CLINIC_LOCATIONS_LIST,
      payload: newLocations,
    });
  };
}

export const getBasicInfo = (clinicId) => {
  return async (dispatch, getState) => {
    let clinicObject = {};
    await firebase
      .database()
      .ref(`/clinics/${clinicId}/basicInfo`)
      .once('value', async (snapshot) => {
        clinicObject = snapshot.val();
        dispatch({
          type: CLINIC_BASIC_INFO,
          payload: clinicObject,
        });
      });
  };
};

export const downloadURL = (url, title) => {
  return async (dispatch, getState) => {
    let returnObj = {
      error: false,
    };
    const config = {
      method: 'get',
      // mode: 'no-cors',
      url: url,
      responseType: 'blob',
      headers: {
        'Access-Control-Allow-Headers':
          'Content-Type, Access-Control-Allow-Headers, Authorization, ApiKey',
        'Access-Control-Allow-Origin': '*',
        ApiKey:
          'e536c691f6c31d016e2c3dd21768e37117f95d269898f446b4bb9163c7ac0305ab43e05ba114215b935dde6d8d0cd8310019e75b5697d6434680ba82799445e7',
      },
    };
    await axios(config)
      .then((response) => {
        fileDownload(response.data, `${title}.pdf`);
      })
      .catch((err) => {
        // eslint-disable-next-line no-alert
        returnObj.error = true;
        console.log(err);
      });
    return returnObj;
  };
};

export const getPatientInfo = (patientId) => {
  return async (dispatch, getState) => {
    let uid = '';
    const {currentUser} = firebase.auth();
    if (currentUser) {
      uid = currentUser.uid;
    } else {
      console.log('loading');
      return 'loading';
    }
    console.log('uid', uid);
    const {clinicBasicInfo} = getState().apiCallsReducer;
    let clinicId = '';
    if (clinicBasicInfo && clinicBasicInfo.clinicId) {
      clinicId = clinicBasicInfo.clinicId;
    } else {
      await firebase
        .database()
        .ref(`/users/${uid}/clinicId`)
        .once('value', async (snapshot) => {
          clinicId = snapshot.val();
        });
    }
    console.log('patientId in get patient info', patientId);
    let currentPatient = null;
    await firebase
      .database()
      .ref(`/clinics/${clinicId}/patients/${patientId}`)
      .once('value', async (snapshot) => {
        currentPatient = snapshot.val();
        return;
      });
    console.log('currentPatient in get patient', currentPatient);
    if (currentPatient) {
      dispatch({type: SET_CURRENT_USER, payload: {currentPatient}});
    }
    return currentPatient;
  };
};

export const searchForPatient = (firstName, lastName, DOB, clinicId) => {
  return async (dispatch, getState) => {
    let patientId = null;
    const config = {
      method: 'post',
      postDate: {
        firstName,
        lastName,
        DOB,
        clinicId,
      },
      // mode: 'no-cors',
      url:
        'https://us-central1-insurance-ca69d.cloudfunctions.net/searchForPatient',
    };
    await axios(config)
      .then((res) => {
        console.log(res);
        patientId = res.patientInfo.patientId;
      })
      .catch((err) => {
        console.log(err);
      });
    return patientId;
  };
};

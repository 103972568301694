import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Login from './Login/Login';
import Signup from './Signup/Signup';
// import TableData from './Table/TableData';
import Profile from './Profile/Profile';
import ProfileBenefits from './Profile/ProfileBenefits/ProfileBenefits';
import SearchComponent from './Profile/SearchInsurance/SearchInsurance';
import Template from './Template/Template';
import LoginEmailPass from './LoginEmailPass/LoginEmailPass';
import '../index.css';
import Settings from './Settings/Settings';
import ResetPassword from './ResetPassword/ResetPassword';
import Insurance from 'components/Profile/InsuranceView/InsuranceView';
import EmployerBenefits from 'components/EmployerBenefits/EmployerBenefits';
import Treatment from 'components/Treatment/Treatment';
import ProfileTabs from 'components/Profile/ProfileTabs/ProfileTabs';

export default function LayoutRoute({children}) {
  return (
    <Router>
      {children}
      <div id={'routerDiv'}>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="/LoginEmailPass" component={LoginEmailPass} />
          <Route path="/ResetPassword" component={ResetPassword} />
          <Route path="/SignUp" component={Signup} />
          {/* <Route
            path="/TableData"
            // component={(props) => <TableData {...props} redirect />}
            component={ProfileTabs}
          /> */}
          <Route path="/section" component={ProfileTabs} />
          <Route path="/Profile" component={Profile} />
          <Route path="/Template" component={Template} />
          <Route path="/Settings" component={Settings} />
          <Route path="/Benefits" component={ProfileBenefits} />
          <Route path="/SearchInsurance" component={SearchComponent} />
          <Route path="/treatment" component={Treatment} />
          <Route path="/patient" component={ProfileTabs} />
          <Route
            path="/insurance/:insuranceId?/:groupNumber?"
            component={Insurance}
          />
          <Route
            path="/employer-benefits/:insuranceId?/:groupNumber?/:planId?"
            component={EmployerBenefits}
          />
          {/* <Route path="*" component={ProfileTabs} /> */}
        </Switch>
      </div>
    </Router>
  );
}

export function validateForm(model) {
  const errors = {};

  if (!model.employerName) {
    errors.employerName = true;
  }

  if (!model.planName) {
    errors.planName = true;
  }

  return Object.keys(errors).length ? errors : false;
}

import React from 'react';
import Modal from 'components/Common/Modal/Modal';
import Autocomplete from 'components/Form/Autocomplete/ADAAutocomplete';
import Input from 'components/Form/Input/Input';
import {getInsuranceCode} from 'Utils/';
import Button from 'components/Common/Button/Button';
import {useHistory} from 'react-router';
import NoteIcon from '@material-ui/icons/Note';
import styles from './EmployerBenefitsModal.module.scss';

const options = getInsuranceCode();

function EmployerBenefitsModal(props) {
  const [modal, setModal] = React.useState(false);
  const [model, setModel] = React.useState({adaCode: '', groupNumber: ''});
  const history = useHistory();

  const handleModel = (name, value) => {
    setModel((currentModel) => ({...currentModel, [name]: value}));
  };

  return (
    <div className={styles.wrapper}>
      <NoteIcon onClick={() => setModal(true)} className={styles.icon} />
      <Modal
        open={modal}
        onClose={() => setModal(false)}
        title="Add profile benefits">
        <div className="row">
          <div className="col-12">
            <Autocomplete
              name="adaCode"
              label="Search Insurance Code"
              value={model.adaCode}
              onChange={handleModel}
              options={options}
            />
          </div>
          <div className="col-12">
            <Input
              label="Group Number"
              name="groupNumber"
              value={model.groupNumber}
              onChange={handleModel}
            />
          </div>
          <div className="col-12 my-4">
            <Button
              fullWidth
              onClick={() => {
                if (model.adaCode && model.groupNumber) {
                  history.push(
                    `/employer-benefits/${model.adaCode}/${model.groupNumber}`,
                  );
                }
              }}>
              Next
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
EmployerBenefitsModal.propTypes = {};

export default EmployerBenefitsModal;

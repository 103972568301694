import {SET_CURRENT_USER} from '../actions/types';

const DEFAULT_STATE = {
  currentPatient: null,
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {currentPatient: action.payload.currentPatient};
    default:
      return state;
  }
}

import React, {useState, useEffect, Fragment} from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

// {/*const testJSON = {
//   Deductible: [
//     {
//       network: 'out_of_network',
//       insurance_type: 'group_policy',
//       plan_period: 'service',
//       amount: '50.00',
//       coverage_level: 'individual',
//       procedure_code: 'AD:D0150',
//       service_dates: [
//         {
//           type: 'service',
//           value: '01/01/2011',
//         },
//       ],
//     },
//   ],
//   Maximums: [],
//   Coinsurance: [
//     {
//       network: 'out_of_network',
//       insurance_type: 'group_policy',
//       percent: '90',
//       procedure_code: 'AD:D0150',
//       limitation: '1 visits in 6 month',
//       service_dates: [
//         {
//           type: 'service',
//           value: '01/01/2011',
//         },
//       ],
//       health_service_descriptions: [
//         {
//           quantity_qualifier: 'visits',
//           quantity: '1',
//           time_period_qualifier: 'month',
//           time_period_value: '6',
//         },
//       ],
//     },
//     {
//       network: 'in_network',
//       insurance_type: 'group_policy',
//       percent: '100',
//       procedure_code: 'AD:D0150',
//       limitation: '1 visits in 6 month',
//       service_dates: [
//         {
//           type: 'service',
//           value: '01/01/2011',
//         },
//       ],
//       health_service_descriptions: [
//         {
//           quantity_qualifier: 'visits',
//           quantity: '1',
//           time_period_qualifier: 'month',
//           time_period_value: '6',
//         },
//       ],
//     },
//   ],
//   Limitations: [],
// };*/}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'scroll',
    [theme.breakpoints.down('xs')]: {
      overflow: 'scroll',
    },
  },
  modalBodyContainer: {
    width: '70%',
    height: '90%',
    backgroundColor: 'white',
  },
  closeButton: {
    color: 'white',
    position: 'absolute',
    padding: 5,
  },
  modalHeader: {
    backgroundColor: '#1B7FE1',
    height: '20%',
    width: '100%',
  },
  modalBody: {
    backgroundColor: 'white',
    flex: 1,
    // height: '80%',
    width: '100%',
    // padding: 20,
    color: 'black',
    overflowWrap: 'break-word',
  },
  titleText: {
    fontSize: 35,
    height: '100%',
    width: '100%',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  JSONItemContainer: {
    backgroundColor: '#EFEFEF',
    display: 'flex',
    color: 'black',
    marginBottom: 15,
    // border: '2px solid #1B7FE1',
    borderRadius: '6px',
    flexDirection: 'column',
  },
  JSONItemTitleContainer: {
    display: 'flex',
    padding: 20,
    fontSize: 24,
    fontWeight: 'bold',
    justifyContent: 'center',
    alignItems: 'center',
  },
  JSONItemItemDetailContainer: {
    display: 'flex',
    paddingRight: 20,
    paddingLeft: 20,
  },
  table: {},
  tableRow: {},
  tableCell: {
    fontSize: '12px',
  },
  tableHead: {
    fontWeight: 'bold',
    backgroundColor: '#c9c9c9',
  },
  // tableInnerLabel: {
  //   display: 'flex',
  //   flex: 1,
  //   fontWeight: 'bold',
  //   paddingLeft: 20,
  //   width: '100%',
  //   backgroundColor: '#dbdbdb',
  // },
}));

const CodeModal = ({
  open,
  passCloseModalStateUp,
  selectedOnederfulData,
  selectedCode,
}) => {
  const [openModal, setOpen] = useState(true);
  const [JSONDataKeys, setJSONDataKeys] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    setOpen(open);
  }, [open]);
  useEffect(() => {
    //replace testJSON with selectedOnederfulData

    if (selectedOnederfulData) {
      setJSONDataKeys(Object.keys(selectedOnederfulData));
    }
  }, [selectedOnederfulData]);

  const handleClose = () => {
    passCloseModalStateUp(false);
  };

  const capitalizeFirstLetterAndRemoveUnderscore = (word) => {
    let capitalized = word.replace(/\b(\w)/g, (s) => s.toUpperCase());
    capitalized = capitalized.replace(/_/g, ' ');
    return capitalized;
  };
  const renderObject = (input) => {
    return Object.entries(input).map((row1) => {
      return (
        <TableRow key={row1[0] ? row1[0] : '1'} className={classes.tableRow}>
          <TableCell className={classes.tableCell} component="th" scope="row">
            {capitalizeFirstLetterAndRemoveUnderscore(row1[0])}
          </TableCell>
          <TableCell className={classes.tableCell}>
            {capitalizeFirstLetterAndRemoveUnderscore(row1[1])}
          </TableCell>
        </TableRow>
      );
    });
  };
  const renderArray = (array) => {
    if (typeof array[0] === 'object') {
      return (
        <Table className={classes.table} size="small">
          {/* <TableHead >
            <TableRow>
              <TableCell className={classes.tableHead}>Item</TableCell>
              <TableCell className={classes.tableHead}>Description</TableCell>
            </TableRow>
          </TableHead> */}
          <TableBody>
            {array.map((row, index) => {
              return renderObject(row);
            })}
          </TableBody>
        </Table>
      );
    } else if (Array.isArray(array[0])) {
      // console.log('array inside renderArray');
      // console.log(array[0]);
    } else {
      return capitalizeFirstLetterAndRemoveUnderscore(array[0]);
    }
  };

  const renderArrayObjectOrText = (data) => {
    if (Array.isArray(data)) {
      return renderArray(data);
    } else if (typeof data === 'object') {
      return renderObject(data);
    } else {
      return capitalizeFirstLetterAndRemoveUnderscore(data);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        closeAfterTransition
        disableEnforceFocus
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          backgroundColor: '#1B7FE1',
        }}>
        <Fade in={openModal}>
          <div className={classes.modalBodyContainer}>
            <div className={classes.modalHeader}>
              <IconButton className={classes.closeButton} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
              <div className={classes.titleText}>{selectedCode} Info</div>
            </div>
            <div className={classes.modalBody}>
              {!JSONDataKeys[0]
                ? null
                : JSONDataKeys.map((object1, index) => {
                    return (
                      <div className={classes.JSONItemContainer}>
                        <div className={classes.JSONItemTitleContainer}>
                          {capitalizeFirstLetterAndRemoveUnderscore(object1)}
                        </div>
                        <div className={classes.JSONItemItemDetailContainer}>
                          <Table className={classes.table} size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell className={classes.tableHead}>
                                  Item
                                </TableCell>
                                <TableCell className={classes.tableHead}>
                                  Description
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {!selectedOnederfulData[
                                object1
                              ] ? null : selectedOnederfulData[object1].length >
                                0 ? (
                                selectedOnederfulData[object1].map((row) => {
                                  return (
                                    <Fragment>
                                      {/* <div className={classes.tableInnerLabel}>New Entry</div> */}
                                      {Object.entries(row).map((row1) => {
                                        return (
                                          <TableRow
                                            key={row1[0] ? row1[0] : '1'}
                                            className={classes.tableRow}>
                                            <TableCell
                                              className={classes.tableCell}
                                              component="th"
                                              scope="row">
                                              {capitalizeFirstLetterAndRemoveUnderscore(
                                                row1[0],
                                              )}
                                            </TableCell>
                                            <TableCell
                                              className={classes.tableCell}>
                                              {renderArrayObjectOrText(row1[1])}
                                              {/* {Array.isArray(row1[1])
                                                ? renderServiceDateArray(
                                                    row1[1],
                                                  )
                                                : capitalizeFirstLetterAndRemoveUnderscore(
                                                    row1[1],
                                                  )} */}
                                            </TableCell>
                                          </TableRow>
                                        );
                                      })}
                                    </Fragment>
                                  );
                                })
                              ) : (
                                <TableCell
                                  className={classes.tableCell}
                                  component="th"
                                  scope="row">
                                  No data available
                                </TableCell>
                              )}
                            </TableBody>
                          </Table>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default CodeModal;

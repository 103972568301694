import React from 'react';
import Phase2 from 'components/Profile/ProfileTabs/Phase2/Phase2';
import styles from './Treatment.module.scss';
import {getPatientInfo} from '../../actions';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import {useParams} from 'react-router-dom';

function Treatment() {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentPatient = useSelector(
    ({patientReducer}) => patientReducer.currentPatient,
  );
  const userInfo = useSelector(({apiCallsReducer}) => apiCallsReducer.userInfo);
  const {patientId} = useParams();
  React.useEffect(() => {
    if (!currentPatient) {
      checkPatient();
    }
  }, [currentPatient, history, userInfo]);

  const checkPatient = async () => {
    let patientInfo = null;
    await dispatch(getPatientInfo(patientId)).then((result) => {
      patientInfo = result;
    });
    if (patientInfo) {
      console.log('got patient info');
    } else if (patientInfo === 'loading') {
      console.log('loading');
    } else {
      history.push('/section/production');
    }
  };

  return (
    <div className={styles.wrapper}>
      <Phase2 currentPatient={currentPatient} />
    </div>
  );
}

export default Treatment;

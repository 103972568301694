import {SET_CURRENT_USER, UPDATE_PATIENT} from './types';
import firebase from 'firebase';
import _ from 'lodash';

export function setCurrentPatient(currentPatient) {
  return {type: SET_CURRENT_USER, payload: {currentPatient}};
}

export function updatePatient(patient) {
  return (dispatch, getState) => {
    const {apiCallsReducer} = getState();
    const {
      searchedClinicPatientsList,
      locationFilteredClinicPatientsList,
      clinicPatientsList,
    } = apiCallsReducer;

    const newSearchedClinicPatientsList = Array.isArray(
      searchedClinicPatientsList,
    )
      ? searchedClinicPatientsList.map((currentPatient) => {
          if (currentPatient.patientId === patient.patientId) {
            // console.log('returning searchedClinicPatientsList');
            // console.log({...currentPatient, ...patient});
            return {...currentPatient, ...patient};
          }

          return currentPatient;
        })
      : searchedClinicPatientsList;

    const newLocationFilteredClinicPatientsList = locationFilteredClinicPatientsList
      ? locationFilteredClinicPatientsList.map((currentPatient) => {
          if (currentPatient.patientId === patient.patientId) {
            // console.log('returning locationFilteredClinicPatientsList');
            // console.log({...currentPatient, ...patient});
            return {...currentPatient, ...patient};
          }

          return currentPatient;
        })
      : locationFilteredClinicPatientsList;

    const newClinicPatientsList = clinicPatientsList
      ? clinicPatientsList.map((currentPatient) => {
          if (currentPatient.patientId === patient.patientId) {
            // console.log('returning locationFilteredClinicPatientsList');
            // console.log({...currentPatient, ...patient});
            return {...currentPatient, ...patient};
          }

          return currentPatient;
        })
      : clinicPatientsList;

    dispatch({
      type: UPDATE_PATIENT,
      payload: {
        newSearchedClinicPatientsList,
        newLocationFilteredClinicPatientsList,
        newClinicPatientsList,
      },
    });
  };
}

export function addPatient(patient) {
  return (dispatch, getState) => {
    const {apiCallsReducer} = getState();
    const {
      searchedClinicPatientsList,
      locationFilteredClinicPatientsList,
      clinicPatientsList,
    } = apiCallsReducer;

    let newSearchedClinicPatientsList = Array.isArray(
      searchedClinicPatientsList,
    )
      ? [...searchedClinicPatientsList, patient]
      : searchedClinicPatientsList;

    let newLocationFilteredClinicPatientsList = [
      ...locationFilteredClinicPatientsList,
      patient,
    ];

    const newClinicPatientsList = clinicPatientsList
      ? clinicPatientsList.map((currentPatient) => {
          if (currentPatient.patientId === patient.patientId) {
            // console.log('returning locationFilteredClinicPatientsList');
            // console.log({...currentPatient, ...patient});
            return {...currentPatient, ...patient};
          }

          return currentPatient;
        })
      : clinicPatientsList;

    newSearchedClinicPatientsList = _.orderBy(
      newSearchedClinicPatientsList,
      ['createdByDate'],
      ['desc'],
    );
    newLocationFilteredClinicPatientsList = _.orderBy(
      newLocationFilteredClinicPatientsList,
      ['createdByDate'],
      ['desc'],
    );

    dispatch({
      type: UPDATE_PATIENT,
      payload: {
        newSearchedClinicPatientsList,
        newLocationFilteredClinicPatientsList,
        newClinicPatientsList,
      },
    });
  };
}

export function removePatient(patientId) {
  return (dispatch, getState) => {
    const {apiCallsReducer} = getState();
    const {
      searchedClinicPatientsList,
      locationFilteredClinicPatientsList,
      clinicPatientsList,
    } = apiCallsReducer;

    const newSearchedClinicPatientsList = Array.isArray(
      searchedClinicPatientsList,
    )
      ? searchedClinicPatientsList.filter(
          (item) => item.patientId !== patientId,
        )
      : searchedClinicPatientsList;

    const newLocationFilteredClinicPatientsList = locationFilteredClinicPatientsList
      ? locationFilteredClinicPatientsList.filter(
          (item) => item.patientId !== patientId,
        )
      : locationFilteredClinicPatientsList;

    const newClinicPatientsList = clinicPatientsList
      ? clinicPatientsList.filter((item) => item.patientId !== patientId)
      : clinicPatientsList;

    dispatch({
      type: UPDATE_PATIENT,
      payload: {
        newSearchedClinicPatientsList,
        newLocationFilteredClinicPatientsList,
        newClinicPatientsList,
      },
    });
  };
}

export async function getPatient(clinicId, patientId) {
  const patient = await firebase
    .database()
    .ref(`/clinics/${clinicId}/patients/${patientId}`)
    .once('value')
    .then((snapshot) => {
      const patientSnapshot = snapshot.val();

      return {...patientSnapshot, patientId: snapshot.key, id: snapshot.key};
    })
    .catch(() => {
      return {};
    });

  return patient;
}

import {makeStyles} from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  locationsDataGrid: {
    width: 200,
    height: '100%',
    minHeight: 300,
    borderColor: 'white',
    '& .MuiDataGrid-colCellTitle': {
      color: 'black',
      fontWeight: 'bolder',
    },
    '& .MuiDataGrid-iconSeparator': {
      color: '#efefef',
    },
    '& .MuiDataGrid-cell': {
      borderColor: 'white',
    },
    '& .MuiDataGrid-columnsContainer': {
      borderColor: 'white',
      backgroundColor: '#efefef',
    },
    '& .MuiDataGrid-window': {
      overflowX: 'hidden',
    },
  },
  addIcon: {
    height: 50,
    width: 50,
    paddingLeft: 10,
    color: '#1B7FE1',
    cursor: 'pointer',
  },
}));

import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'components/Form/Input/Input';
import Title from '../Options/Title';
import Subtitle from '../Options/Subtitle';
import Periodontal from './Periodontal';
import Column from 'components/Grid/Column';
import Autocomplete from 'components/Form/Autocomplete/Autocomplete';
import Options from 'data/options';

const Input = React.memo((props) => {
  const inputProps = React.useRef({'data-submodule': props.submodule});

  return <TextField {...props} inputProps={inputProps.current} />;
});

function Basic({values = {}, onChange, children, disableInputs}) {
  const handleChange = React.useCallback(
    (name, value, {dataset}) => {
      const {submodule} = dataset;
      onChange('basic', submodule, name, value);
    },
    [onChange],
  );

  return (
    <div className="row">
      <div className="col-md-12 my-1">
        <div className="d-flex justify-content-between">
          <Title>Basic</Title>
          {children}
        </div>
      </div>
      <div className="col-md-12 my-1">
        <Subtitle>Fillings</Subtitle>
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="fillings"
          value={
            values.fillings?.fillings || Options.basic.fillings.fillings[0]
          }
          onChange={handleChange}
          label="Fillings"
          disabled={disableInputs}
          submodule="fillings"
          options={Options.basic.fillings.fillings}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="fillingsNotes"
          value={values.fillings?.fillingsNotes}
          onChange={handleChange}
          label="Notes"
          disabled={disableInputs}
          submodule="fillings"
          options={Options.basic.fillings.fillingsNotes}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="posteriorComposites"
          value={
            values.fillings?.posteriorComposites ||
            Options.basic.fillings.posteriorComposites[0]
          }
          onChange={handleChange}
          label="Posterior Composites"
          disabled={disableInputs}
          submodule="fillings"
          options={Options.basic.fillings.posteriorComposites}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="posteriorCompositesLimitations"
          value={values.fillings?.posteriorCompositesLimitations}
          onChange={handleChange}
          label="Notes"
          disabled={disableInputs}
          submodule="fillings"
          options={Options.basic.fillings.posteriorCompositesLimitations}
        />
      </div>
      <div className="col-md-12 my-1">
        <Subtitle>Endodontics</Subtitle>
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="pulpotomy"
          value={
            values.endodontics?.pulpotomy ||
            Options.basic.endodontics.pulpotomy[2]
          }
          onChange={handleChange}
          label="Pulpotomy (3220)"
          disabled={disableInputs}
          submodule="endodontics"
          options={Options.basic.endodontics.pulpotomy}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Input
          name="anteriorPremolarRootCanal"
          value={
            values.endodontics?.anteriorPremolarRootCanal ||
            Options.basic.endodontics.anteriorPremolarRootCanal[1]
          }
          onChange={handleChange}
          label="Anterior/Premolar (3310/3320)"
          disabled={disableInputs}
          submodule="endodontics"
          options={Options.basic.endodontics.anteriorPremolarRootCanal}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Input
          name="rootCanal"
          value={
            values.endodontics?.rootCanal ||
            Options.basic.endodontics.rootCanal[1]
          }
          onChange={handleChange}
          label="Molar Root Canal (3330)"
          disabled={disableInputs}
          submodule="endodontics"
          options={Options.basic.endodontics.rootCanal}
        />
      </div>
      <div className="col-md-12 my-1">
        <Subtitle>Oral Surgery & Extractions</Subtitle>
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="boneGraft"
          value={
            values.oralSurgeryExtractions?.boneGraft ||
            Options.basic.oralSurgeryExtractions.boneGraft[3]
          }
          onChange={handleChange}
          label="Bone Graft (7953)"
          disabled={disableInputs}
          submodule="oralSurgeryExtractions"
          options={Options.basic.oralSurgeryExtractions.boneGraft}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Input
          name="boneGraftNote"
          value={values.oralSurgeryExtractions?.boneGraftNote}
          onChange={handleChange}
          label="Notes"
          disabled={disableInputs}
          submodule="oralSurgeryExtractions"
        />
      </div>
      <div className="col-md-12 my-1">
        <div className="row">
          <div className="col-md-3 col-xs-12">
            <Autocomplete
              name="extraction"
              value={
                values.oralSurgeryExtractions?.extraction ||
                Options.basic.oralSurgeryExtractions.extraction[3]
              }
              onChange={handleChange}
              label="Extraction (7140)"
              disabled={disableInputs}
              submodule="oralSurgeryExtractions"
              options={Options.basic.oralSurgeryExtractions.extraction}
            />
          </div>
        </div>
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="erupted"
          value={
            values.oralSurgeryExtractions?.erupted ||
            Options.basic.oralSurgeryExtractions.erupted[3]
          }
          onChange={handleChange}
          label="Erupted (7210)"
          disabled={disableInputs}
          submodule="oralSurgeryExtractions"
          options={Options.basic.oralSurgeryExtractions.erupted}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="eruptedLimitations"
          value={values.oralSurgeryExtractions?.eruptedLimitations}
          onChange={handleChange}
          label="Notes"
          disabled={disableInputs}
          submodule="oralSurgeryExtractions"
          options={Options.basic.oralSurgeryExtractions.eruptedLimitations}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="softTissue"
          value={
            values.oralSurgeryExtractions?.softTissue ||
            Options.basic.oralSurgeryExtractions.softTissue[3]
          }
          onChange={handleChange}
          label="Soft Tissue (7220)"
          disabled={disableInputs}
          submodule="oralSurgeryExtractions"
          options={Options.basic.oralSurgeryExtractions.softTissue}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="softTissueLimitations"
          value={values.oralSurgeryExtractions?.softTissueLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          submodule="oralSurgeryExtractions"
          options={Options.basic.oralSurgeryExtractions.softTissueLimitations}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="partialBony"
          value={
            values.oralSurgeryExtractions?.partialBony ||
            Options.basic.oralSurgeryExtractions.partialBony[3]
          }
          onChange={handleChange}
          label="Partial Bony (7230)"
          disabled={disableInputs}
          submodule="oralSurgeryExtractions"
          options={Options.basic.oralSurgeryExtractions.partialBony}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="partialBonyLimitations"
          value={values.oralSurgeryExtractions?.partialBonyLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          submodule="oralSurgeryExtractions"
          options={Options.basic.oralSurgeryExtractions.partialBonyLimitations}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="completeBony"
          value={
            values.oralSurgeryExtractions?.completeBony ||
            Options.basic.oralSurgeryExtractions.completeBony[3]
          }
          onChange={handleChange}
          label="Complete Bony (7240)"
          disabled={disableInputs}
          submodule="oralSurgeryExtractions"
          options={Options.basic.oralSurgeryExtractions.completeBony}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="completeBonyLimitations"
          value={values.oralSurgeryExtractions?.completeBonyLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          submodule="oralSurgeryExtractions"
          options={Options.basic.oralSurgeryExtractions.completeBonyLimitations}
        />
      </div>
      <Periodontal
        values={values.periodontal}
        handleChange={handleChange}
        disabled={disableInputs}
      />
      <div className="col-md-12 my-1">
        <Subtitle>Other services</Subtitle>
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="occlusalAdjustment"
          value={values.otherServices?.occlusalAdjustment}
          onChange={handleChange}
          label="Occlusal Adj (9951/9952)"
          disabled={disableInputs}
          submodule="otherServices"
          options={Options.basic.otherServices.occlusalAdjustment}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="occlusalAdjustmentLimitations"
          value={values.otherServices?.occlusalAdjustmentLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          submodule="otherServices"
          options={Options.basic.otherServices.occlusalAdjustmentLimitations}
        />
      </div>
      <div className="col-md-12 my-1">
        <Subtitle>Anesthesia</Subtitle>
      </div>
      <Column>
        <div className="col-md-3 col-xs-12 my-1">
          <Autocomplete
            name="intravenous"
            value={values.anesthesia?.intravenous}
            onChange={handleChange}
            label="Intravenous (9239/9243)"
            disabled={disableInputs}
            submodule="anesthesia"
            options={Options.basic.anesthesia.intravenous}
          />
        </div>
        <div className="col-md-3 col-xs-12 my-1">
          <Autocomplete
            name="generalAnesthesia"
            value={values.anesthesia?.generalAnesthesia}
            onChange={handleChange}
            label="General Anesthesia(9222/9223)"
            disabled={disableInputs}
            submodule="anesthesia"
            options={Options.basic.anesthesia.generalAnesthesia}
          />
        </div>
        <div className="col-md-3 col-xs-12 my-1">
          <Autocomplete
            name="generalAnesthesiaLimitations"
            value={values.anesthesia?.generalAnesthesiaLimitations}
            onChange={handleChange}
            label="Limitations"
            disabled={disableInputs}
            submodule="anesthesia"
            options={Options.basic.anesthesia.generalAnesthesiaLimitations}
          />
        </div>
      </Column>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="nonIntravenous"
          value={values.anesthesia?.nonIntravenous}
          onChange={handleChange}
          label="Non-Intravenous (9248)"
          disabled={disableInputs}
          submodule="anesthesia"
          options={Options.basic.anesthesia.nonIntravenous}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="nonIntravenousLimitations"
          value={values.anesthesia?.nonIntravenousLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          submodule="anesthesia"
          options={Options.basic.anesthesia.nonIntravenousLimitations}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="nitrousOxide"
          value={values.anesthesia?.nitrousOxide}
          onChange={handleChange}
          label="Nitrous Oxide (9230)"
          disabled={disableInputs}
          submodule="anesthesia"
          options={Options.basic.anesthesia.nitrousOxide}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="nitrousOxideLimitations"
          value={values.anesthesia?.nitrousOxideLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          submodule="anesthesia"
          options={Options.basic.anesthesia.nitrousOxideLimitations}
        />
      </div>
      <div className="col-md-12 my-1">
        <Autocomplete
          name="comments"
          multiline
          value={values.anesthesia?.comments}
          onChange={handleChange}
          label="Basic Comments"
          disabled={disableInputs}
          submodule="anesthesia"
          options={Options.basic.anesthesia.comments}
        />
      </div>
    </div>
  );
}

Basic.propTypes = {
  values: PropTypes.object.isRequired,
};

Basic.defaultProps = {
  onChange: () => null,
};

export default Basic;

// import ADACodes from 'data/adaCodeList.json';
import insurances from 'data/insuranceList.json';

// export function getADACodes() {
//   console.log(
//     'need keys:',
//     Object.keys(ADACodes).map((key) => key),
//   );
//   return Object.keys(ADACodes).map((key) => key);
//   // return Object.keys(ADACodes).map((key) => ({key, label: ADACodes[key]}));
// }

export function getInsuranceCode() {
  return insurances.map((insurance) => insurance.id);
}

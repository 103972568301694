import React, {Fragment, useEffect, useState} from 'react';
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import UseForm from '../UseForm';
import validate from '../ResetPassValidationRules';
import TextField from '@material-ui/core/TextField';
import {Spinner1} from '../Spinner1';
import {connect} from 'react-redux';
import {resetPassword} from '../../actions';
//import {useHistory} from 'react-router-dom';
import {useAlert} from 'react-alert';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  fullPageContainer: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: '"Roboto"',
  },
  containerlogin: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 14px 28px #7F7F7F, 0 10px 10px #7F7F7F',
    // position: 'absolute',
    width: '60%',
    height: '60%',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  formcontainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  signinform: {
    padding: '30px 10px auto auto',
    width: '80%',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: '#FFFFFF',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  titletext: {
    padding: '0 30px',
  },
  buttoncontainer: {
    padding: '0 20px',
  },
  button: {
    borderRadius: '20px',
    border: '2px solid #FFFFFF',
    backgroundColor: '#1B7FE1',
    color: '#FFFFFF',
    fontSize: '12px',
    fontWeight: 'bold',
    padding: '12px 45px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    outline: 'none',
    margin: '20px auto 20px auto',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: '#1B7FE1',
      border: '2px solid #1B7FE1',
    },
  },
  redalerts: {
    color: 'red',
    fontSize: '12px',
    margin: '0',
    textAlign: 'center',
  },
}));

function LoginEmailPass(props) {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#1B7FE1',
        contrastText: '#fff', //button text white instead of black
      },
      background: {
        default: '#fff',
      },
    },
    typography: {
      fontFamily: '"Roboto"',
    },
  });

  const styles = useStyles();

  const [loadingLocal, setLoadingLocal] = useState(false);
  // const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    setLoadingLocal(false);
  }, []);
  const alert = useAlert();

  const {values, errors, handleChange, handleSubmit} = UseForm(
    ResetPassword1,
    validate,
  );

  async function ResetPassword1() {
    if (loadingLocal === false) {
      setLoadingLocal(true);
      const result = await props.resetPassword(values.email);
      console.log('result', result);
      alert.show(result);
      setLoadingLocal(false);
    }
  }

  return (
    <Fragment>
      <div className={styles.fullPageContainer}>
        <Spinner1 open={loadingLocal} />
        <div className={styles.containerlogin}>
          <Fragment>
            <div className={styles.formcontainer}>
              <div className={styles.titletext}>
                <h2>Password Reset</h2>
                <p>A link to reset your password will be sent via email.</p>
              </div>
              <form
                onSubmit={handleSubmit}
                noValidate
                className={styles.signinform}>
                <MuiThemeProvider theme={theme}>
                  <TextField
                    variant="outlined"
                    required
                    name="email"
                    label="Email Address"
                    type="email"
                    id="email-address"
                    className={`input ${errors.email && 'is-danger'}`}
                    onChange={handleChange}
                    value={values.email || ''}
                    style={{width: '80%'}}
                    inputProps={{
                      style: {
                        height: '40px',
                        padding: '0 14px',
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        height: '30px',
                        marginTop: '-5px',
                        fontSize: '15px',
                        fontFamily: 'Roboto',
                      },
                    }}
                  />
                  {errors.email && (
                    <p className={styles.redalerts}>{errors.email}</p>
                  )}
                </MuiThemeProvider>
                {/* {errorMessage ? (
                  <Fragment>
                    <br />
                    <p className={styles.redalerts}>{errorMessage}</p>
                  </Fragment>
                ) : null} */}
              <div className={styles.buttoncontainer}>
                <button className={styles.button} type="submit">
                  Send Forgot Password Email
                </button>
              </div>
              </form>
            </div>
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProp = (state) => {
  return {
    clinicBasicInfo: state.apiCallsReducer.clinicBasicInfo,
    clinicUsersList: state.apiCallsReducer.clinicUsersList,
    userInfo: state.apiCallsReducer.userInfo,
    clinicPatientsList: state.apiCallsReducer.clinicPatientsList,
    clinicTemplatesList: state.apiCallsReducer.clinicTemplatesList,
    clinicUsageInfo: state.apiCallsReducer.clinicUsageInfo,
    clinicLocationsList: state.apiCallsReducer.clinicLocationsList,
  };
};

export default connect(mapStateToProp, {resetPassword})(LoginEmailPass);

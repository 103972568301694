import React from 'react';
import PropTypes from 'prop-types';
import MenuOptions from 'components/Profile/ExtraInfo/Options/OptionsButtons/OptionsButtons';
import BenefitsForm from './BenefitsForm/BenefitsForm';
import styles from './EmployerBenefits.module.scss';

function EmployerBenefits({match: {params}, ...props}) {
  const [activeOption, setActiveOption] = React.useState(0);
  const {patientData, selectedInsurance} = props?.location?.state || {};

  const buttonProps = {
    onClick: setActiveOption,
    activeOption,
  };

  React.useEffect(() => {
    if (!patientData) {
      props.history.push('/section/insurance');
    }
  }, [patientData, props.history]);

  return (
    <div className={styles.wrapper}>
      {/* <Navbar /> */}
      <div className="container">
        <div className={styles.formWrapper}>
          <div className="row">
            <div className="col-12">
              <h1>
                {params.insuranceId
                  ? `${params.insuranceId} - ${params.groupNumber}`
                  : 'Submit New Request'}
              </h1>
            </div>
          </div>
          <MenuOptions buttonProps={buttonProps} />
          <BenefitsForm
            {...params}
            activeOption={activeOption}
            setActiveOption={setActiveOption}
            patientData={patientData}
            selectedInsurance={selectedInsurance}
            goBack={props.history.goBack}
          />
        </div>
      </div>
    </div>
  );
}

EmployerBenefits.propTypes = {
  planId: PropTypes.string,
  patientId: PropTypes.string.isRequired,
  groupNumber: PropTypes.string.isRequired,
  insuranceId: PropTypes.string.isRequired,
};

export default EmployerBenefits;

import React from 'react';
import styles from './Communications.module.scss';
import Button from 'components/Common/Button/Button';
import CommunicationsTable from './CommunicationsTable';
import FollowUpModal from './FollowUpModal/FollowUpModal';
import FollowUpSwitch from 'components/Profile/ProfileTabs/Phase2/Communications/FollowUpModal/FollowUpSwitch';
import {getFollowUps as requestFollowUps} from './actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import usePrev from 'Utils/usePrev';

function Communications({
  treatmentId,
  patientId,
  clinicId,
  email,
  phone,
  firstName,
  lastName,
  treatmentList,
  currentTreatment,
  dob,
}) {
  const [state, setState] = React.useState({
    followUps: [],
    lastSend: {},
    fulFilled: false,
  });

  const {lastProcedureDate} = currentTreatment || {};

  const [enabledFollowUps, setEnabledFollowUps] = React.useState(false);

  const prevTreatmentId = usePrev(treatmentId);
  const prevLastProcedureDate = usePrev(lastProcedureDate);

  const getFollowUps = React.useCallback((_treatmentId, _clinicId) => {
    if (_treatmentId && _clinicId) {
      setState({followUps: [], lastSend: {}, fulFilled: false});
      requestFollowUps(
        {treatmentId: _treatmentId, clinicId: _clinicId},
        (followUpsResponse) => {
          const lastSend = getLastFollowUpSend(followUpsResponse);
          setState({followUps: followUpsResponse, lastSend, fulFilled: true});
        },
      );

      function getLastFollowUpSend(list) {
        return list.find((followUp) => followUp.status === 'sent');
      }
    }
  }, []);

  React.useEffect(() => {
    if (clinicId) {
      if (
        prevLastProcedureDate !== lastProcedureDate ||
        (treatmentId && prevTreatmentId !== treatmentId)
      ) {
        getFollowUps(treatmentId, clinicId);
      }
    }
  }, [
    clinicId,
    treatmentId,
    getFollowUps,
    prevTreatmentId,
    lastProcedureDate,
    prevLastProcedureDate,
  ]);

  return (
    <div className={styles.communications}>
      <div className={styles.top}>
        <div
          className={`${styles.half} ${styles.topCommunications} ${styles.first}`}>
          <h3>{state.followUps?.length || 0}</h3>
          <span>Total communications</span>
        </div>
        <div className={`${styles.half} ${styles.topCommunications}`}>
          <h3 className={state.lastSend?.date ? '' : styles.noFollowUpsLabel}>
            {state.lastSend?.date || 'No follow-ups sent'}
          </h3>
          <span>Last communication date</span>
        </div>
      </div>
      <div className={styles.complete}>
        <div className={styles.switch}>
          <span>Communications</span>
          <div>
            <FollowUpSwitch
              patientId={patientId}
              clinicId={clinicId}
              switchCallback={setEnabledFollowUps}
              label={
                <h5 className={styles.enableFollowLabel}>Enable Follow-Ups:</h5>
              }
            />
          </div>
        </div>
        <div className={styles.table}>
          {state.fulFilled ? (
            <CommunicationsTable rows={state.followUps} />
          ) : treatmentId ? (
            <CircularProgress />
          ) : (
            <b>No data</b>
          )}
        </div>
      </div>
      <div className={`${styles.submitWrapper} mt-3`}>
        <FollowUpModal
          hideTreatments
          submitCallback={getFollowUps}
          treatmentList={treatmentList}
          showFollowUpSwitch={false}>
          {({openModal}) => {
            return (
              <Button
                disabled={!enabledFollowUps || !treatmentId}
                onClick={() =>
                  openModal({
                    treatmentId,
                    firstName,
                    lastName,
                    patientId,
                    email,
                    phone,
                  })
                }>
                Send Treatment Plan
              </Button>
            );
          }}
        </FollowUpModal>
      </div>
      <div className={`${styles.submitWrapper} mt-3`}>
        <Button
          disabled={!treatmentId}
          secondaryButton={true}
          onClick={() => {
            const DOBArray = dob.split('-');
            const DOB1 = `${DOBArray[1]}${DOBArray[2]}${DOBArray[0]}`;
            const url = `https://my-treatment-plan.web.app/${clinicId}/${treatmentId}/false?dob=${DOB1}`;
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null;
          }}>
          Preview Treatment Plan
        </Button>
      </div>
    </div>
  );
}

Communications.propTypes = {
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  clinicId: PropTypes.string.isRequired,
  patientId: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  treatmentId: PropTypes.string.isRequired,
};

export default Communications;

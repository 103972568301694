import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Common/Modal/Modal';
import FollowUpForm from './FollowUpForm';
import useTreatments from 'components/Profile/ProfileTabs/Phase2/useTreatments';
import {useSelector} from 'react-redux';

function FollowUpModal({
  children,
  hideTreatments,
  submitCallback,
  treatmentList: propsTreatmentList,
  showFollowUpSwitch,
}) {
  const [modal, setModal] = React.useState({
    open: false,
    patientId: '',
    treatmentId: null,
    enabledFollowUps: false,
  });

  const clinicId = useSelector(
    ({apiCallsReducer}) => apiCallsReducer?.clinicBasicInfo?.clinicId,
  );

  const openModal = React.useCallback(
    ({patientId, treatmentId, phone, email, firstName, lastName}) => {
      setModal({
        open: true,
        patientId,
        treatmentId,
        phone,
        email,
        firstName,
        lastName,
      });
    },
    [],
  );

  const closeModal = React.useCallback(
    () => setModal({open: false, patientId: '', treatmentId: null}),
    [],
  );

  const {treatmentList} = useTreatments({
    clinicId,
    patientId: hideTreatments ? null : modal.patientId,
  });

  return (
    <>
      {children ? (
        children({
          closeModal,
          openModal,
        })
      ) : (
        <button onClick={openModal}>Open modal</button>
      )}

      <Modal
        title={`${modal.firstName || ''} ${modal.lastName || ''} - Follow-Up`}
        open={modal.open}
        onClose={closeModal}>
        <FollowUpForm
          treatmentList={hideTreatments ? propsTreatmentList : treatmentList}
          hideTreatments={hideTreatments}
          treatmentId={modal.treatmentId}
          patientId={modal.patientId}
          phone={modal.phone}
          email={modal.email}
          firstName={modal.firstName}
          lastName={modal.lastName}
          submitCallback={submitCallback}
          closeModal={closeModal}
          showFollowUpSwitch={showFollowUpSwitch}
        />
      </Modal>
    </>
  );
}

FollowUpModal.propTypes = {
  submitCallback: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
};

export default FollowUpModal;

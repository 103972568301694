import React from 'react';
import {useSelector} from 'react-redux';
import {getDocument} from 'actions/firebaseActions';

function useTemplates() {
  const [templates, setTemplates] = React.useState({list: [], collection: []});

  const clinicId = useSelector(
    ({apiCallsReducer}) => apiCallsReducer?.clinicBasicInfo?.clinicId,
  );

  React.useEffect(() => {
    if (clinicId) {
      getDocument(`/procedureTemplates/${clinicId}`, (response) => {
        if (response) {
          const collection = {};
          const _templates = Object.keys(response).map((key) => {
            collection[key] = {...response[key], id: key};
            return {id: key, name: response[key].treatmentPlanName};
          });
  
          setTemplates({list: _templates, collection});
        }
      });
    }
  }, [clinicId]);

  return templates;
}

export default useTemplates;

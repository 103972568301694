import React from 'react';
import UseForm from '../UseForm';
import validate from '../LoginValidationRules';
import TextField from '@material-ui/core/TextField';
import {useHistory} from 'react-router-dom';
import logo from '../123insurancelogo.png';
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  fullPageContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: '"Roboto"',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },
  containerlogin: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 14px 28px #7F7F7F, 0 10px 10px #7F7F7F',
    width: '50%',
    height: '60%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      width: '100%',
      height: 'auto',
    },
    [theme.breakpoints.only('md')]: {
      height: '600px',
      justifyContent: 'space-evenly',
    },
  },
  maincontainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      width: '90%',
      padding: '0 0 20px 0',
      height: 'auto',
    },
    [theme.breakpoints.only('sm')]: {
      height: '100%',
      padding: '0',
    },
    [theme.breakpoints.only('md')]: {
      height: '100%',
      padding: '0 0 40px 0',
    },
  },
  signincontainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    textAlign: 'center',
  },
  signinform: {
    display: 'flex',
    borderRadius: '10px',
    background: '#1B7FE1',
    color: '#FFFFFF',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      borderRadius: '10px 10px 0 0',
    },
  },
  welcometitle: {
    margin: 0,
    [theme.breakpoints.down('md')]: {
      padding: '30px 0 0 0',
    },
  },
  connectedtext: {
    padding: '15px 25px',
    margin: 0,
  },
  signupcontainer: {
    display: 'flex',
    flex: 0.6,
    alignItems: 'center',
    justifyContent: 'center',
  },
  signupform: {
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '30px 0 0 0',
  },
  button: {
    borderRadius: '20px',
    border: '2px solid #FFFFFF',
    backgroundColor: '#1B7FE1',
    color: '#FFFFFF',
    fontSize: '12px',
    fontWeight: 'bold',
    padding: '12px 45px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    cursor: 'pointer',
    outline: 'none',
    margin: '20px auto 20px auto',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: '#1B7FE1',
      border: '2px solid #1B7FE1',
    },
  },
  redalerts: {
    margin: 0,
    color: 'red',
    fontSize: '12px',
  },
  createaccount: {
    padding: '10px 0 0 0 ',
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  registration: {
    padding: '0 0 10px 0',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      margin: '5px',
    },
  },
  logo: {
    width: '320px',
    [theme.breakpoints.down('sm')]: {
      width: '200px',
      padding: '10px 0 5px 0',
    },
  },
  logocontainer: {
    display: 'flex',
    height: '20%',
    padding: '30px 0',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      padding: '0 0 10px 0',
    },
  },
}));

//**************************************************Login Component**************************************************************
export default function Login() {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#1B7FE1',
        contrastText: '#fff', //button text white instead of black
      },
      background: {
        default: '#fff',
      },
    },
    typography: {
      fontFamily: '"Roboto"',
    },
  });
  const styles = useStyles();
  const history = useHistory();
  const {values, errors, handleChange, handleSubmit} = UseForm(login, validate);

  function login() {
    // console.log('No errors, submit callback called!', values);
    history.push('/SignUp', {values});
  }

  //*****************Return Component********************

  return (
    <div className={styles.fullPageContainer}>
      <div className={styles.maincontainer}>
        <div className={styles.logocontainer}>
          <img src={logo} alt="123 Insurance Logo" className={styles.logo} />
        </div>
        <div className={styles.containerlogin}>
          
          <div className={styles.signupcontainer}>
            <form
              onSubmit={handleSubmit}
              noValidate
              className={styles.signupform}>
              <h2 className={styles.createaccount}>Create Account</h2>
              <span className={styles.registration}>
                se your email for registration
              </span>
              <MuiThemeProvider theme={theme}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="email"
                  label="Email Address"
                  type="email"
                  id="email-address"
                  className={`input ${errors.email && 'is-danger'}`}
                  onChange={handleChange}
                  value={values.email || ''}
                  style={{width: '80%', margin: '10px 0 0 0'}}
                  inputProps={{
                    style: {
                      height: '40px',
                      padding: '0 14px',
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      height: '30px',
                      marginTop: '-5px',
                      fontSize: '15px',
                      fontFamily: 'Roboto',
                    },
                  }}
                />
                {errors.email && (
                  <p className={styles.redalerts}>{errors.email}</p>
                )}

                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  className={`input ${errors.password && 'is-danger'}`}
                  onChange={handleChange}
                  value={values.password || ''}
                  style={{width: '80%', margin: '10px 0 0 0'}}
                  inputProps={{
                    style: {
                      height: '40px',
                      padding: '0 14px',
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      height: '30px',
                      marginTop: '-5px',
                      fontSize: '15px',
                      fontFamily: 'Roboto',
                    },
                  }}
                />
                {errors.password && (
                  <p className={styles.redalerts}>{errors.password}</p>
                )}

                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="confirmation"
                  label="Confirm Password"
                  type="password"
                  id="confirm-password"
                  className={`input ${errors.confirmation && 'is-danger'}`}
                  onChange={handleChange}
                  value={values.confirmation || ''}
                  style={{width: '80%', margin: '10px 0 0 0'}}
                  inputProps={{
                    style: {
                      height: '40px',
                      padding: '0 14px',
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      height: '30px',
                      marginTop: '-5px',
                      fontSize: '15px',
                      fontFamily: 'Roboto',
                    },
                  }}
                />
                {errors.confirmation && (
                  <p className={styles.redalerts}>{errors.confirmation}</p>
                )}
                <button className={styles.button}>Sign Up</button>
              </MuiThemeProvider>
            </form>
          </div>
          <div className={styles.signincontainer}>
            <div className={styles.signinform}>
              <form action="#">
                <h2 className={styles.welcometitle}>Welcome Back</h2>
                <br />
                <p className={styles.connectedtext}>
                  To keep connected with us please login with your personal info
                </p>
                <br />
                <button
                  onClick={() => history.push('/LoginEmailPass', {values})}
                  className={styles.button}
                  style={{marginTop: 0}}>
                  Sign In
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {TextField} from '@material-ui/core';
import Fuse from 'fuse.js';
import styles from './Autocomplete.module.scss';
import Tooltip from 'components/Tooltip/Tooltip';

function WestonAutocomplete(props) {
  // const [search, setSearch] = React.useState('');
  const defaultValue = React.useRef(props.value);
  // const inputProps = React.useRef({'data-submodule': props.submodule});

  const searchFn = React.useCallback((list, {inputValue, ...state}) => {
    if (!inputValue) {
      return list;
    }

    const options = {
      isCaseSensitive: false,
      includeScore: true,
      // shouldSort: true,
      includeMatches: true,
      findAllMatches: false,
      // minMatchCharLength: 1,
      // location: 0,
      threshold: 0.0,
      // distance: 100,
      // useExtendedSearch: false,
      ignoreLocation: true,
      // ignoreFieldNorm: false,
    };

    const fuse = new Fuse(list, options);
    const a = fuse.search(inputValue);
    return a.map((item) => item.item);
  }, []);

  const AutocompleteComponent = (
    <Autocomplete
      freeSolo
      filterOptions={searchFn}
      data-html2canvas-ignore="true"
      fullWidth={true}
      options={props.options}
      // inputValue={search}
      // value={search || null}
      defaultValue={defaultValue.current}
      className={`${styles.autoComplete} ${
        props.disabled ? styles.disabled : ''
      } ${props.value ? '' : styles.empty}`}
      onChange={(_, value) => {
        props.onChange(props.name, value, {
          dataset: {submodule: props.submodule},
        });
      }}
      onInputChange={(_, newInputValue) => {
        props.onChange(props.name, newInputValue, {
          dataset: {submodule: props.submodule},
        });
      }}
      disableClearable
      disabled={props.disabled}
      //   getOptionLabel={(option) => option.value}
      renderInput={(params) => (
        <TextField
          {...params}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              console.log(ev);
            }
          }}
          // onChange={({target}) => {
          //   props.onChange(props.name, target.value, {
          //     dataset: {submodule: props.submodule},
          //   });
          //   setSearch(target.value);
          // }}
          label={props.label}
          variant="outlined"
          // inputProps={inputProps.current}
        />
      )}
    />
  );

  return props.value &&
    props.value.toString &&
    props.value.toString().length > 20 &&
    props.disabled ? (
    <Tooltip title={props.value}>{AutocompleteComponent}</Tooltip>
  ) : (
    AutocompleteComponent
  );
}

WestonAutocomplete.propTypes = {};

export default WestonAutocomplete;

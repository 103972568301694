import React from 'react';
import MButton from '@material-ui/core/Button';
import styles from './Button.module.scss';

function Button({children, fullWidth, secondaryButton, ...props}) {
  return (
    <MButton
      {...props}
      className={`${styles.primaryButton} ${
        props.disabled ? styles.disabled : ''
      } ${fullWidth ? styles.fullWidth : ''} ${
        secondaryButton ? styles.secondaryButton : ''
      }`}>
      {children}
    </MButton>
  );
}

Button.defaultProps = {
  fullWidth: false,
};

export default Button;

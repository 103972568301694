import React from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Form/Input/Input';
import Title from '../Options/Title';
import Autocomplete from 'components/Form/Autocomplete/Autocomplete';
import Options from 'data/options';
import Select from 'components/Form/Select/Select';
import States from 'data/states.json';

function Employer({
  values = {employer: {}},
  onChange,
  children,
  disableInputs,
  employerName,
  planName,
  state,
  updateValue,
  errors = {},
}) {
  const handleChange = React.useCallback(
    (name, value) => {
      onChange('employerInformation', name, value);
    },
    [onChange],
  );

  // const handleValue = React.useCallback

  return (
    <div className="row">
      <div className="col-md-12 my-1">
        <div className="d-flex justify-content-between">
          <Title>Employer</Title>
          {children}
        </div>
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Input
          name="employerName"
          value={employerName}
          onChange={updateValue}
          label="Employer Name *"
          disabled={disableInputs}
          error={errors.employerName}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Input
          name="planName"
          value={planName}
          onChange={updateValue}
          label="Plan Name *"
          disabled={disableInputs}
          error={errors.planName}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Input
          name="phoneNumber"
          value={values.phoneNumber}
          onChange={handleChange}
          label="Phone Number"
          disabled={disableInputs}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Select
          name="state"
          value={state}
          onChange={updateValue}
          label="State"
          disabled={disableInputs}
          options={States}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Input
          name="groupName"
          value={values.groupName}
          onChange={handleChange}
          label="Group Name"
          disabled={disableInputs}
        />
      </div>
      <div className="col-md-12 my-1">
        <Input
          name="employerNote"
          value={values.employerNote}
          onChange={handleChange}
          label="Employer Note"
          disabled={disableInputs}
        />
      </div>
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-3 col-xs-12 my-1">
            <Autocomplete
              name="PPOOption"
              value={values.PPOOption}
              onChange={handleChange}
              label="PPO Option"
              disabled={disableInputs}
              options={Options.employerInformation.PPOOption}
            />
          </div>
          <div className="col-md-3 col-xs-12 my-1">
            <Autocomplete
              name="DMOOption"
              value={values.DMOOption}
              onChange={handleChange}
              label="DMO Option"
              disabled={disableInputs}
              options={Options.employerInformation.DMOOption}
            />
          </div>
          <div className="col-md-3 col-xs-12 my-1">
            <Autocomplete
              name="FSAHSA"
              value={values.FSAHSA}
              onChange={handleChange}
              label="FSA/HSA"
              disabled={disableInputs}
              options={Options.employerInformation.FSAHSA}
            />
          </div>
        </div>
      </div>
      <div className="col-md-12 my-1">
        <Autocomplete
          name="medical"
          value={values.medical}
          onChange={handleChange}
          label="Medical"
          disabled={disableInputs}
          options={Options.employerInformation.medical}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Input
          name="dentalCarrier"
          value={values.dentalCarrier}
          onChange={handleChange}
          label="Dental Carrier"
          disabled={disableInputs}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Input
          name="planType"
          value={values.planType}
          onChange={handleChange}
          label="Plan Type"
          disabled={disableInputs}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Input
          name="carrierPhone"
          value={values.carrierPhone}
          onChange={handleChange}
          label="Carrier Phone"
          disabled={disableInputs}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="faxClaims"
          value={values.faxClaims}
          onChange={handleChange}
          label="Fax Claims?"
          disabled={disableInputs}
          options={Options.employerInformation.faxClaims}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Input
          name="claimFaxNumber"
          value={values.claimFaxNumber}
          onChange={handleChange}
          label="Claim Fax Number"
          disabled={disableInputs}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Input
          name="claimFaxNumberNotes"
          value={values.claimFaxNumberNotes}
          onChange={handleChange}
          label="Claim Fax Notes"
          disabled={disableInputs}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Input
          name="carrierGroupNumberNotes"
          value={values.carrierGroupNumberNotes}
          onChange={handleChange}
          label="Carrier Group Number Notes"
          disabled={disableInputs}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Input
          name="ECEC"
          value={values.ECEC}
          onChange={handleChange}
          label="EC&EC"
          disabled={disableInputs}
        />
      </div>
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-3 col-xs-12 my-1">
            <Autocomplete
              name="EClaims"
              value={values.EClaims}
              onChange={handleChange}
              label="E-Claims"
              disabled={disableInputs}
              options={Options.employerInformation.EClaims}
            />
          </div>
          <div className="col-md-3 col-xs-12 my-1">
            <Input
              name="requirements"
              value={values.requirements}
              onChange={handleChange}
              label="Requirements"
              disabled={disableInputs}
            />
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-3 col-xs-12 my-1">
            <Autocomplete
              name="eligibilityRequest"
              value={values.eligibilityRequest}
              onChange={handleChange}
              label="Eligibility Request"
              disabled={disableInputs}
              options={Options.employerInformation.eligibilityRequest}
            />
          </div>
          <div className="col-md-3 col-xs-12 my-1">
            <Autocomplete
              name="EAttachment"
              value={values.EAttachment}
              onChange={handleChange}
              label="E-Attachment"
              disabled={disableInputs}
              options={Options.employerInformation.EAttachment}
            />
          </div>
          <div className="col-md-3 col-xs-12 my-1">
            <Input
              name="mailClaimsTo"
              value={values.mailClaimsTo}
              onChange={handleChange}
              label="Mail Claims To"
              disabled={disableInputs}
            />
          </div>
        </div>
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Input
          name="previousCarrier"
          value={values.previousCarrier}
          onChange={handleChange}
          label="Previous Carrier"
          disabled={disableInputs}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Input
          name="previousCarrierLocation"
          value={values.previousCarrierLocation}
          onChange={handleChange}
          label="Previous Carrier Location"
          disabled={disableInputs}
        />
      </div>
    </div>
  );
}

Employer.propTypes = {
  values: PropTypes.object.isRequired,
};

Employer.defaultProps = {
  errors: {},
  onChange: () => null,
};

export default Employer;

import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Common/Modal/Modal';
import Input from 'components/Form/Input/Input';
import Button from 'components/Common/Button/Button';
import Switch from 'components/Form/Switch/Switch';

function TreatmentModal(props) {
  const [model, setModel] = React.useState(props.model);
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    setModel(props.model);
  }, [props.model]);

  const updateValue = React.useCallback((name, value) => {
    setModel((currentModel) => ({...currentModel, [name]: value}));
  }, []);

  return (
    <>
      <Modal
        title="Follow-Up Settings"
        open={props.open}
        onClose={() => {
          props.onClose();
          setErrors({});
        }}>
        <div>
          <div className="row">
            <div className="col-6">
              <Input
                name="daysToRepeat"
                label="Days Between Follow-ups"
                value={model.daysToRepeat}
                onChange={updateValue}
                error={errors.daysToRepeat}
              />
            </div>
            <div className="col-6">
              <Input
                name="limit"
                label="Number of Follow-ups"
                value={model.limit}
                onChange={updateValue}
                error={errors.limit}
              />
            </div>
            <div className="col-12">
              <span>Enable Automated Follow Up: </span>
              <Switch
                name="enabledFollowUps"
                label="Automated Follow Ups Status"
                value={model.enabledFollowUps}
                onChange={updateValue}
              />
            </div>
          </div>
        </div>
        <div className="my-3">
          <Button
            onClick={() => {
              if (typeof props.submit === 'function') {
                props.submit(model);
              }
              if (typeof props.onClose === 'function') {
                props.onClose();
              }
            }}
            fullWidth>
            Save settings
          </Button>
        </div>
      </Modal>
    </>
  );
}

TreatmentModal.propTypes = {
  open: PropTypes.bool,
  treatmentId: PropTypes.string,
  url: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  editing: PropTypes.bool.isRequired,
  treatment: PropTypes.object,
};

export default TreatmentModal;

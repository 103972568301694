import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

function WDCheckbox(props) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={props.value}
          onChange={({target}) => {
            props.onChange(props.name, target.checked);
          }}
          name={props.name}
          disabled={props.disabled}
        />
      }
      label={props.label}
    />
  );
}

WDCheckbox.propTypes = {
  id: PropTypes.string,
  value: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default WDCheckbox;

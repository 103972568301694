import React from 'react';
import styles from './Patient.module.scss';
import Select from 'components/Form/Select/Select';
import Button from 'components/Common/Button/Button';
import TreatmentModal from './TreatmentModal';
import TransitionsModal from 'components/Modal/Modal';
import {
  getPatient,
  updatePatient,
  setCurrentPatient,
} from 'actions/patientActions';
import {useDispatch, useSelector} from 'react-redux';
import useTemplates from './useTemplates';
import axios from 'axios';
import {handleTreatmentSubmit, updateDocument} from 'actions/firebaseActions';

function Patient(props) {
  const [state, setState] = React.useState({open: false, editing: false});
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const clinicId = useSelector(
    ({apiCallsReducer}) => apiCallsReducer?.clinicBasicInfo?.clinicId,
  );

  const patientPersonalInfo = props.currentPatient?.personalInfo || {};

  const patientId = props.currentPatient?.patientId || null;

  const {
    url,
    updateTreatment,
    treatmentId,
    setCurrentTreatment,
    treatment,
  } = props;

  const submit = React.useCallback(
    async (model) => {
      setLoading(true);
      const lastProcedureDate = state.editing
        ? treatment?.lastProcedureDate || null
        : null;

      const {data} = await axios.post(
        'https://us-central1-insurance-ca69d.cloudfunctions.net/treatment',
        {
          model,
          treatmentId: state.editing ? treatmentId : null,
          patientId,
          clinicId,
          currentLastProcedureDate: lastProcedureDate,
        },
      );

      const patient = await getPatient(clinicId, patientId);
      patient.followUpDates = patient.followUpDates || {};
      patient.procedureDates = patient.procedureDates || {};

      dispatch(updatePatient(patient));

      updateTreatment(data.newTreatmentId, {
        ...model,
        clinicId,
        createdAt: data.createdAt,
        lastProcedureDate: data.lastProcedureDate,
      });
      handleTreatmentSubmit(clinicId, patientId);

      if (!state.editing) {
        setCurrentTreatment(null, data.newTreatmentId);
      }

      if (data.followUpDates) {
        updateDocument(url, data.newTreatmentId);
      }
      setLoading(false);
      setState({open: false, editing: false});
    },
    [
      url,
      dispatch,
      clinicId,
      treatment,
      patientId,
      treatmentId,
      state.editing,
      updateTreatment,
      setCurrentTreatment,
    ],
  );

  const templates = useTemplates();

  return (
    <div className={`${styles.patientInfo}`}>
      <TreatmentModal
        open={state.open}
        editing={state.editing}
        treatment={treatment}
        treatmentId={props.treatmentId}
        url={url}
        submit={submit}
        templates={templates}
        showTemplates
        loading={loading}
        deleteTreatment={props.deleteTreatment}
        fetchTreatments={props.fetchTreatments}
        onClose={() => setState({open: false, editing: false})}
        setCurrentTreatment={setCurrentTreatment}
        setLoading={setLoading}
        patientId={patientId}
      />
      <h3 className="mt-0">
        {patientPersonalInfo.firstName} {patientPersonalInfo.lastName}
      </h3>
      <div className={`${styles.information}`}>
        <div className={styles.personalInfo}>
          <div>
            <span>D.O.B</span>: <span>{patientPersonalInfo.DOB}</span>
          </div>
          <div>
            <span>Email</span>: <span>{patientPersonalInfo.email}</span>
          </div>
          <div>
            <span>Phone</span>: <span>{patientPersonalInfo.phone}</span>
          </div>
          <div>
            <span>Location</span>: <span>{patientPersonalInfo.location}</span>
          </div>
        </div>
        <div>
          <h4>Treatment Plan</h4>
          {props.treatmentList?.length ? (
            <Select
              label="Treatment"
              onChange={setCurrentTreatment}
              name="treatment"
              value={props.treatmentId}
              options={props.treatmentList}
            />
          ) : (
            <div>
              <span>The patient doesn't have any treatments. Click "New Plan" below to add.</span>
            </div>
          )}
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-12 flex xcenter mb-2">
            <TransitionsModal
              patientData={props.currentPatient}
              passPatientDataUp={(data1) => dispatch(setCurrentPatient(data1))}
              editPatient={true}>
              <Button fullWidth>Edit Patient Information</Button>
            </TransitionsModal>
          </div>
          <div className="col-6">
            <Button
              fullWidth
              onClick={() => setState({open: true, editing: false})}>
              New Plan
            </Button>
          </div>
          <div className="col-6">
            <Button
              fullWidth
              disabled={!treatment}
              onClick={() => setState({open: true, editing: true})}>
              Edit Plan
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Patient;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './DeleteTreatment.module.scss';
import axios from 'axios';
import Confirm from 'components/ConfirmDialog/ConfirmDialog';
import {useAlert} from 'react-alert';
import {useSelector, useDispatch} from 'react-redux';
import {getPatient, updatePatient} from 'actions/patientActions';

function DeleteTreatment({
  setLoading,
  patientId,
  closeModal,
  treatmentId,
  deleteTreatment,
  setCurrentTreatment,
}) {
  const clinicId = useSelector(
    ({apiCallsReducer}) => apiCallsReducer?.clinicBasicInfo?.clinicId,
  );

  const dispatch = useDispatch();

  const alert = useAlert();

  const [deleteDialog, setDeleteDialog] = React.useState(false);

  const confirmDelete = async () => {
    const body = {
      clinicId,
      treatmentId,
    };

    setLoading(true);
    setDeleteDialog(false);

    await axios.post(
      'https://us-central1-insurance-ca69d.cloudfunctions.net/deleteTreatment',
      body,
    );

    const patient = await getPatient(clinicId, patientId);

    dispatch(
      updatePatient({...patient, followUpDates: {}, procedureDates: {}}),
    );

    deleteTreatment(treatmentId);
    closeModal();
    setCurrentTreatment(null);
    setLoading(false);
    alert.success('The treatment has been deleted');
  };
  return (
    <div>
      <div className={`${styles.deleteTreatment} my-3`}>
        <span
          onClick={() => {
            setDeleteDialog(true);
          }}>
          Delete treatment
        </span>
      </div>
      <Confirm
        open={deleteDialog}
        handleClose={() => {
          setDeleteDialog(false);
        }}
        handleConfirm={() => {
          confirmDelete();
        }}
        okText="Yes"
        cancelText="Cancel"
        title="Delete treatment?"
      />
    </div>
  );
}

DeleteTreatment.propTypes = {
  setLoading: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  treatmentId: PropTypes.string.isRequired,
  deleteTreatment: PropTypes.func.isRequired,
  setCurrentTreatment: PropTypes.func.isRequired,
};

export default DeleteTreatment;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './Options.module.scss';

function SubTitle({children}) {
  return <h2 className={styles.subTitle}>{children}</h2>;
}

SubTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(SubTitle);

import formatDate from './formatDate';
const width = document.getElementById('root').clientWidth;

export default [
  {
    field: 'insuranceName',
    headerName: 'Insurance',
    width: width < 1200 ? 250 : width / 5,
  },
  {
    field: 'groupNumber',
    headerName: 'Group Number',
    width: width < 1200 ? 250 : width / 5,
  },
  {
    field: 'planName',
    headerName: 'Plan Name',
    width: width < 1200 ? 250 : width / 5,
  },
  {
    field: 'employerName',
    headerName: 'Employer Name',
    width: width < 1200 ? 250 : width / 5,
  },
  {
    field: 'state',
    headerName: 'State',
    width: width < 1200 ? 250 : width / 5,
  },
  {
    field: 'createdAt',
    headerName: 'Date Added',
    width: width < 1200 ? 100 : width / 10,
    renderCell: (params) => formatDate(params.value),
  },
  {
    field: 'modifiedAt',
    headerName: 'Date Modified',
    width: width < 1200 ? 100 : width / 10,
    renderCell: (params) => formatDate(params.value),
  },
];

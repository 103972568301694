import React from 'react';
import PropTypes from 'prop-types';
import MSwitch from '@material-ui/core/Switch';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';

const theme = createMuiTheme({
  overrides: {
    MuiSwitch: {
      switchBase: {
        // Controls default (unchecked) color for the thumb
        color: 'red',
      },
      colorSecondary: {
        '&$checked': {
          // Controls checked color for the thumb
          color: 'green',
        },
      },
      track: {
        // Controls default (unchecked) color for the track
        backgroundColor: 'red',
        '$checked$checked + &': {
          // Controls checked color for the track
          opacity: 0.7,
          backgroundColor: 'green',
        },
      },
    },
  },
});
function Switch(props) {
  return (
    <ThemeProvider theme={theme}>
      <MSwitch
        name={props.name}
        value={props.value}
        checked={!!props.value}
        onChange={({target}) => props.onChange(props.name, target.checked)}
      />
    </ThemeProvider>
  );
}

Switch.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool.isRequired,
};

export default Switch;

const OPTIONS_ENUM = {
  employer: 0,
  general: 1,
  preventative: 2,
  basic: 3,
  major: 4,
  ortho: 5,
  all: 6,
  properties: {
    0: {name: 'Employer', property: 'employer'},
    1: {name: 'General', property: 'general'},
    2: {name: 'Preventative', property: 'Preventative'},
    3: {name: 'Basic', property: 'basic'},
    4: {name: 'Major', property: 'major'},
    5: {name: 'Ortho', property: 'ortho'},
    6: {name: 'All', property: 'all'},
  },
};

export default OPTIONS_ENUM;

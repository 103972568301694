import {combineReducers} from 'redux';
import apiCallsReducer from './apiCallsReducer';
import patientReducer from './patientReducer';
import sectionReducer from './sectionReducer';

export default combineReducers({
  apiCallsReducer,
  patientReducer,
  sectionReducer,
});

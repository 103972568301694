import React, {useEffect, useState} from 'react';
import {
  makeStyles,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import {DataGrid, GridOverlay} from '@material-ui/data-grid';
import {IconButton} from '@material-ui/core';
import {connect} from 'react-redux';
import {useAlert} from 'react-alert';
import {
  changeClinicLocationSelection,
  changeGlobalSearchObj,
  archivePatient,
  unArchivePatient,
} from '../../actions';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import UnarchiveOutlinedIcon from '@material-ui/icons/UnarchiveOutlined';
import {Spinner1} from '../Spinner1';
import {setCurrentPatient} from 'actions/patientActions';
import {useHistory} from 'react-router-dom';
//*************************************************************************************Component Styling Sheet***************************************************
const useStyles = makeStyles({
  tableContainer: {
    width: '100%',
    height: '100%',
  },
  root: {
    '& .MuiDataGrid-sortIcon': {
      color: 'black',
    },
    '& .MuiDataGrid-colCellWrapper': {
      backgroundColor: '#e8e8e8',
    },
    '& .MuiDataGrid-colCell': {
      width: '200px',
    },
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
      color: '#4D4B4B',
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
      outlineWidth: '1px',
      backgroundColor: '#C1C1C1',
      //color: '#FFFFFF',
    },
    cursor: 'pointer',
  },
});

const theme = createMuiTheme({
  typography: {
    fontFamily: '"Roboto"',
  },
});

//*************************************************************************************Generate Table Data**************************************************

//*******************************************************************************Table Data Component**************************************************************

const TableData = (props) => {
  const alert = useAlert();
  const history = useHistory();
  const [loadingLocal, setLoadingLocal] = useState(false);

  const rowsInput = props.searchedClinicPatientsList
    ? props.searchedClinicPatientsList
    : props.locationFilteredClinicPatientsList;

  const locationAccess = props.userInfo?.locationAccess
    ? Object.keys(props.userInfo.locationAccess)[0] || null
    : null;
  const {masterAdmin, admin} = props.userInfo;

  const rows = React.useMemo(() => {
    // Define function that converts UNIX to date
    // console.log(rowsInput);
    const changeDOBFormatting = (DOB) => {
      let DOB2 = ''
      if (DOB) {
        const DOBArray = DOB.split('-');
        // DOBArray = [yyyy, mm, dd]
        const DOB1 = `${DOBArray[1]}/${DOBArray[2]}/${DOBArray[0]}`;
        // eslint-disable-next-line radix
        DOB2 = parseInt(new Date(DOB1).getTime().toFixed(0));
      }
      return DOB2;
    };
    const shortenName = (name) => {
      let shortenedName = '';
      if (name) {
        const nameArray = name.split(' ');
        // DOBArray = [yyyy, mm, dd]
        const first = nameArray[0].substring(0, 1);
        shortenedName = `${first} ${nameArray[1]}`;
      }
      return shortenedName;
    };

    const removeUnderscore = (insurance) => {
      const noUnderscore = insurance.replace(/_/g, ' ');
      return noUnderscore;
    };
    // Map data to object
    return rowsInput
      .map((ele, index) => {
        if (
          (props.activeOrArchivedPatientList === 'Active' && !ele.archived) ||
          (props.activeOrArchivedPatientList === 'Archived' && ele.archived)
        ) {
          const location = ele.location || ele.personalInfo?.location || null;
          if (
            masterAdmin ||
            admin ||
            locationAccess === location ||
            locationAccess === 'All'
          ) {
            return {
              id: ele.patientId ? ele.patientId : index,
              createdDate: ele.createdByDate,
              lastName: ele.personalInfo?.lastName,
              firstName: ele.personalInfo?.firstName,
              DOB: changeDOBFormatting(ele.personalInfo?.DOB),
              clinic: ele.personalInfo?.location,
              status: ele.status,
              lastCheckedInsuranceDate: ele.lastCheckedInsuranceDate,
              lastCheckedInsuranceBy: ele.lastCheckedInsuranceBy
                ? shortenName(ele.lastCheckedInsuranceBy)
                : '--',
              createdByFullName: shortenName(ele.createdByFullName),
              primaryInsurance: ele.primaryInsurance
                ? removeUnderscore(ele.primaryInsurance)
                : 'None',
              nextAppointmentDate: ele.personalInfo?.nextAppointmentDate
                ? changeDOBFormatting(ele.personalInfo?.nextAppointmentDate)
                : '',
              ele,
            };
          }
        }
        return null;
      })
      .filter(Boolean);
  }, [
    rowsInput,
    props.activeOrArchivedPatientList,
    admin,
    locationAccess,
    masterAdmin,
  ]);

  useEffect(() => {
    setRootWidth(document.getElementById('root').clientWidth);
    setRootHeight(document.getElementById('root').clientHeight);
    props.changeGlobalSearchObj('', props.locationFilteredClinicPatientsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [rootWidth, setRootWidth] = useState(0);
  const [rootHeight, setRootHeight] = useState(0);

  const styles = useStyles();

  //*****************Generate Header and Body********************

  const usageNoRowsOverlay = () => {
    return (
      <GridOverlay>
        <div>
          Click the add patient icon in the navbar to add your first patient!
        </div>
      </GridOverlay>
    );
  };

  const renderDateInsideCell = (UNIX) => {
    if (UNIX && UNIX !== '') {
      const date = new Date(UNIX);
      let month = date.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      const year = date.getFullYear();
      let day = date.getDate();
      if (month === 11 || month === 12 || month === 10) {
      } else {
        month = `0${month}`;
      }
      if (
        day === 0 ||
        day === 1 ||
        day === 2 ||
        day === 3 ||
        day === 4 ||
        day === 5 ||
        day === 6 ||
        day === 7 ||
        day === 8 ||
        day === 9
      ) {
        day = `0${day}`;
      }
      const combinedDate = `${month}/${day}/${year}`;
      return <div>{combinedDate}</div>;
    }
    return <div>--</div>;
  };

  const renderStatus = (status) => {
    let color = '#1eae98';
    if (status === 'Verified') {
      color = '#1eae98';
    } else if (status === 'Failed') {
      color = '#f55c47';
    } else if (status === 'Unchecked') {
      color = '#ffcc29';
    }
    return (
      <div>
        <span
          style={{
            backgroundColor: color,
            borderRadius: 10,
            padding: '3px 9px',
            color: 'white',
          }}>
          {status}
        </span>
      </div>
    );
  };

  const archivePatientClick = async (data, activeOrUnarchive) => {
    const patientId = data.id;
    setLoadingLocal(true);
    if (activeOrUnarchive === 'Active') {
      await props.archivePatient(patientId, props.clinicBasicInfo.clinicId);
    } else {
      await props.unArchivePatient(patientId, props.clinicBasicInfo.clinicId);
    }
    props.changeGlobalSearchObj('', props.locationFilteredClinicPatientsList);
    setLoadingLocal(false);
  };

  const renderTrashOrUnArchive = (params) => {
    if (props.activeOrArchivedPatientList === 'Active') {
      return (
        <IconButton
          onClick={(e) => {
            e.ignore = true;
            e.preventDefault();
            e.stopPropagation();
            alert.show(
              'Please confirm that you wish to move this patient to the archived list.',
              {
                title: 'Archive Patient',
                actions: [
                  {
                    copy: 'Confirm',
                    onClick: () => archivePatientClick(params.row, 'Active'),
                  },
                ],
                closeCopy: 'Cancel',
              },
            );
          }}>
          <DeleteOutlineOutlinedIcon color="inherit" />
        </IconButton>
      );
    } else {
      return (
        <IconButton
          onClick={(e) => {
            e.ignore = true;
            e.preventDefault();
            e.stopPropagation();
            alert.show(
              'Please confirm that you wish to move this patient to the active patients list',
              {
                title: 'Re-activate Patient',
                actions: [
                  {
                    copy: 'Confirm',
                    onClick: () => archivePatientClick(params.row, 'unArchive'),
                  },
                ],
                closeCopy: 'Cancel',
              },
            );
          }}>
          <UnarchiveOutlinedIcon color="inherit" />
        </IconButton>
      );
    }
  };

  const columns = [
    {
      field: 'createdDate',
      headerName: 'Created',
      width: rootWidth < 1200 ? 100 : rootWidth / 12,
      renderCell: (params) => renderDateInsideCell(params.value),
    },
    {
      field: 'lastName',
      headerName: 'Last',
      width: rootWidth < 1200 ? 100 : rootWidth / 12,
    },
    {
      field: 'firstName',
      headerName: 'First',
      width: rootWidth < 1200 ? 100 : rootWidth / 12,
    },
    {
      field: 'DOB',
      headerName: 'DOB',
      width: rootWidth < 1200 ? 100 : rootWidth / 12,
      renderCell: (params) => renderDateInsideCell(params.value),
    },
    {
      field: 'clinic',
      headerName: 'Location',
      width: rootWidth < 1200 ? 100 : rootWidth / 12,
    },
    {
      field: 'primaryInsurance',
      headerName: 'Insurance',
      width: rootWidth < 1200 ? 100 : rootWidth / 12,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: rootWidth < 1200 ? 100 : rootWidth / 12,
      renderCell: (params) => renderStatus(params.value),
    },
    {
      field: 'lastCheckedInsuranceDate',
      headerName: 'Verified',
      width: rootWidth < 1200 ? 100 : rootWidth / 12,
      renderCell: (params) => renderDateInsideCell(params.value),
    },
    {
      field: 'nextAppointmentDate',
      headerName: 'Next Appt',
      width: rootWidth < 1200 ? 100 : rootWidth / 12,
      renderCell: (params) => renderDateInsideCell(params.value),
    },
    {
      field: 'lastCheckedInsuranceBy',
      headerName: 'Verif. by',
      width: rootWidth < 1200 ? 100 : rootWidth / 12,
    },
    {
      field: 'createdByFullName',
      headerName: 'Created By',
      width: rootWidth < 1200 ? 100 : rootWidth / 12,
    },
    {
      field: 'archive',
      headerName: ' ',
      width: 90,
      disableClickEventBubbling: true,
      renderCell: (params) => renderTrashOrUnArchive(params),
    },
  ];

  //*****************Return Component********************
  return (
    <MuiThemeProvider theme={theme}>
      <Spinner1 open={loadingLocal} />
      {/* <ProfileTabs> */}
      <div className={styles.tableContainer} style={{height: rootHeight - 60}}>
        <DataGrid
          autoHeight={true}
          columns={columns}
          rows={rows}
          className={styles.root}
          onRowClick={(h) => {
            console.log('h', h);
            console.log(h.row.ele);
            props.changeGlobalSearchObj(
              '',
              props.locationFilteredClinicPatientsList,
            );
            props.setCurrentPatient({...h.row.ele});
            if (props.redirect) {
              history.push(`/section/profile-benefits/${h.row.ele.patientId}`);
            }
            if (props.redirectTo) {
              history.push(`${props.redirectTo}/${h.row.ele.patientId}`);
            }
          }}
          components={{
            noRowsOverlay: usageNoRowsOverlay,
          }}
        />
      </div>
      {/* </ProfileTabs> */}
    </MuiThemeProvider>
  );
};

//*********************************************************REDUX STORE***********************************************************

const mapStateToProp = (state) => {
  return {
    clinicPatientsList: state.apiCallsReducer.clinicPatientsList,
    clinicLocationsList: state.apiCallsReducer.clinicLocationsList,
    searchedClinicPatientsList:
      state.apiCallsReducer.searchedClinicPatientsList,
    locationFilteredClinicPatientsList:
      state.apiCallsReducer.locationFilteredClinicPatientsList,
    userInfo: state.apiCallsReducer.userInfo,
    clinicBasicInfo: state.apiCallsReducer.clinicBasicInfo,
    activeOrArchivedPatientList:
      state.apiCallsReducer.activeOrArchivedPatientList,
  };
};

//*********************************************************Export***********************************************************

export default connect(mapStateToProp, {
  changeClinicLocationSelection,
  changeGlobalSearchObj,
  archivePatient,
  unArchivePatient,
  setCurrentPatient,
})(TableData);

import {SET_CURRENT_SECTION} from '../actions/types';

const DEFAULT_STATE = {
  activeSection: 0,
};

export default function (state = DEFAULT_STATE, {type, payload}) {
  switch (type) {
    case SET_CURRENT_SECTION:
      return {...state, activeSection: payload.activeSection};

    default:
      return state;
  }
}

import firebase from 'firebase';
import helpers from 'Utils/helpers';

export function getFollowUps({treatmentId, clinicId}, callback) {
  firebase
    .database()
    .ref(`/followups/${clinicId}`)
    .orderByChild('treatmentId')
    .equalTo(treatmentId)
    .once('value', (snapshot) => {
      const followUps = [];
      snapshot.forEach((snapFollowUp) => {
        const followUp = snapFollowUp.val();

        followUps.push({...followUp, id: snapFollowUp.key});
      });

      callback(helpers.sortDates(followUps));
    });
}

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {positions, Provider} from 'react-alert';
// import AlertTemplate from 'react-alert-template-basic';
import AlertMUITemplate from 'react-alert-template-mui';
import 'bootstrap-4-grid/scss/grid.scss';

import * as serviceWorker from './serviceWorker';
import './fontawesome';

const options = {
  // you can also just use 'bottom center'
  position: positions.MIDDLE,
  type: 'info',
  containerStyle: {color: 'black'},
  // you can also just use 'scale'
};

// This is if we want to create custom alert template
// const AlertTemplate = ({ style, options, message, close }) => (
//   <div style={style}>
//     {options.type === 'info' && '!'}
//     {options.type === 'success' && ':)'}
//     {options.type === 'error' && ':('}
//     {message}
//     <button onClick={close}>X</button>
//   </div>
// )

ReactDOM.render(
  // <React.StrictMode>
  <Provider template={AlertMUITemplate} {...options}>
    <App />
  </Provider>,
  // </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import firebase from 'firebase';
import axios from 'axios';
import helpers from 'Utils/helpers';
import moment from 'moment';

export async function getDocument(path, callback) {
  if (!path) {
    return null;
  }

  await firebase
    .database()
    .ref(path)
    .once('value', async (snapshot) => {
      const value = await snapshot.val();
      callback(value);
    });
}

export async function getDocumentAsync(path, callback) {
  if (!path) {
    return null;
  }
  return firebase
    .database()
    .ref(path)
    .once('value')
    .then(async (snapshot) => {
      const value = await snapshot.val();
      if (value) {
        return {...value, id: snapshot.key};
      }

      return null;
    });
}

export function pushDocument (path, document, callback) {
  const createdAt = new Date().toISOString();
  firebase
    .database()
    .ref(path)
    .push({...document, createdAt})
    .then((snapshot) => {
      if (typeof callback === 'function') {
        callback(snapshot.key, createdAt);
      }
    });
}

export function updateDocument(path, documentId, callback) {
  const updatedAt = new Date().toISOString();
  firebase
    .database()
    .ref(path)
    .update({[documentId]: true}, () => {
      if (typeof callback === 'function') {
        callback({updatedAt});
      }
    });
}

export function update(path, document, callback) {
  const updatedAt = new Date().toISOString();
  firebase
    .database()
    .ref(path)
    .update(document, () => {
      if (typeof callback === 'function') {
        callback({updatedAt});
      }
    });
}

export function setDocument(path, document, callback) {
  const updatedAt = new Date().toISOString();
  firebase
    .database()
    .ref(path)
    .set({...document, updatedAt})
    .finally(() => {
      if (helpers.isFunction(callback)) {
        callback();
      }
    });
}

export function setSimpleValue(path, value, callback) {
  firebase
    .database()
    .ref(path)
    .set(value)
    .finally(() => {
      if (typeof callback === 'function') {
        callback();
      }
    });
}

export async function getClinicStats(clinicId) {
  if (clinicId) {
    return await axios
      .post(
        'https://us-central1-insurance-ca69d.cloudfunctions.net/getClinicStastics',
        {clinicId},
      )
      .then((result) => {
        return result;
      })
      .catch(() => {
        return {status: 404};
      });
  }

  return {};
}

export function deleteDocument(path, callback) {
  firebase
    .database()
    .ref(path)
    .remove()
    .then(() => {
      if (typeof callback === 'function') {
        callback();
      }
    })
    .catch((error) => {
      if (typeof callback === 'function') {
        callback(error);
      }
    });
}

export function handleTreatmentSubmit(clinicId, patientId, callback) {
  // firebase
  //   .database()
  //   // .ref(`/clinics/${clinicId}/patients/${patientId}/treatments`)
  //   .ref(
  //     'clinics/1IeP2x6iJyYMfWj1S2cEgMun7Uz1/patients/-MUVo2WnmOO9G69E59wt/treatments',
  //   )
  //   .once('value', (snapshot) => {
  //     const treatments = snapshot.val();

  //     console.log(`treatments`, treatments);
  //   });

  if (!clinicId || !patientId) {
    return;
  }

  firebase
    .database()
    .ref(`/treatments/${clinicId}`)
    .orderByChild('patientId')
    .equalTo(patientId)
    .once('value', (snapshot) => {
      const treatmentsNode = snapshot.val();

      let totalUnbilled = 0;
      let unplannedTreatments = 0;

      snapshot.forEach((treatment) => {
        const data = {id: treatment.id, ...treatment.val()};

        if (data.status === 1) {
          const procedures = data.procedures;
          if (procedures) {
            Object.keys(procedures).forEach((key) => {
              const procedure = procedures[key];

              if (!procedure.scheduledDate) {
                totalUnbilled += +procedure.totalFee;
                unplannedTreatments++;
              }
            });
          }
        }
      });

      firebase
        .database()
        .ref(`/clinics/${clinicId}/patients/${patientId}`)
        .update({totalUnbilled, unplannedTreatments})
        .finally(() => {
          if (helpers.isFunction(callback)) {
            callback({
              totalUnbilled,
              unplannedTreatments,
              treatments: treatmentsNode,
            });
          }
        });
    });
}

export function getTodayFollowUps() {
  const currentDate = moment().format('MM/DD/YYYY');
  console.log(`currentDate`, currentDate);
  firebase
    .database()
    .ref('/clinics/1IeP2x6iJyYMfWj1S2cEgMun7Uz1')
    // .orderByChild('status')
    // .equalTo('Verified')
    .once(
      'value',
      (snapshot) => {
        // snapshot.forEach((clinic) => {
        const clinicId = snapshot.key;
        firebase
          .database()
          .ref(`/followups/${clinicId}`)
          .orderByChild('date')
          .equalTo(currentDate)
          .once('value', (followUpSnapshot) => {
            followUpSnapshot.forEach((followUp) => {
              console.log(followUp.val());
            });
          });
        // });
      },
      (error) => console.log(`error`, error),
    );
}

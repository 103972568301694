function validateForm(model, setErrors) {
  const errors = {procedures: {}};
  let invalid = false;

  if (!model.treatmentPlanName) {
    errors.treatmentPlanName = true;
    invalid = true;
  }

  if (Number.isNaN(model.status)) {
    errors.status = true;
    invalid = true;
  }

  Object.keys(model.procedures).forEach((key) => {
    const procedure = model.procedures[key];
    const procedureError = {};

    if (!procedure.adaCode) {
      procedureError.adaCode = true;
      invalid = true;
    }

    if (!procedure.totalFee) {
      procedureError.totalFee = true;
      invalid = true;
    }

    if (Number.isNaN(procedure.discount)) {
      procedureError.discount = true;
      invalid = true;
    }

    if (Number.isNaN(procedure.insuranceAmount)) {
      procedureError.insuranceAmount = true;
      invalid = true;
    }

    errors.procedures[key] = procedureError;
  });

  //   setErrors(errors)/*  */;
  return {errors, invalid};
}

export default validateForm;

export default function validate(values, modalType, newUser) {
  let errors = false;
  if (modalType === 'users') {
    if (values.firstName === '') {
      errors = {message: 'First Name is required', title: 'First Name'};
    }
    if (/\s/g.test(values.firstName)) {
      errors = {
        message: 'No spaces allowed in First Name',
        title: 'First Name',
      };
    }
    if (values.lastName === '') {
      errors = {message: 'Last Name is required', title: 'Last Name'};
    }
    if (/\s/g.test(values.lastName)) {
      errors = {message: 'No spaces allowed in Last Name', title: 'Last Name'};
    }
    if (values.location === '') {
      errors = {
        message: 'Clinic Location is required',
        title: 'Clinic Location',
      };
    }
    if (values.theme === '') {
      errors = {message: 'Theme is required', title: 'Theme'};
    }
    if (newUser) {
      if (values.email === '') {
        errors = {message: 'Email is required', title: 'Email'};
      }
      if (/\s/g.test(values.email)) {
        errors = {message: 'No spaces allowed in Email', title: 'Email'};
      }
      if (values.password === '') {
        errors = {message: 'Password is required', title: 'Password'};
      }
      if (/\s/g.test(values.password)) {
        errors = {message: 'No spaces allowed in Password', title: 'Password'};
      }
    }
  }
  if (modalType === 'npi') {
    if (values.npiNumber === '') {
      errors = {message: 'NPI Number is required', title: 'NPI Number'};
    }
    if (
      /\s/g.test(values.npiNumber) ||
      /[$]/g.test(values.npiNumber) ||
      /[[]/g.test(values.npiNumber) ||
      /]/g.test(values.npiNumber) ||
      /[#]/g.test(values.npiNumber) ||
      /[.]/g.test(values.npiNumber) ||
      values.npiNumber.length !== 10
    ) {
      errors = {
        message: 'NPI Number must be a 10 digit number and contain no spaces.',
        title: 'NPI Number',
      };
    }
    if (values.fullName === '') {
      errors = {message: 'Full Name is required', title: 'Full Name'};
    }
  }
  if (modalType === 'locations') {
    if (values.location === '') {
      errors = {message: 'Clinic Name is required', title: 'Clinic Name'};
    }
    if (
      /[$]/g.test(values.location) ||
      /[[]/g.test(values.location) ||
      /]/g.test(values.location) ||
      /[#]/g.test(values.location) ||
      /[.]/g.test(values.location)
    ) {
      errors = {
        message: 'Remove invalid characters from Clinic Location.',
        title: 'Clinic Location',
      };
    }
  }
  return errors;
}

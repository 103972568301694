import React from 'react';
import ReduxThunk from 'redux-thunk';
import {createStore, applyMiddleware} from 'redux';
import {Provider} from 'react-redux';
import reducers from './reducers';
import LayoutRoute from './components/Routes';
import firebase from 'firebase';
import useAuth from 'actions/useAuth';
import Navbar from 'components/Navbar/Navbar';
import {useSelector} from 'react-redux';

const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));
const firebaseConfig = {
  apiKey: 'AIzaSyDgdy_dCNkRv_QZc5pzkFf7S12YjUoFAyc',
  authDomain: 'insurance-ca69d.firebaseapp.com',
  databaseURL: 'https://insurance-ca69d.firebaseio.com',
  projectId: 'insurance-ca69d',
  storageBucket: 'insurance-ca69d.appspot.com',
  messagingSenderId: '205234913162',
  appId: '1:205234913162:web:eeb50ff014d79982f11273',
};
firebase.initializeApp(firebaseConfig);

// (async function () {
//   const codes = [];
//   Object.keys(adacodes).forEach((adacode) => {
//     codes.push(adacode);
//   });

//   console.log(`codes`, codes);

//   const adaCodeVideosPromises = codes.map(async (adacode) => {
//     return firebase
//       .storage()
//       .ref(`/adaCodeVideos/${adacode}.mp4`)
//       .getDownloadURL()
//       .then((url) => {
//         return {...adacodes[adacode], adacode, url};
//       })
//       .catch(() => {
//         return {...adacodes[adacode], adacode, url: ''};
//       });
//   });

//   console.log(`adaCodeVideosPromises`, adaCodeVideosPromises);

//   const adaCodeVideos = await Promise.all(adaCodeVideosPromises);

//   const adaCodeList = {};
//   adaCodeVideos.forEach(({adacode, ...rest}) => {
//     adaCodeList[adacode] = {adacode, ...rest};
//   });

//   firebase
//     .database()
//     .ref('/adaCodes')
//     .set(adaCodeList)
//     .then((response) => {
//       console.log(`response`, response);
//     })
//     .catch((error) => {
//       console.log(`error`, error);
//     });
//   console.log(`adaCodeList`, adaCodeList);

//   console.log(`adaCodeVideos`, adaCodeVideos);
// })();

const App = () => {
  return (
    <Provider store={store}>
      <LayoutRoute>
        <HandleNavBar />
        <HandleUseAuth />
      </LayoutRoute>
    </Provider>
  );
};

function HandleUseAuth(props) {
  return useAuth({children: props.children});
}

function HandleNavBar() {
  const userInfo = useSelector(({apiCallsReducer}) => apiCallsReducer.userInfo);

  return userInfo.email ? <Navbar /> : null;
}

export default App;

import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
// import HistoryIcon from '@material-ui/icons/History';

const useStyles = makeStyles((theme) => ({
  coloredContainer: {
    display: 'flex',
    backgroundColor: 'white',
    maxHeight: '400px',
    height: '100%',
    marginBottom: 20,
    borderRadius: 5,
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
    boxShadow: '0 2px 5px #7F7F7F, 0 2px 2px #7F7F7F',
  },
  coloredContainerTitle: {
    display: 'flex',
    color: '#1B7FE1',
    padding: 10,
    borderBottom: '2px solid #1B7FE1',
    flex: 1,
    fontWeight: 'bold',
  },
  iconTopRightStyle: {
    //Leave styling to prop
    // padding: 10,
    // paddingTop: 15,
    // paddingLeft: 15,
  },
  upperSection: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  bodySection: {
    display: 'flex',
    height: '100%',
    paddingTop: 10,
    paddingBottom: 10,
    [theme.breakpoints.down('sm')]: {
      height: '300px',
    },
  },
}));

export default function CardContainer({style, children, title, iconTopRight}) {
  useEffect(() => {}, []);

  const classes = useStyles();

  const renderTopRightIcon = () => {
    if (iconTopRight) {
      return <div className={classes.iconTopRightStyle}>{iconTopRight}</div>;
    }
    return;
  };

  return (
    <div className={classes.coloredContainer} style={style}>
      <div className={classes.upperSection}>
        <div className={classes.coloredContainerTitle}>{title}</div>
        {renderTopRightIcon()}
      </div>
      <div className={classes.bodySection}>{children}</div>
    </div>
  );
}

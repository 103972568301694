import React from 'react';
import PropTypes from 'prop-types';
import Basic from 'components/Profile/ExtraInfo/Basic/Basic';
import General from 'components/Profile/ExtraInfo/General/General';
import Preventative from 'components/Profile/ExtraInfo/Preventative/Preventative';
import Major from 'components/Profile/ExtraInfo/Major/Major';
import Ortho from 'components/Profile/ExtraInfo/Ortho/Ortho';
import Employer from 'components/Profile/ExtraInfo/Employer/Employer';
import {getInsurancePlan} from 'actions/apiCallsActions';
import OPTIONS_ENUM from 'constants/Enums/ProfileBenefitsOptionsEnum';
import Button from 'components/Common/Button/Button';
import {Spinner1 as Spinner} from 'components/Spinner1';
import {useSelector} from 'react-redux';
import axios from 'axios';
import {useAlert} from 'react-alert';
import {validateForm} from './benefitsFormActions';

const defaultState = {
  employerInformation: {},
  patientData: {
    personalInfo: '',
    subscriberInfo: '',
  },
  general: {},
  preventive: {
    cleaningsAndFlouride: {
      prophy11101120: 'Preventative',
      prophyLimitations: 'Twice in a Calendar Year',
      fluoride: 'Preventative',
      fluorideVarnish: 'Preventative',
    },
    exams: {
      periodicExam: 'Preventative',
      initialExam: 'Preventative',
      periodontalExam: 'Preventative',
    },
    xrays: {
      periapical: 'Preventative',
      bitewings: 'Preventative',
      fullMouthXRay: 'Preventative',
      panorex: 'Preventative',
    },
    otherServices: {
      sealants: 'Preventative',
      cancerScreening: 'Preventative',
      palliative: 'Preventative',
      spaceMaintainers: 'Preventative',
    },
  },
  basic: {
    fillings: {
      fillings: 'Basic',
      posteriorComposites: 'Basic',
    },
    endodontics: {
      pulpotomy: 'Basic',
      anteriorPremolarRootCanal: 'Basic',
      rootCanal: 'Basic',
    },
    oralSurgeryExtractions: {
      boneGraft: 'Major',
      extraction: 'Major',
      erupted: 'Major',
      softTissue: 'Major',
      partialBony: 'Major',
      completeBony: 'Major',
    },
    otherServices: {},
    anesthesia: {},
    periodontal: {
      osseous: 'Major',
      gingivectomy: 'Major',
      crownLengthening: 'Major',
      tissueGraft: 'Major',
      rootPlanning: 'Major',
      scalingAndInflammation: 'Preventative',
      debridement: 'Basic',
      chemoAgent: 'Major',
      periodontalTrophy: 'Basic',
      periodontalComments: 'Perio Charting required',
    },
  },
  major: {
    restorative: {
      inlays: 'Major',
      onlays: 'Major',
      crownBuildUps: 'Major',
      stainlessSteelCrowns: 'Major',
    },
    dentureRelin: {
      relines: 'Major',
    },
    prosthodontics: {
      singleCrown: 'Major',
      implants: 'Major',
      implantAbutment: 'Major',
      implantCrown: 'Major',
      bridges: 'Major',
      partials: 'Major',
      dentures: 'Major',
    },
    otherServices: {},
  },
  ortho: {
    benefits: {
      benefits: '50',
      orthodonticsNotes: 'of fee table if provider in network',
      maximumDollar: '1500',
    },
    payment: {
      initial: '25% at banding',
      balancePaymentFrequency: 'automatic quarterly',
    },
  },
};

function EmployerBenefits({
  groupNumber,
  insuranceId,
  planId,
  activeOption,
  setActiveOption,
  patientData,
  selectedInsurance,
  goBack,
}) {
  const [model, setModel] = React.useState(null);
  const [loader, setLoader] = React.useState(false);
  const [errors, setErrors] = React.useState({});

  const userInfo = useSelector(({apiCallsReducer}) => apiCallsReducer.userInfo);
  const clinicBasicInfo = useSelector(
    ({apiCallsReducer}) => apiCallsReducer.clinicBasicInfo,
  );

  const alert = useAlert();

  React.useEffect(() => {
    if (!model) {
      if (planId) {
        function callback(data = {}) {
          setModel(data);
        }
        getInsurancePlan(insuranceId, groupNumber, planId, callback);
      } else {
        setModel({...defaultState});
      }
    }
  }, [model, insuranceId, groupNumber, planId]);

  const updateModel = React.useCallback((module, option, name, value) => {
    setModel((currentModel) => {
      const currentModule = currentModel[module];
      const currentOption = currentModule[option];
      return {
        ...currentModel,
        [module]: {
          ...currentModule,
          [option]: {...currentOption, [name]: value},
        },
      };
    });
  }, []);

  const updateModule = React.useCallback((module, name, value) => {
    setModel((currentModel) => {
      const currentModule = currentModel[module];
      return {...currentModel, [module]: {...currentModule, [name]: value}};
    });
  }, []);

  const updateValue = React.useCallback((name, value) => {
    setModel((currentModel) => {
      return {...currentModel, [name]: value};
    });
  }, []);

  const ActionButton = () => {
    return activeOption === OPTIONS_ENUM.all ? (
      <Button
        type="submit"
        variant="contained"
        color="primary"
        onClick={async () => {
          let patient = patientData;
          if (patientData) {
            delete patient.usageHistory;
            if (!patient.personalInfo) {
              patient.personalInfo = '';
            }
            if (!patient.subscriberInfo) {
              patient.subscriberInfo = '';
            }
          }
          const data = {
            ...model,
            patientData: patient || {personalInfo: {}},
            userAddedEmail: userInfo.email,
            isDependent: selectedInsurance?.dependent || '',
            subscriberInfo: selectedInsurance || '',
            clinicInfo: clinicBasicInfo,
            groupNumber,
            insurance: insuranceId,
            insuranceName: insuranceId,
            insuranceId,
          };
          const currentErrors = validateForm(model);
          if (currentErrors) {
            setErrors(currentErrors);
            return;
          }

          setLoader(true);

          const response = await axios.post(
            'https://us-central1-insurance-ca69d.cloudfunctions.net/createNewZohoEmployerBenefitRequest',
            data,
          );

          console.log(`response`, response);
          setLoader(false);
          alert.success(
            'Your employer benefit request has been successfully submitted. Within 1-2 business days, expect to see your request approved.',
            {title: 'Success'},
          );
          console.log(`data`, data);
          goBack();
        }}>
        Submit
      </Button>
    ) : (
      <Button
        type="submit"
        variant="contained"
        color="primary"
        onClick={() => {
          setActiveOption(OPTIONS_ENUM.all);
        }}>
        Review
      </Button>
    );
  };

  if (!model) {
    return null;
  }

  return (
    <div>
      <Spinner open={loader} />
      {(() => {
        switch (activeOption) {
          case OPTIONS_ENUM.employer:
            return (
              <Employer
                values={model.employerInformation}
                onChange={updateModule}
                model={model}
                showSubmitBtn={activeOption === OPTIONS_ENUM.all}
                updateValue={updateValue}
                employerName={model.employerName}
                planName={model.planName}
                state={model.state}
                errors={errors}>
                <ActionButton />
              </Employer>
            );
          case OPTIONS_ENUM.general:
            return (
              <General values={model.general} onChange={updateModule}>
                <ActionButton />
              </General>
            );
          case OPTIONS_ENUM.preventative:
            return (
              <Preventative values={model.preventive} onChange={updateModel}>
                <ActionButton />
              </Preventative>
            );
          case OPTIONS_ENUM.basic:
            return (
              <Basic values={model.basic} onChange={updateModel}>
                <ActionButton />
              </Basic>
            );
          case OPTIONS_ENUM.major:
            return (
              <Major values={model.major} onChange={updateModel}>
                <ActionButton />
              </Major>
            );
          case OPTIONS_ENUM.ortho:
            return (
              <Ortho
                values={model.ortho}
                onChange={updateModel}
                coordinationCOB={model.general.coordinationCOB}
                updateModule={updateModule}>
                <ActionButton />
              </Ortho>
            );
          case OPTIONS_ENUM.all:
            return (
              <>
                <Employer
                  values={model.employerInformation}
                  onChange={updateModule}
                  model={model}
                  showSubmitBtn={activeOption === OPTIONS_ENUM.all}
                  updateValue={updateValue}
                  employerName={model.employerName}
                  planName={model.planName}
                  state={model.state}
                  errors={errors}>
                  <ActionButton />
                </Employer>
                <General values={model.general} onChange={updateModule} />
                <Preventative
                  values={model.preventive}
                  onChange={updateModel}
                />
                <Basic values={model.basic} onChange={updateModel} />
                <Major values={model.major} onChange={updateModel} />
                <Ortho
                  values={model.ortho}
                  onChange={updateModel}
                  coordinationCOB={model.general.coordinationCOB}
                  updateModule={updateModule}
                />
              </>
            );
          default:
            return null;
        }
      })()}
    </div>
  );
}

EmployerBenefits.propTypes = {
  planId: PropTypes.string.isRequired,
  patientId: PropTypes.string.isRequired,
  groupNumber: PropTypes.string.isRequired,
  insuranceId: PropTypes.string.isRequired,
};

export default EmployerBenefits;

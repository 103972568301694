import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'components/Form/Input/Input';
import Title from '../Options/Title';
import Subtitle from '../Options/Subtitle';
import Autocomplete from 'components/Form/Autocomplete/Autocomplete';
import Options from 'data/options';

const Input = React.memo((props) => {
  const inputProps = React.useRef({'data-submodule': props.submodule});

  return <TextField {...props} inputProps={inputProps.current} />;
});
function Ortho({
  values = {},
  coordinationCOB,
  onChange,
  children,
  updateModule,
  disableInputs,
}) {
  const handleChange = React.useCallback(
    (name, value, {dataset}) => {
      const {submodule} = dataset;
      onChange('ortho', submodule, name, value);
    },
    [onChange],
  );
  return (
    <div className="row">
      <div className="col-md-12 my-1">
        <div className="d-flex justify-content-between">
          <Title>Ortho</Title>
          {children}
        </div>
      </div>
      <div className="col-md-12 my-1">
        <Subtitle>Benefits</Subtitle>
      </div>
      <div className="col-md-12 my-1">
        <Autocomplete
          name="coordinationCOB"
          value={coordinationCOB}
          onChange={(name, value) => updateModule('general', name, value)}
          label="Coordination COB"
          disabled={disableInputs}
          options={Options.general.coordinationCOB}
        />
        {/* <Input
          name="coordinatorRule"
          value={coordinatorRule}
          onChange={(name, value) => updateModule('general', name, value)}
          label="Coordination COB"
          disabled={disableInputs}
        /> */}
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="orthodontics"
          value={
            values.benefits.orthodontics ||
            Options.ortho.benefits.orthodontics[0]
          }
          onChange={handleChange}
          label="Orthodontics"
          disabled={disableInputs}
          submodule="benefits"
          options={Options.ortho.benefits.orthodontics}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="orthodonticsNotes"
          value={values.benefits.orthodonticsNotes}
          onChange={handleChange}
          label="Notes"
          disabled={disableInputs}
          submodule="benefits"
          options={Options.ortho.benefits.orthodonticsNotes}
        />
      </div>
      <div className="col-md-12 my-1">
        <Autocomplete
          name="deductible"
          value={values.benefits.deductible}
          onChange={handleChange}
          label="Deductible"
          disabled={disableInputs}
          submodule="benefits"
          options={Options.ortho.benefits.deductible}
        />
      </div>
      <div className="col-md-12 my-1">
        <Autocomplete
          name="maximumDollar"
          value={
            values.benefits.maximumDollar ||
            Options.ortho.benefits.maximumDollar[1]
          }
          onChange={handleChange}
          label="Maximum"
          disabled={disableInputs}
          submodule="benefits"
          options={Options.ortho.benefits.maximumDollar}
        />
      </div>
      <div className="col-md-12 my-1">
        <Subtitle>Payment</Subtitle>
      </div>
      <div className="col-md-12 my-1">
        <Autocomplete
          name="initial"
          value={values.payment.initial || Options.ortho.payment.initial[0]}
          onChange={handleChange}
          label="Initial"
          disabled={disableInputs}
          submodule="payment"
          options={Options.ortho.payment.initial}
        />
      </div>
      <div className="col-md-12 my-1">
        <Autocomplete
          name="balancePaymentFrequency"
          value={
            values.payment.balancePaymentFrequency ||
            Options.ortho.payment.balancePaymentFrequency[1]
          }
          onChange={handleChange}
          label="Payment Frequency/Balance"
          disabled={disableInputs}
          submodule="payment"
          options={Options.ortho.payment.balancePaymentFrequency}
        />
      </div>
      <div className="col-md-12 my-1">
        <Autocomplete
          name="ageLimit"
          value={values.payment.ageLimit}
          onChange={handleChange}
          label="Age Limit"
          disabled={disableInputs}
          submodule="payment"
          options={Options.ortho.payment.ageLimit}
        />
      </div>
      <div className="col-md-12 my-1">
        <Autocomplete
          name="treatmentInProgress"
          value={values.payment.treatmentInProgress}
          onChange={handleChange}
          label="Treatment/Work in Progress"
          disabled={disableInputs}
          submodule="payment"
          options={Options.ortho.payment.treatmentInProgress}
        />
      </div>
      <div className="col-md-12 my-1">
        <Input
          name="comments"
          value={values.payment.comments}
          onChange={handleChange}
          label="Ortho Comments"
          disabled={disableInputs}
          submodule="payment"
        />
      </div>
    </div>
  );
}

Ortho.propTypes = {
  values: PropTypes.object,
};

Ortho.defaultProps = {
  onChange: () => null,
  updateModule: () => null,
};

export default Ortho;

import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'components/Form/Input/Input';
import Title from '../Options/Title';
import Subtitle from '../Options/Subtitle';
import Autocomplete from 'components/Form/Autocomplete/Autocomplete';
import Options from 'data/options';

const Input = React.memo((props) => {
  const inputProps = React.useRef({'data-submodule': props.submodule});

  return <TextField {...props} inputProps={inputProps.current} />;
});

function Preventative({values = {}, onChange, children, disableInputs}) {
  const handleChange = React.useCallback(
    (name, value, {dataset}) => {
      const {submodule} = dataset;
      onChange('preventive', submodule, name, value);
    },
    [onChange],
  );

  return (
    <div className="row">
      <div className="col-md-12 my-1">
        <div className="d-flex justify-content-between">
          <Title>Preventive</Title>
          {children}
        </div>
      </div>
      <div className="col-md-12 my-1">
        <Subtitle>Cleanings and Flouride</Subtitle>
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="prophy11101120"
          value={
            values.cleaningsAndFlouride.prophy11101120 ||
            Options.preventive.cleaningsAndFlouride.prophy11101120[0]
          }
          onChange={handleChange}
          label="Prophy (1110/1120)"
          disabled={disableInputs}
          submodule="cleaningsAndFlouride"
          options={Options.preventive.cleaningsAndFlouride.prophy11101120}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="prophyLimitations"
          value={
            values.cleaningsAndFlouride.prophyLimitations ||
            Options.preventive.cleaningsAndFlouride.prophyLimitations[0]
          }
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          submodule="cleaningsAndFlouride"
          options={Options.preventive.cleaningsAndFlouride.prophyLimitations}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Input
          name="fluoride"
          value={
            values.cleaningsAndFlouride.fluoride ||
            Options.preventive.cleaningsAndFlouride.fluoride[0]
          }
          onChange={handleChange}
          label="Fluoride (1208)"
          disabled={disableInputs}
          submodule="cleaningsAndFlouride"
          options={Options.preventive.cleaningsAndFlouride.fluoride}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="fluorideLimitations"
          value={values.cleaningsAndFlouride.fluorideLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          submodule="cleaningsAndFlouride"
          options={Options.preventive.cleaningsAndFlouride.fluorideLimitations}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="fluorideVarnish"
          value={
            values.cleaningsAndFlouride.fluorideVarnish ||
            Options.preventive.cleaningsAndFlouride.fluorideVarnish[0]
          }
          onChange={handleChange}
          label="Fluoride Varnish(1206)"
          disabled={disableInputs}
          submodule="cleaningsAndFlouride"
          options={Options.preventive.cleaningsAndFlouride.fluorideVarnish}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="fluorideVarnishLimitations"
          value={values.cleaningsAndFlouride.fluorideVarnishLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          submodule="cleaningsAndFlouride"
          options={
            Options.preventive.cleaningsAndFlouride.fluorideVarnishLimitations
          }
        />
      </div>
      <div className="col-md-12 my-1">
        <Subtitle>Exams</Subtitle>
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="periodicExam"
          value={
            values.exams.periodicExam ||
            Options.preventive.exams.periodicExam[0]
          }
          onChange={handleChange}
          label="Periodic Exam (0120)"
          disabled={disableInputs}
          submodule="exams"
          options={Options.preventive.exams.periodicExam}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="periodicExamLimitations"
          value={values.exams.periodicExamLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          submodule="exams"
          options={Options.preventive.exams.periodicExamLimitations}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="initialExam"
          value={
            values.exams.initialExam || Options.preventive.exams.initialExam[0]
          }
          onChange={handleChange}
          label="Initial Exam (0150)"
          disabled={disableInputs}
          submodule="exams"
          options={Options.preventive.exams.initialExam}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="initialExamLimitations"
          value={values.exams.initialExamLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          submodule="exams"
          options={Options.preventive.exams.initialExamLimitations}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="periodontalExam"
          value={
            values.exams.periodontalExam ||
            Options.preventive.exams.periodontalExam[0]
          }
          onChange={handleChange}
          label="Periodontal Exam (0180)"
          disabled={disableInputs}
          submodule="exams"
          options={Options.preventive.exams.periodontalExam}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="periodontalExamLimitations"
          value={values.exams.periodontalExamLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          submodule="exams"
          options={Options.preventive.exams.periodontalExamLimitations}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="limitedOralExamProblemFocusedExam"
          value={values.exams.limitedOralExamProblemFocusedExam}
          onChange={handleChange}
          label="Limited Oral (0140)"
          disabled={disableInputs}
          submodule="exams"
          options={Options.preventive.exams.limitedOralExamProblemFocusedExam}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="limitedOralExamProblemFocusedExamLimitations"
          value={values.exams.limitedOralExamProblemFocusedExamLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          submodule="exams"
          options={
            Options.preventive.exams
              .limitedOralExamProblemFocusedExamLimitations
          }
        />
      </div>
      <div className="col-md-12 my-1">
        <Subtitle>X-Rays</Subtitle>
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="periapical"
          value={
            values.xrays.periapical || Options.preventive.xrays.periapical[0]
          }
          onChange={handleChange}
          label="Periapical (0220/0230)"
          disabled={disableInputs}
          submodule="xrays"
          options={Options.preventive.xrays.periapical}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="periapicalLimitations"
          value={values.xrays.periapicalLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          submodule="xrays"
          options={Options.preventive.xrays.periapicalLimitations}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="bitewings"
          value={
            values.xrays.bitewings || Options.preventive.xrays.bitewings[0]
          }
          onChange={handleChange}
          label="Bitewings (0272/0274)"
          disabled={disableInputs}
          submodule="xrays"
          options={Options.preventive.xrays.bitewings}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="bitewingsLimitations"
          value={values.xrays.bitewingsLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          submodule="xrays"
          options={Options.preventive.xrays.bitewingsLimitations}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="verticalBitewings"
          value={values.xrays.verticalBitewings}
          onChange={handleChange}
          label="Vertical Bitewings (0277)"
          disabled={disableInputs}
          submodule="xrays"
          options={Options.preventive.xrays.verticalBitewings}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="verticalBitewingsLimitations"
          value={values.xrays.verticalBitewingsLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          submodule="xrays"
          options={Options.preventive.xrays.verticalBitewingsLimitations}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="fullMouthXRay"
          value={
            values.xrays.fullMouthXRay ||
            Options.preventive.xrays.fullMouthXRay[0]
          }
          onChange={handleChange}
          label="Full Mouth X-Ray (0210)"
          disabled={disableInputs}
          submodule="xrays"
          options={Options.preventive.xrays.fullMouthXRay}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="fullMouthXRayLimitations"
          value={values.xrays.fullMouthXRayLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          submodule="xrays"
          options={Options.preventive.xrays.fullMouthXRayLimitations}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="panorex"
          value={values.xrays.panorex || Options.preventive.xrays.panorex[0]}
          onChange={handleChange}
          label="Panorex (0330)"
          disabled={disableInputs}
          submodule="xrays"
          options={Options.preventive.xrays.panorex}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="panorexLimitations"
          value={values.xrays.panorexLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          submodule="xrays"
          options={Options.preventive.xrays.panorexLimitations}
        />
      </div>
      <div className="col-md-12 my-1">
        <Subtitle>Other Services</Subtitle>
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Input
          name="sealantsToothChoicesAndAge"
          value={values.otherServices.sealantsToothChoicesAndAge}
          onChange={handleChange}
          label="Sealants (1351)"
          disabled={disableInputs}
          submodule="otherServices"
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Input
          name="sealantsToothChoicesLimitations"
          value={values.otherServices.sealantsToothChoicesLimitations}
          onChange={handleChange}
          label="Tooth Choices and Limitations"
          disabled={disableInputs}
          submodule="otherServices"
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="cancerScreening"
          value={
            values.otherServices.cancerScreening ||
            Options.preventive.otherServices.cancerScreening[0]
          }
          onChange={handleChange}
          label="Cancer Screening (0431)"
          disabled={disableInputs}
          submodule="otherServices"
          options={Options.preventive.otherServices.cancerScreening}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="cancerScreeningLimitations"
          value={values.otherServices.cancerScreeningLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          submodule="otherServices"
          options={Options.preventive.otherServices.cancerScreeningLimitations}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="palliative"
          value={
            values.otherServices.palliative ||
            Options.preventive.otherServices.palliative[2]
          }
          onChange={handleChange}
          label="Palliative (9110)"
          disabled={disableInputs}
          submodule="otherServices"
          options={Options.preventive.otherServices.palliative}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="palliativeLimitations"
          value={values.otherServices.palliativeLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          submodule="otherServices"
          options={Options.preventive.otherServices.palliativeLimitations}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="spaceMaintainers"
          value={
            values.otherServices.spaceMaintainers ||
            Options.preventive.otherServices.spaceMaintainers[0]
          }
          onChange={handleChange}
          label="Space Maintainers (1510)"
          disabled={disableInputs}
          submodule="otherServices"
          options={Options.preventive.otherServices.spaceMaintainers}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="spaceMaintainersLimitations"
          value={values.otherServices.spaceMaintainersLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          submodule="otherServices"
          options={Options.preventive.otherServices.spaceMaintainersLimitations}
        />
      </div>
      <div className="col-md-12 my-1">
        <Input
          name="comments"
          multiline
          value={values.otherServices.comments}
          onChange={handleChange}
          label="Comments"
          disabled={disableInputs}
          submodule="otherServices"
        />
      </div>
    </div>
  );
}

Preventative.propTypes = {
  values: PropTypes.object,
};

Preventative.defaultProps = {
  onChange: () => null,
};

export default Preventative;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './Divider.module.scss';

function Divider({label}) {
  return (
    <div className={styles.divider}>
      <div className={styles.label}>{label}</div>
    </div>
  );
}

Divider.propTypes = {
  label: PropTypes.string.isRequired,
};

export default React.memo(Divider);

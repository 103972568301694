import React, {useState, useEffect} from 'react';
import {
  fade,
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import {
  Select,
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  MenuItem,
  Menu,
  Input,
  Tooltip,
} from '@material-ui/core';
import logo from '../123insurancelogo.png';
import SearchIcon from '@material-ui/icons/Search';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SettingsIcon from '@material-ui/icons/Settings';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import MoreIcon from '@material-ui/icons/MoreVert';
import PageviewIcon from '@material-ui/icons/Pageview';
import TransitionsModal from '../Modal/Modal.js';
import {useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import {Spinner1} from '../Spinner1';
import {
  changeGlobalSearchObj,
  changeClinicLocationSelection,
  loadArchivedPatients,
  loadActivePatientsList,
} from '../../actions';
import {debounce} from 'lodash';
import {setCurrentPatient} from 'actions/patientActions';

const useStyles = makeStyles((theme) => ({
  navbarMenu: {
    backgroundColor: '#1B7FE1',
  },
  grow: {
    flexGrow: 1,
    //caretColor: 'transparent !important',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  menuColor: {
    color: '#1B7FE1',
    padding: '5px',
  },
  personaddmenuColor: {
    color: '#1B7FE1',
    padding: '15px 10px 0 5px',
  },
  personaddmobile: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      fontSize: '25px',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    // [theme.breakpoints.up('xs')]: {
    //   marginLeft: theme.spacing(7),
    //   width: '50%',
    // },
    // [theme.breakpoints.up('md')]: {
    //   marginLeft: theme.spacing(20),
    //   width: 'auto',
    // },
    // [theme.breakpoints.up('sm')]: {
    //   marginLeft: theme.spacing(20),
    //   width: 'auto',
    // },
    // [theme.breakpoints.up('lg')]: {
    //   marginLeft: theme.spacing(40),
    //   width: 'auto',
    // },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    margin: 'auto',
  },
  inputInput: {
    color: 'white',
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '50ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  clinicSelectRoot: {
    borderBottom: '1px solid white',
    color: 'white',
    [theme.breakpoints.up('md')]: {
      width: 120,
    },
    [theme.breakpoints.down('sm')]: {
      width: 30,
    },
  },
  clinicSelectIcon: {
    color: 'white',
  },
  settingsicon: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  logo: {
    width: '120px',
    padding: '15px 0 0 0',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '80px',
      padding: '10px 0 0 0',
    },
  },
}));

// Depending on the current path, this component sets the "active" class on the appropriate navigation link item
function Navbar(props) {
  const theme = createMuiTheme({
    typography: {
      fontFamily: '"Roboto"',
    },
    palette: {
      primary: {
        main: '#1B7FE1',
        contrastText: '#fff', //button text white instead of black
      },
      secondary: {
        main: '#fff',
      },
      background: {
        default: '#fff',
      },
    },
  });

  useEffect(() => {
    renderDefaultClinicValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clinicPatientsList]);

  const classes = useStyles();
  const history = useHistory();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [searchText] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [loadingLocal, setLoadingLocal] = useState(false);
  // const [selectedPatientListValue, setSelectedPatientListValue] = useState(
  //   'Active',
  // );

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const debounceSearch = React.useCallback(
    (callback) => debounce(callback, 400),
    [],
  );

  const handleSearchTextChange = (value) => {
    const val = value.target.value;
    debounceSearch(() => {
      props.changeGlobalSearchObj(val, props.clinicPatientsList);
    })();
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleClinicSelect = async (event) => {
    setSelectedValue(event.target.value);
    // console.log(event.target.value);
    const isSomethingSearched = searchText !== '';
    const updatedLocationFilteredList = await props.changeClinicLocationSelection(
      event.target.value,
      props.clinicPatientsList,
      props.clinicLocationsList,
    );
    if (isSomethingSearched) {
      props.changeGlobalSearchObj(searchText, updatedLocationFilteredList);
    }
  };

  const handlePatientListClick = async (event) => {
    if (event.target.value) {
      setLoadingLocal(true);
      if (event.target.value === 'Active') {
        await props.loadActivePatientsList(props.clinicBasicInfo.clinicId);
      } else {
        await props.loadArchivedPatients(props.clinicBasicInfo.clinicId);
      }
      setLoadingLocal(false);
    }
  };

  const renderDefaultClinicValue = () => {
    // console.log('CALLED RENDER DEFAULT', {...props.userInfo});
    if (props.userInfo) {
      if (props.userInfo.admin || props.userInfo.masterAdmin) {
        setSelectedValue('All');
        return 'All';
      }
      if (props.userInfo.locationAccess) {
        setSelectedValue(Object.keys(props.userInfo.locationAccess)[0]);
        return Object.keys(props.userInfo.locationAccess)[0];
      }
    }
    return;
  };

  const renderClinicArray = () => {
    let output = [];
    if (props.userInfo) {
      if (props.userInfo.locationAccess) {
        if (
          props.userInfo.admin ||
          props.userInfo.masterAdmin ||
          Object.keys(props.userInfo.locationAccess)[0] === 'All'
        ) {
          output = props.clinicLocationsList.map((object, index) => (
            <MenuItem value={object.name} key={index}>
              {object.name}
            </MenuItem>
          ));
          output = [
            <MenuItem value={'All'} key={'all'}>
              All
            </MenuItem>,
          ].concat(output);
        } else {
          output = (
            <MenuItem value={Object.keys(props.userInfo.locationAccess)[0]}>
              {Object.keys(props.userInfo.locationAccess)[0]}
            </MenuItem>
          );
        }
      }
    }
    return output;
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      // data-html2canvas-ignore="true"
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>
      <MenuItem>
        <TransitionsModal hello={true}>
          <div className={classes.personaddmobile}>
            <PersonAddIcon className={classes.personaddmenuColor} />
            <p>• Add Patient</p>
          </div>
        </TransitionsModal>
      </MenuItem>
      {props.clinicBasicInfo.hideInsurance === true ? null : (
        <MenuItem
          onClick={() => {
            history.push('/Template');
          }}>
          <NoteAddIcon className={classes.menuColor} />
          <p>• Add Template</p>
        </MenuItem>
      )}

      <MenuItem
        onClick={() => {
          history.push('/Settings');
        }}>
        <SettingsIcon className={classes.menuColor} />
        <p>• Settings</p>
      </MenuItem>
      {props.clinicBasicInfo.hideInsurance === true ? null : (
        <MenuItem
          onClick={() => {
            history.push('/SearchInsurance');
          }}>
          <PageviewIcon className={classes.menuColor} />
          <p>• Search Insurance</p>
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <nav className={classes.grow}>
      <Spinner1 open={loadingLocal} />
      <AppBar
        position={'relative'}
        className={classes.navbarMenu}
        style={
          props.userInfo.theme ? {backgroundColor: props.userInfo.theme} : {}
        }>
        <Toolbar>
          <span
            onClick={() => {
              props.setCurrentPatient(null);
              if (props.clinicBasicInfo.hideInsurance === true) {
                history.replace('/section/production');
              } else {
                history.replace('/section/insurance');
              }
            }}>
            <img src={logo} alt="123 Insurance" className={classes.logo} />
          </span>
          <div
            style={{marginLeft: 15, marginRight: 15}}
            data-html2canvas-ignore="true">
            <Select
              labelId="demo-simple-select-label"
              outlined={'true'}
              classes={{
                icon: classes.clinicSelectIcon,
                root: classes.clinicSelectRoot,
              }}
              value={selectedValue}
              disabled={props.clinicLocationsList.length === 1 ? true : false}
              input={<Input disableUnderline />}
              onChange={handleClinicSelect}>
              {renderClinicArray()}
            </Select>
          </div>
          <div className={classes.search} data-html2canvas-ignore="true">
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder=""
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{type: 'search'}}
              onChange={handleSearchTextChange}
            />
          </div>
          <div
            className={classes.sectionDesktop}
            data-html2canvas-ignore="true">
            <TransitionsModal hello={true}>
              <MuiThemeProvider theme={theme}>
                <IconButton>
                  <Tooltip title={'Add Patient'}>
                    <PersonAddIcon color="secondary" />
                  </Tooltip>
                </IconButton>
              </MuiThemeProvider>
            </TransitionsModal>
          </div>
          {props.clinicBasicInfo.hideInsurance === true ? null : (
            <div
              className={classes.sectionDesktop}
              data-html2canvas-ignore="true">
              <IconButton
                onClick={() => {
                  history.push('/Template');
                }}
                color="inherit">
                <Tooltip title={'ADA Code Templates'}>
                  <NoteAddIcon />
                </Tooltip>
              </IconButton>
            </div>
          )}
          {props.clinicBasicInfo.hideInsurance === true ? null : (
            <div
              className={classes.sectionDesktop}
              data-html2canvas-ignore="true">
              <IconButton
                onClick={() => {
                  history.push('/SearchInsurance');
                }}
                color="inherit">
                <Tooltip title={'Search Employer Database'}>
                  <PageviewIcon />
                </Tooltip>
              </IconButton>
            </div>
          )}
          <div style={{marginLeft: 20}} data-html2canvas-ignore="true">
            <Select
              labelId="demo-simple-select-label"
              outlined={'true'}
              classes={{
                icon: classes.clinicSelectIcon,
                root: classes.clinicSelectRoot,
              }}
              value={props.activeOrArchivedPatientList}
              input={<Input disableUnderline />}
              onChange={handlePatientListClick}>
              <MenuItem value={'Active'} key={'Active'}>
                Active
              </MenuItem>
              <MenuItem value={'Archived'} key={'Archived'}>
                Archived
              </MenuItem>
            </Select>
          </div>
          <div className={classes.grow} data-html2canvas-ignore="true" />

          <div className={classes.sectionMobile} data-html2canvas-ignore="true">
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit">
              <MoreIcon />
            </IconButton>
          </div>
          <div className={classes.settingsicon} data-html2canvas-ignore="true">
            <IconButton
              onClick={() => {
                history.push('/Settings');
              }}
              color="inherit">
              <Tooltip title={'Settings'}>
                <SettingsIcon />
              </Tooltip>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {/* {renderMenu} */}
    </nav>
  );
}

const mapStateToProp = (state) => {
  return {
    clinicPatientsList: state.apiCallsReducer.clinicPatientsList,
    clinicLocationsList: state.apiCallsReducer.clinicLocationsList,
    locationFilteredClinicPatientsList:
      state.apiCallsReducer.locationFilteredClinicPatientsList,
    userInfo: state.apiCallsReducer.userInfo,
    searchedClinicPatientsList:
      state.apiCallsReducer.searchedClinicPatientsList,
    clinicBasicInfo: state.apiCallsReducer.clinicBasicInfo,
    activeOrArchivedPatientList:
      state.apiCallsReducer.activeOrArchivedPatientList,
  };
};

export default connect(mapStateToProp, {
  changeGlobalSearchObj,
  changeClinicLocationSelection,
  loadArchivedPatients,
  loadActivePatientsList,
  setCurrentPatient,
})(Navbar);

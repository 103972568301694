export default function validate(
  values,
  insuranceArray,
  patientList,
  patientData,
) {
  let errorMessage = null;
  let primaryInsurance = false;
  if (!values.firstName) {
    errorMessage = 'First Name is required.';
  }
  if (!values.lastName) {
    errorMessage = 'Last Name is required.';
  }
  if (!values.location) {
    errorMessage = 'Location is required.';
  }
  if (values.email) {
    if (!/\S+@\S+\.\S+/.test(values.email)) {
      errorMessage = 'Email address is invalid.';
    }
  }
  if (values.phone) {
    if (!/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(values.phone)) {
      errorMessage = 'Phone Number must be 10 digits.';
    }
  }
  if (!values.DOB) {
    errorMessage = 'DOB is required';
  } else if (values.DOB) {
    if (values.DOB === 'NaN-0NaN-NaN') {
      errorMessage = 'Invalid DOB';
    }
  }
  if (values.nextAppointmentDate) {
    if (values.nextAppointmentDate === 'NaN-0NaN-NaN') {
      errorMessage = 'Invalid Next Appointment Date';
    }
  }
  insuranceArray.forEach((object, index) => {
    if (!object.insurance) {
      errorMessage = 'Please select a valid insurance name from the list.';
    }
    if (!object.memberId) {
      errorMessage = 'Member ID is required.';
    }
    if (object.primaryInsurance === true) {
      primaryInsurance = true;
    }
    if (object.dependent === 'empty') {
      errorMessage =
        'Please identify whether the patient is a dependent or not.';
    }

    if (object.dependent === true) {
      if (!object.subscriberLastName || object.subscriberLastName === '') {
        errorMessage = 'Please provide the last name of the subscriber.';
      }
      if (!object.subscriberFirstName || object.subscriberFirstName === '') {
        errorMessage = 'Please provide the first name of the subscriber.';
      }
      if (!object.subscriberDOB || object.subscriberDOB === '') {
        errorMessage = 'Please provide the DOB of the subscriber.';
      }
    }
  });

  if (!primaryInsurance) {
    errorMessage = 'One Primary Insurance is required.';
  }

  if (!patientData?.personalInfo?.firstName) {
    const isDuplicate1 = checkForDuplicatePatient(patientList, values);
    if (isDuplicate1) {
      errorMessage =
        'Patient with the same first name, last name and DOB already exists in the system. Please search for the patient.';
    }
  }
  return errorMessage;
}
const checkForDuplicatePatient = (patientList, values) => {
  const {firstName, lastName, DOB} = values;

  let isDuplicate = false;

  if (patientList) {
    patientList.forEach((object, index) => {
      if (
        object.personalInfo.DOB === DOB &&
        object.personalInfo.firstName.toLowerCase() ===
          firstName.toLowerCase() &&
        object.personalInfo.lastName.toLowerCase() === lastName.toLowerCase()
      ) {
        isDuplicate = true;
      }
    });
  }
  return isDuplicate;
};

/* eslint-disable radix */
import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {DataGrid, GridOverlay} from '@material-ui/data-grid';
import SettingsIcon from '@material-ui/icons/Settings';
import TrashIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import {TextField, MenuItem, Select, Input} from '@material-ui/core';
import {connect} from 'react-redux';
import {CirclePicker} from 'react-color';
import {useAlert} from 'react-alert';
// import Navbar from '../Navbar/Navbar';
import CardContainer from '../CardContainer';
import {ThemedModal} from '../ThemedModal';
import validate from '../SettingsValidationRules';
import {Spinner1} from '../Spinner1';
import {useHistory} from 'react-router-dom';
import {
  addNpiToFb,
  addLocationToFb,
  addUsersToFb,
  deleteNpiOnFb,
  inactivateUser,
  logoutFunc,
  editUserProperties,
} from '../../actions';
import EmployerBenefitsModal from './EmployerBenefitsModal/EmployerBenefitsModal';
import ProcedureTemplates from './ProcedureTemplates/ProcedureTemplates';
import FollowUpSettings from 'components/Settings/FollowUp/FollowUp';
import ClinicLocations from './ClinicLocation/ClinicLocation';
import CarrierCredentials from './CarrierCredentials/CarrierCredentials';

//*************************************************************************************Component Styling Sheet***************************************************
const useStyles = makeStyles((theme) => ({
  settingsContainerRoot: {
    display: 'flex',
    height: 'auto',
    width: '100%',
    flexDirection: 'column',
    fontFamily: '"Roboto"',
    color: 'red',
    [theme.breakpoints.only('md')]: {
      height: '100%',
    },
  },
  '& .MuiDataGrid-mainGridContainer': {
    width: '300px',
  },
  settingsContainer: {
    display: 'flex',
    height: '100%',
    width: 'auto',
    flexDirection: 'column',
    // backgroundColor: '#DEE8F2',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  settingsTitle: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      padding: '15px 0',
    },
  },
  usercontainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  currentUser: {
    color: '#1B7FE1',
    fontSize: 16,
    padding: '0 0 0 20px',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  logoutcontainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-around',
    },
  },
  settings: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#1B7FE1',
    fontWeight: 'bold',
    fontSize: 40,
    padding: 12,
    flex: 1,
    '& p': {
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      '& p': {
        padding: 0,
      },
    },
  },
  logoutStyle: {
    //position: 'absolute',
    margin: 0,
    color: 'red',
    fontWeight: 'lighter',
    fontSize: 18,
    right: 0,
    paddingRight: 20,
    cursor: 'pointer',
  },
  contactUsStyle: {
    //position: 'absolute',
    fontWeight: 'lighter',
    color: '#1B7FE1',
    fontSize: 18,
    right: 70,
    paddingRight: 20,
    cursor: 'pointer',
  },
  settingsInnerContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    minHeight: '1200px',
    paddingLeft: '20px',
    paddingRight: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      minHeight: '0px',
    },
  },
  righContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    minHeight: '1200px',
    paddingLeft: '10px',
    paddingRight: '20px',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      margin: '0 0 0 20px',
      minHeight: '0px',
    },
  },
  usersDataGrid: {
    width: 200,
    height: '100%',
    minHeight: 300,
    borderColor: 'white',
    '& .MuiDataGrid-colCellTitle': {
      color: 'black',
      fontWeight: 'bolder',
    },
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
    },
    '& .MuiDataGrid-iconSeparator': {
      color: '#efefef',
    },
    '& .MuiDataGrid-cell': {
      borderColor: 'white',
    },
    '& .MuiDataGrid-columnsContainer': {
      borderColor: 'white',
      backgroundColor: '#efefef',
    },
    '& .MuiDataGrid-window': {
      overflowX: 'hidden',
    },
  },
  npiDataGrid: {
    width: 200,
    height: 'auto',
    minHeight: 300,
    borderColor: 'white',
    '& .MuiDataGrid-colCellTitle': {
      color: 'black',
      fontWeight: 'bolder',
    },
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
    },
    '& .MuiDataGrid-iconSeparator': {
      color: '#efefef',
    },
    '& .MuiDataGrid-cell': {
      borderColor: 'white',
    },
    '& .MuiDataGrid-columnsContainer': {
      borderColor: 'white',
      backgroundColor: '#efefef',
    },
    '& .MuiDataGrid-window': {
      overflowX: 'hidden',
    },
  },
  locationsDataGrid: {
    width: 200,
    height: '100%',
    minHeight: 300,
    borderColor: 'white',
    cursor: 'pointer',
    '& .MuiDataGrid-colCellTitle': {
      color: 'black',
      fontWeight: 'bolder',
    },
    '& .MuiDataGrid-iconSeparator': {
      color: '#efefef',
    },
    '& .MuiDataGrid-cell': {
      borderColor: 'white',
    },
    '& .MuiDataGrid-columnsContainer': {
      borderColor: 'white',
      backgroundColor: '#efefef',
    },
    '& .MuiDataGrid-window': {
      overflowX: 'hidden',
    },
  },
  usageDataGrid: {
    width: 200,
    height: '100%',
    minHeight: 300,
    borderColor: 'white',
    '& .MuiDataGrid-colCellTitle': {
      color: 'black',
      fontWeight: 'bolder',
    },
    '& .MuiDataGrid-iconSeparator': {
      color: '#efefef',
    },
    '& .MuiDataGrid-cell': {
      borderColor: 'white',
    },
    '& .MuiDataGrid-columnsContainer': {
      borderColor: 'white',
      backgroundColor: '#efefef',
    },
    '& .MuiDataGrid-window': {
      overflowX: 'hidden',
    },
  },
  addIcon: {
    height: 50,
    width: 50,
    paddingLeft: 10,
    color: '#1B7FE1',
    cursor: 'pointer',
  },
  formStyle: {
    display: 'flex',
    flex: 1,
    '& .MuiTextField-root': {
      width: '90%',
      margin: '0 5px',
    },
    flexDirection: 'column',
    padding: '10px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  firstLastInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
    paddingTop: '10px',
    //paddingRight: '10px',
    margin: 0,
  },
  firstLastInputContainerNPIExtraInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
    paddingTop: '15px',
    //paddingRight: '10px',
    margin: 0,
  },
  clinicSelectRoot: {
    width: 120,
    border: '1px solid #BFBFBF',
    paddingLeft: 10,
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  locationContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  locationThemeTitle: {
    display: 'flex',
    paddingTop: 15,
    paddingBottom: 10,
    justifyContent: 'center',
  },
  modalButton: {
    width: '30%',
    backgroundColor: '#1B7FE1',
    height: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    color: 'white',
    borderRadius: '5px',
    fontSize: '18px',
    marginTop: '30px',
    cursor: 'pointer',
  },
  modalCancelButton: {
    width: '30%',
    backgroundColor: '#f4525f',
    height: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    color: 'white',
    borderRadius: '5px',
    fontSize: '18px',
    marginTop: '30px',
    marginRight: '20px',
    cursor: 'pointer',
  },
  circleStyle: {
    justifyContent: 'center',
  },
  modalButtonsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
}));

//*************************************************************************************Settings Component***************************************************
const Settings = ({
  clinicUsersList,
  userInfo,
  clinicBasicInfo,
  clinicLocationsList,
  clinicUsageInfo,
  addNpiToFb,
  addLocationToFb,
  addUsersToFb,
  deleteNpiOnFb,
  inactivateUser,
  logoutFunc,
  editUserProperties,
}) => {
  const [rootWidth, setRootWidth] = useState(0);

  useEffect(() => {
    setRootWidth(document.getElementById('root').clientWidth);
    if (clinicLocationsList.length === 1) {
      setTextValues({
        ...textValues,
        location: 'All',
      });
    }
    // eslint-disable-next-line
  }, []);

  const classes = useStyles();
  const alert = useAlert();

  //******************Local State******************

  const closeAllModals = {
    users: false,
    newUser: false,
    npi: false,
    locations: false,
    usage: false,
  };

  const clearTextFields = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    npiNumber: '',
    fullName: '',
    doctorFirstName: '',
    doctorLastName: '',
    taxId: '',
    taxLicense: '',
    location: 'All',
    theme: '',
    selectedUid: '',
    isMasterAdmin: false,
  };

  const [openModalObj, setOpenModalObj] = useState(closeAllModals);
  const [textValues, setTextValues] = useState(clearTextFields);
  const [loadingLocal, setloadingLocal] = useState(false);
  const history = useHistory();

  //******************Text Change and Prefill Modal Logic*********************************

  const handleTextChange = (name, value, e) => {
    if (e) {
      e.preventDefault();
    }
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    setTextValues({
      ...textValues,
      [name]:
        name !== 'password' && name !== 'admin'
          ? capitalizeFirstLetter(value)
          : value,
    });
  };

  const closeModal = () => {
    setOpenModalObj(closeAllModals);
    setTextValues(clearTextFields);
  };

  const isAdmin = () => {
    return userInfo.admin ? true : false;
  };

  //************Logout Section ****************/

  const handleLogoutClick = async (e) => {
    if (e) {
      e.preventDefault();
    }
    setloadingLocal(true);
    const result = await logoutFunc();
    if (result) {
      setloadingLocal(false);
      history.replace('/');
    } else {
      alert.show('Error logging out. Please try again.', {title: 'Error'});
    }
  };

  //******************Users Section*********************************

  const renderSettingsIcon = () => {
    return <SettingsIcon />;
  };

  const usersNoRowsOverlay = () => {
    return (
      <GridOverlay>
        <div>Login as an Admin to View.</div>
      </GridOverlay>
    );
  };

  const renderUserRows = (clinicUsersObj, indivUserObj) => {
    let userRows = [];
    if (indivUserObj.admin) {
      Object.keys(clinicUsersObj).forEach((ele) => {
        if (clinicUsersObj[ele].active) {
          userRows.push({
            id: clinicUsersObj[ele].email,
            name: `${clinicUsersObj[ele].firstName} ${clinicUsersObj[ele].lastName}`,
            email: clinicUsersObj[ele].email,
            extraData: {...clinicUsersObj[ele], uid: ele},
          });
        }
      });
    }
    return userRows;
  };

  const handleUsersRowClick = (row) => {
    let fullData = row.extraData;
    const locationDisplay = fullData.masterAdmin
      ? 'All'
      : Object.keys(fullData.locationAccess)[0];
    setTextValues({
      ...textValues,
      firstName: fullData.firstName,
      lastName: fullData.lastName,
      email: fullData.email,
      location: locationDisplay,
      theme: fullData.theme,
      selectedUid: fullData.uid,
      isMasterAdmin: fullData.masterAdmin,
      admin: fullData.admin,
    });
    setOpenModalObj({...openModalObj, users: true});
  };

  const renderUsersModal = (newUser) => {
    return (
      <ThemedModal
        open={openModalObj.users}
        onClose={closeModal}
        title={'Create New User'}>
        <form className={classes.formStyle}>
          <div className={classes.inputsContainer}>
            <div className={classes.firstLastInputContainer}>
              <TextField
                required
                name="firstName"
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                onChange={(e) =>
                  handleTextChange(e.target.name, e.target.value, e)
                }
                defaultValue={textValues.firstName ? textValues.firstName : ''}
                inputProps={{
                  style: {
                    height: 40,
                    padding: '0 14px',
                  },
                }}
                InputLabelProps={{
                  style: {
                    height: 40,
                    marginTop: '-5px',
                    fontSize: '15px',
                    fontFamily: 'Roboto',
                  },
                }}
              />
              <TextField
                required
                name="lastName"
                id="outlined-basic"
                label="Last Name"
                variant="outlined"
                onChange={(e) =>
                  handleTextChange(e.target.name, e.target.value, e)
                }
                defaultValue={textValues.lastName ? textValues.lastName : ''}
                inputProps={{
                  style: {
                    height: 40,
                    padding: '0 14px',
                  },
                }}
                InputLabelProps={{
                  style: {
                    height: 40,
                    marginTop: '-5px',
                    fontSize: '15px',
                    fontFamily: 'Roboto',
                  },
                }}
              />
            </div>
            {renderPasswordInput(newUser)}
          </div>
          <div className={classes.locationContainer}>
            <div className={classes.locationThemeTitle}>Location:</div>
            <Select
              labelId="demo-simple-select-label"
              classes={{
                root: classes.clinicSelectRoot,
              }}
              // defaultValue={
              //   textValues.location
              //     ? textValues.location
              //     : clinicLocationsList.length === 1
              //     ? 'All'
              //     : ''
              // }
              // disabled={clinicLocationsList.length === 1 ? true : ''}
              input={<Input disableUnderline />}
              onChange={(e) => handleTextChange('location', e.target.value, e)}>
              <MenuItem value={'All'}>All</MenuItem>
              {clinicLocationsList.map((object) => (
                <MenuItem value={object.name}>{object.name}</MenuItem>
              ))}
            </Select>
          </div>
          <div className={classes.locationContainer}>
            <div className={classes.locationThemeTitle}>User Access:</div>
            <Select
              labelId="demo-simple-select-label"
              classes={{
                root: classes.clinicSelectRoot,
              }}
              defaultValue={textValues.admin ? textValues.admin : false}
              // disabled={clinicLocationsList.length === 1 ? true : ''}
              input={<Input disableUnderline />}
              onChange={(e) => handleTextChange('admin', e.target.value, e)}>
              <MenuItem value={true}>Admin</MenuItem>
              <MenuItem value={false}>Non-admin</MenuItem>
            </Select>
          </div>
          <div className={classes.locationContainer}>
            <div className={classes.locationThemeTitle}>Theme:</div>
            <CirclePicker
              className={classes.circleStyle}
              colors={[
                '#1B7FE1',
                '#E1E1E1',
                '#DBABFF',
                '#DC2127',
                '#FF887C',
                '#FFB878',
                '#FBD75B',
                '#51B749',
                '#7AE7BF',
                '#46D6DB',
                '#A4BDFC',
                '#5484ED',
              ]}
              color={textValues.theme}
              circleSize={40}
              onChangeComplete={(e) => handleTextChange('theme', e.hex)}
            />
          </div>
          <div className={classes.modalButtonsContainer}>
            {renderUsersCancelButton(newUser)}
            <div
              onClick={(e) => usersModalDonePressed(e, newUser)}
              className={classes.modalButton}>
              Done
            </div>
          </div>
        </form>
      </ThemedModal>
    );
  };

  const renderPasswordInput = (newUser) => {
    if (newUser) {
      return (
        <div className={classes.firstLastInputContainer}>
          <TextField
            required
            name="email"
            id="outlined-basic"
            label="Email"
            variant="outlined"
            onChange={(e) => handleTextChange(e.target.name, e.target.value, e)}
            defaultValue={textValues.email ? textValues.email : ''}
            inputProps={{
              style: {
                height: 40,
                padding: '0 14px',
              },
            }}
            InputLabelProps={{
              style: {
                height: 40,
                marginTop: '-5px',
                fontSize: '15px',
                fontFamily: 'Roboto',
              },
            }}
          />
          <TextField
            required
            name="password"
            id="outlined-basic"
            label="Password"
            variant="outlined"
            onChange={(e) => handleTextChange(e.target.name, e.target.value, e)}
            defaultValue={textValues.password ? textValues.password : ''}
            inputProps={{
              style: {
                height: 40,
                padding: '0 14px',
              },
            }}
            InputLabelProps={{
              style: {
                height: 40,
                marginTop: '-5px',
                fontSize: '15px',
                fontFamily: 'Roboto',
              },
            }}
          />
        </div>
      );
    }
  };

  const renderUsersCancelButton = (newUser) => {
    if (!newUser) {
      return (
        <div
          onClick={usersModalDeletePressed}
          className={classes.modalCancelButton}>
          Delete
        </div>
      );
    }
  };

  const usersModalDonePressed = async (e, newUser) => {
    if (e) {
      e.preventDefault();
    }
    //Validate
    const errors = validate(textValues, 'users', newUser);
    if (errors) {
      alert.show(errors.message, {
        title: errors.title,
      });
      return;
    }
    //start spinner
    setloadingLocal(true);
    //Call Action to firebase post
    let result = {error: false};
    if (newUser) {
      result = await addUsersToFb(textValues, clinicBasicInfo.clinicId);
    } else {
      //make new function to edit existing
      await editUserProperties(textValues, clinicBasicInfo.clinicId);
    }
    if (result.error === true) {
      alert.show(result.errorMessage, {title: 'Error'});
    } else {
      alert.show('User successfully updated.', {title: 'Success'});
      //close modal
      closeModal();
    }
    //stop spinner
    setloadingLocal(false);
  };

  const usersModalDeletePressed = async (e) => {
    if (!textValues.isMasterAdmin) {
      //start spinner
      setloadingLocal(true);
      //Call Action to firebase post
      await inactivateUser(textValues, clinicBasicInfo.clinicId);
      //stop spinner
      setloadingLocal(false);
      alert.show('User has been deleted', {
        title: 'Deleted',
      });
    } else {
      alert.show('Cannot delete the master administrator account.', {
        title: 'Cannot Delete',
      });
    }
    //close modal
    closeModal();
  };

  const usersColumns = [
    {
      field: 'settingIcon',
      headerName: ' ',
      width: 40,
      renderCell: renderSettingsIcon,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: rootWidth < 500 ? 110 : 160,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: rootWidth < 500 ? 110 : 300,
    },
  ];

  //******************NPI Numbers Section*********************************
  const renderNpiModal = (newUser) => {
    return (
      <ThemedModal
        style={{height: 'auto'}}
        open={openModalObj.npi}
        onClose={closeModal}
        title={'NPI/Tax Profile'}>
        <form className={classes.formStyle}>
          <div className={classes.inputsContainer}>
            <div className={classes.firstLastInputContainer}>
              <TextField
                required
                name="fullName"
                id="outlined-basic"
                label="Profile Name"
                variant="outlined"
                onChange={(e) =>
                  handleTextChange(e.target.name, e.target.value, e)
                }
                defaultValue={textValues.fullName ? textValues.fullName : ''}
                inputProps={{
                  style: {
                    height: 40,
                    padding: '0 14px',
                  },
                }}
                InputLabelProps={{
                  style: {
                    height: 40,
                    marginTop: '-5px',
                    fontSize: '15px',
                    fontFamily: 'Roboto',
                  },
                }}
              />
              <TextField
                required
                name="npiNumber"
                id="outlined-basic"
                label="NPI Number"
                variant="outlined"
                onChange={(e) =>
                  handleTextChange(e.target.name, e.target.value, e)
                }
                defaultValue={textValues.npiNumber ? textValues.npiNumber : ''}
                inputProps={{
                  style: {
                    height: 40,
                    padding: '0 14px',
                  },
                }}
                InputLabelProps={{
                  style: {
                    height: 40,
                    marginTop: '-5px',
                    fontSize: '15px',
                    fontFamily: 'Roboto',
                  },
                }}
              />
            </div>
            <div className={classes.firstLastInputContainerNPIExtraInfo}>
              <TextField
                // required
                name="doctorFirstName"
                id="outlined-basic"
                label="Doctor's First Name"
                variant="outlined"
                onChange={(e) =>
                  handleTextChange(e.target.name, e.target.value, e)
                }
                defaultValue={
                  textValues.doctorFirstName ? textValues.doctorFirstName : ''
                }
                inputProps={{
                  style: {
                    height: 40,
                    padding: '0 14px',
                  },
                }}
                InputLabelProps={{
                  style: {
                    height: 40,
                    marginTop: '-5px',
                    fontSize: '15px',
                    fontFamily: 'Roboto',
                  },
                }}
              />
              <TextField
                // required
                name="doctorLastName"
                id="outlined-basic"
                label="Doctor's Last Name"
                variant="outlined"
                onChange={(e) =>
                  handleTextChange(e.target.name, e.target.value, e)
                }
                defaultValue={
                  textValues.doctorLastName ? textValues.doctorLastName : ''
                }
                inputProps={{
                  style: {
                    height: 40,
                    padding: '0 14px',
                  },
                }}
                InputLabelProps={{
                  style: {
                    height: 40,
                    marginTop: '-5px',
                    fontSize: '15px',
                    fontFamily: 'Roboto',
                  },
                }}
              />
              <TextField
                // required
                name="taxId"
                id="outlined-basic"
                label="Tax ID"
                variant="outlined"
                onChange={(e) =>
                  handleTextChange(e.target.name, e.target.value, e)
                }
                defaultValue={textValues.taxId ? textValues.taxId : ''}
                inputProps={{
                  style: {
                    height: 40,
                    padding: '0 14px',
                  },
                }}
                InputLabelProps={{
                  style: {
                    height: 40,
                    marginTop: '-5px',
                    fontSize: '15px',
                    fontFamily: 'Roboto',
                  },
                }}
              />
              <TextField
                // required
                name="taxLicense"
                id="outlined-basic"
                label="Tax License"
                variant="outlined"
                onChange={(e) =>
                  handleTextChange(e.target.name, e.target.value, e)
                }
                defaultValue={
                  textValues.taxLicense ? textValues.taxLicense : ''
                }
                inputProps={{
                  style: {
                    height: 40,
                    padding: '0 14px',
                  },
                }}
                InputLabelProps={{
                  style: {
                    height: 40,
                    marginTop: '-5px',
                    fontSize: '15px',
                    fontFamily: 'Roboto',
                  },
                }}
              />
            </div>
          </div>
          <div className={classes.modalButtonsContainer}>
            {renderNpiCancelButton(newUser)}
            <div onClick={npiModalDonePressed} className={classes.modalButton}>
              Done
            </div>
          </div>
        </form>
      </ThemedModal>
    );
  };

  const renderNpiCancelButton = (newUser) => {
    if (!newUser) {
      return (
        <div
          onClick={npiModalDeletePressed}
          className={classes.modalCancelButton}>
          Delete
        </div>
      );
    }
  };

  const npiModalDonePressed = async (e) => {
    if (e) {
      e.preventDefault();
    }
    // Validate
    const errors = validate(textValues, 'npi');
    if (errors) {
      alert.show(errors.message, {
        title: errors.title,
      });
      return;
    }
    //start spinner
    setloadingLocal(true);
    //Call Action to firebase post
    await addNpiToFb(textValues, clinicBasicInfo.clinicId);
    //stop spinner
    setloadingLocal(false);
    //close modal
    closeModal();
  };

  const npiModalDeletePressed = async () => {
    await deleteNpiOnFb(textValues, clinicBasicInfo.clinicId);
    //close modal
    alert.show('NPI has been deleted', {
      title: 'NPI Deleted',
    });
    closeModal();
  };

  const renderTrashIcon = () => {
    return <TrashIcon />;
  };

  const npiNoRowsOverlay = () => {
    return (
      <GridOverlay>
        <div>No NPI Numbers Added.</div>
      </GridOverlay>
    );
  };

  const renderNpiRows = (clinicInfo) => {
    let npiRows = [];
    if (clinicInfo.nationalProviderIds) {
      Object.keys(clinicInfo.nationalProviderIds).forEach((ele) => {
        const eleSpot = clinicInfo.nationalProviderIds[ele];
        npiRows.push({
          id: ele,
          npiNumber: ele,
          name: eleSpot.physician ? eleSpot.physician : '',
          doctorFirstName: eleSpot.doctorFirstName
            ? eleSpot.doctorFirstName
            : '',
          doctorLastName: eleSpot.doctorLastName ? eleSpot.doctorLastName : '',
          taxLicense: eleSpot.taxLicense ? eleSpot.taxLicense : '',
          taxId: eleSpot.taxId ? eleSpot.taxId : '',
        });
      });
    }
    return npiRows;
  };

  const handleNpiRowClick = (data) => {
    console.log(data);
    setTextValues({
      ...textValues,
      npiNumber: data.npiNumber,
      fullName: data.name,
      doctorFirstName: data.doctorFirstName ? data.doctorFirstName : '',
      doctorLastName: data.doctorLastName ? data.doctorLastName : '',
      taxLicense: data.taxLicense ? data.taxLicense : '',
      taxId: data.taxId ? data.taxId : '',
    });
    setOpenModalObj({...openModalObj, npi: true});
  };

  const npiColumns = [
    {
      field: 'trashIcon',
      headerName: ' ',
      flex: 0.5,
      renderCell: renderTrashIcon,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1.3,
    },
    {
      field: 'npiNumber',
      headerName: 'NPI Number',
      flex: 1,
    },
    {
      field: 'taxId',
      headerName: 'Tax ID',
      flex: 1,
    },
  ];

  //******************Clinic Locations Section*********************************
  const renderLocationsModal = () => {
    return (
      <ThemedModal
        style={{height: 'auto'}}
        open={openModalObj.locations}
        onClose={closeModal}
        title={'New Clinic Location'}>
        <form className={classes.formStyle}>
          <div className={classes.inputsContainer}>
            <div className={classes.firstLastInputContainer}>
              <TextField
                required
                name="location"
                id="outlined-basic"
                label="Name"
                variant="outlined"
                onChange={(e) =>
                  handleTextChange(e.target.name, e.target.value, e)
                }
                defaultValue={textValues.location ? textValues.location : ''}
                inputProps={{
                  style: {
                    height: 40,
                    padding: '0 14px',
                  },
                }}
                InputLabelProps={{
                  style: {
                    height: 40,
                    marginTop: '-5px',
                    fontSize: '15px',
                    fontFamily: 'Roboto',
                  },
                }}
              />
            </div>
          </div>
          <div
            onClick={locationsModalDonePressed}
            className={classes.modalButton}>
            Done
          </div>
        </form>
      </ThemedModal>
    );
  };

  const locationsModalDonePressed = async (e) => {
    //Validate
    if (e) {
      e.preventDefault();
    }
    const errors = validate(textValues, 'locations');
    if (errors) {
      alert.show(errors.message, {
        title: errors.title,
      });
      return;
    }
    //start spinner
    setloadingLocal(true);
    //Call Action to firebase post
    await addLocationToFb(textValues, clinicBasicInfo.clinicId);
    //stop spinner
    setloadingLocal(false);
    //close modal
    closeModal();
  };

  //******************Usage History Section*********************************
  const usageNoRowsOverlay = () => {
    return (
      <GridOverlay>
        <div>Usage History Unavailable.</div>
      </GridOverlay>
    );
  };

  const renderusageRows = (clinicUsageObj, indivUserObj) => {
    let usageRows = [];
    if (indivUserObj.admin && clinicUsageObj?.history) {
      Object.keys(clinicUsageObj.history).forEach((ele) => {
        let subscriptionCost = '';
        let apiCallsCost = '$0.00';
        const invoiceMonth = clinicUsageObj.history[ele].month;
        const invoiceYear = clinicUsageObj.history[ele].year;

        const monthYear = `${invoiceMonth}${invoiceYear}`;
        const createdTimeUNIX = clinicBasicInfo.createdTime;
        const date = new Date(createdTimeUNIX);
        const m = date.getMonth() + 1;
        const creationMonth = m.toString();
        const creationYear = date.getFullYear().toString();
        const creationDay = date.getDate().toString();
        const creationMonthYear = `${creationMonth}${creationYear}`;
        // Checking if the curent month is equal to the creation month
        if (monthYear === creationMonthYear) {
          const parsedInvoiceMonth = parseInt(invoiceMonth);
          const parsedInvoiceYear = parseInt(invoiceYear);
          const daysInInvoiceMonth = new Date(
            parsedInvoiceYear,
            parsedInvoiceMonth,
            0,
          ).getDate();

          const totalDays = daysInInvoiceMonth - creationDay;
          const adjustedSubscriptionCost = Math.round(
            (clinicUsageObj.subscriptionPrice / daysInInvoiceMonth) * totalDays,
          );
          subscriptionCost = `${adjustedSubscriptionCost} (Pro-rated)`;
          apiCallsCost =
            clinicUsageObj.apiCallPrice * clinicUsageObj.history[ele].apiCalls;
        } else {
          subscriptionCost = clinicUsageObj.subscriptionPrice;
          apiCallsCost =
            clinicUsageObj.apiCallPrice * clinicUsageObj.history[ele].apiCalls;
        }
        usageRows.push({
          id: `${clinicUsageObj.history[ele].month}${clinicUsageObj.history[ele].year}`,
          month: `${clinicUsageObj.history[ele].month}/${clinicUsageObj.history[ele].year}`,
          apiCalls: clinicUsageObj.history[ele].apiCalls,
          changeApiCalls: clinicUsageObj.history[ele]?.changeOnlyApiCalls
            ? clinicUsageObj.history[ele]?.changeOnlyApiCalls
            : 0,
          apiCallscost: `$${apiCallsCost}`,
          monthlyCost: `$${subscriptionCost}`,
        });
      });
    }
    return usageRows;
  };

  const usageColumns = [
    {
      field: 'month',
      headerName: 'Date',
      flex: 1,
    },
    {
      field: 'changeApiCalls',
      headerName: 'V1',
      flex: 0.8,
    },
    {
      field: 'apiCalls',
      headerName: 'V2',
      flex: 0.8,
    },
    {
      field: 'apiCallscost',
      headerName: 'Usage',
      flex: 1.3,
    },
    {
      field: 'monthlyCost',
      headerName: 'Subscription',
      flex: 1.5,
    },
  ];

  //******************MODAL CONTROLLER*********************************
  const renderAppropriateModal = () => {
    if (openModalObj.users) {
      if (openModalObj.newUser) {
        return renderUsersModal(true);
      }
      return renderUsersModal();
    }
    if (openModalObj.npi) {
      if (openModalObj.newUser) {
        return renderNpiModal(true);
      }
      return renderNpiModal();
    }
    if (openModalObj.locations) {
      return renderLocationsModal();
    }
    if (openModalObj.usage) {
      // return renderUsageModal();
    }
  };

  //******************COMPONENT RETURN*********************************
  return (
    <div className={classes.settingsContainerRoot}>
      <Spinner1 open={loadingLocal} />
      {/* <Navbar /> */}
      <div className={classes.settingsContainer}>
        {renderAppropriateModal()}
        <div className={classes.settingsTitle}>
          <div className={classes.usercontainer}>
            <p className={classes.currentUser}>User: {userInfo.email}</p>
            <p className={classes.currentUser}>
              Clinic: {clinicBasicInfo.clinicName}
            </p>
          </div>
          <div className={classes.settings}>
            <p>Settings</p>
          </div>
          <div className={classes.logoutcontainer}>
            <div>
              <a
                className={classes.contactUsStyle}
                href="mailto: info@123consults.com?subject=Support Question">
                Contact Us
              </a>
            </div>
            <div>
              <p className={classes.logoutStyle} onClick={handleLogoutClick}>
                Logout
              </p>
            </div>
          </div>
        </div>
        <div className={classes.settingsInnerContainer}>
          <div className={classes.leftContainer}>
            <CardContainer
              title="Users"
              iconTopRight={
                <AddIcon
                  onClick={() => {
                    //Check if Administrator
                    if (!isAdmin()) {
                      alert.show(
                        'Only administrator accounts can add new users.',
                        {title: 'Not An Administrator.'},
                      );
                      return;
                    }
                    setOpenModalObj({
                      ...openModalObj,
                      users: true,
                      newUser: true,
                    });
                  }}
                  className={classes.addIcon}
                />
              }>
              <DataGrid
                columns={usersColumns}
                rows={renderUserRows(clinicUsersList, userInfo)}
                className={classes.usersDataGrid}
                disableSelectionOnClick
                hideFooter
                viewportSize={{width: 100}}
                components={{
                  noRowsOverlay: usersNoRowsOverlay,
                }}
                onRowClick={({row}) => {
                  handleUsersRowClick(row);
                }}
              />
            </CardContainer>
            {clinicBasicInfo.hideInsurance === true ? null : (
              <CardContainer
                title="NPI/Tax Profiles"
                iconTopRight={
                  <AddIcon
                    onClick={() =>
                      setOpenModalObj({
                        ...openModalObj,
                        npi: true,
                        newUser: true,
                      })
                    }
                    className={classes.addIcon}
                  />
                }>
                <DataGrid
                  columns={npiColumns}
                  rows={renderNpiRows(clinicBasicInfo)}
                  className={classes.npiDataGrid}
                  disableSelectionOnClick
                  hideFooter
                  components={{
                    noRowsOverlay: npiNoRowsOverlay,
                  }}
                  onRowClick={({row}) => {
                    handleNpiRowClick(row);
                  }}
                />
              </CardContainer>
            )}

            {clinicBasicInfo.hideProduction === true ? null : (
              <ProcedureTemplates />
            )}
            {clinicBasicInfo.hideInsurance === true ? null : (
              <CarrierCredentials
                className={classes.locationsDataGrid}
                isAdmin={isAdmin()}
                addIconClass={classes.addIcon}
              />
            )}
          </div>
          <div className={classes.righContainer}>
            <ClinicLocations
              clinicLocationsList={clinicLocationsList}
              className={classes.locationsDataGrid}
              isAdmin={isAdmin()}
              addIconClass={classes.addIcon}
            />
            <CardContainer title="Usage History">
              <DataGrid
                columns={usageColumns}
                rows={renderusageRows(clinicUsageInfo, userInfo)}
                className={classes.usageDataGrid}
                disableSelectionOnClick
                hideFooter
                components={{
                  noRowsOverlay: usageNoRowsOverlay,
                }}
                onRowClick={(e) => {
                  console.log(true);
                }}
              />
            </CardContainer>
            {clinicBasicInfo.hideProduction === true ? null : (
              <FollowUpSettings />
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-4">
          <EmployerBenefitsModal />
        </div>
      </div>
    </div>
  );
};

const mapStateToProp = (state) => {
  return {
    clinicBasicInfo: state.apiCallsReducer.clinicBasicInfo,
    clinicUsersList: state.apiCallsReducer.clinicUsersList,
    userInfo: state.apiCallsReducer.userInfo,
    clinicPatientsList: state.apiCallsReducer.clinicPatientsList,
    clinicTemplatesList: state.apiCallsReducer.clinicTemplatesList,
    clinicUsageInfo: state.apiCallsReducer.clinicUsageInfo,
    clinicLocationsList: state.apiCallsReducer.clinicLocationsList,
  };
};

export default connect(mapStateToProp, {
  addNpiToFb,
  addLocationToFb,
  addUsersToFb,
  deleteNpiOnFb,
  inactivateUser,
  logoutFunc,
  editUserProperties,
})(Settings);

import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'components/Form/Switch/Switch';
import {getDocument, update} from 'actions/firebaseActions';
import {useDispatch} from 'react-redux';
import {updatePatient} from 'actions/patientActions';

function FollowUpSwitch({
  clinicId,
  label,
  patientId,
  switchCallback,
  showFollowUpSwitch = true,
}) {
  const [followUp, setFollowUp] = React.useState(false);
  const dispatch = useDispatch();

  const handleFollowUpsSwitch = React.useCallback(
    (_name, value) => {
      update(
        `/clinics/${clinicId}/patients/${patientId}`,
        {enabledFollowUps: value},
        () => {
          setFollowUp(value);
          dispatch(updatePatient({patientId, enabledFollowUps: value}));

          if (typeof switchCallback === 'function') {
            switchCallback(value);
          }
        },
      );
    },
    [dispatch, clinicId, patientId, switchCallback],
  );

  React.useEffect(() => {
    if (clinicId && patientId) {
      getDocument(
        `/clinics/${clinicId}/patients/${patientId}/enabledFollowUps`,
        (enabledFollowUps) => {
          const value = enabledFollowUps === false ? enabledFollowUps : true;
          setFollowUp(value);
          if (typeof switchCallback === 'function') {
            switchCallback(value);
          }
        },
      );
    }
  }, [clinicId, patientId, switchCallback]);

  return showFollowUpSwitch ? (
    <>
      {label ? label : <span>Enable Follow-Ups:</span>}
      <Switch value={followUp} onChange={handleFollowUpsSwitch} />
    </>
  ) : null;
}

FollowUpSwitch.propTypes = {
  clinicId: PropTypes.string.isRequired,
  patientId: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export default FollowUpSwitch;

import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getInsurances} from 'actions/searchActions';
import {debounce} from 'lodash';

function useSearch() {
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState('');
  const delay = React.useCallback(
    debounce((value) => setSearch(value), 500),
    [],
  );
  const insurances = useSelector(
    ({apiCallsReducer}) => apiCallsReducer.insurances,
  );

  const insurancesFilter = React.useMemo(() => {
    return insurances.filter((insurance) => {
      if (insurance.searchKey.toLowerCase().includes(search)) {
        return true;
      }
      return false;
    });
  }, [search, insurances]);

  React.useEffect(() => {
    if (!insurances.length) {
      dispatch(getInsurances());
    }
  }, [dispatch, insurances]);

  return {setSearch: delay, data: insurancesFilter};
}

export default useSearch;

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Modal} from '@material-ui/core';
import {Facebook} from 'react-spinners-css';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBodyContainer: {
    display: 'flex',
    width: '70%',
    height: '70%',
    backgroundColor: 'white',
    outline: 0,
    flexDirection: 'column',
  },
  modalHeader: {
    display: 'flex',
    backgroundColor: '#1B7FE1',
    height: '15%',
    width: '100%',
  },
  closeButton: {
    display: 'flex',
    color: 'white',
    position: 'absolute',
    padding: 5,
  },
  titleText: {
    display: 'flex',
    fontSize: 30,
    height: '100%',
    width: '100%',
    color: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  modalBody: {
    display: 'flex',
    flex: 1,
  },
  spinnericon: {
    outline: 'none',
    color: '#1B7FE1',
  },
  spinnercontainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    color: '#1B7FE1',
    textAlign: 'center',
    fontFamily: '"Roboto"',
  },
}));

export const Spinner1 = ({open, label}) => {
  const classes = useStyles();

  return (
    <Modal className={classes.modal} open={open}>
      <div className={classes.spinnercontainer}>
        <Facebook color="#1B7FE1" size={100} />
        <p style={{fontSize: '20px', color: 'white'}}>{label}</p>
      </div>
    </Modal>
  );
};

Spinner1.defaultProps = {
  label: 'Loading ... Please wait',
};

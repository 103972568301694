import React from 'react';
import CardContainer from '../../CardContainer';
import {getDocument, update} from 'actions/firebaseActions';
import {useSelector} from 'react-redux';
import {DataGrid} from '@material-ui/data-grid';
import useStyles from './FollowUpSettingsClasses';
import FollowUpModal from './FollowUpModal';
import SettingsIcon from '@material-ui/icons/Settings';

function FollowUpSettings() {
  const [settings, setSettings] = React.useState({
    id: 1,
    daysToRepeat: 4,
    limit: 7,
    modal: false,
    enabledFollowUps: false,
    fulFilled: false,
  });

  const clinicId = useSelector(
    ({apiCallsReducer}) => apiCallsReducer?.clinicBasicInfo?.clinicId,
  );

  const classes = useStyles();

  React.useEffect(() => {
    (async function () {
      if (clinicId) {
        getDocument(
          `/clinics/${clinicId}/followUpSettings`,
          (requestSettings) => {
            if (requestSettings) {
              const {enabledFollowUps} = requestSettings;
              setSettings((currentSettings) => ({
                ...currentSettings,
                ...requestSettings,
                enabledFollowUps: enabledFollowUps === false ? false : true,
                fulFilled: true,
              }));
            } else {
              setSettings((currentSettings) => ({
                ...currentSettings,
                enabledFollowUps: true,
                fulFilled: true,
              }));
            }
          },
        );
      } else {
        setSettings((currentSettings) => ({
          ...currentSettings,
          enabledFollowUps: true,
          fulFilled: true,
        }));
      }
    })();
  }, [clinicId]);

  const submit = ({modal: _modal, ...model}) => {
    update(`/clinics/${clinicId}/followUpSettings`, model, () => {
      setSettings((currentSettings) => ({
        ...currentSettings,
        ...model,
        modal: false,
      }));
    });
  };

  const columns = [
    {
      field: 'daysToRepeat',
      headerName: 'Days Between',
      width: 150,
    },
    {
      field: 'limit',
      headerName: 'Follow-ups',
      width: 150,
    },
    {
      field: 'enabledFollowUps',
      headerName: 'Follow-up Automation',
      width: 300,
      renderCell: ({value}) => (value === false ? 'Disabled' : 'Enabled'),
    },
  ];

  const toggleModal = React.useCallback((value) => {
    console.log('value', value);
    setSettings((currentSettings) => ({...currentSettings, modal: value}));
  }, []);

  console.log(`settings`, settings);
  return (
    <>
      <CardContainer
        title="Follow-Up Settings"
        iconTopRight={
          <SettingsIcon
            onClick={() => {
              toggleModal(true);
            }}
            className={classes.addIcon}
          />
        }>
        <DataGrid
          columns={columns}
          rows={[settings]}
          disableSelectionOnClick
          hideFooter
          components={{
            noRowsOverlay: () => <div>There's no templates</div>,
          }}
          className={classes.locationsDataGrid}
        />
      </CardContainer>
      {settings.fulFilled && (
        <FollowUpModal
          open={settings.modal}
          submit={submit}
          onClose={toggleModal}
          model={settings}
        />
      )}
    </>
  );
}

FollowUpSettings.propTypes = {};

export default FollowUpSettings;

import React, {useState, useEffect, Fragment} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import CardContainerProfile from '../../CardContainerProfile';
import html2canvas from 'html2canvas';
import LooksOneIcon from '@material-ui/icons/LooksOne';
import LooksTwoIcon from '@material-ui/icons/LooksTwo';
import LooksThreeIcon from '@material-ui/icons/Looks3';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {isTablet, isMobile, isIPad13, isIPod13} from 'react-device-detect';
import {jsPDF} from 'jspdf';
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import {
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Button,
  IconButton,
  TextField,
  Tooltip,
} from '@material-ui/core';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import CheckIcon from '@material-ui/icons/Check';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import {DataGrid, GridOverlay} from '@material-ui/data-grid';
import {Ring} from 'react-spinners-css';
import {connect} from 'react-redux';
import {ThemedModal} from '../../ThemedModal';
import TransitionsModal from '../../Modal/Modal.js';
import {Spinner1} from '../../Spinner1';
import {useAlert} from 'react-alert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
  makeOnederfulCall,
  parseData,
  parseGeneralFilterData,
  uploadToFirebase,
  makeChangeCall,
  sendPDFBenefitsEmail,
  makeThirdVerificationCall,
  getPatientInfo,
} from '../../../actions';
// import data from '../../../data/adaCodeList.json';
import insuranceData from '../../../data/insuranceList.json';
import thirdVerificationData from '../../../data/3rdVerificationList.json';
import DataGrid1 from '../DataGrid1';
import ExtraInfoOptions from '../ExtraInfo/Options/Options';
import ThirdVerificationTableData from '../VerificationThree/VerificationThree';
import useStyles from './ProfileBenefitsStyles';
import styles from './ProfileBenefits.module.scss';

const useStylesBootstrap = makeStyles((theme) => ({
  tooltip: {
    fontSize: 14,
    // backgroundColor: '#DEE8F2',
    // color: 'black'
  },
}));

const ProfileBenefits = (props) => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#1B7FE1',
        contrastText: '#fff',
      },
      secondary: {
        main: '#003B63',
        contrastText: '#fff',
      },
      info: {
        main: '#1BA0E0',
        contrastText: '#fff',
      },
      background: {
        default: '#fff',
      },
      fontFamily: {
        main: '"Roboto"',
      },
    },
  });

  const theme2 = createMuiTheme({
    palette: {
      primary: {
        main: '#707070',
        contrastText: '#fff',
      },
      secondary: {
        main: '#212121',
        contrastText: '#FFFFFF',
      },
    },
  });

  ///*************** BEGINNING STATES ***************/
  const data = useSelector(({apiCallsReducer}) => apiCallsReducer?.adaCodeList);
  const history = useHistory();
  const alert = useAlert();
  const [patientData, setPatientData] = useState({});
  const [patientInsuranceArray, setPatientInsuranceArray] = useState([]);
  const [selectedInsurance, setSelectedInsurance] = useState({});
  const [selectedOnederfulData, setSelectedOnederfulData] = useState({});
  const [rawJSONData, setRawPatientJSONData] = useState({});
  const [rawChangeData, setSelectedChangeData] = useState('');
  const [
    reactFormattedChangeData,
    setSelectedChangeHTMLDataReactFormatted,
  ] = useState('');

  const [pdfIconSpinner, setPdfIconSpinner] = useState(false);
  const [selectedTemplate, setTemplate] = useState({});
  const [searchADACodeText, setSearchAdaCodeText] = useState('');
  const [selectedTab, setSelectedTab] = useState('verification1');
  const [remainingAmounts, setRemainingAmounts] = useState({
    remainingDeductible: {
      amount: '--',
      serviceType: 'Basic',
      coverage: '',
      network: '',
    },
    remainingMaximum: {
      amount: '--',
      serviceType: 'Basic',
      coverage: '',
      network: '',
    },
    remainingOrthoMaximum: {
      amount: false,
      serviceType: 'Orthodontics',
      coverage: '',
      network: '',
    },
    remainingOrthoDeductible: {
      amount: false,
      serviceType: 'Orthodontics',
      coverage: '',
      network: '',
    },
  });
  const [changeAndOnederfulInsuranceData, setInsuranceObjectData] = useState({
    id: '',
    name: '',
    onederfulEligible: true,
    changeEligible: false,
    changeId: '',
    thirdVerification: false,
    features: [],
  });

  // Filtering States
  const [availableFilterObjects, setAvailableFilterObjects] = useState({
    serviceTypeFilter: [],
    coverageLevelFilter: [],
    networkFilter: [],
    planPeriodFilter: [],
  });

  const [generalSearchFilter, setGeneralSearchFilter] = useState({
    service_type: '',
    network: '',
    coverage_level: '',
    plan_period: '',
  });
  const [adaCodeListForSearch, setADACodeListForSearch] = useState([]);
  //const [isReady, setIsPDFReady] = useState(false);

  // End Filtering States
  const [selectedNPINumber, setSelectedNPI] = useState('');
  const [NPIListArray, setNPIListArray] = useState([]);
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [rootWidth, setRootWidth] = useState(0);
  // const [rootHeight, setRootHeight] = useState(0);
  const [openNPIModal, setOpenNPIModal] = useState(false);
  const [numberOfAPICalls, setNumberOfAPICalls] = useState(0);
  const [timeSaved, setTimeSaved] = useState(0);
  const [
    thirdVerificationPDFLinks,
    setSelecteThirdVerificationPDFlinks,
  ] = useState([]);

  const [allServiceTypeData, setAllServiceTypeData] = useState([]);

  ///*************** BEGINNING STATES ***************/

  const classes = useStyles();
  const {patientId} = useParams();
  useEffect(() => {
    setRootWidth(document.getElementById('root').clientWidth);
    setADACodeListForSearch1();
    console.log('patientId', patientId);
    if (props.currentPatient) {
      // const patientData1 = location.state.patientData1;
      setInitialValues(props.currentPatient);
      // determineNPIArray();
    } else if (patientId) {
      getPatient();
    } else {
      history.push('/section/insurance');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, props.currentPatient, props.userInfo]);

  useEffect(() => {
    if (props.currentPatient || patientId) {
      getCurrentUsage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clinicUsageInfo]);

  const getPatient = async () => {
    console.log('calling get paitentinfo');
    const patientInfo = await props.getPatientInfo(patientId);
    if (patientInfo) {
      console.log('got paatient info');
    } else if (patientInfo === 'loading') {
      console.log('loading');
    } else {
      history.push('/section/insurance');
    }
  };
  const getCurrentUsage = () => {
    const d = new Date();
    const month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
    const year = d.getFullYear();
    const monthYear = `${month}${year}`;

    let usageHistory1 = 0;
    if (
      props.clinicUsageInfo.history &&
      props.clinicUsageInfo.history[monthYear]
    ) {
      usageHistory1 = props.clinicUsageInfo.history[monthYear].apiCalls
        ? props.clinicUsageInfo.history[monthYear].apiCalls
        : 0;

      if (props.clinicUsageInfo.history[monthYear].changeOnlyApiCalls) {
        usageHistory1 =
          usageHistory1 +
          props.clinicUsageInfo.history[monthYear].changeOnlyApiCalls;
      }
    }
    // Based on 15 minutes time saved
    const timeSaved1 = (usageHistory1 * 15) / 60;
    setNumberOfAPICalls(usageHistory1);
    setTimeSaved(timeSaved1);
  };

  const setADACodeListForSearch1 = () => {
    // console.log('CALCULATINGGGGG');
    const keys = Object.keys(data);
    const values1 = Object.values(data);
    const result = keys.map((obj, index) => {
      return {
        code: keys[index],
        description: values1[index],
      };
    });
    setADACodeListForSearch(result);
  };

  //************ Handle change of Tabs */

  const handleVerificationTab1 = () => {
    setSelectedTab('verification1');
  };

  const handleVerificationTab2 = () => {
    setSelectedTab('verification2');
  };

  const handleVerificationTab3 = () => {
    setSelectedTab('verification3');
  };

  const handleExtraInfo = () => {
    setSelectedTab('extrainfo');
  };

  //**************** Handle Change Insurance Template **********************/

  const handleChangeTemplate = (event, index) => {
    let selectedTemplateObject = {};
    if (event.target.value === 'all') {
      selectedTemplateObject = 'all';
    } else {
      props.clinicTemplatesList.forEach((object) => {
        if (object.name === event.target.value) {
          selectedTemplateObject = object;
        }
      });
    }
    setTemplateAndParse(selectedTemplateObject, patientData, selectedInsurance);
  };

  const handleSearchADACodeTextChange = (value) => {
    if (typeof value !== 'object') {
      setSearchAdaCodeText(value);
    }
  };
  const handleADACodeClick = async (e) => {
    if (e) {
      e.preventDefault();
      // const patientData1 = rawJSONData;
      const code = searchADACodeText.substring(0, 5);

      const parsedADAData = await props.parseData(
        {[code]: true},
        rawJSONData,
        selectedInsurance.insurance,
      );
      setSelectedOnederfulData(parsedADAData);
      setTemplate({name: 'all'});
    }
  };
  const handleADACodeClearClick = async (e) => {
    if (e) {
      e.preventDefault();
      // const patientData1 = rawJSONData;
      const parsedADAData = await props.parseData(
        'all',
        rawJSONData,
        selectedInsurance.insurance,
      );
      setSearchAdaCodeText('');
      setSelectedOnederfulData(parsedADAData);
      setTemplate({name: 'all'});
    }
  };

  const setChangeHealthCareData = (patientData1, insuranceId) => {
    const usageHistory = patientData1.usageHistory[insuranceId];
    let values = Object.values(usageHistory);
    // To sort times from newest to oldest
    values.sort().reverse();
    let patientJSONData = values[0];
    // Below loop is to set the latest successful call as the patient data
    for (let j = 0; j < values.length; j++) {
      if (
        // values[j].changeError !== true &&
        // Need to also make sure one of the below exists so that means Change data is there
        values[j].patientChangeDentalHTML &&
        values[j].patientChangeDentalHTML !== ''
      ) {
        patientJSONData = values[j];
        // We break so that it doesnt replace with older checks
        break;
      }
    }
    setSelectedChangeData(patientJSONData);
    const htmlInput = patientJSONData.patientChangeDentalHTML;
    const HtmlToReactParser = require('html-to-react').Parser;
    const htmlToReactParser = new HtmlToReactParser();
    const reactElement = htmlToReactParser.parse(htmlInput);
    setSelectedChangeHTMLDataReactFormatted(reactElement);
  };

  const setThirdVerificationData = (patientData1, insuranceId) => {
    const usageHistory = patientData1.usageHistory[insuranceId];
    let values = Object.values(usageHistory);
    // To sort times from newest to oldest
    values.sort().reverse();
    let patientJSONData = values[0];
    // Below loop is to set the latest successful call as the patient data
    for (let j = 0; j < values.length; j++) {
      if (values[j].thirdVerificationError === false) {
        patientJSONData = values[j];
        // We break so that it doesnt replace with older checks
        break;
      }
    }

    const pdfLinks = patientJSONData.thirdVerificationPDF
      ? patientJSONData.thirdVerificationPDF
      : [];
    console.log('pdfLinks', pdfLinks);
    setSelecteThirdVerificationPDFlinks(pdfLinks);
  };

  const setTemplateAndParse = async (template, patientData1, insurance) => {
    const insuranceId = insurance.id;
    // To check if data exists
    if (
      // template.codes &&
      patientData1?.usageHistory &&
      patientData1.usageHistory[insuranceId]
    ) {
      setChangeHealthCareData(patientData1, insuranceId);
      setThirdVerificationData(patientData1, insuranceId);
      const usageHistory = patientData1.usageHistory[insuranceId];
      let values = Object.values(usageHistory);
      // To sort times from newest to oldest
      values.sort().reverse();
      // Below is to set the latest successful call as the patient data
      let patientJSONData = values[0];
      for (let j = 0; j < values.length; j++) {
        if (
          values[j].error !== true &&
          // Need to also make sure one of the below exists so that means Onederful data is in the object
          (values[j].coinsurance ||
            values[j].deductible ||
            values[j].limitations ||
            values[j].maximums)
        ) {
          patientJSONData = values[j];
          // We break so that it doesnt replace with older checks
          break;
        }
      }
      // Below is to go to the previous JSON if the current history call has error: true
      // Checking if there's an error in first JSON history, if so go to next

      if (!patientJSONData || patientJSONData.error === true) {
        if (template === 'all') {
          setTemplate({name: template});
        } else {
          setTemplate(template);
        }
        return;
      }
      // console.log('Raw Selected JSON Data', patientJSONData);
      setRawPatientJSONData(patientJSONData);
      await getRemainingBenefits(patientJSONData, insurance.insurance);
      makeArrayOfServiceTypes(patientJSONData);

      // Checking if third in history has error
      // template will be 'all' or actually pass in the object of codes to parse
      const template1 = template === 'all' ? 'all' : template.codes;
      const parsedADAData = await props.parseData(
        template1,
        patientJSONData,
        insurance.insurance,
      );

      // console.log('parsedADAData', parsedADAData);
      setSelectedOnederfulData(parsedADAData);
    } else {
      setSelectedOnederfulData({});
      await getRemainingBenefits({}, insurance.insurance);
      makeArrayOfServiceTypes({});
    }
    if (template === 'all') {
      setTemplate({name: template});
    } else {
      setTemplate(template);
    }
  };

  // **************************** Handle Service Types *************************///
  const makeArrayOfServiceTypes = async (patientJSONData) => {
    let availableServiceTypesObject = {};
    let availableCoverageLevelObject = {};
    let availableNetworkObject = {};
    let availablePlanPeriodObject = {};

    // we will add service_type: true into the object. This will basically just replace anything that already exists
    const mapOfType = ['deductible', 'maximums', 'limitations', 'coinsurance'];
    mapOfType.forEach((ele, index) => {
      // this if is to check if for example deductible or limitations, etc. exists in the JSONData
      if (patientJSONData[ele]) {
        // Now we need to loop through every data point in the deductible array
        patientJSONData[ele].forEach((ele1) => {
          if (ele1.service_type && ele1.service_type !== 'undefined') {
            availableServiceTypesObject[ele1.service_type] = true;
          }
          if (ele1.coverage_level && ele1.coverage_level !== 'undefined') {
            availableCoverageLevelObject[ele1.coverage_level] = true;
          }
          if (ele1.network && ele1.network !== 'undefined') {
            availableNetworkObject[ele1.network] = true;
          }
          if (ele1.plan_period && ele1.plan_period !== 'undefined') {
            availablePlanPeriodObject[ele1.plan_period] = true;
          }
          return;
        });
      }
    });

    // Below are to only pass back values when something is found
    let availableServiceTypesArray1 = availableServiceTypesObject
      ? Object.keys(availableServiceTypesObject)
      : [];
    availableServiceTypesArray1.sort();
    let availableCoverageLevelArray1 = availableCoverageLevelObject
      ? Object.keys(availableCoverageLevelObject)
      : [];
    availableCoverageLevelArray1.sort();
    let availableNetworkArray1 = availableNetworkObject
      ? Object.keys(availableNetworkObject)
      : [];
    availableNetworkArray1.sort();
    let availablePlanPeriodArray1 = availablePlanPeriodObject
      ? Object.keys(availablePlanPeriodObject)
      : [];
    availableNetworkArray1.sort();

    // handleGeneralFilterChange('service_type', 'all');
    const newGeneralSearchFilter = {
      ...generalSearchFilter,
      service_type: 'all',
    };
    const allServiceType = await props.parseGeneralFilterData(
      newGeneralSearchFilter,
      patientJSONData,
    );
    setAllServiceTypeData(allServiceType);
    setAvailableFilterObjects({
      serviceTypeFilter: availableServiceTypesArray1,
      coverageLevelFilter: availableCoverageLevelArray1,
      networkFilter: availableNetworkArray1,
      planPeriodFilter: availablePlanPeriodArray1,
    });
  };

  // Below is only needed if we add filtering for service types
  const handleGeneralFilterChange = (name, value) => {
    const newFilter = {...generalSearchFilter, [name]: value};
    setGeneralSearchFilter(newFilter);
  };

  const getRemainingBenefits = async (patientJSONData, insuranceName) => {
    let serviceType = 'service_type';

    // For principal the service_type is called coverage
    // if (selectedInsurance.insurance.toLowerCase() === 'principal') {

    // }
    let generalServiceType = 'dental';
    if (insuranceName === 'METLIFE') {
      generalServiceType = 'basic';
    } else if (insuranceName === 'PRINCIPAL') {
      serviceType = 'category';
      generalServiceType = 'basic_and_major';
    } else if (
      insuranceName === 'DD_CALIFORNIA' ||
      insuranceName === 'DD_GEORGIA' ||
      insuranceName === 'DD_PENNSYLVANIA' ||
      insuranceName === 'GUARDIAN'
    ) {
      generalServiceType = 'dental_care';
    } else if (
      insuranceName === 'UNITED_CONCORDIA' ||
      insuranceName === 'UNITED_HEALTHCARE' ||
      insuranceName === 'CIGNA'
    ) {
      generalServiceType = 'health_benefit_plan_coverage';
    }
    // This is for GENERAL (i.e. dental or healtj_benefit plan coverage,  etv.)
    const newGeneralSearchFilter = {
      [serviceType]: generalServiceType,
      plan_period: 'remaining',
    };

    const onlyDataRemaining = await props.parseGeneralFilterData(
      newGeneralSearchFilter,
      patientJSONData,
    );

    let remainingDeductible = false;
    let remainingMaximum = false;
    let remainingOrthoDeductible = false;
    let remainingOrthoMaximum = false;

    /// REMAINING DEDUCTIBLE
    if (
      onlyDataRemaining.deductible &&
      onlyDataRemaining.deductible.length === 0
    ) {
      // setRemainingDeductible('N/A');
      remainingDeductible = {
        amount: '--',
        coverage: '',
        network: '',
        serviceType: '',
      };
    } else if (
      onlyDataRemaining.deductible &&
      onlyDataRemaining.deductible[0]
    ) {
      remainingDeductible = {
        amount: `$${onlyDataRemaining.deductible[0].amount}`,
        coverage: onlyDataRemaining.deductible[0].coverage_level,
        network: onlyDataRemaining.deductible[0].network,
        serviceType: 'Basic',
        // serviceType: onlyDataRemaining.maximums[0][serviceType],
      };
    }
    // REMAINING MAXIMUMMMMM
    if (onlyDataRemaining.maximums && onlyDataRemaining.maximums.length === 0) {
      // setRemainingDeductible('N/A');
      remainingMaximum = {
        amount: '--',
        coverage: '',
        network: '',
        serviceType: '',
      };
    } else if (onlyDataRemaining.maximums && onlyDataRemaining.maximums[0]) {
      remainingMaximum = {
        amount: `$${onlyDataRemaining.maximums[0].amount}`,
        coverage: onlyDataRemaining.maximums[0].coverage_level,
        network: onlyDataRemaining.maximums[0].network,
        serviceType: 'Basic',
        // serviceType: onlyDataRemaining.maximums[0][serviceType],
      };

      // setRemainingAmounts({
      //   remainingM
      // })
    }

    // SAME as above but done for orthodontics
    const newOrthoFilter = {
      [serviceType]: 'orthodontics',
      plan_period: 'lifetime_remaining',
    };

    const onlyOrthoData = await props.parseGeneralFilterData(
      newOrthoFilter,
      patientJSONData,
    );
    if (onlyOrthoData.deductible && onlyOrthoData.deductible.length === 0) {
      // setRemainingDeductible('N/A');
      remainingOrthoDeductible = {
        amount: false,
        coverage: '',
        network: '',
        serviceType: '',
      };
    } else if (onlyOrthoData.deductible && onlyOrthoData.deductible[0]) {
      remainingOrthoDeductible = {
        amount: `$${onlyOrthoData.deductible[0].amount}`,
        coverage: onlyOrthoData.deductible[0].coverage_level,
        network: onlyOrthoData.deductible[0].network,
        serviceType: 'Orthodontics',
        // serviceType: onlyOrthoData.maximums[0][serviceType],
      };
    }
    // REMAINING MAXIMUMMMMM
    if (onlyOrthoData.maximums && onlyOrthoData.maximums.length === 0) {
      // setRemainingDeductible('N/A');
      remainingOrthoMaximum = {
        amount: false,
        coverage: '',
        network: '',
        serviceType: '',
      };
    } else if (onlyOrthoData.maximums && onlyOrthoData.maximums[0]) {
      remainingOrthoMaximum = {
        amount: `$${onlyOrthoData.maximums[0].amount}`,
        coverage: onlyOrthoData.maximums[0].coverage_level,
        network: onlyOrthoData.maximums[0].network,
        serviceType: 'Orthodontics',
        // serviceType: onlyOrthoData.maximums[0][serviceType],
      };
    }
    let newObject = {};
    if (remainingMaximum) {
      newObject.remainingMaximum = remainingMaximum;
    }
    if (remainingDeductible) {
      newObject.remainingDeductible = remainingDeductible;
    }
    if (remainingOrthoDeductible) {
      newObject.remainingOrthoDeductible = remainingOrthoDeductible;
    }
    if (remainingOrthoMaximum) {
      newObject.remainingOrthoMaximum = remainingOrthoMaximum;
    }
    if (
      newObject.remainingMaximum ||
      newObject.remainingDeductible ||
      newObject.remainingOrthoDeductible ||
      newObject.remainingOrthoMaximum
    ) {
      setRemainingAmounts({...remainingAmounts, ...newObject});
    } else {
      setRemainingAmounts({
        remainingDeductible: {
          amount: '--',
          serviceType: 'Basic',
          coverage: '',
          network: '',
        },
        remainingMaximum: {
          amount: '--',
          serviceType: 'Basic',
          coverage: '',
          network: '',
        },
        remainingOrthoMaximum: {
          amount: false,
          serviceType: 'Orthodontics',
          coverage: '',
          network: '',
        },
        remainingOrthoDeductible: {
          amount: false,
          serviceType: 'Orthodontics',
          coverage: '',
          network: '',
        },
      });
    }
    return;
  };

  const capitalizeFirstLetterAndRemoveUnderscore = (word) => {
    if (word && word.length > 1) {
      let capitalized = word.replace(/\b(\w)/g, (s) => s.toUpperCase());
      capitalized = capitalized.replace(/_/g, ' ');
      return capitalized;
    } else if (!word || word === '') {
      return '--';
    }
    return word;
  };

  const changeDOBFormatting = (DOB) => {
    const DOBArray = DOB.split('-');
    // DOBArray = [yyyy, mm, dd]
    const DOB1 = `${DOBArray[1]}/${DOBArray[2]}/${DOBArray[0]}`;
    return DOB1;
  };

  const changePhoneNumberFormatting = (phone) => {
    const newPhone =
      phone.slice(0, 3) + '-' + phone.slice(3, 6) + '-' + phone.slice(6);
    return newPhone;
  };

  const handleClickRunGeneralFilterReport = async (e, clear) => {
    // call parseData but for service type filter
    if (e) {
      e.preventDefault();
      if (clear && clear === 'clear') {
        let search = {
          service_type: '',
          network: '',
          coverage_level: '',
          plan_period: '',
        };
        setGeneralSearchFilter(search);
        search.service_type = 'all';
        const generalFilteredData = await props.parseGeneralFilterData(
          search,
          rawJSONData,
        );
        setAllServiceTypeData(generalFilteredData);
      } else {
        const generalFilteredData = await props.parseGeneralFilterData(
          generalSearchFilter,
          rawJSONData,
        );
        setAllServiceTypeData(generalFilteredData);
      }
    }
  };

  const makePDF = async (sendType) => {
    // setLoadingLocal(true);
    setPdfIconSpinner(true);
    window.scrollTo(0, 0);
    var quotes = document.getElementById('pdfPrint');
    await html2canvas(quotes).then(async (canvas) => {
      var contentWidth = canvas.width;
      var contentHeight = canvas.height;

      //The height of the canvas which one pdf page can show;
      var pageHeight = (contentWidth / 592.28) * 841.89;
      //the height of canvas that haven't render to pdf
      var leftHeight = contentHeight;
      //addImage y-axial offset
      var position = 0;
      //a4 format [595.28,841.89]
      var imgWidth = 595.28;
      var imgHeight = (592.28 / contentWidth) * contentHeight;

      var pageData = canvas.toDataURL('image/jpeg', 1.0);

      var pdf = new jsPDF('', 'pt', 'a4');

      if (leftHeight < pageHeight) {
        pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
      } else {
        while (leftHeight > 0) {
          pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
          leftHeight -= pageHeight;
          position -= 841.89;
          //avoid blank page
          if (leftHeight > 0) {
            pdf.addPage();
          }
        }
      }

      const fileName = `${patientData?.personalInfo?.lastName} ${patientData?.personalInfo?.firstName}`;

      if (sendType === 'email') {
        var pdfBlob = pdf.output('blob');
        const url = await props.uploadToFirebase(
          pdfBlob,
          props.clinicBasicInfo,
          patientData,
        );
        if (url) {
          // const emailBody = `The insurance benefits PDF is ready for the following patient:%0D%0A%0D%0A${emailPatient}%0D%0A%0D%0AClick the link below to download the PDF:%0D%0A ${modifiedURL}`;
          const emailBody = `The insurance benefits PDF is ready for the following patient: <br><br>First Name: ${patientData?.personalInfo?.firstName}<br>Last Name: ${patientData?.personalInfo?.lastName} <br><br>Click the link below to download the PDF:<br>${url}`;

          const subject = `123 Insurance Verification: ${fileName}`;
          const sendEmail = await props.sendPDFBenefitsEmail(
            props.clinicBasicInfo.email,
            emailBody,
            subject,
          );

          if (sendEmail.error !== true) {
            alert.show(
              `A PDF was successfully emailed to the admin mailbox: ${props.clinicBasicInfo.email}`,
              {
                title: 'Success',
              },
            );
          } else {
            alert.show(
              'There was an error emailing the PDF. Please download and send separately.',
              {title: 'Error'},
            );
          }
        } else {
          alert.show('There was an error creating the PDF', {title: 'Error'});
        }
      } else {
        pdf.save(`${fileName}.pdf`);
      }
      setPdfIconSpinner(false);
      // setLoadingLocal(false);
    });
  };

  // ******************* Setting Initial Values *****************************//
  const setInitialValues = (patientData1) => {
    setPatientData(patientData1);
    // to provide array of various insurances the patient has on local state
    let primaryInsurance = {};
    let insuranceArray = patientData1.insurance
      ? Object.values(patientData1.insurance)
      : [];
    const insuranceArrayKeys = patientData1.insurance
      ? Object.keys(patientData1.insurance)
      : [];
    // console.log('insuranceArray', insuranceArray);
    // // forEach loop below is to check for the primary insurance and set it as selected as default
    insuranceArray.forEach((object, index1) => {
      insuranceArray[index1].id = insuranceArrayKeys[index1];
      // console.log('insurance obj', object);
      if (object.primaryInsurance === true) {
        primaryInsurance = object;
      }
      // console.log('primary insurance', primaryInsurance);
    });
    setPatientInsuranceArray(insuranceArray);

    setInsuranceAndAvailabilityOfOnederfulOrChange(
      'all',
      patientData1,
      primaryInsurance,
    );
  };

  const setInsuranceAndAvailabilityOfOnederfulOrChange = (
    all,
    patientData1,
    insurance,
  ) => {
    setSelectedInsurance(insurance);
    setTemplateAndParse('all', patientData1, insurance);
    if (insurance) {
      insuranceData.forEach((object, index) => {
        if (insurance.insurance === object.id) {
          console.log('setInsuranceObjectData', object);
          setInsuranceObjectData(object);
        }
      });
    }
  };
  const handleChangeInsurance = (value) => {
    const insurance = patientData.insurance[value];
    setInsuranceAndAvailabilityOfOnederfulOrChange(
      'all',
      patientData,
      insurance,
    );
  };

  const onCallOnederulPress = async (UNIX, noUpdateOfUsage) => {
    // const npiNumber = '1851549562';
    const npiNumber = selectedNPINumber.code;
    const {memberId, insurance, groupNumber, id, dependent} = selectedInsurance;
    const insuranceId = id;
    const {userInfo, clinicBasicInfo} = props;
    const result = await props.makeOnederfulCall(
      patientData,
      npiNumber,
      userInfo,
      clinicBasicInfo,
      insurance,
      memberId,
      groupNumber,
      insuranceId,
      dependent,
      UNIX,
      noUpdateOfUsage,
      selectedInsurance,
    );
    // let patientData2 = patientData;
    if (result.error) {
      // display error message.
      const errorMessage = result.errorMessage.replace(/_/g, ' ');
      alert.show(errorMessage, {title: 'Verification 2 Error'});
      console.log(errorMessage);
    } else {
      // only in case of successful call do we want to update local state with successful info
      // ^ const = [AD:2101, AD:12122]
      const parsedData = await props.parseData(
        'all',
        result.patientJSONData,
        insurance,
      );
      // NEEEED TO SET Selected Tempalte to 'ALL'
      setTemplate({name: 'all'});
      // Set search text to empty
      setSearchAdaCodeText('');
      await getRemainingBenefits(
        result.patientJSONData,
        selectedInsurance.insurance,
      );
      await makeArrayOfServiceTypes(result.patientJSONData);
      setRawPatientJSONData(result.patientJSONData);
      setSelectedOnederfulData(parsedData);
      alert.show(
        'Verification 2 check has been performed and patient insurance data has been updated.',
        {title: 'Verification 2 Check Success!'},
      );
    }
    // console.log('result.patientData', result.patientData);
    setPatientData(result.patientData);
    setOpenNPIModal(false);
    // Need to call function to nicely return data to be rendered
    setLoadingLocal(false);
    const OnederfulSuccess = result.error ? false : true;
    return OnederfulSuccess;
  };

  const onCallChangePress = async (UNIX, noUpdateOfUsage, OnederfulSuccess) => {
    // const npiNumber = '1851549562';
    const npiNumber = selectedNPINumber.code;
    const taxId = selectedNPINumber.taxId ? selectedNPINumber.taxId : '';
    const taxLicense = selectedNPINumber.taxLicense
      ? selectedNPINumber.taxLicense
      : '';
    const doctorFirstName = selectedNPINumber.doctorFirstName
      ? selectedNPINumber.doctorFirstName
      : '';
    const doctorLastName = selectedNPINumber.doctorLastName
      ? selectedNPINumber.doctorLastName
      : '';

    const {memberId, insurance, groupNumber, id, dependent} = selectedInsurance;
    const insuranceId = id;
    const {userInfo, clinicBasicInfo} = props;
    const result = await props.makeChangeCall(
      patientData,
      npiNumber,
      userInfo,
      clinicBasicInfo,
      insurance,
      memberId,
      groupNumber,
      insuranceId,
      dependent,
      UNIX,
      noUpdateOfUsage,
      changeAndOnederfulInsuranceData.changeId,
      OnederfulSuccess,
      selectedInsurance,
      taxId,
      taxLicense,
      doctorFirstName,
      doctorLastName,
    );
    // console.log('Change Result', result);

    // let patientData2 = patientData;
    if (result.changeError) {
      // display error message.
      const errorMessage = result.changeErrorMessage;
      alert.show(errorMessage, {title: 'Verification 1 Error'});
    } else {
      alert.show(
        'Verification 1 insurance check has been performed and patient insurance data has been updated.',
        {title: 'Verification 1 Check Success!'},
      );
    }
    if (result.patientChangeDentalHTML) {
      setSelectedChangeData(result);
      const htmlInput = result.patientChangeDentalHTML;
      const HtmlToReactParser = require('html-to-react').Parser;
      const htmlToReactParser = new HtmlToReactParser();
      const reactElement = htmlToReactParser.parse(htmlInput);
      setSelectedChangeHTMLDataReactFormatted(reactElement);
    }
    setPatientData(result.patientData);
    setOpenNPIModal(false);
    // Need to call function to nicely return data to be rendered
    setLoadingLocal(false);
    return;
  };

  const onVerification3Press = async (
    UNIX,
    noUpdateOfUsage,
    OnederfulSuccess,
  ) => {
    // const npiNumber = '1851549562';
    const npiNumber = selectedNPINumber.code;
    const taxId = selectedNPINumber.taxId ? selectedNPINumber.taxId : '';
    const taxLicense = selectedNPINumber.taxLicense
      ? selectedNPINumber.taxLicense
      : '';
    const doctorFirstName = selectedNPINumber.doctorFirstName
      ? selectedNPINumber.doctorFirstName
      : '';
    const doctorLastName = selectedNPINumber.doctorLastName
      ? selectedNPINumber.doctorLastName
      : '';

    const {memberId, insurance, groupNumber, id, dependent} = selectedInsurance;
    const insuranceId = id;
    const {userInfo, clinicBasicInfo} = props;
    const thirdCarrierKey = changeAndOnederfulInsuranceData.thirdVerification;
    const result = await props.makeThirdVerificationCall(
      patientData,
      npiNumber,
      userInfo,
      clinicBasicInfo,
      insurance,
      memberId,
      groupNumber,
      insuranceId,
      dependent,
      UNIX,
      noUpdateOfUsage,
      changeAndOnederfulInsuranceData.changeId,
      OnederfulSuccess,
      selectedInsurance,
      taxId,
      taxLicense,
      doctorFirstName,
      doctorLastName,
      changeAndOnederfulInsuranceData.thirdVerification,
      thirdVerificationData[thirdCarrierKey]?.APIcode,
    );

    if (result.thirdVerificationError) {
      // display error message.
      const errorMessage = result.thirdVerificationErrorMessage;
      alert.show(errorMessage, {title: 'Verification 3 Error'});
    } else {
      alert.show(
        'Carrier Verification 3 insurance check is in progress, please wait several minutes as we connect with the carrier. An alert will be displayed when completed.',
        {title: 'Verification 3 Check In Progress.'},
      );
    }

    setPatientData(result.patientData);
    setOpenNPIModal(false);
    // Need to call function to nicely return data to be rendered
    // setLoadingLocal(false);
    return;
  };
  //************ Render Button for the NPI Modal */

  const handleClickSubmitNPIModal = async (e, userSelectedAPI) => {
    if (e) {
      e.preventDefault();
      // console.log('selectedNPINumber', selectedNPINumber);
      if (
        !selectedNPINumber ||
        !selectedNPINumber.code ||
        selectedNPINumber.code === ''
      ) {
        // Checking if NPI number has been selected
        alert.show('Please select a NPI number', {title: 'NPI Required'});
      } else if (
        // Checking if the acccount is active
        props.clinicBasicInfo.active === false ||
        props.clinicBasicInfo.active === 'false'
      ) {
        alert.show(
          'Your account is not active. Please contact customer support by emailing info@123consults.com for activation.',
          {title: 'Account Inactive'},
        );
      } else if (
        // This is to check that the susbcriber information is entered if patient is a dependent
        selectedInsurance.dependent === true &&
        !selectedInsurance.subscriberFirstName &&
        selectedInsurance.subscriberFirstName !== ''
      ) {
        alert.show(
          'Please hit the "Edit Details" button under patient data to add the first, last and DOB of the subscriber.',
          {title: 'Subscriber Info Required'},
        );
      } else {
        const UNIX = new Date().getTime();
        if (userSelectedAPI === 'onederful') {
          handleVerificationTab2();
          setLoadingLocal(true);
          await onCallOnederulPress(UNIX);
        } else if (userSelectedAPI === 'change') {
          handleVerificationTab1();
          setLoadingLocal(true);
          await onCallChangePress(UNIX);
        } else if (userSelectedAPI === 'thirdVerification') {
          setLoadingLocal(true);
          await onVerification3Press(UNIX);
          setLoadingLocal(false);
        } else {
          setLoadingLocal(true);
          const noUpdateOfUsage = true;
          const OnederfulSuccess = await onCallOnederulPress(
            UNIX,
            noUpdateOfUsage,
          );
          setLoadingLocal(false);
          if (!OnederfulSuccess) {
            // This is to set active tab to the second tab if Onederful call was a failure
            handleVerificationTab1();
          } else {
            handleVerificationTab2();
          }
          onCallChangePress(UNIX, noUpdateOfUsage, OnederfulSuccess);
          onVerification3Press(UNIX, noUpdateOfUsage, OnederfulSuccess);
        }
      }
    }
  };

  //************ Verification History Columns styling */
  const historyColumnsWidth1 = () => {
    return 110;
    // if (rootWidth > 1020 && rootWidth < 1100) {
    //   return 100;
    // } else if (rootWidth > 850 && rootWidth < 1020) {
    //   return 250;
    // } else if (rootWidth > 700 && rootWidth < 850) {
    //   return 180;
    // } else if (rootWidth > 500 && rootWidth < 700) {
    //   return 200;
    // } else if (rootWidth < 500) {
    //   return rootWidth / 3;
    // } else {
    //   return rootWidth / 12;
    // }
  };

  const historyColumnsWidth2 = () => {
    if (rootWidth > 1020 && rootWidth < 1100) {
      return 300;
    } else if (rootWidth > 850 && rootWidth < 1020) {
      return 300;
    } else if (rootWidth > 700 && rootWidth < 850) {
      return 250;
    } else if (rootWidth > 500 && rootWidth < 700) {
      return 250;
    } else if (rootWidth < 500) {
      return rootWidth / 4;
    } else {
      return rootWidth / 10;
    }
  };

  const historyColumnsWidth3 = () => {
    return 30;
    // if (rootWidth > 900 && rootWidth < 1100) {
    //   return 30;
    // } else if (rootWidth > 850 && rootWidth < 900) {
    //   return 40;
    // } else if (rootWidth > 700 && rootWidth < 850) {
    //   return 40;
    // } else if (rootWidth > 500 && rootWidth < 700) {
    //   return 40;
    // } else if (rootWidth < 500) {
    //   return 20;
    // } else {
    //   return 45;
    // }
  };

  const historyColumnsWidth4 = () => {
    if (rootWidth > 900 && rootWidth < 1100) {
      return 30;
    } else if (rootWidth > 850 && rootWidth < 900) {
      return 40;
    } else if (rootWidth > 700 && rootWidth < 850) {
      return 40;
    } else if (rootWidth > 500 && rootWidth < 700) {
      return 40;
    } else if (rootWidth < 500) {
      return 45;
    } else {
      return 30;
    }
  };

  const renderStatusCircle1 = (params) => {
    const data = params.data || params.row;
    return (
      <BootstrapTooltip
        placement="bottom-start"
        title={data ? data.changeErrorMessage : ''}
        interactive>
        <LooksOneIcon
          style={{
            width: 20,
            color: data.changeWasSuccessful ? '#003B63' : '#707070',
          }}
        />
      </BootstrapTooltip>
    );
  };

  const renderStatusCircle2 = (params) => {
    const data = params.data || params.row;
    return (
      <BootstrapTooltip
        placement="bottom-start"
        title={data.errorMessage ? data.errorMessage : ''}
        interactive>
        <LooksTwoIcon
          style={{
            width: 20,
            color: data.onederfulWasSuccessful === true ? '#1B7FE1' : '#707070',
          }}
        />
      </BootstrapTooltip>
    );
  };

  const renderStatusCircle3 = (params) => {
    const data = params.data || params.row;
    return (
      <BootstrapTooltip
        placement="bottom-start"
        title={
          data.thirdVerificationErrorMessage
            ? data.thirdVerificationErrorMessage === true
              ? 'Success'
              : data.thirdVerificationErrorMessage
            : ''
        }
        interactive>
        <LooksThreeIcon
          style={{
            width: 20,
            color: data.thirdWasSuccessful === true ? '#1BA0E0' : '#707070',
          }}
        />
      </BootstrapTooltip>
    );
  };

  //********History Columns ******* */
  const historyColumns = [
    {
      field: 'date',
      headerName: 'Date',
      flex: 2,
      // width: historyColumnsWidth1(),
    },
    {
      field: 'status1',
      headerName: 'Status 1',
      flex: 1,
      // width: historyColumnsWidth3(),
      renderCell: (params) => renderStatusCircle1(params),
    },
    {
      field: 'status2',
      headerName: 'Status 2',
      flex: 1,
      // width: historyColumnsWidth3(),
      renderCell: (params) => renderStatusCircle2(params),
    },
    {
      field: 'status3',
      headerName: 'Status 3',
      flex: 1,
      // width: historyColumnsWidth3(),
      renderCell: (params) => renderStatusCircle3(params),
    },
    {
      field: 'successful',
      headerName: 'Result',
      flex: 2,
      // width: historyColumnsWidth2(),
      renderCell: (params) => renderDataInsideCellHistory(params),
    },
  ];
  //****** History Rows ************/
  const convertToDate = (unix1) => {
    const unix = parseInt(unix1);
    const date = new Date(unix);
    const month = date.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
    const year = date.getFullYear();
    const day = date.getDate();
    const combinedDate = `${month}/${day}/${year}`;
    return combinedDate;
  };

  function BootstrapTooltip(props1) {
    const classes1 = useStylesBootstrap();

    return <Tooltip classes={classes1} enterTouchDelay={50} {...props1} />;
  }
  const renderDataInsideCellHistory = (params) => {
    return (
      // <BootstrapTooltip
      //   placement="bottom-start"
      //   title={params.data.errorMessage ? params.data.errorMessage : ''}
      //   interactive>
      <div>{params.value}</div>
      // </BootstrapTooltip>
    );
  };
  const generateHistoryRows = () => {
    const selectedInsuranceId = selectedInsurance.id
      ? selectedInsurance.id
      : null;

    const JSONHistory = patientData?.usageHistory?.[selectedInsuranceId];

    if (JSONHistory) {
      const keys = Object.keys(JSONHistory);
      const values = Object.values(JSONHistory);
      let rows1 = keys.map((ele, index) => {
        return {
          id: index,
          date: convertToDate(ele),
          successful:
            (values[index].calledOnederful && !values[index].error) ||
            (values[index].calledChange && !values[index].changeError) ||
            (values[index].calledThirdVerification &&
              values[index].thirdVerificationError === false)
              ? 'Success'
              : values[index].thirdVerificationError === 'In Progress'
              ? 'Pending'
              : 'Failed',
          errorMessage: values[index].errorMessage
            ? values[index].errorMessage
            : values[index].calledOnederful === true
            ? ''
            : 'No verification attempt made',
          changeErrorMessage: values[index].changeErrorMessage
            ? values[index].changeErrorMessage
            : values[index].calledChange === true
            ? ''
            : 'No verification attempt made',
          changeWasSuccessful:
            !values[index].changeErrorMessage &&
            values[index].patientChangeDentalHTML
              ? true
              : false,
          onederfulWasSuccessful:
            !values[index].errorMessage &&
            (values[index].deductible ||
              values[index].maximums ||
              values[index].limitations ||
              values[index].coinsurance)
              ? true
              : false,
          thirdWasSuccessful:
            values[index].calledThirdVerification === true &&
            values[index].thirdVerificationError === false &&
            values[index].thirdVerificationPDF
              ? true
              : false,
          thirdVerificationErrorMessage:
            values[index].calledThirdVerification === true
              ? values[index].thirdVerificationError
              : 'No verification attempt made',
          dateUNIX: ele,
        };
      });
      rows1 = _.orderBy(rows1, ['dateUNIX'], ['desc']);
      return rows1;
    }
    return [];
  };

  const historyRows = generateHistoryRows();

  //*****************NPI Rendering and Selection Modal********************

  const handleChangeNPI = (NPICode) => {
    const NPIObject = NPIListArray.find((item) => item.code === NPICode);
    setSelectedNPI(NPIObject);
  };

  React.useEffect(() => {
    const NPINumbers = props.clinicBasicInfo.nationalProviderIds;
    if (NPINumbers) {
      const values = Object.values(NPINumbers);
      // console.log('NPI Valuessss', values);
      setNPIListArray(values);
      setSelectedNPI(values[0]);
    }
  }, [props.clinicBasicInfo.nationalProviderIds]);

  const renderNPIModal = () => {
    return (
      <ThemedModal
        open={openNPIModal}
        onClose={() => setOpenNPIModal(false)}
        title={'Perform Insurance Check'}
        className={classes.npimodal}>
        <div className={classes.npimodalcontainer}>
          <form>
            <FormControl variant="outlined" fullWidth>
              <InputLabel
                id="demo-simple-select-outlined-label"
                color="primary">
                NPI
              </InputLabel>
              <Select
                className={classes.label}
                defaultValue={NPIListArray[0] ? NPIListArray[0].code : ''}
                disabled={NPIListArray.length === 1 ? true : false}
                onChange={(e) => handleChangeNPI(e.target.value)}
                label="NPI Number"
                color="primary">
                {NPIListArray.map((object, index) => (
                  <MenuItem value={object.code} key={index}>
                    {object.physician} - NPI:{object.code}{' '}
                    {object.taxId ? `- Tax ID: ${object.taxId}` : ''}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {changeAndOnederfulInsuranceData.changeEligible === true ? (
              <div className={classes.npibuttoncontainer}>
                <MuiThemeProvider theme={theme}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    onClick={(e) => handleClickSubmitNPIModal(e, 'change')}
                    color="secondary">
                    Verification 1
                  </Button>
                </MuiThemeProvider>
              </div>
            ) : null}
            {changeAndOnederfulInsuranceData.onederfulEligible === true ? (
              <div className={classes.npibuttoncontainer}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={(e) => handleClickSubmitNPIModal(e, 'onederful')}
                  color="primary">
                  Verification 2
                </Button>
              </div>
            ) : null}
            {changeAndOnederfulInsuranceData.thirdVerification ? (
              <div className={classes.npibuttoncontainer}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={(e) =>
                    handleClickSubmitNPIModal(e, 'thirdVerification')
                  }
                  color="info">
                  Carrier Verification 3
                </Button>
              </div>
            ) : null}
            {(changeAndOnederfulInsuranceData.onederfulEligible === true &&
              changeAndOnederfulInsuranceData.changeEligible === true) ||
            changeAndOnederfulInsuranceData.thirdVerification ? (
              <div className={classes.npibuttoncontainer}>
                <MuiThemeProvider theme={theme2}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    onClick={(e) => handleClickSubmitNPIModal(e, 'both')}
                    color="primary">
                    All Verifications
                  </Button>
                </MuiThemeProvider>
              </div>
            ) : null}
            <div className={classes.clickheretext}>
              <span>Email </span>
              <a href="mailto: info@123consults.com?subject=Support Question">
                info@123consults.com
              </a>
              <span> for support.</span>
            </div>
          </form>
        </div>
      </ThemedModal>
    );
  };
  //************************************************************************Columns and Rows for Datagrids****************
  const usageNoRowsOverlay = () => {
    return (
      <GridOverlay>
        <div>No Data Available</div>
      </GridOverlay>
    );
  };
  const usageNoRowsOverlayHistory = () => {
    return (
      <GridOverlay>
        <div>No Verifications Made</div>
      </GridOverlay>
    );
  };

  const capitalizeFirstLetterAndRemoveUnderscoreHeaderBoxes = (word) => {
    if (word === '--') {
      return null;
    } else if (word && word.length > 1) {
      let capitalized = word.replace(/\b(\w)/g, (s) => s.toUpperCase());
      capitalized = capitalized.replace(/_/g, ' ');
      return capitalized;
    } else if (!word || word === '') {
      return null;
    }
    return word;
  };

  const roundNumber = (value) => {
    let newValue = value.split('.');

    let withoutDollar = value.replace(/[$]/g, '');

    // eslint-disable-next-line radix
    let number = parseInt(withoutDollar);
    if (number < 0) {
      newValue = '--';
    }
    return newValue[0];
  };

  const renderAgeLimitCell = (value) => {
    if (typeof value === 'object') {
      const entries = Object.entries(value);
      const returnArray = entries.map((obj, index) => {
        const returnObj = `${obj[0]}: ${obj[1]}`;
        let upperCaseReturnObj = capitalizeFirstLetterAndRemoveUnderscore(
          returnObj,
        );
        // 1234
        upperCaseReturnObj = upperCaseReturnObj
          ? upperCaseReturnObj.replace(/ value/g, '')
          : '';

        return upperCaseReturnObj;
      });
      return (
        <BootstrapTooltip
          placement="bottom-start"
          title={returnArray ? returnArray : ''}
          interactive>
          <div>{returnArray}</div>
        </BootstrapTooltip>
      );
    } else {
      return (
        <BootstrapTooltip
          placement="bottom-start"
          title={capitalizeFirstLetterAndRemoveUnderscore(value)}
          interactive>
          <div>{capitalizeFirstLetterAndRemoveUnderscore(value)}</div>
        </BootstrapTooltip>
      );
    }
  };

  const renderDataInsideCell = (value, type) => {
    if (Array.isArray(value)) {
      let modValue = value;
      if (value && value[0] && typeof value[0] === 'object') {
        // service date [{service: 'xxx', date: 'xxx'}]
        value.forEach((object, index) => {
          let modifiedInnerValue = Object.values(object);
          const returnArray = modifiedInnerValue.map((obj, index) => {
            // const returnObj = `${obj[0]}: ${obj[1]}`;
            // this is is to not add a , to the last value
            let upperCaseReturnObj = '';
            if (index === 0) {
              upperCaseReturnObj = obj
                ? capitalizeFirstLetterAndRemoveUnderscore(`${obj}`)
                : '';
            } else {
              upperCaseReturnObj = obj
                ? capitalizeFirstLetterAndRemoveUnderscore(`, ${obj}`)
                : '';
            }
            return upperCaseReturnObj;
          });
          modValue = returnArray;
        });
      } else if (value.length > 1) {
        // This is when an array with more than one item
        modValue = '';
        value.forEach((object, index) => {
          let modifiedInnerValue = object;
          modifiedInnerValue = modifiedInnerValue
            ? modifiedInnerValue?.replace(/BENEFITCLASS=/g, '')
            : '';

          // If only one value just return and dont put commas
          if (modValue === '') {
            modValue = `${modifiedInnerValue.toLowerCase()}`;
          } else {
            // this is for multiple data points to add
            modValue =
              modValue?.toLowerCase() +
              ',' +
              ` ${modifiedInnerValue?.toLowerCase()}`;
          }
          modValue = capitalizeFirstLetterAndRemoveUnderscore(modValue);
        });
      } else {
        modValue = modValue ? modValue[0]?.replace(/BENEFITCLASS=/g, '') : '';
        modValue = modValue ? modValue.toLowerCase() : '';
        modValue = modValue
          ? capitalizeFirstLetterAndRemoveUnderscore(modValue)
          : '';
      }
      // This is when the return value is just an array
      return (
        <BootstrapTooltip
          placement="bottom-start"
          title={modValue ? modValue : ''}
          interactive>
          <div>{modValue}</div>
        </BootstrapTooltip>
      );
    } else if (typeof value === 'object') {
      const entries = Object.entries(value);
      const returnArray = entries.map((obj, index) => {
        const returnObj = `${obj[0]}: ${obj[1]}`;
        const upperCaseReturnObj = capitalizeFirstLetterAndRemoveUnderscore(
          returnObj,
        );

        return upperCaseReturnObj;
      });
      return (
        <BootstrapTooltip
          placement="bottom-start"
          title={returnArray ? returnArray : ''}
          interactive>
          <div>{returnArray}</div>
        </BootstrapTooltip>
      );
    } else {
      // This is for ADA codes only to render the description of the procedure on the tooltip
      if (type === 'adaCode') {
        return (
          <BootstrapTooltip
            placement="bottom-start"
            title={capitalizeFirstLetterAndRemoveUnderscore(
              data[value] ? data[value] : value,
            )}
            interactive>
            <div>{capitalizeFirstLetterAndRemoveUnderscore(value)}</div>
          </BootstrapTooltip>
        );
      }
      return (
        <BootstrapTooltip
          placement="bottom-start"
          title={capitalizeFirstLetterAndRemoveUnderscore(value)}
          interactive>
          <div>{capitalizeFirstLetterAndRemoveUnderscore(value)}</div>
        </BootstrapTooltip>
      );
    }
  };
  const columnsmaximus1 = [
    {
      field: 'service_type',
      headerName: 'Service Type',
      width: 170,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'coverage_level',
      headerName: 'Coverage',
      width: 120,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'network',
      headerName: 'Network',
      width: 130,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 120,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'plan_period',
      headerName: 'Period',
      width: 150,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'age_limit',
      headerName: 'Age Limit',
      width: 110,
      renderCell: (params) => renderAgeLimitCell(params.value),
    },
    {
      field: 'disclaimers',
      headerName: 'Disclaimers',
      width: rootWidth < 1500 ? 300 : (rootWidth - 900) / 2,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'limitation',
      headerName: 'Limitations',
      width: rootWidth < 1500 ? 300 : (rootWidth - 900) / 2,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
  ];

  const columnsdeductible1 = [
    {
      field: 'service_type',
      headerName: 'Service Type',
      width: 170,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'coverage_level',
      headerName: 'Coverage',
      width: 120,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'network',
      headerName: 'Network',
      width: 130,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 120,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'plan_period',
      headerName: 'Period',
      width: 150,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'age_limit',
      headerName: 'Age Limit',
      width: 110,
      renderCell: (params) => renderAgeLimitCell(params.value),
    },
    {
      field: 'disclaimers',
      headerName: 'Disclaimers',
      width: rootWidth < 1500 ? 300 : (rootWidth - 900) / 2,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'limitation',
      headerName: 'Limitations',
      width: rootWidth < 1500 ? 300 : (rootWidth - 900) / 2,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
  ];

  const columnscoinsurance1 = [
    {
      field: 'service_type',
      headerName: 'Service Type',
      width: 170,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'coverage_level',
      headerName: 'Coverage',
      width: 120,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'network',
      headerName: 'Network',
      width: 130,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'percent',
      headerName: 'Percent',
      width: 120,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'plan_period',
      headerName: 'Period',
      width: 150,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'age_limit',
      headerName: 'Age Limit',
      width: 110,
      renderCell: (params) => renderAgeLimitCell(params.value),
    },
    {
      field: 'disclaimers',
      headerName: 'Disclaimers',
      width: rootWidth < 1500 ? 300 : (rootWidth - 900) / 2,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'limitation',
      headerName: 'Limitations',
      width: rootWidth < 1500 ? 300 : (rootWidth - 900) / 2,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
  ];

  const columnslimitations1 = [
    {
      field: 'service_type',
      headerName: 'Service Type',
      width: 150,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'age_limit',
      headerName: 'Age Limit',
      width: 150,
      renderCell: (params) => renderAgeLimitCell(params.value),
    },
    {
      field: 'disclaimers',
      headerName: 'Disclaimers',
      width: rootWidth < 1200 ? 400 : (rootWidth - 300) / 2,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'limitation',
      headerName: 'Limitations',
      width: rootWidth < 1200 ? 200 : (rootWidth - 500) / 2,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
  ];

  const columnsmaximus2 = [
    {
      field: 'procedure_code',
      headerName: 'ADA Code',
      width: 150,
      renderCell: (params) => renderDataInsideCell(params.value, 'adaCode'),
    },
    {
      field: 'coverage_level',
      headerName: 'Coverage',
      width: 120,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'network',
      headerName: 'Network',
      width: 130,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 120,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'plan_period',
      headerName: 'Period',
      width: 150,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'age_limit',
      headerName: 'Age Limit',
      width: 110,
      renderCell: (params) => renderAgeLimitCell(params.value),
    },
    {
      field: 'disclaimers',
      headerName: 'Disclaimers',
      width: rootWidth < 1500 ? 300 : (rootWidth - 900) / 2,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'limitation',
      headerName: 'Limitations',
      width: rootWidth < 1500 ? 300 : (rootWidth - 900) / 2,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
  ];
  const columnsdeductible2 = [
    {
      field: 'procedure_code',
      headerName: 'ADA Code',
      width: 150,
      renderCell: (params) => renderDataInsideCell(params.value, 'adaCode'),
    },
    {
      field: 'coverage_level',
      headerName: 'Coverage',
      width: 120,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'network',
      headerName: 'Network',
      width: 130,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 120,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'plan_period',
      headerName: 'Period',
      width: 150,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'age_limit',
      headerName: 'Age Limit',
      width: 110,
      renderCell: (params) => renderAgeLimitCell(params.value),
    },
    {
      field: 'disclaimers',
      headerName: 'Disclaimers',
      width: rootWidth < 1500 ? 300 : (rootWidth - 900) / 2,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'limitation',
      headerName: 'Limitations',
      width: rootWidth < 1500 ? 300 : (rootWidth - 900) / 2,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
  ];

  const columnscoinsurance2 = [
    {
      field: 'procedure_code',
      headerName: 'ADA Code',
      width: 150,
      renderCell: (params) => renderDataInsideCell(params.value, 'adaCode'),
    },
    {
      field: 'coverage_level',
      headerName: 'Coverage',
      width: 120,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'network',
      headerName: 'Network',
      width: 130,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'percent',
      headerName: 'Percent',
      width: 120,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'plan_period',
      headerName: 'Period',
      width: 150,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'age_limit',
      headerName: 'Age Limit',
      width: 110,
      renderCell: (params) => renderAgeLimitCell(params.value),
    },
    {
      field: 'disclaimers',
      headerName: 'Disclaimers',
      width: rootWidth < 1500 ? 300 : (rootWidth - 900) / 2,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'limitation',
      headerName: 'Limitations',
      width: rootWidth < 1500 ? 300 : (rootWidth - 900) / 2,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
  ];

  const columnslimitations2 = [
    {
      field: 'procedure_code',
      headerName: 'ADA Code',
      width: rootWidth < 1000 ? 100 : rootWidth / 8,
      renderCell: (params) => renderDataInsideCell(params.value, 'adaCode'),
    },
    {
      field: 'age_limit',
      headerName: 'Age Limit',
      width: 150,
      renderCell: (params) => renderAgeLimitCell(params.value),
    },
    {
      field: 'disclaimers',
      headerName: 'Disclaimers',
      width: rootWidth < 1200 ? 400 : (rootWidth - 300) / 2,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
    {
      field: 'limitation',
      headerName: 'Limitations',
      width: rootWidth < 1200 ? 200 : (rootWidth - 500) / 2,
      renderCell: (params) => renderDataInsideCell(params.value),
    },
  ];

  // **** AUTOCOMPLETE FILTERS
  const filterOptions = createFilterOptions({
    limit: 20,
    trim: true,
  });

  const renderPdfEmailIcons = () => {
    if (pdfIconSpinner === true) {
      return (
        <div className={classes.pdfcontainer2} data-html2canvas-ignore="true">
          <Ring color="#1B7FE1" size={40} />
        </div>
      );
    } else if (selectedTab === 'verification2' || selectedTab === 'extrainfo') {
      return (
        <Fragment>
          {
            <div
              className={classes.pdfcontainer2}
              data-html2canvas-ignore="true">
              {isMobile || isTablet || isIPad13 || isIPod13 ? (
                ''
              ) : (
                <IconButton
                  onClick={() => makePDF('download')}
                  className={classes.pdficonbutton}>
                  <PictureAsPdfIcon
                    className={classes.pdficon}
                    color="primary"
                  />
                </IconButton>
              )}
              {isMobile || isTablet || isIPad13 || isIPod13 ? (
                ''
              ) : (
                <IconButton
                  onClick={() => makePDF('email')}
                  className={classes.pdficonbutton}>
                  <MailOutlineIcon
                    className={classes.pdficon}
                    color="primary"
                  />
                </IconButton>
              )}
            </div>
          }
        </Fragment>
      );
    } else if (selectedTab === 'verification1') {
      return (
        <div>
          <div className={classes.pdfcontainer} data-html2canvas-ignore="true">
            {isMobile || isTablet || isIPad13 || isIPod13 ? (
              ''
            ) : (
              <IconButton
                onClick={() => makePDF('download')}
                className={classes.pdficonbutton}>
                <PictureAsPdfIcon className={classes.pdficon} color="primary" />
              </IconButton>
            )}
            {isMobile || isTablet || isIPad13 || isIPod13 ? (
              ''
            ) : (
              <IconButton
                onClick={() => makePDF('email')}
                className={classes.pdficonbutton}>
                <MailOutlineIcon className={classes.pdficon} color="primary" />
              </IconButton>
            )}
          </div>
        </div>
      );
    }
  };

  const renderSelectedTab = () => {
    if (selectedTab === 'verification2') {
      return (
        <Fragment>
          <div className={classes.bottomcardmaincontainer}>
            <div className={classes.gridstitlecontainer1}>
              <p className={classes.patientbenefitstitle}>
                Patient Benefits - {patientData.personalInfo?.lastName},{' '}
                {patientData.personalInfo?.firstName}{' '}
                {patientData.personalInfo?.DOB
                  ? changeDOBFormatting(patientData.personalInfo?.DOB)
                  : null}{' '}
              </p>
            </div>
            {selectedInsurance.insurance === 'AETNA_DENTAL_PLANS' ? null : (
              <Fragment>
                <div className={classes.serviceTypeContainer}>
                  <p className={classes.servicetypetitle}>Service Types</p>
                </div>
                <div
                  className={classes.dropdownfields}
                  data-html2canvas-ignore="true">
                  <FormControl
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={classes.formControl}>
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      color="primary">
                      Service Type Filter
                    </InputLabel>
                    <Select
                      className={classes.label}
                      value={
                        generalSearchFilter.service_type
                          ? generalSearchFilter.service_type
                          : ''
                      }
                      onChange={(e) =>
                        handleGeneralFilterChange(e.target.name, e.target.value)
                      }
                      name="service_type"
                      label="Service Type Filter"
                      color="primary">
                      {availableFilterObjects.serviceTypeFilter.map(
                        (object1, index) => (
                          <MenuItem value={object1} key={index}>
                            {capitalizeFirstLetterAndRemoveUnderscore(object1)}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={classes.formControl}>
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      color="primary">
                      Coverage Level
                    </InputLabel>
                    <Select
                      className={classes.label}
                      value={generalSearchFilter.coverage_level}
                      onChange={(e) =>
                        handleGeneralFilterChange(e.target.name, e.target.value)
                      }
                      name="coverage_level"
                      label="Coverage Level"
                      color="primary">
                      {availableFilterObjects.coverageLevelFilter.map(
                        (object1, index) => (
                          <MenuItem value={object1} key={index}>
                            {capitalizeFirstLetterAndRemoveUnderscore(object1)}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={classes.formControl}>
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      color="primary">
                      Network Filter
                    </InputLabel>
                    <Select
                      className={classes.label}
                      value={generalSearchFilter.network}
                      onChange={(e) =>
                        handleGeneralFilterChange(e.target.name, e.target.value)
                      }
                      name="network"
                      label="Network Filter"
                      color="primary">
                      {availableFilterObjects.networkFilter.map(
                        (object1, index) => (
                          <MenuItem value={object1} key={index}>
                            {capitalizeFirstLetterAndRemoveUnderscore(object1)}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={classes.formControl}>
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      color="primary">
                      Plan Period
                    </InputLabel>
                    <Select
                      className={classes.label}
                      value={generalSearchFilter.plan_period}
                      onChange={(e) =>
                        handleGeneralFilterChange(e.target.name, e.target.value)
                      }
                      name="plan_period"
                      label="Plan Period"
                      color="primary">
                      {availableFilterObjects.planPeriodFilter.map(
                        (object1, index) => (
                          <MenuItem value={object1} key={index}>
                            {capitalizeFirstLetterAndRemoveUnderscore(object1)}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </FormControl>
                </div>
                <div
                  className={classes.dropdownfieldsbutton}
                  data-html2canvas-ignore="true">
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={handleClickRunGeneralFilterReport}
                    className={classes.runreportbutton}
                    color="primary">
                    Run Filter
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={(e) =>
                      handleClickRunGeneralFilterReport(e, 'clear')
                    }
                    className={classes.runreportbutton}
                    color="primary">
                    Clear Filter
                  </Button>
                </div>
                <div
                  className={classes.gridcontainer}
                  ng-show="pdfPrintMax"
                  id="pdfPrintMax">
                  <p>MAXIMUMS</p>

                  <DataGrid1
                    rows={
                      allServiceTypeData.maximums
                        ? allServiceTypeData.maximums
                        : []
                    }
                    columns={columnsmaximus1}
                    disableSelectionOnClick
                    hideFooter
                    autoHeight
                    className={classes.maximusDatagrid}
                    rowHeight={25}
                    components={{
                      noRowsOverlay: usageNoRowsOverlay,
                    }}
                  />
                </div>
                <div
                  className={classes.gridcontainer}
                  ng-show="pdfPrintDed"
                  id="pdfPrintDed">
                  <p>DEDUCTIBLE</p>
                  <DataGrid1
                    rows={
                      allServiceTypeData.deductible
                        ? allServiceTypeData.deductible
                        : []
                    }
                    columns={columnsdeductible1}
                    disableSelectionOnClick
                    hideFooter
                    autoHeight
                    className={classes.maximusDatagrid}
                    rowHeight={25}
                    components={{
                      noRowsOverlay: usageNoRowsOverlay,
                    }}
                  />
                </div>
                <div
                  className={classes.gridcontainer}
                  ng-show="pdfPrintCo"
                  id="pdfPrintCo">
                  <p>COINSURANCE</p>
                  <DataGrid1
                    rows={
                      allServiceTypeData.coinsurance
                        ? allServiceTypeData.coinsurance
                        : []
                    }
                    columns={columnscoinsurance1}
                    disableSelectionOnClick
                    hideFooter
                    autoHeight
                    className={classes.maximusDatagrid}
                    rowHeight={25}
                    components={{
                      noRowsOverlay: usageNoRowsOverlay,
                    }}
                  />
                </div>
                <div
                  className={classes.gridcontainer}
                  ng-show="pdfPrintLim"
                  id="pdfPrintLim">
                  <p>LIMITATIONS</p>
                  <DataGrid1
                    rows={
                      allServiceTypeData.limitations
                        ? allServiceTypeData.limitations
                        : []
                    }
                    columns={columnslimitations1}
                    disableSelectionOnClick
                    hideFooter
                    autoHeight
                    className={classes.maximusDatagrid}
                    rowHeight={25}
                    components={{
                      noRowsOverlay: usageNoRowsOverlay,
                    }}
                  />
                </div>
              </Fragment>
            )}
            <div className={classes.gridstitlecontainer2}>
              <div className={classes.adacodestitle}>
                <p>ADA CODES</p>
              </div>
              <div className={classes.dropdowncontainer2}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    color="primary">
                    Template
                  </InputLabel>
                  <Select
                    className={classes.label}
                    value={selectedTemplate.name ? selectedTemplate.name : ''}
                    defaultValue={'all'}
                    onChange={(e) => handleChangeTemplate(e)}
                    label="Template"
                    color="primary">
                    <MenuItem value={'all'} key={'all'}>
                      All
                    </MenuItem>
                    {props.clinicTemplatesList.map((object, index) => (
                      <MenuItem value={object.name} key={index}>
                        {object.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className={classes.search} data-html2canvas-ignore="true">
                <MuiThemeProvider theme={theme}>
                  <Autocomplete
                    filterOptions={filterOptions}
                    data-html2canvas-ignore="true"
                    fullWidth={true}
                    freeSolo={true}
                    options={adaCodeListForSearch}
                    inputValue={searchADACodeText}
                    onInputChange={(event, newInputValue) => {
                      handleSearchADACodeTextChange(newInputValue);
                    }}
                    disableClearable
                    getOptionLabel={(option) =>
                      `${option.code} - ${option.description}`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onKeyPress={(ev) => {
                          if (ev.key === 'Enter') {
                            handleADACodeClick(ev);
                          }
                        }}
                        label="Search Code"
                        variant="outlined"
                      />
                    )}
                  />
                </MuiThemeProvider>
                <div className={classes.buttonsContainer}>
                  <MuiThemeProvider theme={theme}>
                    <div
                      className={classes.searchbutton}
                      data-html2canvas-ignore="true">
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleADACodeClick}>
                        Search
                      </Button>
                    </div>
                  </MuiThemeProvider>
                  <MuiThemeProvider theme={theme}>
                    <div
                      className={classes.clearbutton}
                      data-html2canvas-ignore="true">
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleADACodeClearClick}>
                        Clear
                      </Button>
                    </div>
                  </MuiThemeProvider>
                </div>
              </div>
            </div>
            <div
              className={classes.gridcontainer}
              ng-show="pdfPrintMax1"
              id="pdfPrintMax1">
              <p>MAXIMUMS</p>
              <DataGrid1
                rows={
                  selectedOnederfulData.parsedByServiceType?.maximums
                    ? selectedOnederfulData.parsedByServiceType.maximums
                    : []
                }
                columns={columnsmaximus2}
                disableSelectionOnClick
                hideFooter
                autoHeight
                className={classes.maximusDatagrid}
                rowHeight={25}
                components={{
                  noRowsOverlay: usageNoRowsOverlay,
                }}
              />
            </div>
            <div
              className={classes.gridcontainer}
              ng-show="pdfPrintDed1"
              id="pdfPrintDed1">
              <p>DEDUCTIBLE</p>
              <DataGrid1
                rows={
                  selectedOnederfulData.parsedByServiceType?.deductible
                    ? selectedOnederfulData.parsedByServiceType.deductible
                    : []
                }
                columns={columnsdeductible2}
                disableSelectionOnClick
                hideFooter
                autoHeight
                className={classes.maximusDatagrid}
                rowHeight={25}
                components={{
                  noRowsOverlay: usageNoRowsOverlay,
                }}
              />
            </div>
            <div
              className={classes.gridcontainer}
              ng-show="pdfPrintCo1"
              id="pdfPrintCo1">
              <p>COINSURANCE</p>
              <DataGrid1
                rows={
                  selectedOnederfulData.parsedByServiceType?.coinsurance
                    ? selectedOnederfulData.parsedByServiceType.coinsurance
                    : []
                }
                columns={columnscoinsurance2}
                disableSelectionOnClick
                hideFooter
                autoHeight
                className={classes.maximusDatagrid}
                rowHeight={25}
                components={{
                  noRowsOverlay: usageNoRowsOverlay,
                }}
              />
            </div>
            <div
              className={classes.gridcontainer}
              ng-show="pdfPrintLim1"
              id="pdfPrintLim1">
              <p>LIMITATIONS</p>
              <DataGrid1
                rows={
                  selectedOnederfulData.parsedByServiceType?.limitations
                    ? selectedOnederfulData.parsedByServiceType.limitations
                    : []
                }
                columns={columnslimitations2}
                disableSelectionOnClick
                hideFooter
                autoHeight
                className={classes.maximusDatagrid}
                rowHeight={25}
                components={{
                  noRowsOverlay: usageNoRowsOverlay,
                }}
              />
            </div>
          </div>
        </Fragment>
      );
    } else if (selectedTab === 'verification1') {
      return (
        <div>
          <div className={classes.htmlContainer}>
            {reactFormattedChangeData}
          </div>
        </div>
      );
    } else if (selectedTab === 'extrainfo') {
      return (
        <ExtraInfoOptions
          groupNumber={selectedInsurance.groupNumber}
          insuranceId={selectedInsurance.insurance}
          profileBenefits
          patientData={patientData}
          selectedInsurance={selectedInsurance}
        />
      );
    } else if (selectedTab === 'verification3') {
      return (
        <ThirdVerificationTableData pdfLinks={thirdVerificationPDFLinks} />
      );
    }
  };
  //*****************************************************Return Component******************************************************
  return (
    <MuiThemeProvider theme={theme}>
      <div
        className={classes.fullPageContainer}
        ng-show="pdfPrint"
        id="pdfPrint">
        {/* <Navbar /> */}
        <Spinner1 open={loadingLocal} label="Verifying... Please wait" />
        {renderNPIModal()}
        <div className={classes.maincontainer}>
          <div
            className={classes.topcontainer}
            ng-show="pdfPrintMain"
            id="pdfPrintMain">
            <div className={classes.lefttopinnercontainer}>
              <CardContainerProfile>
                <div className={classes.lefttopinnercard}>
                  <div>
                    <h2 className={classes.greentitles}>
                      {patientData.personalInfo?.firstName}{' '}
                      {patientData.personalInfo?.lastName}{' '}
                    </h2>
                    {patientData.personalInfo?.nextAppointmentDate ? (
                      <p>
                        Next Appt Date:{' '}
                        {patientData.personalInfo?.nextAppointmentDate
                          ? changeDOBFormatting(
                              patientData.personalInfo?.nextAppointmentDate,
                            )
                          : null}{' '}
                      </p>
                    ) : null}
                    {patientData.personalInfo?.email ? (
                      <p>
                        Email:{' '}
                        {patientData.personalInfo?.email
                          ? patientData.personalInfo?.email
                          : null}{' '}
                      </p>
                    ) : null}
                    {patientData.personalInfo?.phone ? (
                      <p>
                        Phone:{' '}
                        {patientData.personalInfo?.phone
                          ? changePhoneNumberFormatting(
                              patientData.personalInfo?.phone,
                            )
                          : null}{' '}
                      </p>
                    ) : null}
                    <p>
                      D.O.B:{' '}
                      {patientData.personalInfo?.DOB
                        ? changeDOBFormatting(patientData.personalInfo?.DOB)
                        : null}{' '}
                    </p>
                    {patientData.personalInfo?.location ? (
                      <p>
                        Location:{' '}
                        {patientData.personalInfo?.location
                          ? patientData.personalInfo?.location
                          : null}{' '}
                      </p>
                    ) : null}
                  </div>

                  <div className={classes.insurancecontainer}>
                    <div className={classes.dropdowncontainer}>
                      <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel
                          id="demo-simple-select-outlined-label"
                          color="primary">
                          Insurance
                        </InputLabel>
                        <Select
                          disabled={
                            patientInsuranceArray.length === 1 ? true : false
                          }
                          value={
                            selectedInsurance.id ? selectedInsurance.id : ''
                          }
                          onChange={(e) =>
                            handleChangeInsurance(e.target.value)
                          }
                          label="Insurance Company"
                          color="primary">
                          {patientInsuranceArray.map((object, index) => (
                            <MenuItem
                              value={object.id}
                              name={'insurance'}
                              key={object.id}>
                              {object.insurance}{' '}
                              {object.memberId
                                ? `****${object.memberId.slice(-4)}`
                                : null}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div>
                      <p
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}>
                        Member ID:{' '}
                        {selectedInsurance.memberId
                          ? `****${selectedInsurance.memberId.slice(-4)} `
                          : null}
                        <BootstrapTooltip
                          placement="bottom-start"
                          title={
                            selectedInsurance.memberId
                              ? selectedInsurance.memberId
                              : ''
                          }
                          interactive>
                          {/* <div className={classes.toolTipabel}>{params.value}</div> */}
                          <VisibilityIcon
                            style={{
                              color: 'black',
                              alignSelf: 'center',
                              paddingLeft: '2px',
                              cursor: 'pointer',
                            }}
                            fontSize={'small'}
                          />
                        </BootstrapTooltip>
                      </p>
                      <p>
                        Group Number:{' '}
                        {selectedInsurance.groupNumber
                          ? selectedInsurance.groupNumber
                          : 'N/A'}
                      </p>
                      <p>
                        Dependent:{' '}
                        {selectedInsurance.dependent === true ? 'Yes' : 'No'}
                      </p>
                      {selectedOnederfulData.providerData?.npi ? (
                        <p>
                          Provider NPI:{' '}
                          {selectedOnederfulData.providerData?.npi}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div
                    className={classes.buttoncontainer}
                    data-html2canvas-ignore="true">
                    <TransitionsModal
                      patientData={patientData}
                      passPatientDataUp={(data1) => setInitialValues(data1)}
                      editPatient={true}>
                      <button className={classes.button}>Edit Details</button>
                    </TransitionsModal>
                  </div>
                </div>
              </CardContainerProfile>
            </div>
            <div className={classes.centertopinnercontainer}>
              <div className={classes.topcards}>
                <div className={classes.topleftcard}>
                  <CardContainerProfile>
                    <div className={classes.titlecontainer}>
                      <div>
                        <p className={classes.titletext}>Coverage Data</p>
                      </div>
                      <div className={classes.statuscontainer}>
                        <p className={classes.statustext}>
                          {selectedOnederfulData.coverageData?.status
                            ? capitalizeFirstLetterAndRemoveUnderscore(
                                selectedOnederfulData.coverageData?.status,
                              )
                            : null}
                        </p>
                        <div className={classes.startDate}>
                          <p className={classes.titletext}>
                            Start Date:{' '}
                            {selectedOnederfulData.coverageData?.effective_date}
                            {selectedOnederfulData.coverageData?.end_date
                              ? `\nEnd Date: ${selectedOnederfulData.coverageData.end_date}`
                              : null}
                          </p>
                        </div>
                      </div>
                    </div>
                  </CardContainerProfile>
                </div>
                <div className={classes.toprightcard}>
                  <CardContainerProfile>
                    <div className={classes.titlecontainer}>
                      <div>
                        <p className={classes.titletext}>Last Verification</p>
                      </div>
                      <div className={classes.statuscontainer}>
                        <p className={classes.statustext}>
                          {patientData.lastCheckedInsuranceDate
                            ? convertToDate(
                                patientData.lastCheckedInsuranceDate,
                              )
                            : null}
                        </p>
                        <p className={classes.titletext}>
                          Verified By: {patientData.lastCheckedInsuranceBy}
                        </p>
                      </div>
                    </div>
                  </CardContainerProfile>
                </div>
              </div>
              <div className={classes.bottomcards}>
                <div className={classes.bottomleftcard}>
                  <CardContainerProfile>
                    <div className={classes.titlecontainer}>
                      <div>
                        <p className={classes.titletext}>Remaining Benefits</p>
                      </div>
                      <div className={classes.remainingbenefitscontainer}>
                        <div>
                          <p className={classes.statustext}>
                            {roundNumber(
                              remainingAmounts.remainingMaximum.amount,
                            )}
                          </p>
                          <p className={classes.titletext}>
                            {capitalizeFirstLetterAndRemoveUnderscoreHeaderBoxes(
                              remainingAmounts.remainingMaximum.serviceType,
                            )}
                          </p>
                          <p className={classes.titletext}>
                            {capitalizeFirstLetterAndRemoveUnderscoreHeaderBoxes(
                              remainingAmounts.remainingMaximum.network,
                            )}
                          </p>
                          <p className={classes.titletext}>
                            {capitalizeFirstLetterAndRemoveUnderscoreHeaderBoxes(
                              remainingAmounts.remainingMaximum.coverage,
                            )}
                          </p>
                        </div>
                        {!remainingAmounts.remainingOrthoMaximum
                          .amount ? null : (
                          <div>
                            <p className={classes.statustext}>
                              {roundNumber(
                                remainingAmounts.remainingOrthoMaximum.amount,
                              )}
                            </p>
                            <p className={classes.titletext}>
                              {capitalizeFirstLetterAndRemoveUnderscoreHeaderBoxes(
                                remainingAmounts.remainingOrthoMaximum
                                  .serviceType,
                              )}
                            </p>
                            <p className={classes.titletext}>
                              {capitalizeFirstLetterAndRemoveUnderscoreHeaderBoxes(
                                remainingAmounts.remainingOrthoMaximum.network,
                              )}
                            </p>
                            <p className={classes.titletext}>
                              {capitalizeFirstLetterAndRemoveUnderscoreHeaderBoxes(
                                remainingAmounts.remainingOrthoMaximum.coverage,
                              )}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </CardContainerProfile>
                </div>
                <div className={classes.bottomrightcard}>
                  <CardContainerProfile>
                    <div className={classes.titlecontainer}>
                      <div>
                        <p className={classes.titletext}>
                          Deductible Remaining
                        </p>
                      </div>
                      <div className={classes.statuscontainer}>
                        <p className={classes.statustext}>
                          {roundNumber(
                            remainingAmounts.remainingDeductible.amount,
                          )}
                        </p>
                        <p className={classes.titletext}>
                          {capitalizeFirstLetterAndRemoveUnderscoreHeaderBoxes(
                            remainingAmounts.remainingDeductible.serviceType,
                          )}
                        </p>
                        <p className={classes.titletext}>
                          {capitalizeFirstLetterAndRemoveUnderscoreHeaderBoxes(
                            remainingAmounts.remainingDeductible.network,
                          )}
                        </p>
                        <p className={classes.titletext}>
                          {capitalizeFirstLetterAndRemoveUnderscoreHeaderBoxes(
                            remainingAmounts.remainingDeductible.coverage,
                          )}
                        </p>
                      </div>
                      {!remainingAmounts.remainingOrthoDeductible
                        .amount ? null : (
                        <div className={classes.statuscontainer}>
                          <p className={classes.statustext}>
                            {roundNumber(
                              remainingAmounts.remainingOrthoDeductible.amount,
                            )}
                          </p>
                          <p className={classes.titletext}>
                            {capitalizeFirstLetterAndRemoveUnderscoreHeaderBoxes(
                              remainingAmounts.remainingOrthoDeductible
                                .serviceType,
                            )}
                          </p>
                          <p className={classes.titletext}>
                            {capitalizeFirstLetterAndRemoveUnderscoreHeaderBoxes(
                              remainingAmounts.remainingOrthoDeductible.network,
                            )}
                          </p>
                          <p className={classes.titletext}>
                            {capitalizeFirstLetterAndRemoveUnderscoreHeaderBoxes(
                              remainingAmounts.remainingOrthoDeductible
                                .coverage,
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                  </CardContainerProfile>
                </div>
              </div>
            </div>
            <div className={classes.toprightnnercontainer}>
              <CardContainerProfile>
                <div className={classes.righttopinnercard}>
                  <div className={classes.columnscontainer1}>
                    <div className={classes.verificationscontainer}>
                      <h2 className={classes.greentitles}>
                        {numberOfAPICalls}
                      </h2>
                      <p>Verifications this Month</p>
                    </div>
                    <div className={classes.hourssavedcontainer}>
                      <h2 className={classes.greentitles}>{timeSaved}</h2>
                      <p>Hours Saved</p>
                    </div>
                  </div>
                  {/* <div className={classes.verificationhistorycontainer}> */}
                  <div className={classes.verificationhistory}>
                    <p>Verification History</p>
                  </div>
                  <DataGrid
                    columns={historyColumns}
                    rows={historyRows}
                    disableSelectionOnClick
                    headerHeight={0}
                    hideFooter
                    autoHeight
                    className={classes.historyDatagrid}
                    rowHeight={25}
                    components={{
                      noRowsOverlay: usageNoRowsOverlayHistory,
                    }}
                  />
                  {/* </div> */}

                  <div className={classes.buttoncontainer}>
                    <button
                      className={classes.button}
                      data-html2canvas-ignore="true"
                      onClick={() =>
                        // () =>
                        setOpenNPIModal(true)
                      }>
                      Verify Insurance
                    </button>
                  </div>
                </div>
              </CardContainerProfile>
            </div>
          </div>
          <div className={classes.bottomcontainer}>
            <div className={classes.bottomcard}>
              <CardContainerProfile>
                <div
                  className={classes.topTabsContainer}
                  data-html2canvas-ignore="true">
                  <AppBar
                    position="static"
                    className={classes.verificationTabBar}>
                    <Tabs
                      //variant="standard"
                      color="transparent"
                      value={selectedTab}
                      //onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="nav tabs example"
                      classes={{flexContainer: styles.tabsWrapper}}>
                      <Tab
                        label="Verification 1"
                        classes={{wrapper: styles.tabs}}
                        style={{
                          backgroundColor:
                            selectedTab === 'verification1'
                              ? '#003B63'
                              : 'white',
                          color:
                            selectedTab === 'verification1' ? 'white' : null,
                          flex: 1,
                        }}
                        value={'verification1'}
                        icon={
                          rawChangeData.changeError === false &&
                          rawChangeData.calledChange === true ? (
                            <CheckIcon
                              style={{
                                width: 30,
                                color: '#42f548',
                                margin: 0,
                                paddingLeft: 10,
                              }}
                            />
                          ) : null
                        }
                        onClick={handleVerificationTab1}
                      />
                      <Tab
                        label="Verification 2"
                        classes={{wrapper: styles.tabs}}
                        value={'verification2'}
                        style={{
                          borderRight: '1px solid #C1C1C1',
                          borderLeft: '1px solid #C1C1C1',
                          backgroundColor:
                            selectedTab === 'verification2'
                              ? '#1B7FE1'
                              : 'white',
                          color:
                            selectedTab === 'verification2' ? 'white' : null,
                          flex: 1,
                        }}
                        icon={
                          rawJSONData.error === false &&
                          rawJSONData.calledOnederful === true ? (
                            <CheckIcon
                              style={{
                                width: 30,
                                color: '#42f548',
                                margin: 0,
                                paddingLeft: 10,
                              }}
                            />
                          ) : null
                        }
                        onClick={handleVerificationTab2}
                      />
                      {changeAndOnederfulInsuranceData.thirdVerification ? (
                        <Tab
                          label="Carrier Verification 3"
                          classes={{wrapper: styles.tabs}}
                          value={'verification3'}
                          style={{
                            borderRight: '1px solid #C1C1C1',
                            borderLeft: '1px solid #C1C1C1',
                            backgroundColor:
                              selectedTab === 'verification3'
                                ? '#1BA0E0'
                                : 'white',
                            color:
                              selectedTab === 'verification3' ? 'white' : null,
                            flex: 1,
                          }}
                          icon={
                            thirdVerificationPDFLinks &&
                            thirdVerificationPDFLinks.length > 0 ? (
                              <CheckIcon
                                style={{
                                  width: 30,
                                  color: '#42f548',
                                  margin: 0,
                                  paddingLeft: 10,
                                }}
                              />
                            ) : null
                          }
                          onClick={handleVerificationTab3}
                        />
                      ) : null}
                      <Tab
                        label="Employer Info"
                        classes={{wrapper: styles.tabs}}
                        value={'extrainfo'}
                        style={{
                          backgroundColor:
                            selectedTab === 'extrainfo' ? '#A1BED6' : 'white',
                          color: selectedTab === 'extrainfo' ? 'white' : null,
                        }}
                        onClick={handleExtraInfo}
                      />
                    </Tabs>
                  </AppBar>
                  {renderPdfEmailIcons()}
                </div>
                {renderSelectedTab()}
              </CardContainerProfile>
            </div>
          </div>
        </div>
      </div>
    </MuiThemeProvider>
  );
};

//*********************************************************REDUX STORE***********************************************************

const mapStateToProp = (state) => {
  return {
    clinicBasicInfo: state.apiCallsReducer.clinicBasicInfo,
    userInfo: state.apiCallsReducer.userInfo,
    clinicTemplatesList: state.apiCallsReducer.clinicTemplatesList,
    clinicUsageInfo: state.apiCallsReducer.clinicUsageInfo,
    currentPatient: state.patientReducer.currentPatient,
  };
};

export default connect(mapStateToProp, {
  makeOnederfulCall,
  parseData,
  parseGeneralFilterData,
  uploadToFirebase,
  makeChangeCall,
  sendPDFBenefitsEmail,
  makeThirdVerificationCall,
  getPatientInfo,
})(ProfileBenefits);

import React from 'react';
import {
  makeStyles,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import {DataGrid, GridOverlay} from '@material-ui/data-grid';
import {connect, useSelector} from 'react-redux';
import {
  changeClinicLocationSelection,
  changeGlobalSearchObj,
  archivePatient,
  unArchivePatient,
} from '../../actions';
// import adaCodes from 'data/adaCodeList.json';
import Tooltip from 'components/Tooltip/Tooltip';
import helpers from 'Utils/helpers';

const useStyles = makeStyles({
  tableContainer: {
    width: '100%',
    height: '100%',
  },
  root: {
    '& .MuiDataGrid-sortIcon': {
      color: 'black',
    },
    '& .MuiDataGrid-colCellWrapper': {
      backgroundColor: '#f2f2f2',
    },
    '& .MuiDataGrid-colCell': {
      width: '200px',
    },
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
      color: '#4D4B4B',
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
      outlineWidth: '1px',
      backgroundColor: '#C1C1C1',
      //color: '#FFFFFF',
    },
    cursor: 'pointer',
  },
});

const theme = createMuiTheme({
  typography: {
    fontFamily: '"Roboto"',
  },
});

//*************************************************************************************Generate Table Data**************************************************

//*******************************************************************************Table Data Component**************************************************************

const TableData = (props) => {
  const adaCodes = useSelector(
    ({apiCallsReducer}) => apiCallsReducer?.adaCodeList,
  );

  const styles = useStyles();

  //*****************Generate Header and Body********************

  const usageNoRowsOverlay = () => {
    return (
      <GridOverlay>
        <div>
          Click the add patient icon in the navbar to add your first patient!
        </div>
      </GridOverlay>
    );
  };

  const renderDateInsideCell = (UNIX) => {
    if (UNIX && UNIX !== '') {
      const date = new Date(UNIX);
      let month = date.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      const year = date.getFullYear();
      let day = date.getDate();
      if (month === 11 || month === 12 || month === 10) {
      } else {
        month = `0${month}`;
      }
      if (
        day === 0 ||
        day === 1 ||
        day === 2 ||
        day === 3 ||
        day === 4 ||
        day === 5 ||
        day === 6 ||
        day === 7 ||
        day === 8 ||
        day === 9
      ) {
        day = `0${day}`;
      }
      const combinedDate = `${month}/${day}/${year}`;
      return <div>{combinedDate}</div>;
    }
    return <div>--</div>;
  };

  const rows = React.useMemo(() => {
    return (
      (props.procedures &&
        Object.keys(props.procedures).map((key) => ({
          ...props.procedures[key],
          id: key,
          key,
        }))) ||
      []
    );
  }, [props.procedures]);

  const columns = [
    {
      field: 'phase',
      headerName: 'Phase',
      flex: 1,
      renderCell: (params) => <span>{params.value}</span>,
    },
    {
      field: 'scheduledDate',
      headerName: 'Scheduled Date',
      flex: 1.5,
      renderCell: (params) => renderDateInsideCell(params.value),
    },
    {
      field: 'adaCode',
      headerName: 'Procedure',
      flex: 2.5,
      renderCell: ({value}) => {
        const adaCode = value
          ? adaCodes[value]
            ? `${value} - ${adaCodes[value]}`
            : value
          : 'No procedure';

        return (
          <Tooltip title={adaCode}>
            <span>{adaCode}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'totalFee',
      headerName: 'Total Fee',
      flex: 1,
      renderCell: (params) => <span>{helpers.toCurrency(params.value)}</span>,
    },
    {
      field: 'discount',
      headerName: 'Discount',
      flex: 1,
      renderCell: (params) => <span>{helpers.toCurrency(params.value)}</span>,
    },
    {
      field: 'insuranceAmount',
      headerName: 'Insurance Amount',
      flex: 1,
      renderCell: (params) => <span>{helpers.toCurrency(params.value)}</span>,
    },
    {
      field: 'patientAmount',
      headerName: 'Patient Amount',
      flex: 1,
      renderCell: (params) => <span>{helpers.toCurrency(params.value)}</span>,
    },
  ];

  const tableRows = React.useMemo(() => {
    const row = {
      totalFee: 0,
      patientAmount: 0,
      discount: 0,
      insuranceAmount: 0,
      id: +new Date(),
      adaCode: 'Total',
    };

    rows.forEach((currentRow) => {
      row.totalFee += +currentRow.totalFee;
      row.patientAmount += +currentRow.patientAmount;
      row.discount += +currentRow.discount;
      row.insuranceAmount += +currentRow.insuranceAmount;
    });

    return [...rows, row];
  }, [rows]);

  //*****************Return Component********************
  return (
    <MuiThemeProvider theme={theme}>
      <div
        className={styles.tableContainer}
        style={{
          height:
            document.getElementById('root').clientHeight -
            document.getElementById('phase-2').clientHeight,
        }}>
        <DataGrid
          columns={columns}
          rows={tableRows}
          className={styles.root}
          components={{
            noRowsOverlay: usageNoRowsOverlay,
          }}
        />
      </div>
    </MuiThemeProvider>
  );
};

//*********************************************************REDUX STORE***********************************************************

const mapStateToProp = (state) => {
  return {
    clinicPatientsList: state.apiCallsReducer.clinicPatientsList,
    clinicLocationsList: state.apiCallsReducer.clinicLocationsList,
    searchedClinicPatientsList:
      state.apiCallsReducer.searchedClinicPatientsList,
    locationFilteredClinicPatientsList:
      state.apiCallsReducer.locationFilteredClinicPatientsList,
    userInfo: state.apiCallsReducer.userInfo,
    clinicBasicInfo: state.apiCallsReducer.clinicBasicInfo,
    activeOrArchivedPatientList:
      state.apiCallsReducer.activeOrArchivedPatientList,
  };
};

//*********************************************************Export***********************************************************

export default connect(mapStateToProp, {
  changeClinicLocationSelection,
  changeGlobalSearchObj,
  archivePatient,
  unArchivePatient,
})(TableData);

import React from 'react';
import PropTypes from 'prop-types';
import styles from './Options.module.scss';

function Title({children}) {
  return <h1 className={styles.title}>{children}</h1>;
}

Title.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(Title);

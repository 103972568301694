import {jsPDF} from 'jspdf';
import html2canvas from 'html2canvas';

const makePDF = async ({
  fileName,
  uploadToFirebase,
  clinicBasicInfo,
  sendType,
  sendPDFBenefitsEmail,
  alert,
}) => {
  var quotes = document.getElementById('pdfPrint');
  await html2canvas(quotes).then(async (canvas) => {
    var contentWidth = canvas.width;
    var contentHeight = canvas.height;

    //The height of the canvas which one pdf page can show;
    var pageHeight = (contentWidth / 592.28) * 841.89;
    //the height of canvas that haven't render to pdf
    var leftHeight = contentHeight;
    //addImage y-axial offset
    var position = 0;
    //a4 format [595.28,841.89]
    var imgWidth = 595.28;
    var imgHeight = (592.28 / contentWidth) * contentHeight;

    var pageData = canvas.toDataURL('image/jpeg', 1.0);

    var pdf = new jsPDF('', 'pt', 'a4');

    if (leftHeight < pageHeight) {
      pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
    } else {
      while (leftHeight > 0) {
        pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
        leftHeight -= pageHeight;
        position -= 841.89;
        //avoid blank page
        if (leftHeight > 0) {
          pdf.addPage();
        }
      }
    }
    if (sendType === 'email') {
      var pdfBlob = pdf.output('blob');
      const url = await uploadToFirebase(pdfBlob, clinicBasicInfo);
      if (url) {
        const emailBody = `The insurance benefits PDF is ready for the following clinic: <br><br>${clinicBasicInfo?.clinicName}<br><br>Click the link below to download the PDF:<br>${url}`;

        const subject = `123 Insurance Verification: ${fileName}`;
        const sendEmail = await sendPDFBenefitsEmail(
          clinicBasicInfo.email,
          emailBody,
          subject,
        );

        if (sendEmail.error !== true) {
          alert.show(
            `A PDF was successfully emailed to the admin mailbox: ${clinicBasicInfo.email}`,
            {
              title: 'Success',
            },
          );
        } else {
          alert.show(
            'There was an error emailing the PDF. Please download and send separately.',
            {title: 'Error'},
          );
        }
      } else {
        alert.show('There was an error creating the PDF', {title: 'Error'});
      }
    } else {
      pdf.save(`${fileName}.pdf`);
    }
    return true;
  });
};

export default makePDF;

import React from 'react';
import PropTypes from 'prop-types';
import Options from '../ExtraInfo/Options/Options';
// import Navbar from 'components/Navbar/Navbar';
import styles from './InsuranceView.module.scss';

function InsuranceView({match}) {
  return (
    <div className={styles.container}>
      {/* <Navbar /> */}
      <div className={`${styles.wrapper} container`}>
        <Options
          groupNumber={match.params.groupNumber}
          insuranceId={match.params.insuranceId}
          hideNewPlanRequest
        />
      </div>
    </div>
  );
}

InsuranceView.propTypes = {
  match: PropTypes.object.isRequired,
};

export default InsuranceView;

import React from 'react';
import {DataGrid, GridOverlay} from '@material-ui/data-grid';
import Modal from 'components/Common/Modal/Modal';
import Input from 'components/Form/Input/Input';
import Button from 'components/Common/Button/Button';
import {useSelector, useDispatch} from 'react-redux';
import {update} from 'actions/firebaseActions';
import {useAlert} from 'react-alert';
import {pushLocation, updateLocationList} from 'actions/apiCallsActions';
import AddIcon from '@material-ui/icons/Add';
import CardContainer from 'components/CardContainer';
import styles from './ClinicLocation.module.scss';

const INITIAL_LOCATION = {name: '', email: '', phone: '', active: true};

function ProcedureTemplates({
  isAdmin,
  addIconClass,
  clinicLocationsList,
  className,
}) {
  const [state, setState] = React.useState({
    open: false,
    location: INITIAL_LOCATION,
  });

  const clinicId = useSelector(
    ({apiCallsReducer}) => apiCallsReducer?.clinicBasicInfo?.clinicId,
  );

  const alert = useAlert();
  const dispatch = useDispatch();

  const clinicColumns = [
    {
      field: 'name',
      headerName: 'Location Name',
      flex: 1,
    },
    {
      field: 'phone',
      headerName: ' Phone Number',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
  ];

  const locationNoRowsOverlay = () => {
    return (
      <GridOverlay>
        <div>No Active Locations.</div>
      </GridOverlay>
    );
  };

  const rows = React.useMemo(() => {
    return clinicLocationsList.map((location) => ({
      id: location.id || location.name,
      name: location.name,
      phone: location.phone,
      email: location.email,
      active: location.active,
    }));
  }, [clinicLocationsList]);

  const submit = (event) => {
    const {location} = state;
    event.preventDefault();
    if (location.name) {
      const path = location.id || location.name;
      update(`/clinics/${clinicId}/locations/${path}`, location, () => {
        if (location.id) {
          dispatch(updateLocationList(location));
        } else {
          dispatch(pushLocation({...location, id: location.name}));
        }
        const msg = location.id
          ? 'The clinic location information was updated'
          : 'The clinic location information was added correctly!';
        alert.success(msg);
        setState((currentState) => ({...currentState, open: false}));
      });
    }
  };

  const handleValue = React.useCallback((name, value) => {
    setState((currentState) => {
      const {location} = currentState;
      return {...currentState, location: {...location, [name]: value}};
    });
  }, []);

  return (
    <>
      <CardContainer
        title="Clinic Locations"
        iconTopRight={
          <AddIcon
            onClick={() => {
              if (!isAdmin) {
                alert.show(
                  'Only administrator accounts can add new locations.',
                  {title: 'Not An Administrator.'},
                );
                return;
              }
              setState({
                open: true,
                location: INITIAL_LOCATION,
              });
            }}
            className={addIconClass}
          />
        }>
        <DataGrid
          columns={clinicColumns}
          rows={rows}
          className={className}
          disableSelectionOnClick
          hideFooter
          components={{
            noRowsOverlay: locationNoRowsOverlay,
          }}
          onRowClick={({row}) => {
            setState((currentState) => ({
              ...currentState,
              location: {...row, isEditing: true},
              open: true,
            }));
          }}
        />
        <Modal
          title={state.location.id ? 'Edit Clinic Location' : 'Add Location'}
          open={state.open}
          onClose={() => {
            setState((currentState) => ({...currentState, open: false}));
          }}>
          <div className={styles.wrapper}>
            <form onSubmit={submit}>
              <div className="col-12">
                <Input
                  label="Name:"
                  name="name"
                  value={state.location.name}
                  onChange={state.location.isEditing ? () => null : handleValue}
                  disabled={state.location.isEditing}
                />
              </div>
              <div className="col-12">
                <Input
                  label="Phone number:"
                  name="phone"
                  value={state.location.phone}
                  onChange={handleValue}
                />
              </div>
              <div className="col-12">
                <Input
                  label="Email:"
                  name="email"
                  value={state.location.email}
                  onChange={handleValue}
                />
              </div>
              {/* <div className="col-12">
                <Checkbox
                  id="active"
                  label="Active"
                  name="active"
                  value={state.location.active}
                  onChange={handleValue}
                />
              </div> */}
              <div className="col-12 mt-2">
                <Button type="submit" disabled={!state.location.name} fullWidth>
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </Modal>
      </CardContainer>
    </>
  );
}

ProcedureTemplates.propTypes = {};

export default ProcedureTemplates;

import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
// import HistoryIcon from '@material-ui/icons/History';

const useStyles = makeStyles((theme) => ({
  coloredContainer: {
    display: 'flex',
    backgroundColor: 'white',
    height: '100%',
    // borderRadius: 5,
    flexDirection: 'column',
    // boxShadow: '0 2px 5px #7F7F7F, 0 2px 2px #7F7F7F',
  },
}));

export default function CardContainerProfile({style, children}) {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <div className={classes.coloredContainer} style={style}>
      {children}
    </div>
  );
}

import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  fullPageContainer: {
    flexGrow: 1,
    fontFamily: '"Roboto"',
    backgroundColor: '#DEE8F2',
    minHeight: '100%',
    color: '#8b8b8b',
  },
  maincontainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  topcontainer: {
    color: '',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '15px',
    padding: '15px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      padding: '15px',
    },
  },
  bottomcontainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 15px 15px 15px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px',
    },
  },
  bottomcardmaincontainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 20px 200px 20px',
  },
  bottomcard: {
    height: '100%',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  lefttopinnercontainer: {
    width: '25%',
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0 0 20px 0',
    },
  },
  centertopinnercontainer: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0 0 20px 0',
    },
  },
  toprightnnercontainer: {
    width: '25%',
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  lefttopinnercard: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px',
    textAlign: 'flex-start',
    flex: 1,
    '& p': {
      margin: '5px 5px 5px 0',
      fontSize: '14px',
    },
  },
  righttopinnercard: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'flex-start',
    padding: '10px',
    flex: 1,
    '& p': {
      margin: '5px',
      fontSize: '12px',
    },
  },

  topcards: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 0 15px 0',
    height: '100%',
    justifyContent: 'space-evenly',
    gap: '15px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  bottomcards: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'space-evenly',
    gap: '15px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  topleftcard: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  toprightcard: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '10px 0 0 0',
    },
  },
  bottomleftcard: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  bottomrightcard: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '10px 0 0 0',
    },
  },
  button: {
    //borderRadius: '20px',
    border: '2px solid #FFFFFF',
    backgroundColor: '#1B7FE1',
    color: '#FFFFFF',
    fontSize: '12px',
    fontWeight: 'bold',
    padding: '10px 60px',
    //letterSpacing: '1px',
    //textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    cursor: 'pointer',
    outline: 'none',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: '#1B7FE1',
      border: '2px solid #1B7FE1',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10px 90px',
    },
  },
  buttoncontainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: 'auto',
    padding: 0,
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0 0 0',
    },
  },
  titlecontainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px',
    flex: 1,
  },
  statuscontainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    //padding: '30px 0px',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  remainingbenefitscontainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    textAlign: 'center',
    alignItems: 'center',
    //padding: '30px 0',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0',
    },
  },
  titletext: {
    margin: 0,
    fontSize: '14px',
  },
  statustext: {
    fontSize: '25px',
    color: '#1b7fe1',
    margin: 0,
    fontWeight: 'bold',
  },
  greentitles: {
    fontSize: '25px',
    color: '#1B7FE1',
    fontWeight: 'bolder',
    margin: '0 0 10px 0',
  },
  servicetypetitle: {
    fontSize: '25px',
    color: '#1B7FE1',
    fontWeight: 'bolder',
    padding: '10px 0 0 0',
    margin: 0,
  },
  adacodestitle: {
    fontSize: '25px',
    color: '#1B7FE1',
    fontWeight: 'bolder',
    padding: '10px 10px',
  },
  insurancecontainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 0 20px 0',
  },
  dropdowncontainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingTop: '10px',
    paddingBottom: '5px',
  },
  dropdowncontainer2: {
    display: 'flex',
    flexDirection: 'row',
    width: '30%',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      alignItems: 'center',
    },
  },
  columnscontainer1: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center',
    borderBottom: '1px solid #C1C1C1',
  },
  historyDatagrid: {
    borderColor: 'white',
    fontFamily: '"Roboto"',
    textAlign: 'center',
    maxHeight: '250px',
    overflow: 'scroll',
    '& .MuiDataGrid-colCellTitle': {
      fontWeight: 'bold',
    },
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
      color: '#707070',
    },
    '& .MuiDataGrid-iconSeparator': {
      color: 'white',
    },
    '& .MuiDataGrid-cell': {
      borderColor: 'white',
      [theme.breakpoints.only('md')]: {
        padding: '0 0',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0 5x',
        justifyContent: 'center',
      },
      [theme.breakpoints.only('lg')]: {
        padding: '0 5px',
        justifyContent: 'center',
      },
    },
    '& .MuiDataGrid-columnsContainer': {
      borderColor: 'white',
      backgroundColor: 'white',
    },
    '& .MuiDataGrid-colCell	': {
      display: 'flex',
      flexDirection: 'row',
      width: '80%',
      justifyContent: 'center',
    },
    '& .MuiDataGrid-window': {
      overflowX: 'hidden',
      overflowY: 'auto !important',
    },
  },
  maximusDatagrid: {
    borderColor: 'white',
    fontFamily: '"Roboto"',
    '& .MuiDataGrid-colCellTitle': {
      fontWeight: 'bold',
    },
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
      color: '#707070',
    },
    '& .MuiDataGrid-iconSeparator': {
      color: 'white',
    },
    '& .MuiDataGrid-cell': {
      borderColor: 'white',
    },
    '& .MuiDataGrid-columnsContainer': {
      borderColor: 'white',
      backgroundColor: 'white',
    },
    '& .MuiDataGrid-window': {
      [theme.breakpoints.up('md')]: {
        overflowX: 'hidden',
      },
    },
  },
  '& .MuiDataGrid-window': {
    [theme.breakpoints.up('md')]: {
      overflowX: 'hidden',
    },
  },
  patientbenefitstitle: {
    fontSize: '20px',
    color: '#1B7FE1',
    fontWeight: 'bold',
    margin: 0,
  },
  gridstitlecontainer1: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  gridstitlecontainer2: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: '30px 0 0 0',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  gridcontainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '95%',
    borderBottom: '2px solid #e0dede',
    paddingBottom: '20px',
    '& p': {
      fontWeight: 'bold',
      padding: '10px 0 0 0',
    },
  },
  serviceTypeContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& p': {
      fontWeight: 'bold',
      padding: '15px 0 0 0',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  dropdownfields: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  formControl: {
    margin: '15px 10px',
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0',
    },
  },
  dropdownfieldsbutton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  runreportbutton: {
    margin: '0 10px',
  },
  pdficon: {
    fontSize: '50px',
    padding: 0,
  },
  pdficonbutton: {
    padding: 5,
    marginTop: '-15px',
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  bottompdfcontainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: 15,
  },
  pdfcontainer2: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '25px 30px 5px 15px',
  },
  pdfcontainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '25px 30px 5px 15px',
  },
  topTabsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  verificationscontainer: {
    flex: 1,
    borderRight: '1px solid #C1C1C1',
  },
  hourssavedcontainer: {
    flex: 1,
  },
  verificationhistory: {
    padding: '10px 0 0 0',
  },
  search: {
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '10px 0',
      flexDirection: 'column',
    },
  },
  inputRoot: {
    padding: '0 20px 0 10px',
  },
  inputInput: {
    color: 'black',
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    border: '1px solid #C4C4C4',
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: '0 10px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 10px',
    },
  },
  searchbutton: {
    padding: '0 10px',
  },
  clearbutton: {
    padding: '0 10px',
  },
  startDate: {
    whiteSpace: 'pre-wrap',
  },
  clickheretext: {
    fontSize: '16px',
    textAlign: 'center',
    padding: '25px 0',
  },
  npimodal: {
    fontFamily: 'Roboto',
    alignItems: 'center',
    height: '40%',
    width: '50%',
  },
  npimodalcontainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    height: '50%',
  },
  npibuttoncontainer: {
    padding: '20px 0 0 0',
  },
  verificationTabBar: {
    backgroundColor: 'white',
    width: '700px',
    justifyContent: 'space-around',
    height: 'fit-content',
    '& .MuiButtonBase-root': {
      flex: 1,
      height: '-webkit-fill-available',
    },
    '& .MuiTab-wrapper': {
      padding: 0,
      display: 'flex',
      flexDirection: 'row-reverse',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        fontSize: '10px',
        fontWeight: 'bold',
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
    '& .MuiTabs-flexContainer': {
      height: '60px',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        height: '80px',
      },
    },
  },
  tabBarOption: {
    padding: '0px 60px',
    borderBottom: '3px solid #e0dede',
    borderRadius: '0px 0px 50px 0px',
    backgroundColor: 'white',
    zIndex: 3,
    '&:hover': {
      backgroundColor: '#003B63',
      cursor: 'pointer',
      color: 'white',

      borderBottom: '3px solid #003B63',
    },
  },
  tabBarOption2: {
    padding: '0px 60px',
    borderBottom: '3px solid #e0dede',
    borderRadius: '0px 0px 50px 0px',
    backgroundColor: 'white',
    zIndex: 2,
    marginLeft: '-40px',
    '&:hover': {
      backgroundColor: '#1B7FE1',
      cursor: 'pointer',
      color: 'white',
      borderBottom: '3px solid #1B7FE1',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  tabBarOption3: {
    padding: '0px 60px',
    borderBottom: '3px solid #e0dede',
    borderRadius: '0px 0px 50px 0px',
    backgroundColor: 'white',
    zIndex: 1,
    marginLeft: '-40px',
    '&:hover': {
      backgroundColor: '#1B7FE1',
      cursor: 'pointer',
      color: 'white',
      borderBottom: '3px solid #1B7FE1',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  htmlContainer: {
    padding: 30,
    color: 'black',
    overflowY: 'scroll',
    [theme.breakpoints.down('sm')]: {
      overflowX: 'auto',
      cursor: 'grab',
    },
  },
  extraButtonsMainContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    [theme.breakpoints.only('sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
    },
  },
  extraButtonsContainer: {
    padding: '30px 0 20px 0',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  extraButton: {
    border: '1px solid #C1C1C1',
    color: '#1B7FE1',
    backgroundColor: 'white',
    fontWeight: 'bold',
    padding: '10px',
    margin: '0 20px',
    borderRadius: '10px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#1B7FE1',
      color: 'white',
      cursor: 'pointer',
      border: '1px solid #1B7FE1',
    },
    [theme.breakpoints.only('xs')]: {
      width: 'auto',
      margin: '0 3px',
    },
  },
}));

export default useStyles;

import React from 'react';
import styles from './OptionsButtons.module.scss';
import OptionsButton from '../OptionButton';
import OPTIONS_ENUM from 'constants/Enums/ProfileBenefitsOptionsEnum';

function OptionsButtons({buttonProps, ...props}) {
  return (
    <div className={styles.extraButtonsMainContainer}>
      <OptionsButton {...buttonProps} value={OPTIONS_ENUM.employer}>
        Employer
      </OptionsButton>
      <OptionsButton {...buttonProps} value={OPTIONS_ENUM.general}>
        General
      </OptionsButton>
      <OptionsButton {...buttonProps} value={OPTIONS_ENUM.preventative}>
        Preventive
      </OptionsButton>
      <OptionsButton {...buttonProps} value={OPTIONS_ENUM.basic}>
        Basic
      </OptionsButton>
      <OptionsButton {...buttonProps} value={OPTIONS_ENUM.major}>
        Major
      </OptionsButton>
      <OptionsButton {...buttonProps} value={OPTIONS_ENUM.ortho}>
        Ortho
      </OptionsButton>
      <OptionsButton {...buttonProps} value={OPTIONS_ENUM.all}>
        All
      </OptionsButton>
    </div>
  );
}


export default OptionsButtons;

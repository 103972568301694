import moment from 'moment';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const helpers = {
  isFunction(variable) {
    return typeof variable === 'function';
  },
  toCurrency(value = 0) {
    return currencyFormatter.format(value);
  },
  sortDates(dates, key = 'date', onlyLast = false) {
    if (Array.isArray(dates)) {
      dates.sort((a, b) => moment(b[key]) - moment(a[key]));
      return onlyLast ? dates[0] : dates;
    }

    return null;
  },
  sortDatesAsc(dates, key = 'date', onlyLast = false) {
    if (Array.isArray(dates)) {
      dates.sort((a, b) => moment(a[key]) - moment(b[key]));
      return onlyLast ? dates[0] : dates;
    }

    return null;
  },
};

export default helpers;

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {getDocument, getDocumentAsync} from 'actions/firebaseActions';

function useTreatments({clinicId, patientId}) {
  const [treatments, setTreatments] = React.useState(null);

  const url = React.useMemo(() => {
    return patientId ? `clinics/${clinicId}/patients/${patientId}` : null;
  }, [patientId, clinicId]);

  const getModel = React.useCallback(
    async (result) => {
      if (!result) {
        return;
      }
      const treatmenstPromises = Object.keys(result).map((key) => {
        if (result[key] === true) {
          return getDocumentAsync(`/treatments/${clinicId}/${key}`);
        }

        return null;
      });

      const treatmentsResolved = await Promise.all(treatmenstPromises);

      const treatmentList = {};
      treatmentsResolved.forEach((item) => {
        if (item) {
          treatmentList[item.id] = item;
        }
      });

      if (treatmentList) {
        setTreatments(treatmentList);
      } else {
        setTreatments({});
      }
    },
    [clinicId],
  );

  const fetchTreatments = React.useCallback(() => {
    if (url) {
      getDocument(`${url}/treatments`, getModel);
    } else {
      setTreatments({});
    }
  }, [url, getModel]);

  const deleteTreatment = React.useCallback(
    (treatmentId) => {
      setTreatments((currentTreatments) => {
        const _currentTreatments = {...currentTreatments};

        delete _currentTreatments[treatmentId];
        return {..._currentTreatments};
      });
    },
    [setTreatments],
  );

  React.useEffect(() => {
    fetchTreatments();
  }, [fetchTreatments]);

  const treatmentList = React.useMemo(() => {
    const t = treatments
      ? Object.keys(treatments)
          .map((key) => {
            return {
              treatmentPlanName: treatments[key].treatmentPlanName,
              name: `${treatments[key].treatmentPlanName} - ${moment(
                treatments[key].createdAt,
              ).format('ll')}`,
              id: key,
              date: treatments[key].createdAt,
            };
          })
          .sort((a, b) => new Date(b.date) - new Date(a.date))
      : null;
    return t;
  }, [treatments]);

  return {
    treatments,
    treatmentList,
    url,
    setTreatments,
    fetchTreatments,
    deleteTreatment,
  };
}

useTreatments.propTypes = {
  clinicId: PropTypes.string.isRequired,
  patientId: PropTypes.string.isRequired,
};

export default useTreatments;

import React from 'react';
import styles from './Treatment.module.scss';
import TextField from 'components/Form/Input/TextArea';
import Button from 'components/Common/Button/Button';
import {setSimpleValue} from 'actions/firebaseActions';
import {useAlert} from 'react-alert';
import helpers from 'Utils/helpers';
import moment from 'moment';

function Treatment({treatment, treatmentId, clinicId, updateTreatment}) {
  const [model, setModel] = React.useState({note: ''});
  const alert = useAlert();

  React.useEffect(() => {
    if (treatmentId) {
      setModel((currentModel) => ({...currentModel, note: treatment?.note}));
    }
  }, [treatment, treatmentId]);

  const updateValue = React.useCallback((name, value) => {
    setModel((currentModel) => ({...currentModel, [name]: value}));
  }, []);

  const boxesModel = React.useMemo(() => {
    if (!treatmentId) {
      return {};
    }

    const procedures = Object.keys(treatment?.procedures || {}).map(
      (key) => treatment.procedures[key],
    );

    const sortedProcedures = helpers.sortDatesAsc(procedures, 'scheduledDate');

    const totalProcedures = procedures.length;
    let lastProcedure = null;
    let nextProcedure = null;
    let totalCost = 0;
    let currentEarned = 0;
    let currentProcedure = 0;
    let lastScheduled = null;
    let nextProcedureDate = null;
    let today = new Date();

    sortedProcedures.forEach((procedure, index) => {
      if (!procedure.scheduledDate) {
        if (index > 0) {
          lastProcedure = procedures[index - 1];
          nextProcedure = procedure;
        } else {
          lastProcedure = procedure;
          currentProcedure = 0;
          nextProcedure = procedure;
        }
      } else {
        lastScheduled = procedure.scheduledDate;
        currentProcedure = currentProcedure + 1;
        currentEarned += +procedure.totalFee;

        if (!nextProcedureDate && new Date(procedure.scheduledDate) > today) {
          nextProcedureDate = procedure.scheduledDate;
        }
      }
      totalCost += +procedure.totalFee;
    });

    if (!lastProcedure) {
      lastProcedure = procedures[procedures.length - 1];
    }

    return {
      totalCost,
      lastProcedure,
      lastScheduled,
      nextProcedure,
      currentEarned,
      totalProcedures,
      currentProcedure,
      nextProcedureDate,
    };
  }, [treatment, treatmentId]);

  return (
    <div className={`${styles.treatment}`}>
      <div className={styles.half}>
        <div className={styles.title}>
          <span>Treatment Plan Procedures</span>
        </div>
        <div className={styles.treatmentPlanProgress}>
          <h1>
            {boxesModel.currentProcedure}/{boxesModel.totalProcedures}
          </h1>
          <div>
            <span className={styles.label}>Scheduled/Total</span>
          </div>
          <div>
            <span>
              Next Scheduled:{' '}
              {boxesModel.nextProcedureDate
                ? moment(boxesModel.nextProcedureDate).format('L')
                : 'No scheduled procedures'}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.half}>
        <div className={styles.title}>
          <span>Treatment Plan Amount</span>
        </div>
        <div className={styles.treatmentPlanProgress}>
          <h1>
            {helpers.toCurrency(boxesModel.currentEarned)}/
            {helpers.toCurrency(boxesModel.totalCost)}
          </h1>
          <div>
            <span className={styles.label}>Scheduled/Total</span>
          </div>
        </div>
      </div>
      <div className={`${styles.complete}`}>
        <TextField
          label="Treatment Notes"
          multiline
          name="note"
          rows={8}
          value={model.note}
          onChange={updateValue}
        />
        <Button
          disabled={!treatmentId}
          onClick={() => {
            setSimpleValue(
              `treatments/${clinicId}/${treatmentId}/note`,
              model.note,
              () => {
                updateTreatment(treatmentId, model);
                alert.success('Note updated for this treatment plan.');
              },
            );
          }}>
          Save
        </Button>
      </div>
    </div>
  );
}

export default Treatment;

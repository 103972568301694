import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Modal, IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: '"Roboto"',
  },
  modalBodyContainer: {
    display: 'flex',
    width: '70%',
    backgroundColor: 'white',
    outline: 0,
    flexDirection: 'column',
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  modalHeader: {
    display: 'flex',
    backgroundColor: '#1B7FE1',
    height: '80px',
    width: '100%',
    borderRadius: '10px 10px 0px 0px',
  },
  closeButton: {
    display: 'flex',
    color: 'white',
    position: 'absolute',
    padding: 5,
  },
  titleText: {
    display: 'flex',
    fontSize: 30,
    height: '100%',
    width: '100%',
    color: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
}));

export const ThemedModal = ({style, children, title, open, onClose}) => {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <Modal className={classes.modal} open={open} onClose={onClose}>
      <div className={classes.modalBodyContainer} style={style}>
        <div className={classes.modalHeader}>
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <div className={classes.titleText}>{title}</div>
        </div>
        <div className={classes.modalBody}>{children}</div>
      </div>
    </Modal>
  );
};

import React from 'react';
import {DataGrid} from '@material-ui/data-grid';
import styles from './CommunicationsTable.module.scss';
import SmsIcon from '@material-ui/icons/Sms';
import EmailIcon from '@material-ui/icons/Email';
import PropTypes from 'prop-types';
import moment from 'moment';

function CommunicationsTable({rows}) {
  const columns = [
    {
      flex: 1,
      field: 'date',
      headerName: 'Date',
      renderCell: ({value}) => moment(value).format('L'),
    },
    {
      field: 'method',
      headerName: 'Method',
      width: 70,
      renderCell: ({value, ...data}) => {
        const {opened} = data.row;

        const statusClassName = opened
          ? styles.opened
          : styles[data.row.status];

        return value === 'sms' ? (
          data.row.smsStatus !== 'error' ? (
            <SmsIcon className={statusClassName} />
          ) : (
            <SmsIcon className={styles.error} />
          )
        ) : value === 'email' ? (
          data.row.emailStatus !== 'error' ? (
            <EmailIcon className={statusClassName} />
          ) : (
            <EmailIcon className={styles.error} />
          )
        ) : (
          <>
            {data.row.smsStatus !== 'error' ? (
              <SmsIcon className={statusClassName} />
            ) : (
              <SmsIcon className={styles.error} />
            )}
            {data.row.emailStatus !== 'error' ? (
              <EmailIcon className={statusClassName} />
            ) : (
              <EmailIcon className={styles.error} />
            )}
          </>
        );
      },
    },

    {
      flex: 1,
      field: 'opened',
      headerName: 'Opened',
      renderCell: ({value, ...data}) => {
        return (
          <span>
            {data.row.status === 'scheduled'
              ? 'Scheduled'
              : value
              ? 'Opened'
              : 'Sent'}
          </span>
        );
      },
    },
  ];

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      disableSelectionOnClick
      headerHeight={0}
      hideFooter
      autoHeight
      className={styles.wrapper}
      rowHeight={25}
    />
  );
}

CommunicationsTable.propTypes = {
  rows: PropTypes.array.isRequired,
};

export default CommunicationsTable;

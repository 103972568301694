import React from 'react';
import styles from './Phase2.module.scss';
import Patient from './Patient/Patient';
import Treatment from './Treatment/Treatment';
import Communications from './Communications/Communications';
import Procedure from 'components/Table/Procedure';
import {useSelector} from 'react-redux';
import useTreatments from './useTreatments';

function Phase2(props) {
  const [treatment, setTreatment] = React.useState(null);
  const {patientId, enabledFollowUps, personalInfo = {}} =
    props.currentPatient || {};
  const {firstName, lastName, email, phone, DOB } = personalInfo;

  const clinicId = useSelector(
    ({apiCallsReducer}) => apiCallsReducer?.clinicBasicInfo?.clinicId,
  );

  const {
    treatments,
    url,
    setTreatments,
    treatmentList,
    fetchTreatments,
    deleteTreatment,
  } = useTreatments({
    clinicId,
    patientId,
  });

  const setCurrentTreatment = (_, value) => {
    setTreatment(value);
  };

  const currentTreatment = (treatments && treatments[treatment]) || null;

  React.useEffect(() => {
    if (!treatment) {
      const selectedTreatment = Array.isArray(treatmentList)
        ? treatmentList[0]
        : null;

      const treatmentId = selectedTreatment
        ? selectedTreatment.id || null
        : null;

      if (treatmentId) {
        setTreatment(treatmentId);
      }
    }
  }, [treatmentList, treatment]);

  const updateTreatment = React.useCallback(
    (treatmentId, _treatment) => {
      setTreatments((currentTreatments) => {
        const pastTreatment = currentTreatments
          ? currentTreatments[treatmentId]
            ? {...currentTreatments[treatmentId]}
            : {}
          : {};

        return {
          ...currentTreatments,
          [treatmentId]: {...pastTreatment, ..._treatment},
        };
      });
    },
    [setTreatments],
  );

  return (
    <div>
      <div id="phase-2" className={`${styles.wrapper}`}>
        <div className={`${styles.content} ${styles.patient}`}>
          <Patient
            setCurrentTreatment={setCurrentTreatment}
            treatment={currentTreatment}
            treatmentId={treatment}
            treatments={treatments}
            updateTreatment={updateTreatment}
            currentPatient={props.currentPatient}
            url={`${url}/treatments`}
            treatmentList={treatmentList}
            fetchTreatments={fetchTreatments}
            deleteTreatment={deleteTreatment}
          />
        </div>
        <div className={styles.treatment}>
          <Treatment
            treatment={currentTreatment}
            treatmentId={treatment}
            clinicId={clinicId}
            updateTreatment={updateTreatment}
          />
        </div>
        <div className={`${styles.communications}`}>
          <Communications
            clinicId={clinicId}
            patientId={patientId}
            treatmentId={treatment}
            enabledFollowUps={enabledFollowUps}
            firstName={firstName}
            lastName={lastName}
            email={email}
            phone={phone}
            treatmentList={treatmentList}
            currentTreatment={currentTreatment}
            dob={DOB}
          />
        </div>
      </div>
      <div className={styles.insurances}>
        {currentTreatment ? (
          <Procedure procedures={currentTreatment?.procedures} />
        ) : (
          <div className={`${styles.placeholder} p-5`}>
            Please choose a Treatment
          </div>
        )}
      </div>
    </div>
  );
}

export default Phase2;

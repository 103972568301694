import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import General from '../General/General';
import Preventative from '../Preventative/Preventative';
import Basic from '../Basic/Basic';
import Major from '../Major/Major';
import Ortho from '../Ortho/Ortho';
import Employer from '../Employer/Employer';
import UserInfo from './UserInfo/UserInfo';
import EmptyPlaceholder from 'components/Placeholder/EmptyPlaceholder';
import {getInsurance} from 'actions/apiCallsActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Title from './Title';
import moment from 'moment';
import PDFButtons from './PDFButtons';
import OPTIONS_ENUM from 'constants/Enums/ProfileBenefitsOptionsEnum';
import OptionsButtons from './OptionsButtons/OptionsButtons';
import styles from './Options.module.scss';
import {Link} from 'react-router-dom';
import Button from 'components/Common/Button/Button';

function getZohoFormUrl({
  groupNumber = '',
  insuranceId = '',
  clinicName = '',
  clinicId = '',
  subscriberName = '',
  subscriberLast = '',
  subscriberDOB = '',
  patientName = '',
  patientLast = '',
  patientDOB = '',
  patientId = '',
  isDependent = false,
  zohoRecordId = '',
}) {
  return `/employer-benefits/${insuranceId}/${groupNumber}/${zohoRecordId}/clinicName=${clinicName}&clinicId=${clinicId}
  &subscriberName=${subscriberName}&subscriberLast=${subscriberLast}&isDependent=${
    isDependent ? 'Yes' : ''
  }&subscriberDOB=${parseDOB(
    subscriberDOB,
  )}&patientId=${patientId}&patientName=${patientName}&patientLast=${patientLast}&patientDOB=${parseDOB(
    patientDOB,
  )}`;
}

function parseDOB(dbo) {
  return dbo ? moment(dbo).format('DD-MMM-yyyy') : '';
}

function Options({
  groupNumber,
  insuranceId,
  patientData = {},
  profileBenefits,
  clinicBasicInfo,
  selectedInsurance,
  hideNewPlanRequest,
}) {
  const [activeOption, setActiveOption] = React.useState(0);
  const [benefits, setBenefits] = React.useState({
    employerBenefits: null,
    options: [],
    fullFilled: false,
  });

  const {personalInfo: patient, patientId, isDependent} = patientData;

  const buttonProps = {
    onClick: setActiveOption,
    activeOption,
  };

  React.useEffect(() => {
    getInsurance(insuranceId, groupNumber, setBenefits);
  }, [groupNumber, insuranceId]);

  const setEmployerBenefits = React.useCallback((employerBenefits) => {
    setBenefits((state) => ({...state, employerBenefits}));
  }, []);

  const {insuranceName} = benefits.employerBenefits || {};

  console.log(`groupNumber`, groupNumber);

  return (
    <div
      id="pdfPrint"
      className={`${styles.optionsContainer} container-fluid my-3`}>
      {insuranceId && groupNumber ? (
        benefits.fullFilled ? (
          benefits.employerBenefits ? (
            <>
              <div className="row">
                <div className="col-12 mt-4">
                  <div className="row">
                    {insuranceName && (
                      <>
                        <div className="col-10">
                          <Title>{insuranceName}</Title>
                        </div>
                        <div className="col-2">
                          <Link
                            to={{
                              pathname: `/employer-benefits/${insuranceId}/${groupNumber}/${benefits?.employerBenefits?.zohoRecordId}`,
                              state: {patientData, selectedInsurance},
                            }}
                            className={styles.editBtn}>
                            <Button>Edit</Button>
                          </Link>
                        </div>
                        <div
                          className={`col-12 ${styles.iconsContainer} d-flex justify-content-end`}>
                          {!profileBenefits && (
                            <PDFButtons
                              insuranceName={insuranceName}
                              groupNumber={groupNumber}
                              clinicBasicInfo={clinicBasicInfo}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <OptionsButtons buttonProps={buttonProps} />
              <UserInfo
                groupNumber={groupNumber}
                insurance={insuranceId}
                setEmployerBenefits={setEmployerBenefits}
                employerBenefits={benefits.employerBenefits}
                options={benefits.options}
                showEmployerPhoneNumber={profileBenefits}>
                {!hideNewPlanRequest && (
                  <Link
                    to={{
                      pathname: getZohoFormUrl({
                        groupNumber: groupNumber,
                        insuranceId: insuranceId,
                        clinicName: clinicBasicInfo?.clinicName,
                        clinicId: clinicBasicInfo?.clinicId,
                        subscriberName: selectedInsurance?.subscriberFirstName,
                        subscriberLast: selectedInsurance?.subscriberLastName,
                        subscriberDOB: selectedInsurance?.subscriberDOB,
                        patientName: patient?.firstName,
                        patientLast: patient?.lastName,
                        patientDOB: patient?.DOB,
                        patientId: patientId,
                        isDependent: isDependent,
                      }),
                      state: {patientData, selectedInsurance},
                    }}>
                    Submit New Plan Request
                  </Link>
                )}
              </UserInfo>
              <div className={`${styles.inputsContainer}`}>
                <RenderSelectedOption
                  employer={benefits.employerBenefits || {}}
                  activeOption={activeOption}
                />
              </div>
            </>
          ) : (
            <EmptyPlaceholder>
              <div>
                This patient's employer is not in the database. To add this
                employer's benefits, please{' '}
                <Link
                  to={{
                    pathname: getZohoFormUrl({
                      groupNumber: groupNumber,
                      insuranceId: insuranceId,
                      clinicName: clinicBasicInfo?.clinicName,
                      clinicId: clinicBasicInfo?.clinicId,
                      subscriberName: selectedInsurance?.subscriberFirstName,
                      subscriberLast: selectedInsurance?.subscriberLastName,
                      subscriberDOB: selectedInsurance?.subscriberDOB,
                      patientName: patient?.firstName,
                      patientLast: patient?.lastName,
                      patientDOB: patient?.DOB,
                      patientId: patientId,
                      isDependent: isDependent,
                      zohoRecordId: benefits?.employerBenefits?.zohoRecordId,
                    }),
                    state: {patientData, selectedInsurance},
                  }}>
                  click here
                </Link>
              </div>
            </EmptyPlaceholder>
          )
        ) : (
          <div className="row justify-content-center align-items-center">
            <CircularProgress />
          </div>
        )
      ) : (
        <EmptyPlaceholder
          text={`No group number is associated to this patient, if applicable, please
        hit "Edit Details" on the top left to add a group number in order to
        associate this patient to an employer.`}
        />
      )}
    </div>
  );
}

const RenderSelectedOption = React.memo(({employer, activeOption}) => {
  console.log(`employer`, employer);
  switch (activeOption) {
    case OPTIONS_ENUM.employer:
      return (
        <Employer
          values={employer.employerInformation}
          employerName={employer.employerName}
          planName={employer.planName}
          state={employer.state}
          disableInputs
        />
      );
    case OPTIONS_ENUM.general:
      return <General values={employer.general} disableInputs />;
    case OPTIONS_ENUM.preventative:
      return <Preventative values={employer.preventive} disableInputs />;
    case OPTIONS_ENUM.basic:
      return <Basic values={employer.basic} disableInputs />;
    case OPTIONS_ENUM.major:
      return <Major values={employer.major} disableInputs />;
    case OPTIONS_ENUM.ortho:
      return (
        <Ortho
          values={employer.ortho}
          coordinationCOB={employer.general.coordinationCOB}
          disableInputs
        />
      );
    case OPTIONS_ENUM.all:
      return (
        <>
          <Employer
            values={employer.employerInformation}
            employerName={employer.employerName}
            planName={employer.planName}
            state={employer.state}
            disableInputs
          />
          <General values={employer.general} disableInputs />
          <Preventative values={employer.preventive} disableInputs />
          <Basic values={employer.basic} disableInputs />
          <Major values={employer.major} disableInputs />
          <Ortho
            values={employer.ortho}
            coordinationCOB={employer.general.coordinationCOB}
            disableInputs
          />
        </>
      );
    default:
      return null;
  }
});

Options.propTypes = {
  selectedInsurance: PropTypes.object,
  clinicBasicInfo: PropTypes.object,
  groupNumber: PropTypes.string,
  insuranceId: PropTypes.string,
  profileBenefits: PropTypes.bool,
  patientData: PropTypes.object,
};

const mapStateToProps = ({apiCallsReducer}) => ({
  clinicBasicInfo: apiCallsReducer.clinicBasicInfo,
});

export default connect(mapStateToProps)(Options);

import React from 'react';
import firebase from 'firebase';
import {reloadData, loginUser, searchForPatient} from './index';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {Spinner1} from '../components/Spinner1';
import {RESETALL} from './types';

const no_session_urls = ['/LoginEmailPass', '/ResetPassword', '/SignUp'];

function useAuth() {
  const firstTime = React.useRef(true);
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  React.useEffect(() => {
    (async function checkAuth() {
      setLoading(true);
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          if (history.location.pathname === '/SignUp') {
            firstTime.current = false;
          }

          if (firstTime.current) {
            firstTime.current = false;
            setLoading((isLoading) => {
              if (!isLoading) {
                return true;
              }
              return isLoading;
            });
            await dispatch(
              reloadData((clinicBasicInfo) => {
                let url = '';
                if (
                  clinicBasicInfo.clinicData.basicInfo.hideInsurance === true
                ) {
                  url = '/section/production';
                } else {
                  url = '/section/insurance';
                }
                if (
                  [...no_session_urls, '/'].includes(history.location.pathname)
                ) {
                  history.replace(url);
                } else if (
                  history.location.pathname.includes('insurance') &&
                  clinicBasicInfo.clinicData.basicInfo.hideInsurance === true
                ) {
                  history.replace(url);
                } else if (
                  history.location.pathname.includes('production') &&
                  clinicBasicInfo.clinicData.basicInfo.hideProduction === true
                ) {
                  history.replace(url);
                }
                setLoading(false);
              }),
            );
          } else {
            setLoading(false);
          }
        } else {
          const email = query.get('email');
          const password = query.get('password');
          // Below variables are for if we wanted to search fo first/lastName of the patient
          // const firstName = query.get('firstName');
          // const lastName = query.get('lastName');
          // const DOB = query.get('DOB');
          // const clinicId = query.get('clinicId');

          let skipRedirect = false;
          let patientId = null;
          if (email && password) {
            await dispatch(loginUser(email, password)).then(async (result1) => {
              // if (firstName && lastName && DOB && clinicId) {
              //   await dispatch(
              //     searchForPatient(firstName, lastName, DOB, clinicId),
              //   ).then((result2) => {});
              // }

              console.log('result1', result1);
              skipRedirect = true;

              if (!result1.error) {
                if (
                  history.location.pathname.includes('details') ||
                  history.location.pathname.includes('profile-benefits')
                ) {
                  // if (patientId) {
                  //   history.replace(
                  //     `${history.location.pathname}/${patientId}`,
                  //   );
                  // } else {
                    history.replace(history.location.pathname);
                } else if (result1.clinicBasicInfo.hideInsurance === true) {
                  history.replace('/section/production');
                } else {
                  history.replace('/section/insurance');
                }
              } else {
                skipRedirect = false;
              }
            });
          }
          // User is signed out of firebase send them to sign-in.
          if (!skipRedirect) {
            if (!no_session_urls.includes(history.location.pathname)) {
              firstTime.current = true;
              dispatch({type: RESETALL});
              history.replace('/');
            }
          }

          setLoading(false);
        }
      });
    })();
  }, [dispatch, history]);

  // console.log(`loading`, loading);
  return <Spinner1 open={loading} />;
}

export default useAuth;

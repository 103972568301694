import React from 'react';

const renderDateInsideCell = (UNIX) => {
  if (UNIX && UNIX !== '') {
    const date = new Date(UNIX);
    let month = date.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
    const year = date.getFullYear();
    let day = date.getDate();
    if (month === 11 || month === 12 || month === 10) {
    } else {
      month = `0${month}`;
    }
    if (
      day === 0 ||
      day === 1 ||
      day === 2 ||
      day === 3 ||
      day === 4 ||
      day === 5 ||
      day === 6 ||
      day === 7 ||
      day === 8 ||
      day === 9
    ) {
      day = `0${day}`;
    }
    const combinedDate = `${month}/${day}/${year}`;
    return <div>{combinedDate}</div>;
  }
  return <div>--</div>;
};

export default renderDateInsideCell;

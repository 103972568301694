import React, {Fragment, useEffect, useState} from 'react';
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import UseForm from '../UseForm';
import validate from '../LoginEmailPassValidationRules';
import TextField from '@material-ui/core/TextField';
import logo from '../123insurancelogo.png';
import {connect} from 'react-redux';
import {loginUser} from '../../actions';
import {useHistory} from 'react-router-dom';
import {Spinner1} from '../Spinner1';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  fullPageContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: '"Roboto"',
    [theme.breakpoints.down('xs')]: {
      //height: 'auto',
    },
  },

  maincontainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      //height: 'auto',
    },
    [theme.breakpoints.only('md')]: {
      padding: '0 0 40px 0',
    },
  },
  containerlogin: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 14px 28px #7F7F7F, 0 10px 10px #7F7F7F',
    overflow: 'hidden',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    width: '50%',
    height: '60%',
    [theme.breakpoints.down('md')]: {
      width: '90%',
      //height: 'auto',
    },
    [theme.breakpoints.only('xs')]: {
      alignItems: 'flex-start',
    },
    [theme.breakpoints.only('sm')]: {
      height: '538px',
    },
    [theme.breakpoints.only('md')]: {
      height: '600px',
      justifyContent: 'space-evenly',
    },
  },
  formcontainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // padding: '25px 0 0 0',
    height: '100%',
    '& h2': {
      margin: 0,
      padding: '15px 0 0 0',
    },
  },
  credentialstext: {
    margin: 0,
    padding: '15px 0',
  },
  signinform: {
    width: '80%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.only('md')]: {
      width: '100%',
    },
  },
  buttoncontainer: {
    padding: '15px',
  },
  button: {
    borderRadius: '20px',
    border: '2px solid #FFFFFF',
    backgroundColor: '#1B7FE1',
    color: '#FFFFFF',
    fontSize: '12px',
    fontWeight: 'bold',
    padding: '12px 45px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    outline: 'none',
    //margin: '15px auto 15px auto',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: '#1B7FE1',
      border: '2px solid #1B7FE1',
    },
  },
  redalerts: {
    color: 'red',
    fontSize: '12px',
    margin: '0',
    textAlign: 'center',
    padding: '5px 0 10px 0',
  },
  password: {
    padding: '10px 0',
    color: '#0080FF',
    cursor: 'pointer',
    '&:hover': {
      color: '#0080FF',
    },
  },

  logo: {
    width: '320px',
    [theme.breakpoints.down('sm')]: {
      width: '200px',
      padding: '10px 0 5px 0',
    },
  },
  logocontainer: {
    display: 'flex',
    height: '20%',
    padding: '30px 0',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      padding: 0,
    },
  },
}));

function LoginEmailPass(props) {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#1B7FE1',
        contrastText: '#fff', //button text white instead of black
      },
      background: {
        default: '#fff',
      },
    },
    typography: {
      fontFamily: '"Roboto"',
    },
  });

  const styles = useStyles();

  const [loadingLocal, setLoadingLocal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    setLoadingLocal(false);
  }, []);

  const history = useHistory();
  const {values, errors, handleChange, handleSubmit} = UseForm(
    LoginEmailPass1,
    validate,
  );

  async function LoginEmailPass1() {
    if (loadingLocal === false) {
      setLoadingLocal(true);
      const result = await props.loginUser(values.email, values.password);
      if (!result.error) {
        setLoadingLocal(false);
        if (result.clinicBasicInfo.hideInsurance === true) {
          history.replace('/section/production');
        } else {
          history.replace('/section/insurance');
        }
      } else {
        // Need to render error and setLoadingLocal(false)
        setLoadingLocal(false);
        setErrorMessage(result.errorMessage);
        // set State to render error message to the user
      }
    }
  }

  //*****************Return Component********************

  return (
    <div className={styles.fullPageContainer}>
      <div className={styles.maincontainer}>
        <div className={styles.logocontainer}>
          <img src={logo} alt="123 Insurance Logo" className={styles.logo} />
        </div>
        <div className={styles.containerlogin}>
          <Spinner1 open={loadingLocal} />
          <div className={styles.formcontainer}>
            <h2>Sign-in</h2>
            <p className={styles.credentialstext}>
              Please enter your credentials
            </p>
            <form
              onSubmit={handleSubmit}
              noValidate
              className={styles.signinform}>
              <MuiThemeProvider theme={theme}>
                <TextField
                  variant="outlined"
                  required
                  name="email"
                  label="Email Address"
                  type="email"
                  id="email-address"
                  className={`input ${errors.email && 'is-danger'}`}
                  onChange={handleChange}
                  value={values.email || ''}
                  style={{width: '80%', padding: '15px 0'}}
                  inputProps={{
                    style: {
                      height: '40px',
                      padding: '0 14px',
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      height: '30px',
                      marginTop: '10px',
                      fontSize: '15px',
                      fontFamily: 'Roboto',
                    },
                  }}
                />
                {errors.email && (
                  <p className={styles.redalerts}>{errors.email}</p>
                )}
                <TextField
                  variant="outlined"
                  required
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  className={`input ${errors.password && 'is-danger'}`}
                  onChange={handleChange}
                  value={values.password || ''}
                  style={{width: '80%'}}
                  inputProps={{
                    style: {
                      height: '40px',
                      padding: '0 14px',
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      height: '30px',
                      marginTop: '-5px',
                      fontSize: '15px',
                      fontFamily: 'Roboto',
                    },
                  }}
                />
              </MuiThemeProvider>
              {errors.password && (
                <p className={styles.redalerts}>{errors.password}</p>
              )}
              {errorMessage ? (
                <Fragment>
                  <br />
                  <p className={styles.redalerts}>{errorMessage}</p>
                </Fragment>
              ) : null}
              <div className={styles.buttoncontainer}>
                <button className={styles.button} type="submit">
                  Sign-In
                </button>
              </div>

              <span
                onClick={() => {
                  history.push('/ResetPassword');
                }}
                className={styles.password}>
                Forgot Password?
              </span>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProp = (state) => {
  return {
    clinicBasicInfo: state.apiCallsReducer.clinicBasicInfo,
    clinicUsersList: state.apiCallsReducer.clinicUsersList,
    userInfo: state.apiCallsReducer.userInfo,
    clinicPatientsList: state.apiCallsReducer.clinicPatientsList,
    clinicTemplatesList: state.apiCallsReducer.clinicTemplatesList,
    clinicUsageInfo: state.apiCallsReducer.clinicUsageInfo,
    clinicLocationsList: state.apiCallsReducer.clinicLocationsList,
  };
};

export default connect(mapStateToProp, {loginUser})(LoginEmailPass);

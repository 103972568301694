import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Insurance from 'components/Profile/ProfileTabs/Phase2/Insurance/Insurance';
import styles from './ProfileTabs.module.scss';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import {useSelector, useDispatch} from 'react-redux';
import TableData from 'components/Table/TableData';
import ProductionTable from 'components/Table/ProductionTable';
import ProfileBenefits from 'components/Profile/ProfileBenefits/ProfileBenefits';
import Treatment from 'components/Treatment/Treatment';
import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  Link,
  useParams,
} from 'react-router-dom';
import {setCurrentPatient} from 'actions/patientActions';
// import axios from 'axios';
// import TableData from 'components/Table/TableData';

const theme = createMuiTheme({
  typography: {
    fontFamily: '"Roboto"',
  },
});

// const urls = {
//   insurance: ['/insurance', '/insurance/profile-benefits'],
//   production: ['/production', '/production/details'],
//   sectionBase: ['/insurance', '/production'],
// };

function ProfileTabs(props) {
  const {path} = useRouteMatch();
  const {patientId} = useParams();
  const {pathname} = props.location;
  const history = useHistory();

  const currentPatient = useSelector(
    ({patientReducer}) => patientReducer.currentPatient,
  );
  const clinicBasicInfo = useSelector(
    ({apiCallsReducer}) => apiCallsReducer?.clinicBasicInfo,
  );

  // const makeRequest = () => {
  //   axios.post(
  //     'https://us-central1-insurance-ca69d.cloudfunctions.net/sendFollowUp',
  //     {
  //       clinicId: 'tnEvS4xFIbPSB7fWjWlD0Nc765u2',
  //       clinicBasicInfo: {clinicName: 'Weston Clinic'},
  //     },
  //   );
  // };

  // if (clinicBasicInfo.hideInsurance || clinicBasicInfo.hideProduction) {
  //   return <div>hi</div>;
  // }

  return (
    <MuiThemeProvider theme={theme}>
      {/* <button onClick={makeRequest}>Make request</button> */}
      {clinicBasicInfo.hideInsurance ||
      clinicBasicInfo.hideProduction ? null : (
        <Tabs
          value={pathname.indexOf('production') > -1 ? 1 : 0}
          indicatorColor="primary"
          textColor="primary"
          className={styles.tabs}
          centered
          onChange={(_, value) => {
            console.log('onchange currentPatient', currentPatient);
            let newPath = '';
            if (value === 1) {
              if (
                (currentPatient || patientId) &&
                (pathname.indexOf('profile-benefits') > -1 ||
                  pathname.indexOf('details') > -1)
              ) {
                newPath = `/production/details/${currentPatient.patientId}`;
              } else {
                newPath = '/production';
              }
            } else {
              if (
                (currentPatient || patientId) &&
                (pathname.indexOf('details') > -1 ||
                  pathname.indexOf('profile-benefits') > -1)
              ) {
                newPath = `/insurance/profile-benefits/${currentPatient.patientId}`;
              } else {
                newPath = '/insurance';
              }
            }

            history.push(path + newPath);
          }}
          aria-label="disabled tabs example">
          <Tab label="Insurance" className={styles.tab} value={0} />
          <Tab label="Production" className={styles.tab} value={1} />
          {currentPatient &&
            (pathname.indexOf('profile-benefits') > -1 ||
              pathname.indexOf('details') > -1) && (
              <div className={styles.linkWrapper}>
                <Link
                  to={`/section/${
                    pathname.indexOf('profile-benefits') > 0 ? '' : 'production'
                  }`}>
                  Back to{' '}
                  {pathname.indexOf('profile-benefits') > 0
                    ? 'insurance'
                    : 'production'}{' '}
                  table
                </Link>
              </div>
            )}
        </Tabs>
      )}
      <Switch>
        <Route exact path={`${path}/production`}>
          <ProductionHandler />
        </Route>
        <Route path={`${path}/production/details/:patientId?`}>
          <Treatment />
        </Route>
        <Route path={`${path}/insurance/profile-benefits/:patientId?`}>
          <ProfileBenefits />
        </Route>
        <Route>
          <InsuranceHandler />
        </Route>
      </Switch>
    </MuiThemeProvider>
  );
}

function ProductionHandler({currentPatient, ...props}) {
  const dispatch = useDispatch();
  const {path} = useRouteMatch();

  const [filter, setFilter] = React.useState(true);

  React.useEffect(() => {
    console.log('currentPatient in production handler', currentPatient);
    if (currentPatient) {
      dispatch(setCurrentPatient(null));
    }
  }, [currentPatient, dispatch]);

  return (
    <>
      <Insurance {...props} setFilter={setFilter} filter={filter} />
      <ProductionTable
        redirectTo={`${path}/details`}
        {...props}
        filter={filter}
      />
    </>
  );
}

function InsuranceHandler({currentPatient, ...props}) {
  const dispatch = useDispatch();
  const {path} = useRouteMatch();

  React.useEffect(() => {
    console.log('currentPatient in insurance handler', currentPatient);
    if (currentPatient) {
      dispatch(setCurrentPatient(null));
    }
  }, [currentPatient, dispatch]);

  return <TableData redirectTo={`${path}/insurance/profile-benefits`} />;
}

export default ProfileTabs;

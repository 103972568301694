import React from 'react';
import PropTypes from 'prop-types';
import Datepicker from 'components/Form/DatePicker/Datepicker';
import {useSelector} from 'react-redux';
import Input from 'components/Form/Input/Input';
import styles from './Procedures.module.scss';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from 'components/Form/Autocomplete/ADAAutocomplete';
// import {getADACodes} from 'Utils';
import Select from 'components/Form/Select/Select';

// const ADACodes = getADACodes();

function calculateTotal({totalFee = 0, discount = 0, insuranceAmount = 0}) {
  if (totalFee) {
    return (
      Number(totalFee) -
      (Number(discount) || 0) -
      (Number(insuranceAmount) || 0)
    );
  }

  return totalFee;
}

const Procedures = React.forwardRef((props, ref) => {
  const ADACodes = useSelector(
    ({apiCallsReducer}) => apiCallsReducer?.adaCodeKeys,
  );
  const {
    id,
    errors,
    index,
    divider,
    removeItem,
    onChange,
    adaCode = '',
    phase = '1',
    scheduledDate = '',
    totalFee = 0,
    discount = 0,
    insuranceAmount = 0,
    patientAmount = 0,
  } = props;

  const handleUpdate = (name, value) => {
    onChange(id, name, value);
  };

  const handleUpdateTotalValues = (name, value) => {
    const values = {
      totalFee: props.totalFee,
      discount: props.discount,
      insuranceAmount: props.insuranceAmount,
      [name]: value,
    };

    const newPatientAmount = calculateTotal(values);

    onChange(id, name, value);
    onChange(id, 'patientAmount', newPatientAmount);
  };

  return (
    <div className="row">
      {divider && <hr className={styles.divider} />}
      <div className="col-12">
        <div className="row">
          <div className="col-4 flex ycenter">
            <Autocomplete
              label="ADA Code"
              name="adaCode"
              value={adaCode}
              options={ADACodes}
              onChange={handleUpdate}
              error={errors.adaCode}
            />
          </div>
          <div className="col-4 flex ycenter">
            <Select
              label="Phase"
              value={phase}
              name="phase"
              defaultValue="1"
              options={[
                {
                  name: '1',
                  id: '1',
                },
                {
                  name: '2',
                  id: '2',
                },
                {
                  name: '3',
                  id: '3',
                },
                {
                  name: '4',
                  id: '4',
                },
                {
                  name: '5',
                  id: '5',
                },
                {
                  name: '6',
                  id: '6',
                },
                {
                  name: '7',
                  id: '7',
                },
                {
                  name: '8',
                  id: '8',
                },
                {
                  name: '9',
                  id: '9',
                },
                {
                  name: '10',
                  id: '10',
                },
                {
                  name: '11',
                  id: '11',
                },
                {
                  name: '12',
                  id: '12',
                },
                {
                  name: '13',
                  id: '13',
                },
                {
                  name: '14',
                  id: '14',
                },
                {
                  name: '15',
                  id: '15',
                },
                {
                  name: '16',
                  id: '16',
                },
                {
                  name: '17',
                  id: '17',
                },
                {
                  name: '18',
                  id: '18',
                },
                {
                  name: '19',
                  id: '19',
                },
                {
                  name: '20',
                  id: '20',
                },
              ]}
              error={errors.phase}
              onChange={handleUpdate}
            />
          </div>
          <div className="col-4 flex ycenter">
            <Datepicker
              label="Scheduled Date"
              value={scheduledDate}
              name="scheduledDate"
              onChange={handleUpdate}
              error={errors.scheduledDate}
            />
          </div>
        </div>
      </div>
      <div className="col-12 mt-2">
        <div className="row">
          <div className="col-3">
            <Input
              label="Total Fee"
              value={totalFee}
              name="totalFee"
              onChange={handleUpdateTotalValues}
              type="number"
              error={errors.totalFee}
            />
          </div>
          <div className="col-3">
            <Input
              label="Discount"
              value={discount}
              name="discount"
              onChange={handleUpdateTotalValues}
              type="number"
              error={errors.discount}
            />
          </div>
          <div className="col-3">
            <Input
              label="Insurance"
              value={insuranceAmount}
              name="insuranceAmount"
              onChange={handleUpdateTotalValues}
              type="number"
              error={errors.insuranceAmount}
            />
          </div>
          <div className="col-3">
            <Input
              label="Patient Fee"
              value={patientAmount}
              name="patientAmount"
              onChange={handleUpdate}
              type="number"
              disabled
              error={errors.patientAmount}
            />
          </div>
        </div>
      </div>
      {index > 0 && (
        <IconButton onClick={() => removeItem(id)}>
          <DeleteIcon />
        </IconButton>
      )}
    </div>
  );
});

Procedures.propTypes = {
  index: PropTypes.number,
  divider: PropTypes.bool,
  id: PropTypes.string.isRequired,
  adaCode: PropTypes.any.isRequired,
  totalFee: PropTypes.any.isRequired,
  discount: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  patientAmount: PropTypes.any.isRequired,
  insuranceAmount: PropTypes.any.isRequired,
  scheduledDate: PropTypes.string.isRequired,
};

export default React.memo(Procedures);

import React from 'react';
import PropTypes from 'prop-types';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import styles from './Datepicker.module.scss';

function Datepicker(props) {
  return (
    <div className="mt-1">
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          className={styles.datepicker}
          autoOk
          variant="inline"
          inputVariant="outlined"
          label="Procedure Date"
          fullWidth
          format="MM/DD/yyyy"
          placeholder="MM/DD/YYYY"
          value={props.value || null}
          onChange={(date) => {
            const value = date ? date.utc().format() : '';
            props.onChange(props.name, value);
          }}
          inputProps={{
            style: {
              padding: '0 14px',
              paddingRight: 0,
              height: '40px',
            },
          }}
          InputLabelProps={{
            style: {
              // paddingRight: 0,
              height: '40px',
              marginTop: '-5px',
              fontSize: '15px',
              fontFamily: 'Roboto',
            },
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}

Datepicker.propTypes = {
  value: PropTypes.any,
  name: PropTypes.string.isRequired,
};

export default Datepicker;

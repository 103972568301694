import React, {useState, useEffect} from 'react';
import { useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
// import Navbar from '../Navbar/Navbar';
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import {
  Grid,
  MenuItem,
  InputLabel,
  Select,
  IconButton,
  FormControl,
  Button,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import HistoryIcon from '@material-ui/icons/History';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import {DataGrid} from '@material-ui/data-grid';
import {connect} from 'react-redux';
import CodeModal from '../CodeModal/CodeModal.js';
import TransitionsModal from '../Modal/Modal.js';
// import adaCodeData from '../../data/adaCodeList.json';
import {ThemedModal} from '../ThemedModal';
import {Spinner1} from '../Spinner1';
import {
  makeOnederfulCall,
  parseData,
  parseGeneralFilterData,
} from '../../actions';
import {useAlert} from 'react-alert';
// import data from '../../data/adaCodeList.json';

//*************************************************************************************Component Styling Sheet***************************************************

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: 'blue',
    '& .MuiDataGrid-sortIcon': {
      color: 'black',
    },
    '& .MuiDataGrid-colCellWrapper': {
      backgroundColor: '#e8e8e8',
      textAlign: 'center',
    },
    '& .': {
      height: '400px',
    },
    '& .MuiDataGrid-cell': {
      textAlign: 'start',
    },
    fontFamily: 'Roboto',
  },
  topcontainer: {
    padding: '10px 0 50px 0',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      padding: '10px 0 20px 0',
    },
  },
  centerdatacontainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  createicon: {
    padding: theme.spacing(1),
    textAlign: 'center',
    marginLeft: '50px',
    flex: 1,
    position: 'absolute',
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
    },
  },
  nametitle: {
    padding: theme.spacing(1),
    color: '#1B7FE1',
    fontSize: '30px',
    fontWeight: 1000,
    margin: '0 0 25px 0',
    flex: 1,
  },
  onederfulstatus: {
    right: 200,
    position: 'absolute',
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      right: 0,
      textAlign: 'center',
    },
  },
  onederfulicon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAling: 'center',
    color: '#1B7FE1',
    padding: theme.spacing(1),
    right: 30,
    flex: 1,
    position: 'absolute',
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      right: 0,
    },
  },
  onederbutton: {
    fontSize: '70px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '40px',
    },
  },
  generalSearchContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  runreportbutton: {
    backgroundColor: '#1B7FE1',
    margin: 'auto',
  },
  topcardscontainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  leftcard: {
    width: '45%',
    [theme.breakpoints.down('xs')]: {
      width: '85%',
    },
  },
  rightcard: {
    width: '45%',
    [theme.breakpoints.down('xs')]: {
      width: '85%',
    },
  },
  bottomcard: {
    flexDirection: 'row',
    width: '90%',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    margin: 'auto',
  },
  card: {
    backgroundColor: '#EFEFEF',
    textAlign: 'left',
    margin: 15,
    height: '90%',
  },
  titletext: {
    backgroundColor: '#EFEFEF',
    fontSize: '16px',
    padding: '10px',
    position: 'absolute',
    fontWeight: 'bold',
    color: '#1B7FE1',
  },
  divider: {
    borderTop: '2px solid #1B7FE1',
    width: '100%',
    float: 'right',
  },
  divider2: {
    borderTop: '2px solid #1B7FE1',
    width: '90%',
    float: 'right',
    [theme.breakpoints.down('md')]: {
      width: '85%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '75%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    },
  },
  subtitlecontainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    padding: '20px 0',
  },
  historyicon: {
    position: 'absolute',
    right: '80px',
    margin: '0 10px 0 0',
    padding: theme.spacing(1),
  },
  bottomicons: {
    float: 'right',
    padding: '20px',
  },
  subtitleContent: {
    margin: theme.spacing(1),
    color: '#161719',
    fontSize: '14px',
    fontWeight: 1000,
    paddingRight: '10px',
    fontFamily: 'Roboto',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '80%',
  },

  tableContainer: {
    width: '100%',
    minHeight: 500,
    cursor: 'pointer',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'scroll',
    [theme.breakpoints.down('xs')]: {
      overflow: 'scroll',
    },
  },
  paper: {
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 0, 0),
    backgroundColor: '#1B7FE1',
    borderRadius: '10px',
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '70%',
      margin: 'auto',
      marginBottom: '20px',
    },
    maincontainer: {
      height: '100%',
      display: 'flex',
      // flex: 1,
      flexDirection: 'column',
    },
    // Themed Modal elements
    clinicSelectRoot: {
      width: 120,
      border: '1px solid #BFBFBF',
      paddingLeft: 10,
    },
  },
}));

function Profile(props) {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#1B7FE1',
        contrastText: '#fff', //button text white instead of black
      },
      background: {
        default: '#fff',
      },
    },
    typography: {
      fontFamily: '"Roboto"',
    },
  });

  const location = useLocation();
  const alert = useAlert();
  const [patientData, setPatientData] = useState({});
  const [patientInsuranceArray, setPatientInsuranceArray] = useState([]);
  const [selectedInsurance, setSelectedInsurance] = useState({});
  const [selectedOnederfulData, setSelectedOnederfulData] = useState({});
  const [selectedTemplate, setTemplate] = useState({});
  // Filtering States
  const [availableServiceTypesArray, setAvailableServiceTypeArray] = useState(
    [],
  );
  //const [availableCoverageLevelArray, setAvailableCoverageLevelArray] = useState([]);
  //const [availableNetworkArray, setAvailableNetworkArray] = useState([]);
  //const [availablePlanPeriodArray, setAvailablePlanPeriodArray] = useState([]);

  const [generalSearchFilter, setGeneralSearchFilter] = useState({
    service_type: '',
    network: '',
    coverage_level: '',
    plan_period: '',
  });
  const [rawJSONData, setRawPatientJSONData] = useState({});
  //const [isReady, setIsPDFReady] = useState(false);

  // End Filtering States
  const [selectedNPINumber, setSelectedNPI] = useState('');
  const [NPIListArray, setNPIListArray] = useState([]);
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [rootWidth, setRootWidth] = useState(0);
  // const [rootHeight, setRootHeight] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [openNPIModal, setOpenNPIModal] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [
    selectedDataToSendToCodeModal,
    setSelectedDataToSendToCodeModal,
  ] = useState({});
  const [
    selectedCodeToSendToCodeModal,
    setSelectedCodeToSendToCodeModal,
  ] = useState({});
  const adaCodeData = useSelector(
    ({apiCallsReducer}) => apiCallsReducer?.adaCodeList,
  );

  useEffect(() => {
    setRootWidth(document.getElementById('root').clientWidth);
    if (location.state) {
      const patientData1 = location.state.patientData;
      setInitialValues(patientData1);
      determineNPIArray();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //****************************Extra Styling********************************
  const classes = useStyles();
  const handleChangeTemplate = (event, index) => {
    let selectedTemplateObject = {};
    props.clinicTemplatesList.forEach((object) => {
      if (object.name === event.target.value) {
        selectedTemplateObject = object;
      }
    });
    setTemplateAndParse(selectedTemplateObject, patientData, selectedInsurance);
  };

  const setTemplateAndParse = async (template, patientData1, insurance) => {
    const insuranceId = insurance.id;
    // To check if data exists
    if (
      template.codes &&
      patientData1?.usageHistory &&
      patientData1.usageHistory[insuranceId]
    ) {
      const usageHistory = patientData1.usageHistory[insuranceId];
      let values = Object.values(usageHistory);
      // To sort times from oldest to newest
      values.sort();
      const length = values.length - 1;
      const lengthbackup1 = length - 1;
      const lengthbackup2 = length - 2;
      const lengthbackup3 = length - 3;
      const lengthbackup4 = length - 4;

      let patientJSONData = values[length];

      if (patientJSONData.error === true) {
        setTemplate(template);
        return;
      }

      // Below is to go to the previous JSON if the current history call has error: true
      // Checking if there's an error in first JSON history, if so go to next
      if (patientJSONData.error === true || !patientJSONData) {
        patientJSONData = values[lengthbackup1];
      }
      // Checking if second in history has error
      if (patientJSONData.error === true || !patientJSONData) {
        patientJSONData = values[lengthbackup2];
      }
      if (patientJSONData.error === true || !patientJSONData) {
        patientJSONData = values[lengthbackup3];
      }
      if (patientJSONData.error === true || !patientJSONData) {
        patientJSONData = values[lengthbackup4];
      }
      setRawPatientJSONData(patientJSONData);
      // Checking if third in history has error
      const parsedData = await props.parseData(
        adaCodeData,
        patientJSONData,
        insurance.insurance,
      );
      makeArrayOfServiceTypes(patientJSONData);
      console.log('parsedData', parsedData);
      setSelectedOnederfulData(parsedData.parsedByCode);
    }
    setTemplate(template);
  };

  // ************* FILTERING - Below is for setting what filter options and then handling filtering ******************
  const makeArrayOfServiceTypes = (patientJSONData) => {
    let availableServiceTypesObject = {};
    let availableCoverageLevelObject = {};
    let availableNetworkObject = {};
    let availablePlanPeriodObject = {};

    // we will add service_type: true into the object. This will basically just replace anything that already exists
    const mapOfType = ['deductible', 'maximums', 'limitations', 'coinsurance'];
    mapOfType.forEach((ele, index) => {
      // this if is to check if for example deductible or limitations, etc. exists in the JSONData
      if (patientJSONData[ele]) {
        // Now we need to loop through every data point in the deductible array
        patientJSONData[ele].forEach((ele1) => {
          if (ele1.service_type && ele1.service_type !== 'undefined') {
            availableServiceTypesObject[ele1.service_type] = true;
          }
          if (ele1.coverage_level && ele1.coverage_level !== 'undefined') {
            availableCoverageLevelObject[ele1.coverage_level] = true;
          }
          if (ele1.network && ele1.network !== 'undefined') {
            availableNetworkObject[ele1.network] = true;
          }
          if (ele1.plan_period && ele1.plan_period !== 'undefined') {
            availablePlanPeriodObject[ele1.plan_period] = true;
          }
          return;
        });
      }
    });

    // Below are to only pass back values when something is found
    let availableServiceTypesArray1 = availableServiceTypesObject
      ? Object.keys(availableServiceTypesObject)
      : [];
    availableServiceTypesArray1.sort();
    let availableCoverageLevelArray1 = availableCoverageLevelObject
      ? Object.keys(availableCoverageLevelObject)
      : [];
    availableCoverageLevelArray1.sort();
    let availableNetworkArray1 = availableNetworkObject
      ? Object.keys(availableNetworkObject)
      : [];
    availableNetworkArray1.sort();
    //let availablePlanPeriodArray1 = availablePlanPeriodObject
    //  ? Object.keys(availablePlanPeriodObject)
    //  : [];
    //availableNetworkArray1.sort();

    setAvailableServiceTypeArray(availableServiceTypesArray1);
    // handleGeneralFilterChange('service_type', 'all');
    const newGeneralSearchFilter = {
      ...generalSearchFilter,
      service_type: 'all',
    };
    props.parseGeneralFilterData(newGeneralSearchFilter, patientJSONData);
    //setAvailableCoverageLevelArray(availableCoverageLevelArray1);
    //setAvailableNetworkArray(availableNetworkArray1);
    //setAvailablePlanPeriodArray(availablePlanPeriodArray1);
  };

  const handleGeneralFilterChange = (name, value) => {
    const newFilter = {...generalSearchFilter, [name]: value};
    setGeneralSearchFilter(newFilter);
  };

  const capitalizeFirstLetterAndRemoveUnderscore = (word) => {
    if (word && word.length > 1) {
      let capitalized = word.replace(/\b(\w)/g, (s) => s.toUpperCase());
      capitalized = capitalized.replace(/_/g, ' ');
      return capitalized;
    }
    return word;
  };

  const handleClickRunGeneralFilterReport = async (e) => {
    // call parseData but for service type filter
    if (e) {
      e.preventDefault();
      console.log('generalSearchFilter', generalSearchFilter);
      const generalFilteredData = await props.parseGeneralFilterData(
        generalSearchFilter,
        rawJSONData,
      );
      console.log(generalFilteredData);
      setSelectedDataToSendToCodeModal(generalFilteredData);
      setSelectedCodeToSendToCodeModal('Filtered Report');
      setOpenModal(true);
    }
  };
  // ********************* END FILTERING ********************

  const setInitialValues = (patientData1) => {
    console.log('patientData1', patientData1);
    setPatientData(patientData1);
    // to provide array of various insurances the patient has on local state;
    const insuranceArray = Object.values(patientData1.insurance);
    setPatientInsuranceArray(insuranceArray);
    let primaryInsurance = {};
    // forEach loop below is to check for the primary insurance and set it as selected as default
    insuranceArray.forEach((object) => {
      if (object.primaryInsurance === true) {
        primaryInsurance = object;
      }
    });
    setSelectedInsurance(primaryInsurance);
    // To set template as [0]
    if (props.clinicTemplatesList.length > 0) {
      setTemplateAndParse(
        props.clinicTemplatesList[0],
        patientData1,
        primaryInsurance,
      );
    }
  };

  const handleChangeInsurance = (value) => {
    const insurance = patientData.insurance[value];
    setSelectedInsurance(insurance);
  };

  const onCallOnederulPress = async () => {
    // const npiNumber = '1851549562';
    const npiNumber = selectedNPINumber;
    const {memberId, insurance, groupNumber, id} = selectedInsurance;
    const insuranceId = id;
    const {userInfo, clinicBasicInfo} = props;
    setLoadingLocal(true);
    const result = await props.makeOnederfulCall(
      patientData,
      npiNumber,
      userInfo,
      clinicBasicInfo,
      insurance,
      memberId,
      groupNumber,
      insuranceId,
    );
    console.log(result);
    // let patientData2 = patientData;
    if (result.error) {
      // display error message.
      const errorMessage = result.errorMessage.replace(/_/g, ' ');
      alert.show(errorMessage);
      console.log(errorMessage);
    } else {
      // only in case of successful call do we want to update local state with successful info
      const ADAcodes = selectedTemplate.codes;
      // ^ const = [AD:2101, AD:12122]
      const parsedData = await props.parseData(
        ADAcodes,
        result.patientJSONData,
        insurance,
      );
      makeArrayOfServiceTypes(result.patientJSONData);
      setRawPatientJSONData(result.patientJSONData);
      setSelectedOnederfulData(parsedData.parsedByCode);
      alert.show(
        'Insurance check has been performed and patient insurance data has been updated.',
        {title: 'Success!'},
      );
    }
    console.log('result.patientData', result.patientData);
    setPatientData(result.patientData);
    setOpenNPIModal(false);
    // Need to call function to nicely return data to be rendered
    setLoadingLocal(false);
  };

  //************ Render Button for the NPI Modal */

  const handleClickSubmitNPIModal = (e) => {
    if (e) {
      e.preventDefault();
      if (!selectedNPINumber || selectedNPINumber === '') {
        alert.show('Please select a NPI number', {title: 'NPI Required'});
      } else if (
        props.clinicBasicInfo.active === false ||
        props.clinicBasicInfo.active === 'false'
      ) {
        alert.show(
          'Your account is not active. Please contact customer support by emailing info@123consults.com to request account activation:',
          {title: 'Account Inactive'},
        );
      } else {
        onCallOnederulPress();
      }
    }
  };
  //*****************Generate Header and Body for Codes Datagrid********************

  const columns = [
    {
      field: 'code',
      headerName: 'Code',
      width: rootWidth / 8,
    },
    {
      field: 'procedure',
      headerName: 'Procedure',
      width: rootWidth / 3,
    },
    // {
    //   field: 'coverage',
    //   headerName: 'Coverage',
    //   width: rootWidth / 10,
    //   align: 'center',
    //   headerAlign: 'center',
    // },
  ];

  const generateADACodeRows = () => {
    const templateCodes = selectedTemplate?.codes
      ? selectedTemplate.codes
      : null;
    // below is to get object of all procedure codes and their data
    const procedureCodeData = selectedOnederfulData.procedureCodes
      ? selectedOnederfulData.procedureCodes
      : false;
    if (templateCodes) {
      const keys = Object.keys(templateCodes);
      const rows1 = keys.map((ele, index) => {
        let data = {};
        // if there is parsed onederful data try to map it
        if (procedureCodeData[ele]) {
          data = procedureCodeData[ele];
        }
        return {
          id: ele,
          code: ele,
          procedure: adaCodeData[ele],
          data,
          // successful: values[index].error ? 'Unsuccessful' : 'Successful',
        };
      });
      return rows1;
    }
    return [];
  };

  const rows = generateADACodeRows();

  //********History Columns ******* */
  const historyColumns = [
    {
      field: 'date',
      headerName: 'Date',
      width: rootWidth / 5,
    },
    {
      field: 'successful',
      headerName: 'Result of Insurance Lookup',
      width: rootWidth / 5,
    },
  ];
  //******History Rows ************/
  const convertToDate = (unix1) => {
    const unix = parseInt(unix1);
    const date = new Date(unix);
    const month = date.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
    const year = date.getFullYear();
    const day = date.getDate();
    const combinedDate = `${month}/${day}/${year}`;
    return combinedDate;
  };

  const generateHistoryRows = () => {
    const selectedInsuranceId = selectedInsurance.id
      ? selectedInsurance.id
      : null;

    const JSONHistory = patientData?.usageHistory?.[selectedInsuranceId];

    if (JSONHistory) {
      const keys = Object.keys(JSONHistory);
      const values = Object.values(JSONHistory);
      const rows1 = keys.map((ele, index) => {
        return {
          id: ele,
          date: convertToDate(ele),
          successful: values[index].error ? 'Unsuccessful' : 'Successful',
        };
      });
      return rows1;
    }
    return [];
  };

  const historyRows = generateHistoryRows();

  //*****************Functions and html that control whether to show History or Table********************
  const historyOpen = openHistory;
  let datagrid;

  const handleHistoryOpen = () => {
    setOpenHistory(true);
  };
  const handleHistoryClose = () => {
    setOpenHistory(false);
  };

  if (historyOpen) {
    datagrid = (
      <div className={classes.tableContainer}>
        <DataGrid
          columns={historyColumns}
          rows={historyRows}
          className={classes.root}
          disableSelectionOnClick
          onRowClick={(e) => {
            setOpenModal(true);
          }}
        />
      </div>
    );
  } else {
    datagrid = (
      <div style={{width: '100%', textAlign: 'center', alignItems: 'center'}}>
        <FormControl
          variant="outlined"
          fullWidth
          size="small"
          className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label" color="primary">
            Template
          </InputLabel>
          <Select
            className={classes.label}
            value={selectedTemplate.name ? selectedTemplate.name : ''}
            defaultValue={selectedInsurance.id ? selectedInsurance.id : ''}
            onChange={(e) => handleChangeTemplate(e)}
            label="Template"
            color="primary">
            {props.clinicTemplatesList.map((object, index) => (
              <MenuItem value={object.name} key={index}>
                {object.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Grid container>
          <div className={classes.tableContainer} style={{height: 250}}>
            <DataGrid
              columns={columns}
              rows={rows}
              className={classes.root}
              disableSelectionOnClick
              hideFooter
              onRowClick={(e) => {
                // e.data.data
                setSelectedDataToSendToCodeModal(e.data.data);
                setSelectedCodeToSendToCodeModal(e.data.code);
                setOpenModal(true);
              }}
            />
          </div>
          <CodeModal
            open={openModal}
            passCloseModalStateUp={(x) => setOpenModal(x)}
            selectedOnederfulData={selectedDataToSendToCodeModal}
            selectedCode={selectedCodeToSendToCodeModal}
          />
        </Grid>
      </div>
    );
  }

  //*****************NPI Rendering and Selection Modal********************

  const handleChangeNPI = (NPInumber) => {
    setSelectedNPI(NPInumber);
  };

  const determineNPIArray = () => {
    const NPINumbers = props.clinicBasicInfo.nationalProviderIds;
    if (NPINumbers) {
      const values = Object.values(NPINumbers);
      setNPIListArray(values);
      if (values.length === 1) {
        setSelectedNPI(values[0].code);
      }
    }
  };
  const renderNPIModal = () => {
    return (
      <ThemedModal
        open={openNPIModal}
        onClose={() => setOpenNPIModal(false)}
        title={'Perform Insurance Check'}
        style={{
          fontFamily: 'Roboto',
          alignItems: 'center',
          height: '40%',
          width: '50%',
        }}>
        <div style={{display: 'flex', alignItems: 'center', padding: '20px'}}>
          <form>
            {/* <div className={classes.NPIModalLocationThemeTitle}>NPI:</div>
              <Select
                labelId="demo-simple-select-label"
                classes={{
                  root: classes.clinicSelectRoot,
                }}
                defaultValue={
                  NPIListArray.length === 1 ? NPIListArray[0].code : ''
                }
                disabled={NPIListArray.length === 1 ? true : ''}
                input={<Input disableUnderline />}
                onChange={(e) => handleChangeNPI(e.target.value)}>
                {NPIListArray.map((object) => (
                  <MenuItem value={object.code}>
                    {object.physician} - {object.code}
                  </MenuItem>
                ))}
              </Select> */}
            <FormControl variant="outlined" fullWidth>
              <InputLabel
                id="demo-simple-select-outlined-label"
                color="primary">
                NPI
              </InputLabel>
              <Select
                className={classes.label}
                defaultValue={
                  NPIListArray.length === 1 ? NPIListArray[0].code : ''
                }
                disabled={NPIListArray.length === 1 ? true : false}
                onChange={(e) => handleChangeNPI(e.target.value)}
                label="NPI Number"
                color="primary">
                {NPIListArray.map((object, index) => (
                  <MenuItem value={object.code} key={index}>
                    {object.physician} - {object.code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={handleClickSubmitNPIModal}
              color="primary"
              style={{
                margin: '15px 0 0 0',
              }}>
              Perform Insurance Check
            </Button>
            <a href="mailto: info@123consults.com?subject=Requesting Account Activation">
              Click here to email info@123consults.com for account activation
            </a>
          </form>
        </div>
      </ThemedModal>
    );
  };

  // ************************ Generatinng PDF ***************************/

  //const MyDoc = () => (
  // <PDFViewer key={Math.random()}>
  //  <Document>
  //    <Page size="A4">
  //      <View>
  //        <Text>This is a title</Text>
  //      </View>
  //    </Page>
  //  </Document>
  // </PDFViewer>
  // );
  //*****************Return Component********************

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <div className={classes.maincontainer}>
          {/* <Navbar /> */}
          <Spinner1 open={loadingLocal} />
          {renderNPIModal()}
          <div className={classes.topcontainer}>
            <TransitionsModal
              patientData={patientData}
              passPatientDataUp={(data) => setInitialValues(data)}>
              <MuiThemeProvider theme={theme}>
                <IconButton className={classes.createicon}>
                  <CreateIcon color="primary" fontSize="large" />
                </IconButton>
              </MuiThemeProvider>
            </TransitionsModal>
            <div className={classes.centerdatacontainer}>
              <p className={classes.nametitle}>
                {patientData.personalInfo?.firstName}{' '}
                {patientData.personalInfo?.lastName}{' '}
              </p>
              <div className={classes.onederfulstatus}>
                <p className={classes.subtitleContent}>
                  Last Verified Date: {patientData.lastCheckedInsuranceDate}
                </p>
                <p className={classes.subtitleContent}>
                  Last Verified By: {patientData.lastCheckedInsuranceBy}
                </p>
                <p className={classes.subtitleContent}>
                  Status: {patientData.status}
                </p>
              </div>
            </div>
            <div className={classes.onederfulicon}>
              <IconButton onClick={() => setOpenNPIModal(true)}>
                <SettingsInputAntennaIcon
                  color="primary"
                  className={classes.onederbutton}
                />
              </IconButton>
              <p style={{margin: 0, textAlign: 'center'}}>Get Insurance Data</p>
            </div>
          </div>
          <div className={classes.topcardscontainer}>
            <div className={classes.leftcard}>
              <Card className={classes.card}>
                <CardContent>
                  <div className={classes.subtitlecontainer}>
                    <p className={classes.titletext}>Personal Information</p>
                    <hr className={classes.divider} />
                  </div>
                  <p className={classes.subtitleContent}>
                    Email: {patientData.personalInfo?.email}{' '}
                  </p>
                  <p className={classes.subtitleContent}>
                    D.O.B: {patientData.personalInfo?.DOB}{' '}
                  </p>
                </CardContent>
              </Card>
            </div>
            <div className={classes.rightcard}>
              <Card className={classes.card}>
                <CardContent>
                  <div className={classes.subtitlecontainer}>
                    <p className={classes.titletext}>Insurance Information</p>
                    <hr className={classes.divider} />
                  </div>
                  <div>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      size="small"
                      className={classes.formControl}>
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        color="primary">
                        Insurnace Company
                      </InputLabel>
                      <Select
                        disabled={
                          patientInsuranceArray.length === 1 ? true : false
                        }
                        value={selectedInsurance.id ? selectedInsurance.id : ''}
                        onChange={(e) => handleChangeInsurance(e.target.value)}
                        label="Insurance Company"
                        color="primary">
                        {patientInsuranceArray.map((object, index) => (
                          <MenuItem
                            value={object.id}
                            name={'insurance'}
                            key={index}>
                            {object.insurance} {object.memberId}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <p className={classes.subtitleContent}>
                    Company: {selectedInsurance.insurance}
                  </p>
                  <p className={classes.subtitleContent}>
                    Group #: {selectedInsurance.groupNumber}
                  </p>
                  <p className={classes.subtitleContent}>
                    Member ID #: {selectedInsurance.memberId}
                  </p>
                </CardContent>
              </Card>
            </div>
          </div>
          <div
            className={classes.generalSearchcontainer}
            style={{width: '90%', margin: 'auto'}}>
            <Card className={classes.card}>
              <CardContent>
                <div className={classes.subtitlecontainer}>
                  <p className={classes.titletext}>
                    General Insurance Reporting
                  </p>
                  <hr className={classes.divider} />
                </div>
                <div
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    alignItems: 'center',
                  }}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    size="small"
                    className={classes.formControl}>
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      color="primary">
                      Service Type Filter
                    </InputLabel>
                    <Select
                      className={classes.label}
                      value={
                        generalSearchFilter.service_type
                          ? generalSearchFilter.service_type
                          : ''
                      }
                      onChange={(e) =>
                        handleGeneralFilterChange(e.target.name, e.target.value)
                      }
                      name="service_type"
                      label="Service Type"
                      color="primary">
                      {availableServiceTypesArray.map((object) => (
                        <MenuItem value={object}>
                          {capitalizeFirstLetterAndRemoveUnderscore(object)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                {/* <FormControl
                variant="outlined"
                fullWidth
                size="small"
                className={classes.formControl}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  color="primary">
                  Network Filter
                </InputLabel>
                <Select
                  className={classes.label}
                  value={generalSearchFilter.network}
                  onChange={(e) => handleGeneralFilterChange(e.target.name, e.target.value)}
                  name="network"
                  label="Network"
                  color="primary">
                  {availableNetworkArray.map((object) => (
                    <MenuItem value={object}>
                      {capitalizeFirstLetterAndRemoveUnderscore(object)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                variant="outlined"
                fullWidth
                size="small"
                className={classes.formControl}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  color="primary">
                  Coverage Level
                </InputLabel>
                <Select
                  className={classes.label}
                  value={generalSearchFilter.coverage_level}
                  onChange={(e) => handleGeneralFilterChange(e.target.name, e.target.value)}
                  name="coverage_level"
                  label="Coverage Level"
                  color="primary">
                  {availableCoverageLevelArray.map((object) => (
                    <MenuItem value={object}>
                      {capitalizeFirstLetterAndRemoveUnderscore(object)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={handleClickRunGeneralFilterReport}
                    className={classes.runreportbutton}
                    color="primary">
                    Run Report
                  </Button>
                </div>
              </CardContent>
            </Card>
          </div>
          <div className={classes.bottomcard}>
            <Card className={classes.card}>
              <CardContent>
                <div className={classes.subtitlecontainer}>
                  <p className={classes.titletext}>Insurance Eligibility</p>
                  <hr className={classes.divider2} />
                  <MuiThemeProvider theme={theme}>
                    <IconButton
                      className={classes.historyicon}
                      onClick={
                        historyOpen ? handleHistoryClose : handleHistoryOpen
                      }>
                      <HistoryIcon color="primary" style={{fontSize: '50px'}} />
                    </IconButton>
                  </MuiThemeProvider>
                </div>
                {datagrid}
                <MuiThemeProvider theme={theme}>
                  <div className={classes.bottomicons}>
                    {/* <IconButton> */}
                    {/* {renderPDFDownload()} */}
                    {/* {isReady ? (
                    <PDFDownloadLink
                      document={<MyDoc />}
                      fileName={`${patientData.personalInfo?.firstName} ${patientData.personalInfo?.lastName} ${patientData.personalInfo?.DOB} Insurance Detail.pdf`}>
                      {({blob, url, loading, error}) =>
                        loading ? 'Loading document...' : 'Download PDF'
                      }
                    </PDFDownloadLink>
                  ) : null} */}
                    {/* <PictureAsPdfIcon color="primary" fontSize="large" /> */}
                    {/* </IconButton> */}
                    <IconButton>
                      <PictureAsPdfIcon color="primary" fontSize="large" />
                    </IconButton>
                    <IconButton>
                      <MailOutlineIcon color="primary" fontSize="large" />
                    </IconButton>
                  </div>
                </MuiThemeProvider>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </MuiThemeProvider>
  );
}

//*********************************************************REDUX STORE***********************************************************

const mapStateToProp = (state) => {
  return {
    clinicBasicInfo: state.apiCallsReducer.clinicBasicInfo,
    clinicUsersList: state.apiCallsReducer.clinicUsersList,
    userInfo: state.apiCallsReducer.userInfo,
    clinicPatientsList: state.apiCallsReducer.clinicPatientsList,
    clinicTemplatesList: state.apiCallsReducer.clinicTemplatesList,
    clinicUsageInfo: state.apiCallsReducer.clinicUsageInfo,
    clinicLocationsList: state.apiCallsReducer.clinicLocationsList,
  };
};

export default connect(mapStateToProp, {
  makeOnederfulCall,
  parseData,
  parseGeneralFilterData,
})(Profile);

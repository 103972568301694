export const CLINIC_BASIC_INFO = 'clinic_basic_info';
export const CLINIC_USERS_LIST = 'clinic_users_list';
export const USER_INFO = 'user_info';
export const CLINIC_PATIENTS_LIST = 'clinic_patients_list';
export const CLINIC_TEMPLATES_LIST = 'clinic_templates_list';
export const CLINIC_USAGE_INFO = 'clinic_usage_info';
export const CLINIC_LOCATIONS_LIST = 'clinic_locations_list';
export const UPDATE_SEARCH_RESULTS = 'update_search_results';
export const UPDATE_CLINIC_LOCATION_FILTER_RESULTS =
  'update_clinic_location_filter_results';
export const RESETALL = 'resetall';
export const ACTIVE_OR_ARCHIVED_PATIENT_LIST =
  'active_or_archived_patient_list';
export const INSURANCES = 'insurances';
export const SET_CURRENT_USER = 'set_current_user';
export const SET_CURRENT_SECTION = 'set_current_section';
export const UPDATE_PATIENT = 'update_patient';
export const ADA_CODE_LIST = 'ada_code_list';
export const ADA_CODE_KEYS = 'ada_code_keys';
import React, {useEffect, useState} from 'react';
import {
  makeStyles,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import {DataGrid, GridOverlay} from '@material-ui/data-grid';
import {IconButton, Button} from '@material-ui/core';
import {connect} from 'react-redux';
import {useHistory, Link} from 'react-router-dom';
import useStyles from './VerificationThreeStyles';
import {useAlert} from 'react-alert';
import {downloadURL} from '../../../actions';

//*************************************************************************************Component Styling Sheet***************************************************

const theme = createMuiTheme({
  typography: {
    fontFamily: '"Roboto"',
  },
  palette: {
    primary: {
      main: '#1B7FE1',
      contrastText: '#fff',
    },
    secondary: {
      main: '#003B63',
      contrastText: '#fff',
    },
    info: {
      main: '#1BA0E0',
      contrastText: '#fff',
    },
    background: {
      default: '#fff',
    },
    fontFamily: {
      main: '"Roboto"',
    },
  },
});

//*************************************************************************************Generate Table Data**************************************************

//*******************************************************************************Table Data Component**************************************************************

const ThirdVerificationTableData = (props) => {
  const history = useHistory();
  const alert = useAlert();
  const {pdfLinks} = props;

  const rows = React.useMemo(() => {
    // Define function that converts UNIX to date
    // console.log(rowsInput);
    // Map data to object
    console.log('pdfLinks in component', pdfLinks);
    return pdfLinks
      .map((ele) => {
        if (ele.URL) {
          return {
            id: ele.name,
            title: ele.name,
            URL: ele.URL,
            ele,
          };
        }
        return null;
      })
      .filter(Boolean);
  }, [pdfLinks]);

  useEffect(() => {
    setRootWidth(document.getElementById('root').clientWidth - 200);
    setRootHeight(document.getElementById('root').clientHeight);
    // props.changeGlobalSearchObj('', props.locationFilteredClinicPatientsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [rootWidth, setRootWidth] = useState(0);
  const [rootHeight, setRootHeight] = useState(0);
  //*****************Generate Header and Body********************

  const usageNoRowsOverlay = () => {
    return (
      <GridOverlay>
        <div>No data available</div>
      </GridOverlay>
    );
  };

  // const renderLinkCell = (URL) => {
  //   return (
  //     <a href={URL} target="_blank">
  //       {URL}
  //     </a>
  //   );
  // };
  const renderLinkCell = (URL, params) => {
    const title = params.row.title;
    return (
      <MuiThemeProvider theme={theme}>
        <Button
          // type="submit"
          width={200}
          variant="contained"
          onClick={(e) => openInNewTab(e, URL, title)}
          color="primary">
          Click to Download
        </Button>
      </MuiThemeProvider>
    );
  };

  const openInNewTab = async (e, url, title) => {
    e.preventDefault();
    const result = await props.downloadURL(url, title);
    if (result.error === true) {
      alert.show(
        `There was an error downloading the ${title} PDF. Please try again or contact 123 Support if the issue persists.`,
        {
          title: 'Error',
        },
      );
    }
  };
  // const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  // if (newWindow) newWindow.opener = null;

  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      width: 200,
    },
    {
      field: 'URL',
      headerName: 'PDF Download Link',
      flex: 1,
      renderCell: (params, other) => renderLinkCell(params.value, params, other),
      // render
    },
  ];
  const classes = useStyles();

  //*****************Return Component********************
  return (
    <MuiThemeProvider theme={theme}>
      {/* <Spinner1 open={loadingLocal} /> */}
      {/* <ProfileTabs> */}
      <div style={{height: rootHeight - 60}}>
        <DataGrid
          autoHeight={true}
          columns={columns}
          rows={rows}
          className={classes.Datagrid}
          // onRowClick={(h) => {
          //   console.log(h.row.ele);
          //   openInNewTab(h.row.ele.URL);
          // }}
          components={{
            noRowsOverlay: usageNoRowsOverlay,
          }}
        />
      </div>
      {/* </ProfileTabs> */}
    </MuiThemeProvider>
  );
};

//*********************************************************REDUX STORE***********************************************************

const mapStateToProp = (state) => {
  return {
    // clinicPatientsList: state.apiCallsReducer.clinicPatientsList,
    // clinicLocationsList: state.apiCallsReducer.clinicLocationsList,
    // searchedClinicPatientsList:
    //   state.apiCallsReducer.searchedClinicPatientsList,
    // locationFilteredClinicPatientsList:
    //   state.apiCallsReducer.locationFilteredClinicPatientsList,
    // userInfo: state.apiCallsReducer.userInfo,
    // clinicBasicInfo: state.apiCallsReducer.clinicBasicInfo,
    // activeOrArchivedPatientList:
    //   state.apiCallsReducer.activeOrArchivedPatientList,
  };
};

//*********************************************************Export***********************************************************

export default connect(mapStateToProp, {downloadURL})(
  ThirdVerificationTableData,
);

import React from 'react';
import PropTypes from 'prop-types';
import {MenuItem, Select, FormControl} from '@material-ui/core';
import styles from './UserInfo.module.scss';

function UserInfo({
  options,
  children,
  insurance,
  groupNumber,
  employerBenefits,
  setEmployerBenefits,
  showEmployerPhoneNumber,
  ...props
}) {
  return (
    <div className={`${styles.wrapper} row`}>
      <div className="col-12">
        <h1 className={styles.insuranceTitle}>
          {employerBenefits?.employerName}
        </h1>
        <h4 className={styles.insuranceTitle}>{props.insuranceName}</h4>
        <h4 className={styles.insuranceSubtitle}>
          Group Number: {groupNumber || 'N/A'}
        </h4>
        <h4 className={styles.insuranceSubtitle}>
          Group Name: {employerBenefits.employerInformation?.groupName || ''}
        </h4>
      </div>
      <div className="col-12 my-1">
        <div className="row align-items-center">
          <div className="col-xs-12 col-md-4">
            {options.length ? (
              <FormControl variant="outlined" size="small" fullWidth>
                <Select
                  value={employerBenefits?.zohoRecordId || 0}
                  onChange={(e) => {
                    const value = options.find(
                      (item) => item.zohoRecordId === e.target.value,
                    );
                    setEmployerBenefits(value);
                  }}
                  name="coverage_level"
                  label="Coverage Level"
                  color="primary">
                  {options.map((option, i) => (
                    <MenuItem
                      value={option.zohoRecordId}
                      key={option.zohoRecordId}>
                      {option.planName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
          </div>
          <div className="col-xd-12 col-md-8 my-1">{children}</div>
        </div>
      </div>
    </div>
  );
}

UserInfo.propTypes = {
  showEmployerPhoneNumber: PropTypes.bool,
};

export default UserInfo;

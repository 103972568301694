import React, {useEffect} from 'react';
// import shallowCompare from 'react-addons-shallow-compare';
import _ from 'lodash';
import {DataGrid, GridOverlay} from '@material-ui/data-grid';
import {connect} from 'react-redux';
import {} from '../../actions';

// Depending on the current path, this component sets the "active" class on the appropriate navigation link item
const DataGrid1 = React.memo(
  ({rows, columns, className}) => {
    useEffect(() => {
      // setRootHeight(document.getElementById('root').clientHeight);
    }, []);

    // const rows1 = useMemo(() => rows, [rows]);
    // const columns1 = useMemo(() => columns, [columns]);
    // const className1 = useMemo(() => className, [className]);

    const usageNoRowsOverlay = () => {
      return (
        <GridOverlay>
          <div>No Data Available</div>
        </GridOverlay>
      );
    };
    // console.log('re-render Grid');
    return (
      <DataGrid
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        hideFooter
        autoHeight
        className={className}
        rowHeight={25}
        components={{
          noRowsOverlay: usageNoRowsOverlay,
        }}
      />
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps.rows, nextProps.rows)) {
      return true; // props are equal
    } else {
      // props are not equal -> update the component
      return false;
    }
  },
);

const mapStateToProp = (state) => {
  return {};
};

export default connect(mapStateToProp, {})(DataGrid1);

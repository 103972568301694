import React from 'react';
import {InputBase} from '@material-ui/core';
import {DataGrid, GridOverlay} from '@material-ui/data-grid';
import SearchIcon from '@material-ui/icons/Search';
import {connect} from 'react-redux';
import useSearch from './useSearch';
import useStyles from './SearchInsuranceStyles';
import columns from './SearchInsuranceColumns';

const usageNoRowsOverlay = () => {
  return (
    <GridOverlay>
      <div>No Data Available</div>
    </GridOverlay>
  );
};

const SearchComponent = ({history, clinicBasicInfo}) => {
  const {setSearch, data} = useSearch();
  const classes = useStyles();

  const [timer, setTimer] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setTimer(true);
    }, 100);
  }, []);

  // console.log(
  //   document.getElementById('search-insurance-wrapper')
  //     ? document.getElementById('search-insurance-wrapper').clientHeight
  //     : null,
  // );

  // console.log(`timer`, timer);

  // console.log(
  //   `calc(100vh - ${
  //     document.getElementById('search-insurance-wrapper')
  //       ? document.getElementById('search-insurance-wrapper').clientHeight
  //       : '200px'
  //   })`,
  // );
  return (
    <div className={`${classes.mainContainer}`}>
      <div id="search-insurance-wrapper">
        <div className={classes.pageTitle}>
          <p className={classes.pageTitleText}>Search Employer Database</p>
          {/* <Button>
            <Link to="/employer-benefits">Submit New Employer Request</Link>
          </Button> */}
        </div>
        <div className={classes.searchContainer}>
          <div className={classes.search} data-html2canvas-ignore="true">
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <div>
              <InputBase
                placeholder="Type to search an employer"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{type: 'search'}}
                onChange={({target: {value}}) => {
                  setSearch(value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {timer ? (
        <div
          style={{
            height: `calc(100vh - ${
              document.getElementById('search-insurance-wrapper')
                ? `${
                    document.getElementById('search-insurance-wrapper')
                      .clientHeight + 64
                  }px`
                : '200px'
            })`,
          }}>
          <DataGrid
            rows={data}
            onRowClick={({row}) => {
              history.push(`insurance/${row.insuranceName}/${row.groupNumber}`);
            }}
            columns={columns}
            className={classes.root}
            components={{
              noRowsOverlay: usageNoRowsOverlay,
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = ({apiCallsReducer}) => ({
  clinicBasicInfo: apiCallsReducer.clinicBasicInfo,
});

export default connect(mapStateToProps)(SearchComponent);

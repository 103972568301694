import React, {useState, useEffect, Fragment} from 'react';
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import {useDispatch} from 'react-redux';
import {
  updatePatient,
  getPatient,
  setCurrentPatient,
} from 'actions/patientActions';

import {
  FormHelperText,
  Modal,
  Backdrop,
  Fade,
  Button,
  TextField,
  Link,
  Grid,
  MenuItem,
  InputLabel,
  Select,
  IconButton,
  FormControl,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import validate from '../AddPatientValidationRules';
import validate1 from '../SendPatientFormValidationRules';
import {useAlert} from 'react-alert';
import {connect} from 'react-redux';
import {
  addOrEditPatientData,
  sendEmailAndOrTextToPatient,
  changeGlobalSearchObj,
} from '../../actions';
import {Spinner1} from '../Spinner1';
import data from '../../data/insuranceList.json';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    overflowY: 'auto',
    padding: '40px',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      justifyContent: 'flex-start',
    },
  },
  modalcontainer: {
    boxShadow: theme.shadows[5],
    borderRadius: '10px',
    fontFamily: '"Roboto"',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '50%',
    outline: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
    },
  },
  topmodalcontainer: {
    backgroundColor: '#1B7FE1',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '10px 10px 0 0',
    justifyContent: 'center',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#fff',
    fontSize: '20px',
    flex: 1,
    width: '100%',
  },
  closebuttoncontainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  centercontainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
  },
  icon: {
    color: '#fff',
    fontSize: '50px',
  },
  subtitle: {
    margin: 0,
    padding: '0 0 15px 0',
  },
  formcontainer: {
    backgroundColor: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '0 0 10px 10px',
    padding: '25px 25px',
    flex: 4,
  },
  formcontainer2: {
    backgroundColor: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '0 0 10px 10px',
    width: '100%',
    height: 'auto',
  },
  fieldscontainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  phonefield: {
    padding: '0 10px 0 0',
    [theme.breakpoints.down('sm')]: {
      padding: '0 0 10px 0',
    },
  },
  infotitle: {
    backgroundColor: 'white',
    fontSize: '15px',
    padding: '5px',
    position: 'absolute',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    color: '#1B7FE1',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '7px',
    },
  },
  subtitlecontainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  divider: {
    borderTop: '2px solid #1B7FE1',
    width: '100%',
    margin: '20px 0 20px 0',
  },
  locationfield: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
  sendbutton: {
    padding: '15px',
    margin: 'auto',
  },
  addicon: {
    float: 'left',
    padding: '0px',
  },
  submitbotom: {
    width: '40%',
    margin: 'auto',
  },
  errorMessage: {
    color: 'red',
    fontSize: 14,
    textAlign: 'center',
  },
  patientDependentText: {
    paddingBottom: '4px',
  },
}));
const useStylesBootstrap = makeStyles((theme) => ({
  tooltip: {
    fontSize: 14,
  },
}));

//**************************************************Modal Component**************************************************************
function TransitionsModal(props) {
  const insuranceObject = {
    insurance: '',
    groupNumber: '',
    memberId: '',
    primaryInsurance: true,
    dependent: '',
    id: false,
    subscriberFirstName: '',
    subscriberLastName: '',
    subscriberDOB: null,
  };
  // Doing the below to set primaryInsurance default to true for only initial state for first insurance
  const insuranceArrayState = [insuranceObject];
  const [insuranceArray, setInsuranceArray] = useState(insuranceArrayState);
  const [loadingLocal, setloadingLocal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorMessage1, setErrorMessage1] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    // Below is to set location name on state if only one location as it will never fire onchange
    // Add logic here to replace with default values if editing patient data.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const classes = useStyles();
  const alert = useAlert();
  const [open, setOpen] = useState(false);
  const [showSendForm, setShowSendForm] = useState(true);
  const [textValues, setTextValues] = useState({
    firstName: '',
    lastName: '',
    DOB: null,
    email: '',
    phone: '',
    nextAppointmentDate: null,
    location: '',
    phoneNumberSendForm: '',
    emailSendForm: '',
    locationSendForm: '',
  });
  const handleArrayChange = (name, value, index) => {
    let newInsuranceArray = [...insuranceArray];
    newInsuranceArray[index][name] = value;
    setInsuranceArray(newInsuranceArray);
  };
  const handleClickSubmit = async (e) => {
    if (e) {
      e.preventDefault();
      // START SPINNER
      if (textValues.DOB && textValues.DOB._d) {
        const DOB = textValues.DOB._d;
        let month = DOB.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
        const year = DOB.getFullYear();
        let day = DOB.getDate();
        if (month === 11 || month === 12 || month === 10) {
        } else {
          month = `0${month}`;
        }
        if (
          day === 0 ||
          day === 1 ||
          day === 2 ||
          day === 3 ||
          day === 4 ||
          day === 5 ||
          day === 6 ||
          day === 7 ||
          day === 8 ||
          day === 9
        ) {
          day = `0${day}`;
        }
        textValues.DOB = `${year}-${month}-${day}`;
      }
      if (textValues.nextAppointmentDate && textValues.nextAppointmentDate._d) {
        const nextAppointmentDate = textValues.nextAppointmentDate._d;
        let month = nextAppointmentDate.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
        const year = nextAppointmentDate.getFullYear();
        let day = nextAppointmentDate.getDate();
        if (month === 11 || month === 12 || month === 10) {
        } else {
          month = `0${month}`;
        }
        if (
          day === 0 ||
          day === 1 ||
          day === 2 ||
          day === 3 ||
          day === 4 ||
          day === 5 ||
          day === 6 ||
          day === 7 ||
          day === 8 ||
          day === 9
        ) {
          day = `0${day}`;
        }
        textValues.nextAppointmentDate = `${year}-${month}-${day}`;
      }
      setloadingLocal(true);
      setErrorMessage(false);

      const insuranceArrayWithInsuranceID = insuranceArray.map(
        (object, index) => {
          let insurance = '';
          data.forEach((object1) => {
            if (object1.name === object.insuranceName) {
              insurance = object1.id;
            }
          });
          if (object.dependent === '') {
            object.dependent = 'empty';
          } else if (object.dependent === true) {
            object.dependent = true;
          } else {
            object.dependent = false;
          }
          if (
            object.dependent === true &&
            object.subscriberDOB &&
            object.subscriberDOB._d
          ) {
            const subscriberDOB = object.subscriberDOB._d;
            let month = subscriberDOB.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
            const year = subscriberDOB.getFullYear();
            let day = subscriberDOB.getDate();
            if (month === 11 || month === 12 || month === 10) {
            } else {
              month = `0${month}`;
            }
            if (
              day === 0 ||
              day === 1 ||
              day === 2 ||
              day === 3 ||
              day === 4 ||
              day === 5 ||
              day === 6 ||
              day === 7 ||
              day === 8 ||
              day === 9
            ) {
              day = `0${day}`;
            }
            object.subscriberDOB = `${year}-${month}-${day}`;
          }
          const newObject = {...object, insurance};
          return newObject;
        },
      );
      const errors = validate(
        textValues,
        insuranceArrayWithInsuranceID,
        props.clinicPatientsList,
        props.patientData,
      );

      if (errors) {
        setErrorMessage(errors);
        setloadingLocal(false);
      } else {
        let textValues1 = textValues;
        if (!textValues.nextAppointmentDate) {
          textValues1.nextAppointmentDate = '';
        }

        textValues1.firstName = textValues1.firstName.replace(/\b(\w)/g, (s) =>
          s.toUpperCase(),
        );
        textValues1.lastName = textValues1.lastName.replace(/\b(\w)/g, (s) =>
          s.toUpperCase(),
        );

        const newPatientData = await props.addOrEditPatientData(
          textValues1,
          insuranceArrayWithInsuranceID,
          props.clinicBasicInfo,
          props.userInfo,
          props.patientData,
          props.editPatient,
        );

        if (props.patientData && props.clinicBasicInfo) {
          const patient = await getPatient(
            props.clinicBasicInfo.clinicId,
            props.patientData.patientId,
          );

          dispatch(updatePatient(patient));
          dispatch(setCurrentPatient(patient));
        }
        // STOP SPINNER
        // This is to reset insurance back to original state
        setInsuranceArray(insuranceArrayState);
        setTextValues({
          firstName: '',
          lastName: '',
          DOB: null,
          email: '',
          phone: '',
          nextAppointmentDate: null,
          location: '',
          phoneNumberSendForm: '',
          emailSendForm: '',
          locationSendForm: '',
        });
        if (props.patientData) {
          props.passPatientDataUp(newPatientData);
        }
        props.changeGlobalSearchObj(
          '',
          props.locationFilteredClinicPatientsList,
        );
        setloadingLocal(false);
        setOpen(false);
      }
      //existingPatientInfo only passed if editing existing patient
    }
  };

  const handleClickSubmitSendForm = async (e) => {
    if (e) {
      e.preventDefault();
      // START SPINNER
      setloadingLocal(true);
      setErrorMessage1(false);
      const errors = validate1(textValues);
      if (errors) {
        setErrorMessage1(errors);
        setloadingLocal('sendFormToPatient');
      } else {
        const {
          phoneNumberSendForm,
          emailSendForm,
          locationSendForm,
        } = textValues;
        const responseFromSendingForm = await props.sendEmailAndOrTextToPatient(
          phoneNumberSendForm,
          emailSendForm,
          locationSendForm,
          props.clinicBasicInfo,
          props.userInfo,
        );

        if (
          responseFromSendingForm.textMessageSendError &&
          !responseFromSendingForm.emailSendError
        ) {
          setErrorMessage1(
            // Case that only text sending has an error
            'Error sending the text message to the patient. Please re-enter the phone number only and try again.',
          );
          setloadingLocal('sendFormToPatient');
          return;
        } else if (
          !responseFromSendingForm.textMessageSendError &&
          responseFromSendingForm.emailSendError
        ) {
          // Case that only email sending has an error
          setErrorMessage1(
            'Error sending an email to the patient. Please re-enter the email only and try again.',
          );
          setloadingLocal('sendFormToPatient');
          return;
        } else if (
          responseFromSendingForm.textMessageSendError &&
          responseFromSendingForm.emailSendError
        ) {
          // Case that both have erros
          setErrorMessage1(
            'Error sending both the email and text message to the patient. Please re-enter and try again.',
          );
          setloadingLocal('sendFormToPatient');
          return;
        } else {
          alert.show('Successfully sent the form to the patient.', {
            title: 'Success!',
          });
          setInsuranceArray(insuranceArrayState);
          setTextValues({
            firstName: '',
            lastName: '',
            DOB: null,
            email: '',
            phone: '',
            nextAppointmentDate: null,
            location: '',
            phoneNumberSendForm: '',
            emailSendForm: '',
            locationSendForm: '',
          });
          setloadingLocal(false);
          setOpen(false);
        }
        // STOP SPINNER
        // This is to reset insurance back to original state
      }
      //existingPatientInfo only passed if editing existing patient
    }
  };
  const handleTextChange = (name, value, e) => {
    // insuranceArrayNew.push(insuranceObject);
    if (e) {
      e.preventDefault();
    }
    const newTextValues = {...textValues};
    newTextValues[name] = value;
    setTextValues(newTextValues);
  };
  const handleOpen = () => {
    let values = {...textValues};
    if (props.patientData) {
      values.firstName = props.patientData.personalInfo.firstName
        ? props.patientData.personalInfo.firstName
        : '';
      values.lastName = props.patientData.personalInfo.lastName
        ? props.patientData.personalInfo.lastName
        : '';
      values.email = props.patientData.personalInfo.email
        ? props.patientData.personalInfo.email
        : '';
      values.DOB = props.patientData.personalInfo.DOB
        ? props.patientData.personalInfo.DOB
        : null;
      values.phone = props.patientData.personalInfo.phone
        ? props.patientData.personalInfo.phone
        : '';
      values.nextAppointmentDate =
        props.patientData.personalInfo.nextAppointmentDate === '' ||
        !props.patientData.personalInfo.nextAppointmentDate
          ? null
          : props.patientData.personalInfo.nextAppointmentDate;
      values.location = props.patientData.personalInfo.location;
      setTextValues(values);
      const insuranceArray1 = props.patientData.insurance
        ? Object.values(props.patientData.insurance)
        : [];
      const modifiedInsuranceArray = insuranceArray1
        ? insuranceArray1.map((object, index) => {
            let insuranceName = '';
            data.forEach((object1) => {
              if (object1.id === object.insurance) {
                insuranceName = object1.name;
              }
            });
            if (!object.subscriberDOB || object.subscriberDOB === '') {
              object.subscriberDOB = null;
            }
            const newObject = {...object, insuranceName};
            return newObject;
          })
        : [];
      setInsuranceArray(modifiedInsuranceArray);
      setShowSendForm(false);
    } else {
      if (props.clinicLocationsList.length === 1) {
        values.location = props.clinicLocationsList[0].name;
        values.locationSendForm = props.clinicLocationsList[0].name;
        setTextValues(values);
      }
      setShowSendForm(true);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setloadingLocal(false);
  };

  // ******* ADD INSURANCE

  function BootstrapTooltip(props1) {
    const classes1 = useStylesBootstrap();

    return <Tooltip classes={classes1} {...props1} />;
  }
  const handleAdd = () => {
    // this function is to add additional insurance
    const insuranceObject1 = {
      insurance: '',
      groupNumber: '',
      memberId: '',
      primaryInsurance: false,
      dependent: '',
      id: false,
      subscriberFirstName: '',
      subscriberLastName: '',
      subscriberDOB: null,
    };
    const insuranceArrayNew = [...insuranceArray, insuranceObject1];
    // insuranceArrayNew.push(insuranceObject);
    setInsuranceArray(insuranceArrayNew);
  };

  const turnOffOtherPrimaryCheck = (index1) => {
    // this functiono is to turn off other primary checkboxes  when one primary insurer is checked
    let newInsuranceArray = [...insuranceArray];
    if (insuranceArray.length === 1) {
      // this is to force box to stay checked if only one insurance is added
      newInsuranceArray[0].primaryInsurance = true;
    } else {
      insuranceArray.forEach((ele, index) => {
        if (index !== index1) {
          ele.primaryInsurance = false;
          newInsuranceArray[index] = ele;
        }
      });
    }
    setInsuranceArray(newInsuranceArray);
  };
  const deleteInsurance = (index) => {
    let newInsuranceArray = [...insuranceArray];
    newInsuranceArray.splice(index, 1);
    setInsuranceArray(newInsuranceArray);
  };

  const theme = createMuiTheme({
    typography: {
      fontFamily: '"Roboto"',
    },
    palette: {
      primary: {
        main: '#1B7FE1',
        contrastText: '#fff', //button text white instead of black
      },
      background: {
        default: '#fff',
      },
    },
  });

  const height = 40;

  const sendformModal = (
    <div className={classes.formcontainer2}>
      <form noValidate style={{padding: '15px', width: '90%'}}>
        <div
          className={classes.subtitlecontainer}
          style={{paddingBottom: '15px'}}>
          <p className={classes.infotitle}>Enter Patient Contact Info</p>
          <hr className={classes.divider} />
        </div>
        <div className={classes.fieldscontainer} style={{margin: '0 0 15px 0'}}>
          <TextField
            // autoComplete="fname"
            name="phoneNumberSendForm"
            variant="outlined"
            required
            fullWidth
            id="phoneNumber"
            label="Phone Number"
            type="number"
            className={classes.phonefield}
            onChange={(e) => handleTextChange(e.target.name, e.target.value)}
            autoFocus
            inputProps={{
              style: {
                height,
                padding: '0 14px',
              },
            }}
            InputLabelProps={{
              style: {
                height,
                marginTop: '-5px',
                fontSize: '15px',
                fontFamily: 'Roboto',
              },
            }}
          />
          <TextField
            variant="outlined"
            fullWidth
            id="email"
            label="Email Address"
            name="emailSendForm"
            onChange={(e) => handleTextChange(e.target.name, e.target.value)}
            inputProps={{
              style: {
                height,
                padding: '0 14px',
              },
            }}
            InputLabelProps={{
              style: {
                height,
                marginTop: '-5px',
                fontSize: '15px',
                fontFamily: 'Roboto',
              },
            }}
          />
        </div>
        <div className={classes.locationfield}>
          <FormControl
            variant="outlined"
            fullWidth
            style={{margin: '0 0 15px 0'}}>
            <InputLabel id="demo-simple-select-outlined-label" color="primary">
              Location
            </InputLabel>
            <Select
              disabled={props.clinicLocationsList.length === 1 ? true : false}
              label="Clinic Location"
              name="locationSendForm"
              onChange={(e) => handleTextChange(e.target.name, e.target.value)}
              // value={textValues.locationSendForm}
              defaultValue={
                props.clinicLocationsList.length === 1
                  ? props.clinicLocationsList[0].name
                  : ''
              }
              color="primary">
              {props.clinicLocationsList.map((object, index) => (
                <MenuItem value={object.name} key={index}>
                  {object.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {errorMessage1 ? (
            <Grid item xs={12} sm={12}>
              <FormHelperText className={classes.errorMessage}>
                {errorMessage1}
              </FormHelperText>
            </Grid>
          ) : null}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleClickSubmitSendForm}>
            Send Form to Patient
          </Button>
        </div>
      </form>
    </div>
  );

  const renderSpinner = () => {
    if (loadingLocal === true) {
      return (
        <Fragment>
          <Spinner1 open={loadingLocal} />
          {/* {sendformModal} */}
        </Fragment>
      );
    } else if (loadingLocal === 'sendFormToPatient') {
      return <Fragment>{sendformModal}</Fragment>;
    }
    return (
      <div className={classes.formcontainer}>
        <form noValidate>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid container spacing={2}>
              {showSendForm ? (
                <div className={classes.sendbutton}>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => setloadingLocal('sendFormToPatient')}>
                    Send Form to Patient
                  </Button>
                </div>
              ) : null}
              <div className={classes.subtitlecontainer}>
                <p className={classes.infotitle}>Patient Information</p>
                <hr className={classes.divider} />
              </div>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  autoFocus
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  defaultValue={
                    textValues.firstName ? textValues.firstName : ''
                  }
                  id="firstName"
                  label="First Name"
                  type="text"
                  onChange={(e) =>
                    handleTextChange(e.target.name, e.target.value)
                  }
                  inputProps={{
                    style: {
                      height,
                      padding: '0 14px',
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      height,
                      marginTop: '-5px',
                      fontSize: '15px',
                      fontFamily: 'Roboto',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                  defaultValue={textValues.lastName ? textValues.lastName : ''}
                  onChange={(e) =>
                    handleTextChange(e.target.name, e.target.value)
                  }
                  inputProps={{
                    style: {
                      height,
                      padding: '0 14px',
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      height,
                      marginTop: '-5px',
                      fontSize: '15px',
                      fontFamily: 'Roboto',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  defaultValue={textValues.email ? textValues.email : ''}
                  onChange={(e) =>
                    handleTextChange(e.target.name, e.target.value)
                  }
                  inputProps={{
                    style: {
                      height,
                      padding: '0 14px',
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      height,
                      marginTop: '-5px',
                      fontSize: '15px',
                      fontFamily: 'Roboto',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="phone"
                  label="Phone Number"
                  name="phone"
                  defaultValue={textValues.phone ? textValues.phone : ''}
                  onChange={(e) =>
                    handleTextChange(e.target.name, e.target.value)
                  }
                  inputProps={{
                    style: {
                      height,
                      padding: '0 14px',
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      height,
                      marginTop: '-5px',
                      fontSize: '15px',
                      fontFamily: 'Roboto',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="DOB"
                  required
                  fullWidth
                  format="MM/DD/yyyy"
                  value={textValues.DOB}
                  maxDate={new Date()}
                  placeholder="MM/DD/YYYY"
                  // InputAdornmentProps={{position: 'start'}}
                  onChange={(date) => handleTextChange('DOB', date)}
                  inputProps={{
                    style: {
                      height,
                      padding: '0 14px',
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      height,
                      marginTop: '-5px',
                      fontSize: '15px',
                      fontFamily: 'Roboto',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="Next Appt Date"
                  format="MM/DD/yyyy"
                  fullWidth
                  value={textValues.nextAppointmentDate}
                  // maxDate={new Date()}
                  placeholder="MM/DD/YYYY"
                  // InputAdornmentProps={{position: 'start'}}
                  onChange={(date) =>
                    handleTextChange('nextAppointmentDate', date)
                  }
                  inputProps={{
                    style: {
                      height,
                      padding: '0 14px',
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      height,
                      marginTop: '-5px',
                      fontSize: '15px',
                      fontFamily: 'Roboto',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                <FormControl variant="outlined" required fullWidth size="small">
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    color="primary">
                    Location
                  </InputLabel>
                  <Select
                    className={classes.label}
                    required
                    disabled={
                      props.clinicLocationsList.length === 1 ? true : false
                    }
                    label="Clinic Location"
                    name="location"
                    onChange={(e) =>
                      handleTextChange(e.target.name, e.target.value)
                    }
                    value={textValues.location}
                    defaultValue={
                      textValues.location
                        ? textValues.location
                        : props.clinicLocationsList.length === 1
                        ? props.clinicLocationsList[0].name
                        : ''
                    }
                    color="primary">
                    {props.clinicLocationsList.map((object, index) => (
                      <MenuItem value={object.name} key={index}>
                        {object.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <div className={classes.subtitlecontainer}>
                <p className={classes.infotitle}>Insurance Information</p>
                <hr className={classes.divider} />
              </div>
              {insuranceArray.map((object, index) => (
                <Fragment key={index}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    {/* {test(object, index)} */}
                    <FormControl
                      variant="outlined"
                      fullWidth
                      required
                      size="small">
                      {/* <InputLabel id="demo-simple-select-outlined-label">
                        Insurance Name
                      </InputLabel> */}
                      <Autocomplete
                        // id="combo-box-demo"
                        // filterOptions={filterOptions}
                        fullWidth={true}
                        freeSolo={true}
                        value={object.insuranceName ? object.insuranceName : ''}
                        options={data}
                        inputValue={
                          object.insuranceName ? object.insuranceName : ''
                        }
                        onInputChange={(event, newInputValue, reason) => {
                          if (newInputValue && newInputValue !== 'undefined') {
                            handleArrayChange(
                              'insuranceName',
                              newInputValue,
                              index,
                            );
                          } else if (reason === 'clear' || !newInputValue) {
                            handleArrayChange('insuranceName', '', index);
                          }
                        }}
                        // disableClearable
                        getOptionLabel={(option) => `${option.name}`}
                        getOptionSelected={(option, value) => {
                          return option.name === value;
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Insurance Name *"
                            variant="outlined"
                          />
                        )}
                      />

                      {/* <Select
                        labelId="demo-simple-select-placeholder-label-label"
                        id="demo-simple-select-placeholder-label"
                        required
                        // value={age}
                        label="Insurance Name"
                        name={'insurance'}
                        defaultValue={object.insurance ? object.insurance : ''}
                        onChange={(e) =>
                          handleArrayChange(
                            e.target.name,
                            e.target.value,
                            index,
                          )
                        }
                        displayEmpty
                        variant="outlined"> */}
                      {/* <MenuItem
                          value={'AETNA_DENTAL_PLANS'}
                          className={classes.menuitem}>
                          Aetna
                        </MenuItem>
                        <MenuItem value={'ANTHEM'}>Anthem</MenuItem>
                        <MenuItem value={'CIGNA'}>Cigna</MenuItem>
                        <MenuItem value={'DD_CALIFORNIA'}>
                          Delta Dental CA
                        </MenuItem>
                        <MenuItem value={'DD_GEORGIA'}>
                          Delta Dental GA
                        </MenuItem>
                        <MenuItem value={'DD_MICHIGAN'}>
                          Delta Dental Michigan
                        </MenuItem>
                        <MenuItem value={'DD_PENNSYLVANIA'}>
                          Delta Dental PA
                        </MenuItem>
                        <MenuItem value={'DD_WASHINGTON'}>
                          Delta Dental Washington
                        </MenuItem>
                        <MenuItem value={'GUARDIAN'}>Guardian</MenuItem>
                        <MenuItem value={'METLIFE'}>Metlife</MenuItem>
                        <MenuItem value={'PRINCIPAL'}>Principal</MenuItem>
                        <MenuItem value={'UNITED_CONCORDIA'}>
                          United Concordia
                        </MenuItem>
                        <MenuItem value={'UNITED_HEALTHCARE'}>
                          United Healthcare
                        </MenuItem> */}
                      {/* </Select> */}
                    </FormControl>

                    {/* <FormHelperText>Label + placeholder</FormHelperText> */}
                    {/* <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="insurance-company"
                    label="Insurance Company"
                    name="insurancecompany"
                    inputProps={{
                      style: {
                        height,
                        padding: '0 14px',
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        height,
                        marginTop: '-10px',
                        fontSize: '15px',
                        fontFamily: 'Roboto',
                      },
                    }}
                  /> */}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            handleArrayChange(
                              e.target.name,
                              e.target.checked,
                              index,
                            );
                            turnOffOtherPrimaryCheck(index);
                          }}
                          name="primaryInsurance"
                          checked={object.primaryInsurance}
                        />
                      }
                      label="Primary Insurance"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="memberId"
                      label="Member ID / SSN (Subscriber)"
                      name="memberId"
                      defaultValue={object.memberId ? object.memberId : ''}
                      onChange={(e) => {
                        handleArrayChange(e.target.name, e.target.value, index);
                      }}
                      // value={object.memberId}
                      inputProps={{
                        style: {
                          height,
                          padding: '0 14px',
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          height,
                          marginTop: '-5px',
                          fontSize: '15px',
                          fontFamily: 'Roboto',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="groupNumber"
                      label="Group #"
                      name="groupNumber"
                      defaultValue={
                        object.groupNumber ? object.groupNumber : ''
                      }
                      // value={insuranceArray[index].groupNumber}
                      onChange={(e) =>
                        handleArrayChange(e.target.name, e.target.value, index)
                      }
                      inputProps={{
                        style: {
                          height,
                          padding: '0 14px',
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          height,
                          marginTop: '-5px',
                          fontSize: '15px',
                          fontFamily: 'Roboto',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl variant="outlined" size="small">
                      <div className={classes.patientDependentText}>
                        Is the patient a dependent? *
                      </div>
                      <Select
                        label="Dependent?"
                        name="dependent"
                        onChange={(e) =>
                          handleArrayChange(
                            e.target.name,
                            e.target.value,
                            index,
                          )
                        }
                        // value={textValues.locationSendForm}
                        defaultValue={
                          object.dependent === true ||
                          object.dependent === false
                            ? object.dependent
                            : ''
                        }
                        color="primary">
                        <MenuItem value={true} key={'Yes'}>
                          Yes
                        </MenuItem>
                        <MenuItem value={false} key={'No'}>
                          No
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {object.dependent === true ? (
                    <Fragment>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          autoFocus
                          autoComplete="fname"
                          name="subscriberFirstName"
                          variant="outlined"
                          required
                          fullWidth
                          defaultValue={
                            object.subscriberFirstName
                              ? object.subscriberFirstName
                              : ''
                          }
                          id="subscriberFirstName"
                          label="Subscriber First Name"
                          type="text"
                          onChange={(e) => {
                            handleArrayChange(
                              e.target.name,
                              e.target.value,
                              index,
                            );
                          }}
                          inputProps={{
                            style: {
                              height,
                              padding: '0 14px',
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              height,
                              marginTop: '-5px',
                              fontSize: '15px',
                              fontFamily: 'Roboto',
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          required
                          id="subscriberLastName"
                          label="Subscriber Last Name"
                          name="subscriberLastName"
                          autoComplete="lname"
                          defaultValue={
                            object.subscriberLastName
                              ? object.subscriberLastName
                              : ''
                          }
                          onChange={(e) => {
                            handleArrayChange(
                              e.target.name,
                              e.target.value,
                              index,
                            );
                          }}
                          inputProps={{
                            style: {
                              height,
                              padding: '0 14px',
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              height,
                              marginTop: '-5px',
                              fontSize: '15px',
                              fontFamily: 'Roboto',
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <KeyboardDatePicker
                          autoOk
                          required
                          variant="inline"
                          inputVariant="outlined"
                          label="Subscriber DOB"
                          fullWidth
                          format="MM/DD/yyyy"
                          value={object.subscriberDOB}
                          maxDate={new Date()}
                          placeholder="MM/DD/YYYY"
                          // InputAdornmentProps={{position: 'start'}}
                          onChange={(date) =>
                            handleArrayChange('subscriberDOB', date, index)
                          }
                          inputProps={{
                            style: {
                              height,
                              padding: '0 14px',
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              height,
                              marginTop: '-5px',
                              fontSize: '15px',
                              fontFamily: 'Roboto',
                            },
                          }}
                        />
                      </Grid>
                    </Fragment>
                  ) : null}

                  {insuranceArray.length > 1 && !props.patientData ? (
                    <IconButton>
                      <DeleteForeverIcon
                        onClick={() => deleteInsurance(index)}
                        style={{color: 'black'}}
                      />
                    </IconButton>
                  ) : null}
                  <hr
                    className={classes.divider}
                    style={{margin: '10px 0 0 0'}}
                  />
                </Fragment>
              ))}
              <Grid item xs={12} sm={12}>
                <BootstrapTooltip
                  placement="bottom-start"
                  title={
                    'Click to add an additional insurance for this patient.'
                  }>
                  {/* <div className={classes.toolTipabel}>{params.value}</div> */}
                  <IconButton className={classes.addicon} onClick={handleAdd}>
                    <AddIcon style={{color: 'black'}} />
                  </IconButton>
                </BootstrapTooltip>
              </Grid>
              {errorMessage ? (
                <Grid item xs={12} sm={12}>
                  <FormHelperText className={classes.errorMessage}>
                    {errorMessage}
                  </FormHelperText>
                </Grid>
              ) : null}
              <div className={classes.submitbotom}>
                <Button
                  // type="submit"
                  fullWidth
                  variant="contained"
                  onClick={handleClickSubmit}
                  color="primary">
                  Done
                </Button>
              </div>
            </Grid>
          </MuiPickersUtilsProvider>
        </form>
      </div>
    );
  };
  //*****************Return Component********************
  return (
    <MuiThemeProvider theme={theme}>
      <Link onClick={handleOpen}>{props.children}</Link>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        // closeAfterTransition
        // disableEnforceFocus
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          backgroundcolor: '#1B7FE1',
        }}>
        <Fade in={open}>
          <div className={classes.modalcontainer}>
            <div className={classes.topmodalcontainer}>
              <div className={classes.closebuttoncontainer}>
                <IconButton
                  onClick={handleClose}
                  style={{padding: 5, color: 'white'}}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div className={classes.title}>
                <div className={classes.centercontainer}>
                  <PersonAddIcon className={classes.icon} />
                  <p className={classes.subtitle}>New Patient</p>
                </div>
              </div>
            </div>

            {renderSpinner()}
          </div>
        </Fade>
      </Modal>
    </MuiThemeProvider>
  );
}

const mapStateToProp = (state) => {
  return {
    clinicBasicInfo: state.apiCallsReducer.clinicBasicInfo,
    clinicUsersList: state.apiCallsReducer.clinicUsersList,
    userInfo: state.apiCallsReducer.userInfo,
    clinicPatientsList: state.apiCallsReducer.clinicPatientsList,
    clinicTemplatesList: state.apiCallsReducer.clinicTemplatesList,
    clinicUsageInfo: state.apiCallsReducer.clinicUsageInfo,
    clinicLocationsList: state.apiCallsReducer.clinicLocationsList,
    locationFilteredClinicPatientsList:
      state.apiCallsReducer.locationFilteredClinicPatientsList,
  };
};

export default connect(mapStateToProp, {
  addOrEditPatientData,
  sendEmailAndOrTextToPatient,
  changeGlobalSearchObj,
})(TransitionsModal);

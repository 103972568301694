import React from 'react';
import {InputLabel, MenuItem, Select, FormControl} from '@material-ui/core';
import styles from './Select.module.scss';

function WestonSelect(props) {
  return (
    <div className="mt-1 fw">
      <FormControl
        variant={props.variant}
        name={props.name}
        fullWidth
        size={props.size}
        className={styles.formControl}>
        <InputLabel id={props.id || props.name} color={props.color}>
          {props.label}
        </InputLabel>
        <Select
          disabled={props.disabled}
          value={props.value || ''}
          onChange={({target}) => props.onChange(target.name, target.value)}
          label={props.label}
          name={props.name}
          defaultValue={props.defaultValue || ''}
          color={props.color}>
          {props.customOptions
            ? props.customOptions
            : props.options.map((object) => (
                <MenuItem value={object.id} name={object.name} key={object.id}>
                  {object.name}{' '}
                </MenuItem>
              ))}
        </Select>
        {props.error && (
          <p className={styles.redAlerts}>{props.label} is required</p>
        )}
      </FormControl>
    </div>
  );
}

WestonSelect.defaultProps = {
  color: 'primary',
  variant: 'outlined',
  options: [],
  size: 'small',
};

export default WestonSelect;

import React, {useState, useEffect} from 'react';
// import Navbar from '../Navbar/Navbar';
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import {useSelector} from 'react-redux';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import {
  Card,
  Modal,
  Backdrop,
  Fade,
  TextField,
  MenuItem,
  InputLabel,
  Select,
  IconButton,
  FormControl,
  Button,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import CardContent from '@material-ui/core/CardContent';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {connect} from 'react-redux';
import {
  createTemplate,
  saveTemplate,
  changeGlobalTemplateSearchObj,
} from '../../actions';
//import {KeyboardReturn, LensTwoTone} from '@material-ui/icons';
import {useAlert} from 'react-alert';
import {Spinner1} from '../Spinner1';
// import data from '../../data/adaCodeList.json';

//*************************************************************************************Component Styling Sheet***************************************************

const useStyles = makeStyles((theme) => ({
  root: {
    // overflow: 'hidden',
    // '.MuiButton-root-484': {
    //   padding: '100px',
    // },
    display: 'flex',
    flex: 1,
    fontFamily: 'Roboto',
  },

  nametitle: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#1B7FE1',
    fontSize: '30px',
    fontWeight: 1000,
  },
  addbuttonicon: {
    fontSize: '50px',
  },
  infotitle: {
    color: '#1B7FE1',
    backgroundColor: '#EFEFEF',
    fontSize: '15px',
    postion: 'absolute',
    fontWeight: '1000',
    margin: '0 0 -15px 0',
    padding: '10px',
  },
  divider: {
    borderTop: '2px solid #1B7FE1',
    width: '100%',
  },
  subtitle: {
    color: '#161719',
    fontSize: '14px',
    fontWeight: 1000,
    padding: '0 10px 0 0',
  },
  maincontainer: {
    // height: '100%',
    display: 'flex',
    // flex: 1,
    flexDirection: 'column',
  },
  contentcontainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '10px 0 0 0',
    alignItems: 'center',
  },
  tablecontainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '30px',
    justifyContent: 'center',
    width: '90%',
    //height: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '80%',
    },
  },
  leftcardcontainer: {
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  rightcardcontainer: {
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  card: {
    backgroundColor: '#EFEFEF',
    fontFamily: 'Roboto',
    overflowY: 'scroll',
    width: '100%',
    label: {
      textAlign: 'center',
    },
  },
  arrowscontainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px',
    },
  },
  forwardarrow: {
    paddingTop: '30px',
    [theme.breakpoints.down('md')]: {
      paddingTop: '10px',
    },
  },
  backarrow: {
    paddingBottom: '30px',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '10px',
    },
  },
  savebutton: {
    // padding: '0 10px',
    margin: '20px',
  },
  tableRow: {
    cursor: 'pointer',
    '&$selected, &$selected:hover': {
      backgroundColor: '#1B7FE1',
    },
  },
  tableCell: {
    '$selected &': {
      color: '#fff',
    },
    fontSize: '12px',
  },
  tablehead: {
    fontWeight: 1000,
  },
  hover: {},
  selected: {},
  templatedropdown: {
    display: 'flex',
    flexDirection: 'row',
    width: '40%',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '20px 0 0 0',
    [theme.breakpoints.down('xs')]: {
      width: '70%',
    },
  },
  topcontainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  addbutton: {
    display: 'flex',
    padding: theme.spacing(0),
    margin: '0 0 0 10px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBodyContainer: {
    width: '50%',
    height: 'auto',
    backgroundColor: 'white',
    borderRadius: '10px',
    outline: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  closeButton: {
    color: 'white',
    //position: 'absolute',
    padding: 4,
    float: 'right',
  },
  modalHeader: {
    backgroundColor: '#1B7FE1',
    height: '60px',
    width: '100%',
    borderRadius: '10px 10px 0 0',
  },
  titleText: {
    fontSize: 22,
    height: '0',
    width: '100%',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  inputfield: {
    display: 'flex',
    justifyContent: 'center',
  },
  modalcontentcontainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '90%',
    padding: '30px 30px 10px 30px',
  },
  donebotom: {
    margin: '20px',
    width: 'auto',
    justifyContent: 'center',
  },
  search: {
    position: 'relative',
    width: '35%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '70%',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      padding: '10px 0 0 0',
    },
  },
  searchbar: {
    // display: 'flex',
    // flexDirection: 'row',
    alignSelf: 'center',
    flex: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchIcon: {
    padding: '15px 10px',
    pointerEvents: 'none',
    display: 'flex',
  },
  inputRoot: {
    padding: '0 0 0 0',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 10px 0 0',
    },
  },
  inputInput: {
    color: 'black',
    // padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    transition: theme.transitions.create('width'),
  },
  searchButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  clearSearchButton: {
    color: 'black',
    padding: '5px',
  },
}));

const filterOptions = createFilterOptions({
  limit: 20,
  trim: true,
});

//*****************Generate Header and Body for Table********************


const height = 25;

//*******************************************************************************Template Component**************************************************************

function Template(props) {
  const data = useSelector(({apiCallsReducer}) => apiCallsReducer?.adaCodeList);
  const adaCodeKeys = Object.keys(data);
  const rows = adaCodeKeys.map((object1, index) => {
    const object = {
      activelySelected: false,
      leftSide: true,
      code: object1,
      procedure: data[object1],
      inSearch: false,
    };
    return object;
  });
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#1B7FE1',
        contrastText: '#fff', //button text white instead of black
      },
      background: {
        default: '#fff',
      },
    },
    typography: {
      fontFamily: '"Roboto"',
    },
  });

  const alert = useAlert();
  const [selectedTemplate, setTemplate] = useState({});
  const [searchText, setSearchText] = useState('');
  const [searchActive, setActivelySearched] = useState(false);
  const [open, setModalOpen] = useState(false);
  const [templateText, setTemplateText] = useState('');
  // const [errorMessage, setErrorMessage] = useState(false);
  const [adaCodeList, setADACodeMasterList] = useState([]);
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [adaCodeListForSearch, setADACodeListForSearch] = useState([]);

  useEffect(() => {
    // Below is to open modal if clinic doesn't have any templates set
    // if(props.clinicTemplatesList.length < 1) {
    //   setModalOpen(true);
    // }
    console.log('use effect called');
    console.log(rows);
    setActivelySearched(false);
    const rows1 = [...rows];
    setADACodeMasterList(rows1);
    setLoadingLocal(false);
    setModalOpen(false);
    setTemplate({});
    setRootHeight(document.getElementById('root').clientHeight);
    setADACodeListForSearch1();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setADACodeListForSearch1 = () => {
    const keys = Object.keys(data);
    const values1 = Object.values(data);
    const result = keys.map((obj, index) => {
      return {
        code: keys[index],
        description: values1[index],
      };
    });
    setADACodeListForSearch(result);
  };

  const [rootHeight, setRootHeight] = useState(0);

  const handleChangeTemplate = (event, index) => {
    let selectedTemplateObject = {};
    props.clinicTemplatesList.forEach((object) => {
      if (object.name === event) {
        selectedTemplateObject = object;
      }
    });
    let newList = [...rows];
    setActivelySearched(false);
    setSearchText('');
    newList.forEach((object, index2) => {
      // this is to reset all to the leftSide and back to default
      newList[index2].leftSide = true;
      newList[index2].activelySelected = false;
      newList[index2].inSearch = false;
    });
    if (selectedTemplateObject.codes) {
      const ADAKeysOfSelected = Object.keys(selectedTemplateObject.codes);
      // First foreach is to loop through [D0120, D0121, etc.]
      ADAKeysOfSelected.forEach((object) => {
        // Second foreach is to loop through adaCodeList to check if the code equals
        newList.forEach((object1, index1) => {
          if (object1.code === object) {
            newList[index1].leftSide = false;
          }
        });
      });
      setADACodeMasterList(newList);
    } else {
      // if the template has no codes, set it to default
      // only reason i am doing the below is becuase if i don't it just keeps giving back old array
      newList.forEach((object, index2) => {
        newList[index2].leftSide = true;
        newList[index2].activelySelected = false;
      });
      setADACodeMasterList(newList);
    }
    // this is to put leftSide = false for all the items already i
    setTemplate(selectedTemplateObject);
  };

  const handleTextChange = (e) => {
    if (e) {
      e.preventDefault();
      setTemplateText(e.target.value);
    }
  };

  const handleSearchClick = async (e, clear) => {
    if (e) {
      e.preventDefault();
      setLoadingLocal(true);
      const code1 = searchText.substring(0, 5);
      if (searchText === '' || searchText === ' ' || clear === 'clear') {
        setActivelySearched(false);
        let searchResult = [...adaCodeList];
        searchResult.forEach((ele, index) => {
          searchResult[index].inSearch = false;
        });
        setADACodeMasterList(searchResult);
        setSearchText('');
        setLoadingLocal(false);
      } else {
        setActivelySearched(true);
        const searchResult = await changeGlobalTemplateSearchObj(
          code1,
          adaCodeList,
        );
        // console.log(searchResult);
        setADACodeMasterList(searchResult);
        setLoadingLocal(false);
      }
    }
  };

  const handleSearchTextChange = async (value) => {
    // if (value === '' || value === ' ') {
    //   setActivelySearched(false);
    //   setSearchText(value);
    // } else {
    if (typeof value !== 'object') {
      setSearchText(value);
    }
    // }
  };

  const selectRow = (id1) => {
    if (selectedTemplate.name) {
      const newList = [...adaCodeList];
      const trueOrFalse = !adaCodeList[id1].activelySelected;
      newList[id1].activelySelected = trueOrFalse;
      setADACodeMasterList(newList);
    } else {
      alert.show('Please select or create a new template', {
        title: 'No Template',
      });
    }
  };

  const handleClickSubmit = async (e) => {
    if (e) {
      e.preventDefault();
      // Below loop is to check if template name already exists
      let error = false;

      if (props.clinicTemplatesList.length > 0) {
        props.clinicTemplatesList.forEach((object) => {
          if (object.name.toLowerCase() === templateText.toLowerCase()) {
            error = true;
          }
        });
      }
      if (error) {
        alert.show('Template name already exists', {title: 'Template Exists'});
        return;
      } else if (templateText === '') {
        alert.show('Please enter a template name', {title: 'Name Required'});
        return;
      } else if (
        /[$]/g.test(templateText) ||
        /[[]/g.test(templateText) ||
        /]/g.test(templateText) ||
        /[#]/g.test(templateText) ||
        /[.]/g.test(templateText)
      ) {
        alert.show('Please remove invalid character(s) from template name.', {
          title: 'Invalid Template Name',
        });
        return;
      } else {
        setLoadingLocal(true);
        await props.createTemplate(
          templateText,
          props.clinicBasicInfo,
          props.userInfo,
        );
        handleChangeTemplate(templateText);
        setTemplate({name: templateText});
        setLoadingLocal(false);
        setModalOpen(false);
      }
    }
  };

  const handleClickSave = async (e) => {
    if (e) {
      e.preventDefault();
      if (selectedTemplate.name) {
        setLoadingLocal(true);
        let templateObject = {
          name: selectedTemplate.name,
          codes: {},
        };
        adaCodeList.forEach((object, index) => {
          if (object.leftSide === false) {
            const code = object.code;
            templateObject.codes[code] = true;
          }
        });
        await props.saveTemplate(
          selectedTemplate.name,
          templateObject,
          props.userInfo,
          props.clinicBasicInfo,
        );
        alert.show('Template successfully saved.', {title: 'Success!'});

        setLoadingLocal(false);
      } else {
        alert.show('You must select or create a new template.', {
          title: 'No Template Selected',
        });
      }
    }
  };

  const onRightArrowPress = () => {
    let newList = [...adaCodeList];
    adaCodeList.forEach((object, index) => {
      if (object.activelySelected) {
        newList[index].leftSide = false;
        newList[index].activelySelected = false;
      }
    });
    setADACodeMasterList(newList);
  };

  const onLeftArrowPress = () => {
    let newList = [...adaCodeList];
    adaCodeList.forEach((object, index) => {
      if (object.activelySelected) {
        newList[index].leftSide = true;
        newList[index].activelySelected = false;
      }
    });
    setADACodeMasterList(newList);
  };

  const classes = useStyles();

  //*****************Return Component********************

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.maincontainer}>
        {/* <Navbar /> */}
        <Spinner1 open={loadingLocal} />
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          closeAfterTransition
          disableEnforceFocus
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
            backgroundColor: '#1B7FE1',
          }}>
          <Fade in={open}>
            <div className={classes.modalBodyContainer}>
              <div className={classes.modalHeader}>
                <IconButton
                  className={classes.closeButton}
                  onClick={() => setModalOpen(false)}>
                  <CloseIcon />
                </IconButton>
                <div className={classes.titleText}>Create Template</div>
              </div>
              <div className={classes.inputfield}>
                <div className={classes.modalcontentcontainer}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="templateName"
                    label="Template Name"
                    type="text"
                    value={templateText}
                    onChange={(e) => handleTextChange(e)}
                    inputProps={{
                      style: {
                        height: 40,
                        padding: '0 14px',
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        height,
                        marginTop: '-5px',
                        fontSize: '15px',
                        fontFamily: 'Roboto',
                      },
                    }}
                  />
                  <div style={{textAlign: 'center'}}>
                    <MuiThemeProvider theme={theme}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        className={classes.donebotom}
                        onClick={handleClickSubmit}
                        color="primary">
                        Done
                      </Button>
                    </MuiThemeProvider>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>

        {/*****************Card Containers************************************************************/}
        <div className={classes.contentcontainer}>
          <div className={classes.topcontainer}>
            <div className={classes.templatedropdown}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  color="primary">
                  Template
                </InputLabel>
                <Select
                  className={classes.label}
                  value={selectedTemplate.name ? selectedTemplate.name : ''}
                  defaultValue={
                    selectedTemplate.name ? selectedTemplate.name : ''
                  }
                  onChange={(e) => handleChangeTemplate(e.target.value)}
                  label="Template"
                  color="primary">
                  {props.clinicTemplatesList.map((object, index) => (
                    <MenuItem value={object.name} key={index}>
                      {object.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <IconButton
                className={classes.addbutton}
                onClick={() => setModalOpen(true)}>
                <AddIcon
                  color="primary"
                  fontSize="large"
                  className={classes.addbuttonicon}
                />
              </IconButton>
            </div>
            <div className={classes.search}>
              {/* <div className={classes.searchbar}> */}
              {/* <SearchIcon className={classes.searchIcon} />
                <InputBase
                  placeholder="Search for ADA Codes"
                  fullWidth
                  color="primary"
                  // value={searchText}
                  defaultValue={searchText ? searchText : ''}
                  onBlur={(e) => handleSearchTextChange(e)}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{background: '#E6E6E6'}}
                /> */}
              <MuiThemeProvider theme={theme}>
                <Autocomplete
                  // id="combo-box-demo"
                  filterOptions={filterOptions}
                  data-html2canvas-ignore="true"
                  fullWidth={true}
                  freeSolo={false}
                  // value={searchADACodeText}
                  // onChange={handleSearchTextChange}
                  options={adaCodeListForSearch}
                  inputValue={searchText}
                  onInputChange={(event, newInputValue) => {
                    handleSearchTextChange(newInputValue);
                  }}
                  size={'small'}
                  disableClearable
                  getOptionLabel={(option) =>
                    `${option.code} - ${option.description}`
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onKeyPress={(ev) => {
                        console.log(`Pressed keyCode ${ev.key}`);
                        if (ev.key === 'Enter') {
                          // Do code here
                          handleSearchClick(ev);
                        }
                      }}
                      label="Search Code"
                      variant="outlined"
                    />
                  )}
                />
              </MuiThemeProvider>
              {/* </div> */}

              <div className={classes.searchButtonContainer}>
                <MuiThemeProvider theme={theme}>
                  <div className={classes.savebutton}>
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={handleSearchClick}
                      color="primary">
                      Search
                    </Button>
                  </div>
                </MuiThemeProvider>
                <MuiThemeProvider theme={theme}>
                  <div className={classes.clearbutton}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={(e) => {
                        handleTextChange('');
                        handleSearchClick(e, 'clear');
                      }}>
                      Clear
                    </Button>
                  </div>
                </MuiThemeProvider>
                {/* <IconButton
                  className={classes.clearSearchButton}
                  onClick={(e) => {
                    setSearchText('');
                    handleSearchClick(e);
                  }}>
                  <CloseIcon />
                </IconButton> */}
              </div>
            </div>
          </div>

          {/*****************Left card******************************************************/}
          <div className={classes.tablecontainer}>
            <div className={classes.leftcardcontainer}>
              <Card className={classes.card} style={{height: rootHeight - 60}}>
                <CardContent>
                  <p className={classes.infotitle}>All ADA Codes</p>
                  <hr className={classes.divider} />
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tablehead}>
                          Code
                        </TableCell>
                        <TableCell className={classes.tablehead}>
                          Procedure
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {adaCodeList.map((row, index) =>
                        searchActive === true &&
                        row.inSearch === false ? null : row.leftSide ===
                          true ? (
                          <TableRow
                            hover
                            key={index}
                            onClick={() => {
                              selectRow(index);
                            }}
                            selected={row.activelySelected === true}
                            classes={{
                              hover: classes.hover,
                              selected: classes.selected,
                            }}
                            className={classes.tableRow}>
                            <TableCell
                              className={classes.tableCell}
                              component="th"
                              scope="row">
                              {row.code}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {row.procedure}
                            </TableCell>
                          </TableRow>
                        ) : null,
                      )}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </div>
            {/*****************Center card******************************************************/}
            <div className={classes.arrowscontainer}>
              <div className={classes.forwardarrow}>
                <IconButton onClick={() => onRightArrowPress()}>
                  <ArrowForwardIcon
                    color="primary"
                    style={{fontSize: '50px'}}
                  />
                </IconButton>
              </div>
              <div className={classes.backarrow}>
                <IconButton onClick={() => onLeftArrowPress()}>
                  <ArrowBackIcon color="primary" style={{fontSize: '50px'}} />
                </IconButton>
              </div>

              <div>
                <MuiThemeProvider theme={theme}>
                  <Button
                    type="submit"
                    variant="contained"
                    className={classes.savebutton}
                    onClick={handleClickSave}
                    color="primary">
                    Save
                  </Button>
                </MuiThemeProvider>
              </div>
            </div>
            {/*****************Right card******************************************************/}
            <div className={classes.rightcardcontainer}>
              <Card className={classes.card} style={{height: rootHeight - 60}}>
                <CardContent>
                  <p className={classes.infotitle}>Ada Codes in Template</p>
                  <hr className={classes.divider} />
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tablehead}>
                          Code
                        </TableCell>
                        <TableCell className={classes.tablehead}>
                          Procedure
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {adaCodeList.map((row, index) =>
                        searchActive === true &&
                        row.inSearch === false ? null : row.leftSide ===
                          false ? (
                          <TableRow
                            hover
                            key={index}
                            onClick={() => {
                              selectRow(index);
                            }}
                            selected={row.activelySelected}
                            classes={{
                              hover: classes.hover,
                              selected: classes.selected,
                            }}
                            className={classes.tableRow}>
                            <TableCell
                              className={classes.tableCell}
                              component="th"
                              scope="row">
                              {row.code}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {row.procedure}
                            </TableCell>
                          </TableRow>
                        ) : null,
                      )}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>

        {/*****************Card Containers***********************************************************************************/}
      </div>
    </MuiThemeProvider>
  );
}

const mapStateToProp = (state) => {
  return {
    clinicBasicInfo: state.apiCallsReducer.clinicBasicInfo,
    clinicUsersList: state.apiCallsReducer.clinicUsersList,
    userInfo: state.apiCallsReducer.userInfo,
    clinicPatientsList: state.apiCallsReducer.clinicPatientsList,
    clinicTemplatesList: state.apiCallsReducer.clinicTemplatesList,
    clinicUsageInfo: state.apiCallsReducer.clinicUsageInfo,
    clinicLocationsList: state.apiCallsReducer.clinicLocationsList,
  };
};

export default connect(mapStateToProp, {
  createTemplate,
  saveTemplate,
  changeGlobalTemplateSearchObj,
})(Template);

import React from 'react';
import PropTypes from 'prop-types';
import styles from './OptionsButtons/OptionsButtons.module.scss';

function OptionButton(props) {
  return (
    <div>
      <button
        onClick={() => props.onClick(props.value)}
        className={`${styles.extraButton} ${
          props.activeOption === props.value ? styles.isActive : ''
        }`}>
        {props.children}
      </button>
    </div>
  );
}

OptionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default OptionButton;

export default function validate(values) {
  let errorMessage = null;
  if (!values.locationSendForm) {
    errorMessage = 'Location is required.';
  }
  if (values.phoneNumberSendForm.length > 0) {
    if (
      !/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(
        values.phoneNumberSendForm,
      )
    ) {
      errorMessage = 'Phone Number must be 10 digits. Or leave empty.';
    }
  }
  if (values.emailSendForm.length > 0) {
    if (!/\S+@\S+\.\S+/.test(values.emailSendForm)) {
      errorMessage = 'Please enter a valid email. Or leave empty.';
    }
  }
  if (
    values.phoneNumberSendForm.length < 1 &&
    values.emailSendForm.length < 1
  ) {
    errorMessage = 'Please enter either a phone number or email, or both!';
  }
  return errorMessage;
}

import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'components/Form/Input/Input';
import Title from '../Options/Title';
import Subtitle from '../Options/Subtitle';
import Autocomplete from 'components/Form/Autocomplete/Autocomplete';
import Options from 'data/options';

const Input = React.memo((props) => {
  const inputProps = React.useRef({'data-submodule': props.submodule});

  return <TextField {...props} inputProps={inputProps.current} />;
});

function Major({values = {}, onChange, children,disableInputs}) {
  const handleChange = React.useCallback(
    (name, value, {dataset}) => {
      const {submodule} = dataset;
      onChange('major', submodule, name, value);
    },
    [onChange],
  );

  return (
    <div className="row">
      <div className="col-md-12 my-1">
        <div className="d-flex justify-content-between">
          <Title>Major</Title>
          {children}
        </div>
      </div>
      <div className="col-md-12 my-1">
        <Subtitle>Restorative</Subtitle>
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="inlays"
          value={
            values.restorative.inlays || Options.major.restorative.inlays[0]
          }
          onChange={handleChange}
          label="Inlays (2610)"
          disabled={disableInputs}
          submodule="restorative"
          options={Options.major.restorative.inlays}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="inlaysComments"
          value={values.restorative.inlaysComments}
          onChange={handleChange}
          label="Comments"
          disabled={disableInputs}
          submodule="restorative"
          options={Options.major.restorative.inlaysComments}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="onlays"
          value={
            values.restorative.onlays || Options.major.restorative.onlays[0]
          }
          onChange={handleChange}
          label="Onlays (2662)"
          disabled={disableInputs}
          submodule="restorative"
          options={Options.major.restorative.onlays}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="onlaysComments"
          value={values.restorative.onlaysComments}
          onChange={handleChange}
          label="Comments"
          disabled={disableInputs}
          submodule="restorative"
          options={Options.major.restorative.onlaysComments}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="crownBuildUps"
          value={
            values.restorative.crownBuildUps ||
            Options.major.restorative.crownBuildUps[0]
          }
          onChange={handleChange}
          label="Crown BuildUps (2950)"
          disabled={disableInputs}
          submodule="restorative"
          options={Options.major.restorative.crownBuildUps}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="crownBuildUpsLimitations"
          value={values.restorative.crownBuildUpsLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          submodule="restorative"
          options={Options.major.restorative.crownBuildUpsLimitations}
        />
      </div>
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-3 col-xs-12">
            <Autocomplete
              name="stainlessSteelCrowns"
              value={
                values.restorative.stainlessSteelCrowns ||
                Options.major.restorative.stainlessSteelCrowns[1]
              }
              onChange={handleChange}
              label="Stainless Steel Crowns (2930)"
              disabled={disableInputs}
              submodule="restorative"
              options={Options.major.restorative.stainlessSteelCrowns}
            />
          </div>
        </div>
      </div>
      <div className="col-md-12 my-1">
        <Subtitle>Denture Reline</Subtitle>
      </div>
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-3 col-xs-12">
            <Autocomplete
              name="relines"
              value={
                values.dentureRelin.relines ||
                Options.major.dentureRelin.relines[0]
              }
              onChange={handleChange}
              label="Relines (5750-5751)"
              disabled={disableInputs}
              submodule="dentureRelin"
              options={Options.major.dentureRelin.relines}
            />
          </div>
        </div>
      </div>
      <div className="col-md-12 my-1">
        <Subtitle>Prosthodontics</Subtitle>
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="singleCrown"
          value={
            values.prosthodontics.singleCrown ||
            Options.major.prosthodontics.singleCrown[0]
          }
          onChange={handleChange}
          label="Single Crown (2700's)"
          disabled={disableInputs}
          submodule="prosthodontics"
          options={Options.major.prosthodontics.singleCrown}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="porcelainPosteriorLimitations"
          value={values.prosthodontics.porcelainPosteriorLimitations}
          onChange={handleChange}
          label="Porcelain Posterior Limitations"
          disabled={disableInputs}
          submodule="prosthodontics"
          options={Options.major.prosthodontics.porcelainPosteriorLimitations}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="implants"
          value={
            values.prosthodontics.implants ||
            Options.major.prosthodontics.implants[0]
          }
          onChange={handleChange}
          label="Implants (6010)"
          disabled={disableInputs}
          submodule="prosthodontics"
          options={Options.major.prosthodontics.implants}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="implantsLimitations"
          value={values.prosthodontics.implantsLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          submodule="prosthodontics"
          options={Options.major.prosthodontics.implantsLimitations}
        />
      </div>

      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="implantAbutment"
          value={
            values.prosthodontics.implantAbutment ||
            Options.major.prosthodontics.implantAbutment[0]
          }
          onChange={handleChange}
          label="Implant Abutment (6057)"
          disabled={disableInputs}
          submodule="prosthodontics"
          options={Options.major.prosthodontics.implantAbutment}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="implantAbutmentLimitations"
          value={values.prosthodontics.implantAbutmentLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          submodule="prosthodontics"
          options={Options.major.prosthodontics.implantAbutmentLimitations}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="implantCrown"
          value={
            values.prosthodontics.implantCrown ||
            Options.major.prosthodontics.implantCrown[0]
          }
          onChange={handleChange}
          label="Implant Crown (6065)"
          disabled={disableInputs}
          submodule="prosthodontics"
          options={Options.major.prosthodontics.implantCrown}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="implantCrownLimitations"
          value={values.prosthodontics.implantCrownLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          submodule="prosthodontics"
          options={Options.major.prosthodontics.implantCrownLimitations}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="bridges"
          value={
            values.prosthodontics.bridges ||
            Options.major.prosthodontics.bridges[0]
          }
          onChange={handleChange}
          label="Bridges (6200-6700's)"
          disabled={disableInputs}
          submodule="prosthodontics"
          options={Options.major.prosthodontics.bridges}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="partials"
          value={
            values.prosthodontics.partials ||
            Options.major.prosthodontics.partials[0]
          }
          onChange={handleChange}
          label="Partials (5213/5214)"
          disabled={disableInputs}
          submodule="prosthodontics"
          options={Options.major.prosthodontics.partials}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="dentures"
          value={
            values.prosthodontics.dentures ||
            Options.major.prosthodontics.dentures[0]
          }
          onChange={handleChange}
          label="Dentures (5110/5120)"
          disabled={disableInputs}
          submodule="prosthodontics"
          options={Options.major.prosthodontics.dentures}
        />
      </div>
      <div className="col-md-12 my-1">
        <Autocomplete
          name="missingToothClause"
          value={values.prosthodontics.missingToothClause}
          onChange={handleChange}
          label="Missing Tooth Clause"
          disabled={disableInputs}
          submodule="prosthodontics"
          options={Options.major.prosthodontics.missingToothClause}
        />
      </div>
      <div className="col-md-12 my-1">
        <Autocomplete
          name="replacementRule"
          value={values.prosthodontics.replacementRule}
          onChange={handleChange}
          label="Replacement Rule"
          disabled={disableInputs}
          submodule="prosthodontics"
          options={Options.major.prosthodontics.replacementRule}
        />
      </div>
      <div className="col-md-12 my-1">
        <Input
          name="other"
          value={values.prosthodontics.other}
          onChange={handleChange}
          label="Other"
          disabled={disableInputs}
          submodule="prosthodontics"
        />
      </div>
      <div className="col-md-12 my-1">
        <Autocomplete
          name="prepSeatDate"
          value={values.prosthodontics.prepSeatDate}
          onChange={handleChange}
          label="Prep/Seat Date"
          disabled={disableInputs}
          submodule="prosthodontics"
          options={Options.major.prosthodontics.prepSeatDate}
        />
      </div>
      <div className="col-md-12 my-1">
        <Subtitle>Other Services</Subtitle>
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="nightguardsForBruxism"
          value={values.otherServices.nightguardsForBruxism}
          onChange={handleChange}
          label="Brux Nightguards (9944/9945)"
          disabled={disableInputs}
          submodule="otherServices"
          options={Options.major.otherServices.nightguardsForBruxism}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="nightguardsForBruxismLimitations"
          value={values.otherServices.nightguardsForBruxismLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          submodule="otherServices"
          options={Options.major.otherServices.nightguardsForBruxismLimitations}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="TMJNonSurgical"
          value={values.otherServices.TMJNonSurgical}
          onChange={handleChange}
          label="TMJ Non-Surgical (7880)"
          disabled={disableInputs}
          submodule="otherServices"
          options={Options.major.otherServices.TMJNonSurgical}
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="TMJNonSurgicalLimitations"
          value={values.otherServices.TMJNonSurgicalLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          submodule="otherServices"
          options={Options.major.otherServices.TMJNonSurgicalLimitations}
        />
      </div>
      <div className="col-md-12 my-1">
        <Autocomplete
          name="comments"
          value={values.otherServices.comments}
          onChange={handleChange}
          label="Comments"
          disabled={disableInputs}
          submodule="otherServices"
          multiline
          options={Options.major.otherServices.comments}
        />
      </div>
    </div>
  );
}

Major.propTypes = {
  values: PropTypes.object.isRequired,
};

Major.defaultProps = {
  onChange: () => null,
};

export default Major;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './EmptyPlaceholder.module.scss';

function EmptyPlaceholder({text, children}) {
  return (
    <div className={styles.wrapper}>
      <DefaultPlaceholder>{children || text}</DefaultPlaceholder>
    </div>
  );
}

function DefaultPlaceholder({children}) {
  return <div className={styles.placeholder}>{children}</div>;
}

EmptyPlaceholder.defaultProps = {
  text: 'There are no records to show you right now',
};

EmptyPlaceholder.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
};

export default EmptyPlaceholder;

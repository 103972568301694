import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'components/Form/Input/Input';
import Subtitle from '../Options/Subtitle';
import Autocomplete from 'components/Form/Autocomplete/Autocomplete';
import Options from 'data/options';

const Input = React.memo((props) => {
  const inputProps = React.useRef({'data-submodule': 'periodontal'});
  return <TextField {...props} inputProps={inputProps.current} />;
});
function Periodontal({handleChange, values = {}, disableInputs}) {
  return (
    <>
      <div className="col-md-12">
        <Subtitle>Periodontal</Subtitle>
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="osseous"
          value={values.osseous || Options.basic.periodontal.osseous[0]}
          onChange={handleChange}
          label="Osseous (4260)"
          disabled={disableInputs}
          options={Options.basic.periodontal.osseous}
          submodule="periodontal"
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="osseousLimitations"
          value={values.osseousLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          options={Options.basic.periodontal.osseousLimitations}
          submodule="periodontal"
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="gingivectomy"
          value={
            values.gingivectomy || Options.basic.periodontal.gingivectomy[0]
          }
          onChange={handleChange}
          label="Gingivectomy (4210)"
          disabled={disableInputs}
          options={Options.basic.periodontal.gingivectomy}
          submodule="periodontal"
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="gingivectomyLimitations"
          value={values.gingivectomyLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          options={Options.basic.periodontal.gingivectomyLimitations}
          submodule="periodontal"
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="crownLengthening"
          value={
            values.crownLengthening ||
            Options.basic.periodontal.crownLengthening[0]
          }
          onChange={handleChange}
          label="Crown Lengthening (4249)"
          disabled={disableInputs}
          options={Options.basic.periodontal.crownLengthening}
          submodule="periodontal"
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="crownLengtheningLimitations"
          value={values.crownLengtheningLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          options={Options.basic.periodontal.crownLengtheningLimitations}
          submodule="periodontal"
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="tissueGraft"
          value={values.tissueGraft || Options.basic.periodontal.tissueGraft[0]}
          onChange={handleChange}
          label="Tissue Graft (4273/4277/4278)"
          disabled={disableInputs}
          options={Options.basic.periodontal.tissueGraft}
          submodule="periodontal"
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="tissueGraftLimitations"
          value={values.tissueGraftLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          options={Options.basic.periodontal.tissueGraftLimitations}
          submodule="periodontal"
        />
      </div>
      <div className="col-md-12 my-1">
        <Autocomplete
          name="surgicalPeriodontalFrequency"
          value={values.surgicalPeriodontalFrequency}
          onChange={handleChange}
          label="Surgical Periodontal Frequency"
          disabled={disableInputs}
          options={Options.basic.periodontal.surgicalPeriodontalFrequency}
          submodule="periodontal"
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="rootPlanning"
          value={
            values.rootPlanning || Options.basic.periodontal.rootPlanning[0]
          }
          onChange={handleChange}
          label="Root Planning (4341)"
          disabled={disableInputs}
          options={Options.basic.periodontal.rootPlanning}
          submodule="periodontal"
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="rootPlanningLimitations"
          value={values.rootPlanningLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          options={Options.basic.periodontal.rootPlanningLimitations}
          submodule="periodontal"
        />
      </div>
      <div className="col-md-12 my-1">
        <Input
          name="extraLimitations"
          value={values.extraLimitations}
          onChange={handleChange}
          label="Extra Limitations"
          disabled={disableInputs}
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="scalingAndInflammation"
          value={
            values.scalingAndInflammation ||
            Options.basic.periodontal.scalingAndInflammation[0]
          }
          onChange={handleChange}
          label="Scaling and Inflammation (4346)"
          disabled={disableInputs}
          options={Options.basic.periodontal.scalingAndInflammation}
          submodule="periodontal"
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="scalingAndInflammationLimitations"
          value={values.scalingAndInflammationLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          options={Options.basic.periodontal.scalingAndInflammationLimitations}
          submodule="periodontal"
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="debridement"
          value={values.debridement || Options.basic.periodontal.debridement[1]}
          onChange={handleChange}
          label="Debridement (4355)"
          disabled={disableInputs}
          options={Options.basic.periodontal.debridement}
          submodule="periodontal"
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="debridementLimitations"
          value={values.debridementLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          options={Options.basic.periodontal.debridementLimitations}
          submodule="periodontal"
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="chemoAgent"
          value={values.chemoAgent || Options.basic.periodontal.chemoAgent[1]}
          onChange={handleChange}
          label="Chemo Agent (4381)"
          disabled={disableInputs}
          options={Options.basic.periodontal.chemoAgent}
          submodule="periodontal"
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="chemoAgentLimitations"
          value={values.chemoAgentLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          options={Options.basic.periodontal.chemoAgentLimitations}
          submodule="periodontal"
        />
      </div>
      <div className="col-md-3 col-xs-12 my-1">
        <Autocomplete
          name="periodontalTrophy"
          value={
            values.periodontalTrophy ||
            Options.basic.periodontal.periodontalTrophy[1]
          }
          onChange={handleChange}
          label="Periodontal Trophy (4910)"
          disabled={disableInputs}
          options={Options.basic.periodontal.periodontalTrophy}
          submodule="periodontal"
        />
      </div>
      <div className="col-md-9 col-xs-12 my-1">
        <Autocomplete
          name="trophyLimitations"
          value={values.trophyLimitations}
          onChange={handleChange}
          label="Limitations"
          disabled={disableInputs}
          options={Options.basic.periodontal.trophyLimitations}
          submodule="periodontal"
        />
      </div>
      <div className="col-md-12 my-1">
        <Autocomplete
          name="periodontalComments"
          multiline
          value={
            values.periodontalComments ||
            Options.basic.periodontal.periodontalComments[0]
          }
          onChange={handleChange}
          label="Periodontal Comments"
          disabled={disableInputs}
          options={Options.basic.periodontal.periodontalComments}
          submodule="periodontal"
        />
      </div>
    </>
  );
}

Periodontal.propTypes = {
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default React.memo(Periodontal);

import {fade, makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '0 0 30px 0',
  },
  search: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    padding: '20px 0 0 0',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
  },
  searchIcon: {
    padding: theme.spacing(1, 2),
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    color: 'black',
    border: '1px solid #C1C1C1',
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '50ch',
    [theme.breakpoints.down('xs')]: {
      width: '80%',
    },
  },
  root: {
    '& .MuiDataGrid-sortIcon': {
      color: 'black',
    },
    '& .MuiDataGrid-colCellWrapper': {
      backgroundColor: '#e8e8e8',
    },
    '& .MuiDataGrid-colCell': {
      width: '200px',
    },
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
      color: '#4D4B4B',
    },
    '.MuiDataGrid-cell:focus': {
      outline: 'none',
      outlineWidth: '1px',
      backgroundColor: '#C1C1C1',
    },
    cursor: 'pointer',
  },
  tableContainer: {
    width: '100%',
    height: '100%',
  },
  mainContainer: {
    height: 'calc(100vh - 263px + 41px)',
    fontFamily: '"Roboto"',
  },
  pageTitle: {
    fontSize: '35px',
    textAlign: 'center',
  },
  pageTitleText: {
    margin: 0,
    padding: '30px 0 0 0',
    color: '#1B7FE1',
    fontWeight: 'bold',
  },
}));

export default useStyles;

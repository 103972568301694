// import getNpiResponse from './Api';
// import axios from 'axios';

export default function validate(values) {
  let errors = {};
  if (!values.firstName) {
    errors.firstName = 'First Name is required';
  }
  if (!values.lastName) {
    errors.lastName = 'Last Name is required';
  }
  if (!values.phonenumber) {
    errors.phonenumber = 'Phone Number is required';
  } else if (
    !/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(values.phonenumber)
  ) {
    errors.phonenumber = 'Phone Number must be 10 digits';
  }
  if (!values.provideid) {
    errors.provideid = 'National Provider ID is required';
  } else if (
    !/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(values.provideid) ||
    /\s/g.test(values.provideid) ||
    !/^\d+$/.test(values.provideid) ||
    /[$]/g.test(values.provideid) ||
    /[[]/g.test(values.provideid) ||
    /]/g.test(values.provideid) ||
    /[.]/g.test(values.provideid) ||
    /[#]/g.test(values.provideid)
  ) {
    errors.provideid = 'National Provider ID must be 10 digits.';
  }
  // if (!values.taxid) {
  //   errors.taxid = 'Tax ID is required';
  // }
  if (!values.clinicname) {
    errors.clinicname = 'Clinic Name is required';
  } else if (
    /[$]/g.test(values.clinicname) ||
    /[[]/g.test(values.clinicname) ||
    /]/g.test(values.clinicname) ||
    /[.]/g.test(values.clinicname) ||
    /[#]/g.test(values.clinicname)
  ) {
    errors.clinicname = 'Please remove invalid characters';
  }
  if (!values.clinicaddress) {
    errors.clinicaddress = 'Clinic Address is required';
  }
  // if (!values.clinicaddress2) {
  //   errors.clinicaddress2 = 'Clinic Address 2 is required';
  // }
  if (!values.city) {
    errors.city = 'City is required';
  } else if (
    /[$]/g.test(values.city) ||
    /[[]/g.test(values.city) ||
    /]/g.test(values.city) ||
    /[.]/g.test(values.city) ||
    /[#]/g.test(values.city)
  ) {
    errors.city = 'Please remove invalid characters';
  }
  if (!values.state) {
    errors.state = 'State is required';
  }
  if (!values.zip) {
    errors.zip = 'Zip Code is required';
  }

  return errors;
}

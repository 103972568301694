import {
  CLINIC_BASIC_INFO,
  CLINIC_USERS_LIST,
  USER_INFO,
  CLINIC_PATIENTS_LIST,
  CLINIC_TEMPLATES_LIST,
  CLINIC_USAGE_INFO,
  CLINIC_LOCATIONS_LIST,
  UPDATE_SEARCH_RESULTS,
  UPDATE_CLINIC_LOCATION_FILTER_RESULTS,
  ACTIVE_OR_ARCHIVED_PATIENT_LIST,
  RESETALL,
  INSURANCES,
  UPDATE_PATIENT,
  ADA_CODE_LIST,
  ADA_CODE_KEYS
} from '../actions/types';

const INITIAL_STATE = {
  insurances: [],
  userInfo: {},
  clinicBasicInfo: {},
  clinicUsersList: {},
  clinicPatientsList: [],
  clinicTemplatesList: [],
  searchedClinicPatientsList: false,
  clinicUsageInfo: {},
  clinicLocationsList: [],
  locationFilteredClinicPatientsList: [],
  activeOrArchivedPatientList: 'Active',
  adaCodeList: {},
  adaCodeKeys: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLINIC_BASIC_INFO:
      return {...state, clinicBasicInfo: action.payload};
    case CLINIC_USERS_LIST:
      return {...state, clinicUsersList: action.payload};
    case USER_INFO:
      return {...state, userInfo: action.payload};
    case CLINIC_PATIENTS_LIST:
      return {
        ...state,
        clinicPatientsList: action.payload,
        locationFilteredClinicPatientsList: setNextEventDates(action.payload),
      };
    case CLINIC_TEMPLATES_LIST:
      return {...state, clinicTemplatesList: action.payload};
    case CLINIC_USAGE_INFO:
      return {...state, clinicUsageInfo: action.payload};
    case CLINIC_LOCATIONS_LIST:
      return {...state, clinicLocationsList: action.payload};
    case UPDATE_SEARCH_RESULTS:
      return {...state, searchedClinicPatientsList: action.payload};
    case UPDATE_CLINIC_LOCATION_FILTER_RESULTS:
      return {
        ...state,
        locationFilteredClinicPatientsList: setNextEventDates(action.payload),
      };
    case ACTIVE_OR_ARCHIVED_PATIENT_LIST:
      return {...state, activeOrArchivedPatientList: action.payload};
    case RESETALL:
      return {...INITIAL_STATE};
    case INSURANCES: {
      return {...state, insurances: action.payload.insurances};
    }
    case UPDATE_PATIENT: {
      return {
        ...state,
        searchedClinicPatientsList:
          action.payload.newSearchedClinicPatientsList,
        clinicPatientsList: action.payload.newClinicPatientsList,
        locationFilteredClinicPatientsList: setNextEventDates(
          action.payload.newLocationFilteredClinicPatientsList,
        ),
      };
    }
    case ADA_CODE_LIST: {
      return {...state, adaCodeList: action.payload};
    }
    case ADA_CODE_KEYS: {
      return {...state, adaCodeKeys: action.payload};
    }
    default:
      return state;
  }
};

function setNextEventDates(patientrs) {
  return patientrs.map((patient) => {
    let procedureDates = [];
    let followUpDates = [];

    if (patient && patient.procedureDates) {
      procedureDates = setDatesToArray(patient.procedureDates);
    }

    if (patient && patient.followUpDates) {
      followUpDates = setDatesToArray(patient.followUpDates);
    }

    procedureDates.sort((a, b) => new Date(a) - new Date(b));
    followUpDates.sort((a, b) => new Date(a) - new Date(b));

    const filteredProcedureDates = filterPastDates(procedureDates);
    const filteredFollowUpDates = filterPastDates(followUpDates);

    return {
      ...patient,
      nextProcedureDate: filteredProcedureDates[0] || null,
      nextFollowUpDate: filteredFollowUpDates[0] || null,
    };
  });
}

function setDatesToArray(idList) {
  let dateList = [];
  if (idList) {
    Object.keys(idList).forEach((id) => {
      const dates = idList[id];
      Object.keys(dates).forEach((date) => {
        dateList.push(new Date(Number(date)));
      });
    });
  }

  return dateList;
}

function filterPastDates(dates) {
  return dates.filter((date) => new Date(date) >= new Date());
}

import {
  UPDATE_SEARCH_RESULTS,
  UPDATE_CLINIC_LOCATION_FILTER_RESULTS,
  INSURANCES,
} from './types';
import Fuse from 'fuse.js';
import firebase from 'firebase';

export const changeGlobalSearchObj = (searchText, clinicPatientsList) => {
  // Search Options
  const options = {
    threshold: 0.1,
    keys: [
      'personalInfo.firstName',
      'personalInfo.lastName',
      'personalInfo.DOB',
      'primaryInsurance',
    ],
    includeScore: true,
    ignoreLocation: true,
  };

  // Split incoming string into array if contains space
  const wordArray = searchText.split(' ');
  let sendToGlobalState = false;
  // If search is not empty, loop through all inidividual words and filter down the array with search from each word
  if (searchText !== '') {
    let searchArray = clinicPatientsList;
    wordArray.forEach((word) => {
      if (word !== '') {
        const fuse = new Fuse(searchArray, options);
        const searchResult = fuse.search(word);
        searchArray = searchResult.map((ele) => {
          return ele.item;
        });
      }
    });
    sendToGlobalState = searchArray;
  }
  return async (dispatch) => {
    dispatch({
      type: UPDATE_SEARCH_RESULTS,
      payload: sendToGlobalState,
    });
  };
};

export const changeClinicLocationSelection = (
  clinicLocation,
  clinicPatientsList,
  clinicLocationsList,
) => {
  const output = handleClinicLocationSelection(
    clinicLocation,
    clinicPatientsList,
    clinicLocationsList,
  );
  return async (dispatch) => {
    dispatch({
      type: UPDATE_CLINIC_LOCATION_FILTER_RESULTS,
      payload: output,
    });
    return output;
  };
};

export const handleClinicLocationSelection = (
  clinicLocation,
  clinicPatientsList,
  clinicLocationsList,
) => {
  let output = clinicPatientsList;
  if (clinicLocation !== 'All' && clinicLocationsList.length > 1) {
    output = [];
    clinicPatientsList.forEach((ele) => {
      if (ele.personalInfo.location === clinicLocation) {
        output.push(ele);
      }
    });
  }

  return output;
};

export const changeGlobalTemplateSearchObj = (
  searchText,
  adaCodeMasterList,
) => {
  // Search Options
  const options = {
    threshold: 0.1,
    keys: ['code', 'procedure'],
    includeScore: true,
    ignoreLocation: true,
  };

  // Split incoming string into array if contains space
  const wordArray = searchText.split(' ');
  // If search is not empty, loop through all inidividual words and filter down the array with search from each word
  let searchArray = adaCodeMasterList;
  if (searchText !== '') {
    // To set all search items as false
    searchArray.forEach(function (ele, index2) {
      searchArray[index2].inSearch = false;
      return;
    });
    wordArray.forEach((word) => {
      if (word !== '') {
        const fuse = new Fuse(adaCodeMasterList, options);
        const searchResult = fuse.search(word);
        searchResult.forEach((ele) => {
          // ele.item.code if what we need to look for in adaCodeMasterList
          searchArray.forEach((ele1, index1) => {
            if (ele1.code === ele.item.code) {
              searchArray[index1].inSearch = true;
              return;
            }
            return;
          });
          return;
        });
      }
    });
    // sendToGlobalState = searchArray;
  }
  return searchArray;
};

export function getInsurances() {
  return (dispatch) => {
    firebase
      .database()
      .ref('employerBenefitsLibraries')
      .once('value', (snapshot) => {
        const data = snapshot.val();
        let insurances = flatObject(data);
        if (!insurances) {
          insurances = [];
        }
        dispatch({type: INSURANCES, payload: {insurances}});
      });
  };
}

function flatObject(object) {
  const result = [];
  Object.keys(object).forEach((key) => {
    const insurances = object[key] || {};
    Object.keys(insurances).forEach((groupKey) => {
      const groups = insurances[groupKey] || {};
      Object.keys(groups).forEach((insuranceKey) => {
        const insurance = groups[insuranceKey];
        if (insurance) {
          result.push({
            ...insurance,
            id: `${key}${groupKey}-${insuranceKey}`,
            searchKey: `${insurance.insuranceName} ${insurance.groupNumber} ${insurance.planName} ${insurance.employerName}`,
          });
        }
      });
    });
  });

  return result;
}

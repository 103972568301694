import React from 'react';
import PropTypes from 'prop-types';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import {Ring} from 'react-spinners-css';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PDF from 'Utils/makePDF';
import styles from './Options.module.scss';
import {uploadToFirebase, sendPDFBenefitsEmail} from 'actions/apiCallsActions';
import {connect} from 'react-redux';
import {useAlert} from 'react-alert';

const LOADER_TYPES = {
  pdf: 0,
  email: 1,
};

function PDFButtons({insuranceName, groupNumber, clinicBasicInfo, ...props}) {
  const alert = useAlert();
  console.log(clinicBasicInfo);
  const [pdfLoader, setPdfLoader] = React.useState(false);

  const makePdf = async () => {
    setPdfLoader(LOADER_TYPES.pdf);
    await PDF({
      fileName: `${insuranceName}-${groupNumber}`,
      clinicBasicInfo,
      uploadToFirebase: props.uploadToFirebase,
      sendPDFBenefitsEmail: props.sendPDFBenefitsEmail,
      alert,
    });
    setPdfLoader(false);
  };

  const sendEmail = async () => {
    setPdfLoader(LOADER_TYPES.email);
    await PDF({
      sendType: 'email',
      fileName: `${insuranceName}-${groupNumber}`,
      clinicBasicInfo,
      uploadToFirebase: props.uploadToFirebase,
      sendPDFBenefitsEmail: props.sendPDFBenefitsEmail,
      alert,
    });
    setPdfLoader(false);
  };

  return (
    <div className={styles.pdfIcon}>
      {pdfLoader === LOADER_TYPES.pdf ? (
        <Ring color="#1B7FE1" size={40} />
      ) : (
        <PictureAsPdfIcon
          onClick={makePdf}
          color="inherit"
          fontSize="inherit"
        />
      )}
      {pdfLoader === LOADER_TYPES.email ? (
        <Ring color="#1B7FE1" size={40} />
      ) : (
        <MailOutlineIcon
          color="inherit"
          fontSize="inherit"
          onClick={sendEmail}
        />
      )}
    </div>
  );
}

PDFButtons.propTypes = {
  groupNumber: PropTypes.string.isRequired,
  insuranceName: PropTypes.string.isRequired,
};

export default connect(null, {uploadToFirebase, sendPDFBenefitsEmail})(
  PDFButtons,
);

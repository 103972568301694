import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    width: '100%',
    height: '100%',
  },
  root: {
    '& .MuiDataGrid-sortIcon': {
      color: 'black',
    },
    '& .MuiDataGrid-colCellWrapper': {
      backgroundColor: '#e8e8e8',
    },
    // '& .MuiDataGrid-colCell': {
    //   width: '200px',
    // },
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
      color: '#4D4B4B',
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
      outlineWidth: '1px',
      backgroundColor: '#C1C1C1',
      //color: '#FFFFFF',
    },
    cursor: 'pointer',
  },
  Datagrid: {
    borderColor: 'white',
    fontFamily: '"Roboto"',
    '& .MuiDataGrid-colCellTitle': {
      fontWeight: 'bold',
    },
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
      color: '#707070',
    },
    '& .MuiDataGrid-iconSeparator': {
      color: 'white',
    },
    '& .MuiDataGrid-cell': {
      borderColor: 'white',
    },
    '& .MuiDataGrid-columnsContainer': {
      borderColor: 'white',
      backgroundColor: 'white',
    },
    '& .MuiDataGrid-window': {
      [theme.breakpoints.up('md')]: {
        overflowX: 'hidden',
      },
    },
  },
}));

export default useStyles;

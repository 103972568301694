import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './Loader.module.scss';

function Loader(props) {
  return props.loading ? (
    <>
      <div className={styles.loader}>
        <CircularProgress />
      </div>
      <div className={styles.overlay} />
    </>
  ) : null;
}

Loader.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default Loader;

import React from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogTitle, DialogContent} from '@material-ui/core';
import styles from './Modal.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

function Modal(props) {
  return (
    <>
      <Dialog
        open={props.open}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        onClose={props.onClose}
        className={styles.dialog}
        disableAutoFocus>
        <DialogTitle className={styles.dialogTitle}>
          <span className="dialog-title">{props.title}</span>
          <IconButton onClick={props.onClose} className={styles.closeIcon}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          {props.children}
        </DialogContent>
      </Dialog>
    </>
  );
}

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Modal;

import React from 'react';
import PropTypes from 'prop-types';

function Column({children}) {
  return (
    <div className="col-12">
      <div className="row">{children}</div>
    </div>
  );
}

Column.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Column;

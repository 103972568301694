import React from 'react';
import PropTypes from 'prop-types';
import Select from 'components/Form/Select/Select';

function TemplatesSelect(props) {
  return (
    <Select
      label="Templates"
      value={props.value}
      name="selectedTemplate"
      options={props.templates.list}
      onChange={(_, value) => {
        props.onChange(props.templates.collection[value]);
      }}
    />
  );
}

TemplatesSelect.propTypes = {
  selectedTemplate: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default TemplatesSelect;

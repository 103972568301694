import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/Form/Checkbox/Checkbox';
import Button from 'components/Common/Button/Button';
import Select from 'components/Form/Select/Select';
import moment from 'moment';
import {useAlert} from 'react-alert';
import FollowUpSwitch from './FollowUpSwitch';
import axios from 'axios';
import {useSelector, useDispatch} from 'react-redux';
import {updatePatient, getPatient} from 'actions/patientActions';

function FollowUpForm({
  patientId,
  treatmentList,
  treatmentId = '',
  email,
  phone,
  firstName,
  lastName,
  hideTreatments,
  submitCallback,
  closeModal,
  showFollowUpSwitch = true,
}) {
  const [model, setModel] = React.useState({
    sms: false,
    email: false,
    both: false,
    treatmentId,
    enabledFollowUps: false,
  });

  const [loading, setLoading] = React.useState(false);

  const clinicId = useSelector(
    ({apiCallsReducer}) => apiCallsReducer?.clinicBasicInfo?.clinicId,
  );

  const clinicName = useSelector(
    ({apiCallsReducer}) => apiCallsReducer?.clinicBasicInfo?.clinicName,
  );

  const alert = useAlert();
  const dispatch = useDispatch();

  const handleChange = React.useCallback((name, value) => {
    if (name === 'both') {
      setModel((currentModel) => ({
        ...currentModel,
        sms: value,
        email: value,
        both: value,
      }));
    } else {
      setModel((currentModel) => {
        const newModel = {
          ...currentModel,
          [name]: value,
        };
        newModel.both = newModel.email && newModel.sms;

        return newModel;
      });
    }
  }, []);

  React.useEffect(() => {
    if (patientId && treatmentList && treatmentList.length && !hideTreatments) {
      const treatment = treatmentList[0];

      setModel((currentModel) => ({
        ...currentModel,
        treatmentId: treatment.id,
      }));
    }
  }, [patientId, treatmentList, hideTreatments]);

  const submit = async () => {
    let method = 'both';
    if (model.sms && !model.email) {
      method = 'sms';
    } else if (model.email && !model.sms) {
      method = 'email';
    }

    const currentTreatment = (treatmentList &&
      treatmentList.find(
        (treatment) => treatment.id === model.treatmentId,
      )) || {treatmentPlanName: ''};

    const followUp = {
      date: moment().format('MM/DD/YYYY'),
      opened: false,
      patientId,
      status: 'sent',
      treatmentId: model.treatmentId,
      treatmentPlanName: currentTreatment.treatmentPlanName,
      type: 'manual',
      method,
    };

    const body = {
      followUp,
      clinic: {clinicId, clinicName},
      patient: {
        email,
        name: firstName,
        lastName: lastName,
        patientId,
        phoneNumber: phone,
      },
    };

    setLoading(true);

    const {
      data: {status, smsStatus, emailStatus},
    } = await axios.post(
      'https://us-central1-insurance-ca69d.cloudfunctions.net/sendManualFollowUp',
      body,
    );

    const patient = await getPatient(clinicId, patientId);

    if (smsStatus === 'error') {
      alert.error(
        'There was an error sending the sms to the patient. Please verify a valid phone number was provided and try again.',
      );
    }

    if (emailStatus === 'error') {
      alert.error(
        'There was an error sending the email to the patient. Please verify a valid phone number was provided and try again.',
      );
    }

    setLoading(false);

    if (patient) {
      dispatch(updatePatient(patient));
    }

    if (status === 'success') {
      if (typeof submitCallback === 'function') {
        submitCallback(model.treatmentId, clinicId);
      }
      if (typeof closeModal === 'function') {
        closeModal();
      }
      alert.success('Follow-up was sent to the patient');
    } else {
      alert.success(
        'There was an error sending the follow-up reminder. Please verify the patient information and try again.',
      );
    }
  };

  const switchCallback = React.useCallback((enabledFollowUps) => {
    setModel((currentModel) => ({...currentModel, enabledFollowUps}));
  }, []);

  return (
    <div className="row">
      {!hideTreatments && treatmentList.length ? (
        <div className="col-12">
          <Select
            label="Treatment"
            onChange={handleChange}
            name="treatmentId"
            value={model.treatmentId}
            options={treatmentList}
          />
        </div>
      ) : !hideTreatments ? (
        <div className="col-12 my-3">
          This patient doesn't have any treatments.
        </div>
      ) : null}
      <div className="col-12">
        <Checkbox
          id="sms"
          label="Text Message"
          name="sms"
          value={model.sms}
          onChange={handleChange}
          disabled={!phone}
        />
      </div>
      <div className="col-12">
        <Checkbox
          id="email"
          label="E-mail"
          name="email"
          value={model.email}
          onChange={handleChange}
          disabled={!email}
        />
      </div>
      <div className="col-12">
        <Checkbox
          id="both"
          label="Both"
          name="both"
          value={model.both}
          onChange={handleChange}
          disabled={!email || !phone}
        />
      </div>
      <div className="col-6">
        <div className="d-flex justify-content-end">
          <Button
            onClick={submit}
            disabled={
              (!model.email && !model.sms) ||
              loading ||
              !model.treatmentId ||
              !model.enabledFollowUps
            }>
            {loading ? 'Sending...' : 'Send follow ups'}
          </Button>
        </div>
      </div>
      <div className="col-6">
        <FollowUpSwitch
          showFollowUpSwitch={showFollowUpSwitch}
          patientId={patientId}
          clinicId={clinicId}
          switchCallback={switchCallback}
        />
      </div>
    </div>
  );
}

FollowUpForm.propTypes = {
  treatmentId: PropTypes.string,
  submitCallback: PropTypes.func,
  patientId: PropTypes.string.isRequired,
};

export default FollowUpForm;

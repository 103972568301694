import React from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Form/Input/Input';
import Title from '../Options/Title';
import Column from 'components/Grid/Column';
import Autocomplete from 'components/Form/Autocomplete/Autocomplete';
import Options from 'data/options';

function General({values = {}, disableInputs = false, onChange, children}) {
  const handleChange = React.useCallback(
    (name, value) => {
      onChange('general', name, value);
    },
    [onChange],
  );

  return (
    <div>
      <div className="row">
        <div className="col-md-12 my-1">
          <div className="d-flex justify-content-between">
            <Title>General</Title>
            {children}
          </div>
        </div>
        <div className="col-md-3 col-xs-12 my-1">
          <Autocomplete
            name="deductibleDollar"
            value={values.deductibleDollar}
            onChange={handleChange}
            label="Deductible"
            disabled={disableInputs}
            options={Options.general.deductibleDollar}
          />
        </div>
        <div className="col-md-9 col-xs-12 my-1">
          <Autocomplete
            name="deductibleNote"
            value={values.deductibleNote}
            onChange={handleChange}
            label="Deductible Note"
            disabled={disableInputs}
            options={Options.general.deductibleNote}
          />
        </div>
        <div className="col-md-3 col-xs-12 my-1">
          <Autocomplete
            name="familyDeductibleDollar"
            value={values.familyDeductibleDollar}
            onChange={handleChange}
            label="Family Deductible"
            disabled={disableInputs}
            options={Options.general.familyDeductibleDollar}
          />
        </div>
        <div className="col-md-9 col-xs-12 my-1">
          <Autocomplete
            name="familyDeductibleNote"
            value={values.familyDeductibleNote}
            onChange={handleChange}
            label="Family Deductible Note"
            disabled={disableInputs}
            options={Options.general.familyDeductibleNote}
          />
        </div>
        <Column>
          <div className="col-md-3 col-xs-12 my-1">
            <Autocomplete
              name="benefitYear"
              value={values.benefitYear}
              onChange={handleChange}
              label="Benefit Year"
              disabled={disableInputs}
              options={Options.general.benefitYear}
            />
          </div>
          <div className="col-md-3 col-xs-12 my-1">
            <Autocomplete
              name="dependentAge"
              value={values.dependentAge}
              onChange={handleChange}
              label="Dependent Age"
              disabled={disableInputs}
              options={Options.general.dependentAge}
            />
          </div>
          <div className="col-md-3 col-xs-12 my-1">
            <Autocomplete
              name="dependentStudentAge"
              value={values.dependentStudentAge}
              onChange={handleChange}
              label="Dependent Student Age"
              disabled={disableInputs}
              options={Options.general.dependentStudentAge}
            />
          </div>
        </Column>
        <div className="col-md-3 col-xs-12 my-1">
          <Autocomplete
            name="preventivePercent"
            value={values.preventivePercent}
            onChange={handleChange}
            label="Preventive"
            disabled={disableInputs}
            options={Options.general.preventivePercent}
          />
        </div>
        <div className="col-md-9 col-xs-12 my-1">
          <Input
            name="preventiveNote"
            value={values.preventiveNote}
            onChange={handleChange}
            label="Preventive Note"
            disabled={disableInputs}
          />
        </div>
        <div className="col-md-3 col-xs-12 my-1">
          <Autocomplete
            name="basicPercent"
            value={values.basicPercent}
            onChange={handleChange}
            label="Basic"
            disabled={disableInputs}
            options={Options.general.basicPercent}
          />
        </div>
        <div className="col-md-9 col-xs-12 my-1">
          <Input
            name="basicNote"
            value={values.basicNote}
            onChange={handleChange}
            label="Basic Note"
            disabled={disableInputs}
          />
        </div>
        <div className="col-md-3 col-xs-12 my-1">
          <Autocomplete
            name="majorPercent"
            value={values.majorPercent}
            onChange={handleChange}
            label="Major"
            disabled={disableInputs}
            options={Options.general.majorPercent}
          />
        </div>
        <div className="col-md-9 col-xs-12 my-1">
          <Input
            name="majorNote"
            value={values.majorNote}
            onChange={handleChange}
            label="Major Note"
            disabled={disableInputs}
          />
        </div>
        <Column>
          <div className="col-md-3 col-xs-12 my-1">
            <Autocomplete
              name="signatureOnFile"
              value={values.signatureOnFile}
              onChange={handleChange}
              label="Signature on File"
              disabled={disableInputs}
              options={Options.general.signatureOnFile}
            />
          </div>
          <div className="col-md-3 col-xs-12 my-1">
            <Autocomplete
              name="standardADAForm"
              value={values.standardADAForm}
              onChange={handleChange}
              label="Standard ADA Form"
              disabled={disableInputs}
              options={Options.general.standardADAForm}
            />
          </div>
          <div className="col-md-3 col-xs-12 my-1">
            <Autocomplete
              name="coordinationCOB"
              value={values.coordinationCOB}
              onChange={handleChange}
              label="Coordination COB"
              disabled={disableInputs}
              options={Options.general.coordinationCOB}
            />
          </div>
        </Column>
        <div className="col-md-12 my-1">
          <Autocomplete
            name="coordinatorRule"
            value={values.coordinatorRule}
            onChange={handleChange}
            label="Coordination Rule"
            disabled={disableInputs}
            options={Options.general.coordinatorRule}
          />
        </div>
        <div className="col-md-12 my-1">
          <Autocomplete
            name="comments"
            multiline
            value={values.comments}
            onChange={handleChange}
            label="Comments"
            disabled={disableInputs}
            options={Options.general.comments}
          />
        </div>
      </div>
    </div>
  );
}

General.propTypes = {
  values: PropTypes.object.isRequired,
};

General.defaultProps = {
  onChange: () => null,
};

export default General;

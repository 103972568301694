import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import {useHistory, useLocation} from 'react-router-dom';
import UseForm from '../UseForm';
import validate from '../SignupValidationRules';
import {connect} from 'react-redux';
import {submitFormToFirebase} from '../../actions';
import logo from '../123insurancelogo.png';
import {Spinner1} from '../Spinner1';
import {useAlert} from 'react-alert';

//*************************************************************************************Component Styling Sheet***************************************************

const useStyles = makeStyles((theme) => ({
  root: {
    // overflow: 'hidden',
    display: 'flex',
    flex: 1,
    fontFamily: '"Roboto"',
  },
  fullPageContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    fontFamily: '"Roboto"',
    backgroundColor: '#1B7FE1',
    [theme.breakpoints.only('md')]: {
      height: '100%',
    },
    [theme.breakpoints.only('sm')]: {
      height: '100%',
    },
  },
  maincontainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: '0 0 20px 0',
    [theme.breakpoints.only('md')]: {
      justifyContent: 'space-evenly',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },
  signupcontainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '60%',
    height: 'auto',
    justifyContent: 'center',
    boxShadow: '0 14px 28px #7F7F7F, 0 10px 10px #7F7F7F',
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
    [theme.breakpoints.only('sm')]: {
      width: '90%',
    },
    [theme.breakpoints.only('md')]: {
      width: '90%',
    },
  },
  signupform: {
    backgroundColor: '#FFFFFF',
    padding: '25px 25px',
    textAlign: 'center',
    borderRadius: '10px',
    width: '100%',
    height: '100%',
  },
  titletext: {
    backgroundColor: 'white',
    fontSize: '16px',
    padding: '10px',
    position: 'absolute',
    fontWeight: 'bold',
    color: '#1B7FE1',
  },
  signuptitle: {
    color: '#1B7FE1',
    textAlign: 'center',
    fontSize: '30px',
    margin: 1,
  },
  divider: {
    borderTop: '2px solid #1B7FE1',
    width: '100%',
    float: 'right',
  },
  subtitlecontainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    padding: '20px 0',
  },
  topfields: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  middlefields: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  bottomfields: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  veryBottomfields: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  natidlink: {
    fontSize: '12px',
    float: 'left',
    color: '#0080FF',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  redalerts: {
    margin: 1,
    color: 'red',
    fontSize: '12px',
    float: 'left',
  },
  signupbutton: {
    margin: '20px 0 0 0',
  },
  haveanaccount: {
    fontSize: '14px',
    paddingTop: '10px',
    color: '#0080FF',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  logo: {
    width: '250px',
    padding: '20px 0',
    margin: 'auto',
  },
  logocontainer: {
    display: 'flex',
    height: '14%',
  },
}));

//*******************************************************************************Sign up Component**************************************************************
function Signup(props) {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#1B7FE1',
        contrastText: '#fff', //button text white instead of black
      },
      background: {
        default: '#fff',
      },
    },
  });

  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [loadingLocal, setLoadingLocal] = useState(false);
  const alert = useAlert();

  useEffect(() => {
    setLoadingLocal(false);
    if (!location?.state?.values || !location?.state?.values.email) {
      history.replace('/');
    }
    // eslint-disable-next-line
  }, []);
  // const location = useLocation();
  const {values, errors, handleChange, handleSubmit} = UseForm(
    signup,
    validate,
  );

  async function signup() {
    if (loadingLocal === false) {
      console.log('No errors, submit callback called!');
      console.log('start spinner');
      setLoadingLocal(true);
      const loginState = location.state.values;
      const result = await props.submitFormToFirebase({
        ...values,
        ...loginState,
      });
      if (!result.error) {
        setLoadingLocal(false);
        history.replace('/section/insurance');
      } else {
        // ***** Neeed to render error and setLoadingLocal(false)
        setLoadingLocal(false);
        alert.show(result.errorMessage);
        console.log(result);
      }
    }
  }

  //*****************Return Component********************
  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.fullPageContainer}>
        <div className={classes.maincontainer}>
          <div className={classes.logocontainer}>
            <img src={logo} alt="123 Insurance Logo" className={classes.logo} />
          </div>
          <Spinner1 open={loadingLocal} />
          <div className={classes.signupcontainer}>
            <form
              onSubmit={handleSubmit}
              noValidate
              className={classes.signupform}>
              <p className={classes.signuptitle}>Create an Account</p>
              <div className={classes.subtitlecontainer}>
                <p className={classes.titletext}>Personal Information</p>
                <hr className={classes.divider} />
              </div>
              <div className={classes.topfields}>
                <div style={{flex: 1, padding: '10px'}}>
                  <TextField
                    autoComplete="fname"
                    name="firstName"
                    variant="outlined"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    type="text"
                    autoFocus
                    size="small"
                    className={`input ${errors.firstName && 'is-danger'}`}
                    onChange={handleChange}
                    value={values.firstName || ''}
                    InputLabelProps={{
                      style: {
                        fontFamily: 'Roboto',
                        fontSize: '14px',
                      },
                    }}
                  />
                  {errors.firstName && (
                    <p className={classes.redalerts}>{errors.firstName}</p>
                  )}
                </div>
                <div style={{flex: 1, padding: '10px'}}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="lname"
                    size="small"
                    className={`input ${errors.lastName && 'is-danger'}`}
                    onChange={handleChange}
                    value={values.lastName || ''}
                    InputLabelProps={{
                      style: {
                        fontFamily: 'Roboto',
                        fontSize: '14px',
                      },
                    }}
                  />
                  {errors.lastName && (
                    <p className={classes.redalerts}>{errors.lastName}</p>
                  )}
                </div>
              </div>
              <div style={{padding: '10px'}}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="phonenumber"
                  label="Phone Number"
                  type="text"
                  id="phone-number"
                  size="small"
                  className={`input ${errors.phonenumber && 'is-danger'}`}
                  onChange={handleChange}
                  value={values.phonenumber || ''}
                  InputLabelProps={{
                    style: {
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                    },
                  }}
                />
                {errors.phonenumber && (
                  <p className={classes.redalerts}>{errors.phonenumber}</p>
                )}
              </div>

              <div className={classes.subtitlecontainer}>
                <p className={classes.titletext}>Clinic Information</p>
                <hr className={classes.divider} />
              </div>

              <div className={classes.middlefields}>
                <div style={{flex: 1, padding: '10px'}}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    type="text"
                    id="clinic-name"
                    label="Clinic Name"
                    name="clinicname"
                    size="small"
                    className={`input ${errors.clinicname && 'is-danger'}`}
                    onChange={handleChange}
                    value={values.clinicname || ''}
                    InputLabelProps={{
                      style: {
                        fontFamily: 'Roboto',
                        fontSize: '14px',
                      },
                    }}
                  />
                  {errors.clinicname && (
                    <p className={classes.redalerts}>{errors.clinicname}</p>
                  )}
                </div>
                <div style={{flex: 1, padding: '10px'}}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="provideid"
                    label="National Provider ID"
                    type="text"
                    id="provide-id"
                    size="small"
                    className={`input ${errors.provideid && 'is-danger'}`}
                    onChange={handleChange}
                    value={values.provideid || ''}
                    InputLabelProps={{
                      style: {
                        fontFamily: 'Roboto',
                        fontSize: '14px',
                      },
                    }}
                  />
                  <Link
                    href="https://npiregistry.cms.hhs.gov/"
                    target="_blank"
                    className={classes.natidlink}>
                    Don’t know your National Provider ID? Find it here.
                  </Link>
                  {errors.provideid && (
                    <p className={classes.redalerts}>{errors.provideid}</p>
                  )}
                </div>
              </div>
              <div style={{padding: '10px'}}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  type="text"
                  id="clinic-address"
                  label="Clinic Address"
                  name="clinicaddress"
                  size="small"
                  className={`input ${errors.clinicaddress && 'is-danger'}`}
                  onChange={handleChange}
                  value={values.clinicaddress || ''}
                  InputLabelProps={{
                    style: {
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                    },
                  }}
                />
                {errors.clinicaddress && (
                  <p className={classes.redalerts}>{errors.clinicaddress}</p>
                )}
              </div>

              <div style={{padding: '10px'}}>
                <TextField
                  variant="outlined"
                  // required
                  fullWidth
                  type="text"
                  id="clinic-address2"
                  label="Clinic Address 2"
                  name="clinicaddress2"
                  size="small"
                  className={`input ${errors.clinicaddress2 && 'is-danger'}`}
                  onChange={handleChange}
                  value={values.clinicaddress2 || ''}
                  InputLabelProps={{
                    style: {
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                    },
                  }}
                />
                {errors.clinicaddress2 && (
                  <p className={classes.redalerts}>{errors.clinicaddress2}</p>
                )}
              </div>

              <div className={classes.bottomfields}>
                <div style={{flex: 1, padding: '10px'}}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    type="text"
                    id="city"
                    label="City"
                    name="city"
                    size="small"
                    className={`input ${errors.city && 'is-danger'}`}
                    onChange={handleChange}
                    value={values.city || ''}
                    InputLabelProps={{
                      style: {
                        fontFamily: 'Roboto',
                        fontSize: '14px',
                      },
                    }}
                  />
                  {errors.city && (
                    <p className={classes.redalerts}>{errors.city}</p>
                  )}
                </div>

                <div style={{flex: 1, padding: '10px'}}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    type="text"
                    id="state"
                    label="State"
                    name="state"
                    size="small"
                    className={`input ${errors.state && 'is-danger'}`}
                    onChange={handleChange}
                    value={values.state || ''}
                    InputLabelProps={{
                      style: {
                        fontFamily: 'Roboto',
                        fontSize: '14px',
                      },
                    }}
                  />
                  {errors.state && (
                    <p className={classes.redalerts}>{errors.state}</p>
                  )}
                </div>

                <div style={{flex: 1, padding: '10px'}}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    type="text"
                    id="zip"
                    label="Zip"
                    name="zip"
                    size="small"
                    className={`input ${errors.zip && 'is-danger'}`}
                    onChange={handleChange}
                    value={values.zip || ''}
                    InputLabelProps={{
                      style: {
                        fontFamily: 'Roboto',
                        fontSize: '14px',
                      },
                    }}
                  />
                </div>
              </div>
              <div className={classes.veryBottomfields}>
                <div style={{flex: 1, padding: '10px'}}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    id="referralCode"
                    label="Referral Code"
                    name="referralCode"
                    size="small"
                    // className={`input ${errors.zip && 'is-danger'}`}
                    onChange={handleChange}
                    value={values.referralCode || ''}
                    InputLabelProps={{
                      style: {
                        fontFamily: 'Roboto',
                        fontSize: '14px',
                      },
                    }}
                  />
                </div>
              </div>
              <div>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className={classes.signupbutton}
                  color="primary">
                  Sign Up
                </Button>

                <Link
                  className={classes.haveanaccount}
                  onClick={() => history.push('/LoginEmailPass')}>
                  Already have an account? Sign in
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </MuiThemeProvider>
  );
}

const mapStateToProp = (state) => {
  return {
    clinicBasicInfo: state.apiCallsReducer.clinicBasicInfo,
    clinicUsersList: state.apiCallsReducer.clinicUsersList,
    userInfo: state.apiCallsReducer.userInfo,
    clinicPatientsList: state.apiCallsReducer.clinicPatientsList,
    clinicTemplatesList: state.apiCallsReducer.clinicTemplatesList,
    clinicUsageInfo: state.apiCallsReducer.clinicUsageInfo,
    clinicLocationsList: state.apiCallsReducer.clinicLocationsList,
  };
};

export default connect(mapStateToProp, {submitFormToFirebase})(Signup);
